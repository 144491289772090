import Constants from 'src/utils/constants';
const intialState = {
    all_complaince: []
};
export const allComplaincereducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_COMPLAINCE:
      return { ...state, all_complaince: action.payload };
    default:
      return state;
  }
};