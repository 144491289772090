import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
const Other_Book_Return_Challan = () => {
  return (
    <>
        <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">Month</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Compliance Name</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Date of Filing </TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Attachment</TableCell>
            <TableCell align="center" sx={{display: isStartupFino ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableCell align="center">Gujrat</TableCell>
            <TableCell align="center">Jan</TableCell>
            <TableCell align="center">ESI</TableCell>
            <TableCell align="center">ESI Challan</TableCell>
            <TableCell align="center">15/02/2021</TableCell>
            <TableCell align="center">15/02/2021</TableCell>
            <TableCell align="center">1000</TableCell>
            <TableCell align="center">Attachment</TableCell>
            <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                <ModeEditIcon />
                <DeleteIcon sx={{color:'red',ml:2}}/>
            </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default Other_Book_Return_Challan