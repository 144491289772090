import { useTheme } from '@mui/material/styles';
import { Card, CardHeader, Container, Grid, TableRow, Typography } from '@mui/material';
import * as React from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PfChallan from './PfTables/PfChallan';
import { OutstandingEpfDemand, PfOutstandingDemand } from './PfTables/OutstandingEpfDemand';
import { PaidEpfDemand } from './PfTables/PaidEpfDemand';
import { ComplianceScore } from './ComplianceScore';
import BasicChart from './BasicChart';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { getPfChallans, getPfDemands, fetchDueDates } from 'src/redux/actions/pf';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isStartupFino,istalenteye } from 'src/config';
import PfbasicD from './PfDailog';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const years = ['2021-22', '2022-23', '2023-24'];

export default function PfDashboard() {
  const orgState = useSelector((state) => state.orgProfileReducer);
  const dispatch = useDispatch();
  const [year, setYear] = React.useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);
  let score_data = dashBoardScore?.[year];
  console.log('dashBoardScore', dashBoardScore?.[year], year);
  const pfcount = score_data !== null ? score_data?.pf_data.count : 0;
  const pffilled = score_data !== null ? score_data?.pf_data.ontime : 0;
  // const pfcount = dashBoardScore !== null ? dashBoardScore?.[year]?.pf_data.count : 0;
  // const pffilled = dashBoardScore !== null ? dashBoardScore?.[year]?.pf_data.ontime : 0;
  // const pf_data_score =
  //   parseInt(
  //     ((dashBoardScore?.pf_data?.filled - dashBoardScore?.pf_data?.delay) /
  //       dashBoardScore?.pf_data?.count) *
  //       100
  //   ) || 0;
  const pf_data_score =
    score_data?.pf_data?.count == 0
      ? 100
      : parseInt((score_data?.pf_data?.ontime / score_data?.pf_data?.count) * 100) || 0;
  const handleChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    dispatch(getPfChallans(companyId));
    dispatch(getPfDemands(companyId));
    dispatch(fetchDueDates());
  }, []);

  return (
    <>
      {!(isStartupFino||istalenteye) && <PfbasicD/>}
      <Card>
        <CardHeader title="PF Dashboard" />
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              // multiple
              value={year}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
              )}
              MenuProps={MenuProps}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <BasicChart title="PF" score={pf_data_score} />
            <Typography textAlign="center" mt={-2}>
              {/* {pffilled}/{pfcount} */}
              {score_data?.pf_data?.ontime}/ {score_data?.pf_data?.count}
              <br />
              Compliances cleared
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <br></br>
      <Card>
        <CardHeader title="PF Challan Paid" />
        {<PfChallan year={year} />}
      </Card>
      <br></br>
      <Card>
        <CardHeader title="Outstanding EPF Demand" />
        {<OutstandingEpfDemand year={year} />}
      </Card>
      <br></br>
      <Card>
        <CardHeader title="Paid EPF Demand" />
        <PaidEpfDemand year={year} />
      </Card>
    </>
  );
}
