import Constants from '../../utils/constants';

const intialState={
    tdsChallans:null,
    tdsReceipts:null,
    dueDates:[],
    tdsDemands:null,
    isLoading:false,
    error:null
}

export const tdsReducer=(state=intialState,action)=>{
    switch(action.type)
    {
        case Constants.SET_COMPANY_TDS_RECEIPT_START:
        case Constants.SET_DUE_DATES_START:
        case Constants.SET_COMPANY_TDS_CHALLAN_START:
        case Constants.SET_TDS_DEMAND_START:
            return {...state,isLoading:true}

    case Constants.SET_COMPANY_TDS_CHALLAN_SUCCESS:
      return { ...state, tdsChallans: action.payload, isLoading: false };

    case Constants.SET_COMPANY_TDS_RECEIPT_SUCCESS:
      return { ...state, tdsReceipts: action.payload, isLoading: false };

        case Constants.SET_DUE_DATES_SUCCESS:
            return {...state,dueDates:action.payload,isLoading:false}
        
        case Constants.SET_TDS_DEMAND_SUCCESS:
            return {...state,tdsDemands:action.payload,isLoading:false}

        case Constants.SET_COMPANY_TDS_CHALLAN_FAILURE:
        case Constants.SET_COMPANY_TDS_RECEIPT_FAILURE:
        case Constants.SET_DUE_DATES_FAILURE:
        case Constants.SET_TDS_DEMAND_FAILURE:
            return {...state,error:action.payload,isLoading:false}

    default:
      return state;
  }
};
