import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Typography, Chip } from '@mui/material';
import { Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSearchParams } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import Label from 'src/components/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MUIDataTable from 'mui-datatables';
import {
  allfillstat_patch,
  get_due_dates_by_period,
  get_due_dates_by_period_2,
  get_all_due_dates_company_id
} from 'src/redux/actions/fillstatAction';
import { CurrencyYenTwoTone, RawOff } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment, { months } from 'moment';
import { chunk } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import {
  add_Task,
  task_all_get,
  team_Mates_get,
  task_Names_get,
  deletetask,
  updatetask,
  client_mail_list
} from 'src/redux/actions/task_creation_Action';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import { red } from '@mui/material/colors';

export default function Add_task() {
  const task_Data = useSelector((state) => state.taskreducers);
  const teams = useSelector((state) => state.team_mates_reducers);
  const filling_data = useSelector((state) => state.allFillStatreducers);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const task_name = useSelector((state) => state.team_name_reducers);

  const [tasks_data, settasks_data] = useState([]);
  const [teamates_data, setteamates_data] = useState([]);
  const [task_names, setTask_names] = useState([]);

  useEffect(() => {
    dispatch(task_all_get());
    dispatch(team_Mates_get());
    dispatch(task_Names_get());
    dispatch(client_mail_list());
  }, []);
  useEffect(() => {
    settasks_data(task_Data.task_management);
  }, [task_Data]);
  useEffect(() => {
    setteamates_data(teams.team_mate);
  }, [teams]);
  useEffect(() => {
    setTask_names(task_name.task_name);
  }, [task_name]);

  const [extra, setExtra] = useState(null);
  const [tabdata, setTabdata] = useState([]);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [selectedID, setID] = useState('');
//   const [value, setValue] = useState(new Date());
  const [opt_value, setOpt_value] = useState(' ');
  const [open, setOpen] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [counter, setCounter] = useState(0);
  var companies_id;
  let data_total = filling_data.comp_fillstat.length;

  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const years = ['2020-21', '2021-22', '2022-23'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    //let a = 0;
    const now = new Date();
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
    dispatch(get_due_dates_by_period(lastDay, firstDay));
    dispatch(getAllCompanies());
    setTabdata(filling_data);
  }, []);

  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const handleCheckbox = (e, id) => {
    setOpen(true);
    setID(id);
  };
  const companys_name = all_companies.all_companies.map((option) => option.company_name);
  let arrays = [companys_name];

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    let b = 0;
    dispatch(get_due_dates_by_period(b));
  };
  const [opene, setOpene] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };

  const handleClosee = () => {
    setOpene(false);
  };
const client_emails = useSelector((state) => state.team_mates_reducers.client_emails);
const client_emails_list = client_emails.map((item) => item.client_mail);
const tsk_name = task_names.map((option) => option.task_name);
const team_name = teamates_data.map((option) => option.name);
const tsk_data = tasks_data.map((option) => option.sr_id);
const [value, setValue] = useState(null);
const [formData, setFormData] = useState({
  company_name: '',
  task_name: '',
  task_description: '',
  task_owner: '',
  task_assignee: '',
  due_date: '',
  billing_info: '',
  task_status: '',
  remark: '',
  billing_amount: '',
  client_mails: ''
});
const [Cname, setCname] = useState();
const [Fname, setFname] = useState();
const [Tdes, setTdes] = useState();
const [Binfo, setBinfo] = useState();
const [Tstat, setTstat] = useState();
const [remark, setRemark] = useState();
const [Bamount, setBamount] = useState();
const [Town, setTown] = useState();
const [Tassign, setTassign] = useState();
const [clientMail, setClientMail] = useState([]);
// const dispatch = useDispatch();
let name, valuee;
const [searchParams, setSearchParams] = useSearchParams();
const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

const handleFormDataChange = (e) => {
  name = e.target.name;
  valuee = e.target.value;
  setFormData({ ...formData, [name]: valuee });
};
const dis = (e) => {
  formData['company_id'] = all_companies.all_companies.filter(
    (item) => item.company_name === formData['company_name']
  )[0]['id'];
  const mail_list_last_item = formData.client_mails[formData.client_mails.length - 1];
  formData.client_mails = mail_list_last_item.join(',');
  formData.due_date = moment(formData.due_date).format('YYYY-MM-DD');
  let temp_name = formData.task_name;
  const opts = task_names.filter((x) => x.task_name.includes(temp_name));
  formData['task_id'] = opts[0].id;
  let temp_ass = formData.task_assignee;
  const opts_ass = teamates_data.filter((x) => x.name.includes(temp_ass));
  formData['task_assignee_id'] = opts_ass[0].id;
  let temp_own = formData.task_owner;
  const opts_own = teamates_data.filter((x) => x.name.includes(temp_own));
  formData['task_owner_id'] = opts_own[0].id;
  var bodyFormData = new FormData();
  for (let key in formData) {
    if ((key, formData[key] !== '')) {
      bodyFormData.append(key, formData[key]);
    }
  }
  dispatch(add_Task(bodyFormData));
  handleClosee();
  Swal.fire('Successfully!', 'Task has been successfully added', 'success');
};
  return (
    <>
      <h1>ADD Task</h1>
       <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Cname}
              name="company_name"
              onChange={(event, newValue) => {
                setCname(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={companys_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Fname}
              name="task_name"
              onChange={(event, newValue) => {
                setFname(newValue);
                {
                  formData.task_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={tsk_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              value={Tdes}
              name="task_description"
              fullWidth
              onChange={(e) => {
                setTdes(e.target.value);
                {
                  formData.task_description = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Task Description"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Town}
              name="task_owner"
              onChange={(event, newValue) => {
                setTown(newValue);
                {
                  formData.task_owner = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Owner" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tassign}
              name="task_assignee"
              onChange={(event, newValue) => {
                setTassign(newValue);
                {
                  formData.task_assignee = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Assigne" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                value={value}
                name="due_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setValue(newValue);
                  {
                    formData.due_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Binfo}
              name="billing_info"
              onChange={(event, newValue) => {
                setBinfo(newValue);
                {
                  formData.billing_info = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={['Billiable', 'Non Billiable', 'Reimbursement']}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Assigne" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="billing_amount"
              onChange={(e) => {
                setBamount(e.target.value);
                {
                  formData.billing_amount = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Bamount}
              label="Biling Amount"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tstat}
              name="task_status"
              onChange={(event, newValue) => {
                setTstat(newValue);
                {
                  formData.task_status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={[
                'Completed',
                'Initiated',
                'In the Process',
                'Data is Pending from Client',
                'Not Started',
                'Cancelled'
              ]}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Status of Task" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={remark}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              multiple
              name="client_mails"
              freeSolo
              options={client_emails_list}
              onChange={(event, newValue) => {
                setClientMail([...clientMail, newValue]);
                {
                  formData.client_mails = [...clientMail, newValue];
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Client Mail" placeholder="Enter mail id" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
}
