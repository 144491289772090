import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchProfitAndLossInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function ProfitAndLoss() {
  // const rocState = useSelector((state) => state.rocReducer);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchProfitAndLossInfo());
  // }, []);
  // if ((rocState.profitLossInfo = !null)) {
  //   return <></>;
  // }
  // const data = rocState.profitLossInfo[0];

  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .StatementOfProfitAndLoss
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  const fields = {
    Revenue: 'Operating Revenue',
    EmployeeBenefitExpense: 'EBDITA',
    // TotalBorrowings: 'Depreciation',
    // SecuredBorrowings: 'Interest',
    // UnsecuredBorrowings: 'Other Income',
    // TotalBorrowings: 'Total Revenue',
    // SecuredBorrowings: 'Total Expenses',
    ProfitBeforeTax: 'PBT',
    CurrentTax: 'Tax',
    // SecuredBorrowings: ' PAT',
    BasicEarningPerEquityShare: 'Earning Per Share (EPS)'
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {/* <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">2017 - 2018</TableCell>
            <TableCell align="center">2018 - 2019</TableCell>
            <TableCell align="center">2019 - 2020</TableCell>
          </TableRow>
        </TableHead> */}
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(fields).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return yearToDataMap[year][key] != null ? (
                  <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>
                ) : (
                  <TableCell key={index}>NA</TableCell>
                );
              })}
              {/* <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>;
              })} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
