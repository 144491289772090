import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
// import { getVaultData, updateVaultData } from 'src/redux/actions/loginVault';
// import LoginVoltPopup from 'src/components/LoginVoltPopup';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
// import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AddRegsitration } from './AddRegsitration';
import { useSearchParams } from 'react-router-dom';
import { Notifications } from './notifications/Notifications';
export default function organizationProfile() {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  return (
    <>
      <TableContainer component={Paper}>
        {/* <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Registration Portal</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">
                <TextField name="creadName" />
              </TableCell>
              <TableCell align="center">
                <TextField name="creadNum" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table> */}
        <AddRegsitration companyId={companyId} />
        <Notifications companyId={companyId} />
      </TableContainer>
    </>
  );
}
