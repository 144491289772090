import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { deleteVaultData, getVaultData, updateVaultData } from 'src/redux/actions/loginVault';
import LoginVoltPopup from 'src/components/LoginVoltPopup';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { addVaultData } from 'src/redux/actions/loginVault';
import { useConfirm } from 'material-ui-confirm';
import { ConfirmProvider } from "material-ui-confirm";

export default function Loginvault() {
  const companyState = useSelector((state) => state.orgProfileReducer);
  const vaultState = useSelector((state) => state.vaultReducer);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const [open, setOpen] = useState(false);
  const [credName, setCredName] = useState('');
  const [credNum, setCredNum] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const confirm = useConfirm();
  useEffect(() => {
    dispatch(getVaultData(companyId));
  }, []);

  useEffect(() => {
    console.log('hello');
    setData(vaultState.vault_data);
  }, [vaultState.vault_data]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    console.log(index);
    const tmp = data;
    tmp[index][name] = value;
    setData([...tmp]);
  };

  const handleSubmit = (data) => {
    dispatch(updateVaultData(data.id, data,companyId));
  };

  const handlemDelete = (row) => {
    confirm({ description: 'This will permanently delete ' })
    .then(() => {console.log("yes")
    dispatch(deleteVaultData(row.id,companyId));
  })
    .catch(() => { console.log("no")});
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = () => {
    if (credName !== '' && credNum !== '' && username !== '' && password !== '') {
      setOpen(false);
      const data = {
        creadName: credName,
        creadNum: credNum,
        isRegister: true,
        bookName: 'NA',
        username: username,
        password: password
      };

      dispatch(addVaultData(companyId, [data]))

      setCredName('');
      setCredNum('');
      setUsername('');
      setPassword('');
    } else {
      toast.error('All fields required');
    }
  };

  return (
    <>
      {/* {vaultState.isUpdated && <Alert severity="success">Successfully Updated!</Alert>} */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" color="success" onClick={handleClickOpen}>
            Add Credentials
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                '& th': {
                  fontSize: '1rem',
                  background: 'hsl(149,100%,33%)',
                  color: 'white'
                }
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Portal Name</TableCell>
                  <TableCell align="center">Number</TableCell>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Password</TableCell>
                  <TableCell align="center">Update</TableCell>
                  <TableCell align="center">Delete</TableCell>
                  {/* <TableCell align="center">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">
                        <TextField
                          name="creadName"
                          value={row.creadName}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          name="creadNum"
                          value={row.creadNum}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          name="username"
                          value={row.username}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          name="password"
                          value={row.password}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleSubmit(row)}
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <ConfirmProvider>
                          <Button 
                          variant="contained"
                          color="error"
                          onClick={() => handlemDelete(row)}>
                            Delete
                          </Button>
                        </ConfirmProvider>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Add Credentials'}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">Add Credentials</DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Credential Name*"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              value={credName}
              onChange={(e) => {
                setCredName(e.target.value);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Credential Number*"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              value={credNum}
              onChange={(e) => {
                setCredNum(e.target.value);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Username*"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              required={true}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Password*"
              type="password"
              fullWidth
              variant="standard"
              required={true}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleFormSubmit} autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
