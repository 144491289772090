import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import moment from 'moment';
import Label from 'src/components/Label';
import { dateFormat } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import { deleteesichallan, updateesiChallan } from 'src/redux/actions/esiBook';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { isStartupFino,istalenteye } from 'src/config';

export const EsiChallans = ({ challans }) => {
  const esiState = useSelector((state) => state.esiReducer);
  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (esiState.dueDates != null) {
      const dueDateObj = esiState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>ESI Paid Amount</TableCell>
            <TableCell>Challan Number</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Delay Days</TableCell>
            {/* <TableCell>Payment Bank</TableCell> */}
            <TableCell>Challan Receipt</TableCell>
            <TableCell>Contribution History</TableCell>
            <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {challans?.length > 0 &&
            challans
              .sort(function (a, b) {
                return (
                  new Date(getExpiryDate(a.complianceID, a.month, a.finance_year)) -
                  new Date(getExpiryDate(b.complianceID, b.month, b.finance_year))
                );
              })
              .map((row, i) => {
                const delay =
                  esiState.dueDates.length > 0
                    ? calDelay(
                      getExpiryDate(row.complianceID, row.month, row.finance_year),
                      row.payment_dates
                    )
                    : 0;
                return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.esi_paid_amt}</TableCell>
                    <TableCell>{row.challan_num}</TableCell>
                    <TableCell>{row.esi_type}</TableCell>
                    <TableCell>
                      {esiState.dueDates.length > 0
                        ? dateFormat(getExpiryDate(row.complianceID, row.month, row.finance_year))
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {row.payment_dates != null && dateFormat(row.payment_dates)}
                    </TableCell>
                    <TableCell>
                      <Label color={delay > 0 ? 'error' : 'success'}>{delay}</Label>
                    </TableCell>
                    {/* <TableCell>{row.payment_bank}</TableCell> */}
                    <TableCell>
                      <Link
                        variant="a"
                        href={row.receipt}
                        target="_blank"
                      // underline="none"
                      >
                        Receipt
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        variant="a"
                        href={row.contribution_history}
                        target="_blank"
                      // underline="none"
                      >
                        Contribution History
                      </Link>
                    </TableCell>
                    <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>
                      <Box textAlign="center" sx={{ display: 'flex' }}>
                        <Update row={row} />
                        <ConfirmProvider>
                          <Delete row={row} />
                        </ConfirmProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {challans
                ?.map((item) => parseInt(item.esi_paid_amt))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props) => {
  const { row } = props;
  console.log("Row",row.id);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deleteesichallan(row.id))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rows are", row);
  // const handleDelete = () => {
  //   confirm({ description: "This will permanently delete " })
  //     .then(() => {
  //       setNewrows(null);
  //       console.log("newrows are after delete ",newrows);
  //     })
  //     .catch(() => console.log("Deletion cancelled."));
  // };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <EsiChallanUpdate row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const EsiChallanUpdate = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row } = props;
  const [value, setValue] = useState(row.payment_dates);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    esi_type: '',
    esi_paid_amt: '',
    challan_num: '',
    payment_dates: '',
    // receipt: '',
    // contribution_history:'',
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState(row.finance_year);
  const [mon, setMon] = useState(row.month);
  const [esiType, setEsiType] = useState(row.esi_type);
  const [esiamount, setEsiamount] = useState(row.esi_paid_amt);
  const [challanno, setChallanno] = useState(row.challan_num);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    // setFormData({ ...formData, [name]: valuee });
    formData[`${name}`] = valuee
    console.log('File name', formData)
  }
  const dis = (e) => {
    console.log('form data', formData);
    // formData['complianceID'] = "22fb1e74-4170-44a9-a48e-c13ccf88e732"
    // formData['companyId'] = companyId
    // if(formData.contribution_history == '')
    //   {
    //     formData.contribution_history = row.contribution_history;
    //   }
    //   if(formData.receipt == '')
    //   {
    //     formData.receipt = row.receipt;
    //   }
    var bodyFormData = new FormData();
    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    console.log('form data', formData);
    dispatch(updateesiChallan(row.id, bodyFormData));
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
        <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
        <MenuItem value={"Monthly"}>Monthly</MenuItem>
        <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
        <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
        <MenuItem value={"Yearly"}>Yearly</MenuItem>
        </Select> */}
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required />}
        />
        <TextField
          value={esiamount}
          required
          name="esi_paid_amt"
          type="number"
          onChange={(e) => {
            setEsiamount(e.target.value);
            {
              formData.esi_paid_amt = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="ESI Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <Autocomplete
          value={esiType}
          name="esi_type"
          onChange={(event, newValue) => {
            setEsiType(newValue);
            {
              formData.esi_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={["Original", "Supplement"]}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="ESI Type" required />}
        />
        <TextField
          name="challan_num"
          required
          type="number"
          onChange={(e) => {
            setChallanno(e.target.value);
            {
              formData.challan_num = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          value={challanno}
          label="Challan Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date "
            value={value}
            name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <h4>Payment Challan Receipt</h4>
        <Box textAlign="right" >
          <a href={row.receipt} target="_blank">
            Payment Challan Receipt
          </a>
        </Box>
        <TextField required name='receipt' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <h4>Contribution History</h4>
        <Box textAlign="right" >
          <a href={row.contribution_history} target="_blank">
            Contribution History
          </a>
        </Box>
        <TextField required name='contribution_history' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};