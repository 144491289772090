import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import { useSelector } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, IconButton } from '@mui/material';
import { add_invoice, invoice_descriptions } from 'src/redux/actions/invoice_manage_action';
import moment from 'moment';
export default function Bill_Add(props) {
  const { handleClickClose, add } = props;
  const dispatch = useDispatch();
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const all_services = useSelector((state) => state.invoice_reducers);
  console.log(all_services, 'all_services');

  //opts
  const requesters = ['Deepak Malhotra', 'Karan Malhotra'];
  const status_opts = ['Paid', 'Partially Paid', 'Unpaid'];
  const billing_opts = [
    'RM Financial Consultancy',
    'LetsGoLegal Advisory Private Limited',
    'Accounts Matrix',
    'Suvidha Consultancy',
    'Karan Malhotra & Associates',
    'KDR Management Services'
  ];
  const bill_type_opts = ['Bill of Supply', 'Tax Invoice'];

  // useStates
  const [comp_name, setcomp_name] = useState();
  const [req_date, setreq_date] = useState(moment().format('YYYY-MM-DD'));
  console.log('upper_reqdate', req_date);
  const [req_by, setreq_by] = useState();
  const [gst_no, setgst_no] = useState();
  const [bill_ent, setbill_ent] = useState();
  const [in_no, setin_no] = useState();
  // const [in_desc, setin_desc] = useState();
  // const [in_amt, setin_amt] = useState();
  const [in_date, setin_date] = useState(null);
  const [pay_stat, setpay_stat] = useState();
  const [pay_rcvd, setpay_rcvd] = useState();
  const [tds_ded, settds_ded] = useState();
  const [out_amt, setout_amt] = useState();
  const [bill_types, setbill_types] = useState();
  const [in_remarks, setin_remarks] = useState();
  const [list_company_id, setlist_company_id] = useState();
  const [services_list, setservices_list] = useState();

  const [matched_company_id, setmatched_company_id] = useState();

  const [formValues, setFormValues] = useState([{ description: '', amount: '' }]);

  let addFormFields = () => {
    if (
      formValues[formValues.length - 1].description !== '' &&
      formValues[formValues.length - 1].amount !== ''
    ) {
      setFormValues([...formValues, { description: '', amount: '' }]);
    }
  };

  let removeFormFields = (index) => {
    let newFormValues = [...formValues];
    const new_arr = [];
    newFormValues.forEach((ele, i) => {
      if (index !== i) {
        new_arr.push(ele);
      }
    });
    setFormValues(new_arr);
  };

  let handleChange = (index, field_name, newValue) => {
    let newFormValues = [...formValues];
    newFormValues[index][field_name] = newValue;
    setFormValues(newFormValues);
  };

  const [formData, setFormData] = useState({
    company_name: '',
    request_date: '',
    request_by: '',
    gst_number: '',
    billing_entity: '',
    bill_type: '',
    invoice_description: [],
    remark: '',
    invoice_amoint: 0
  });
  let name, valuee;
  const opts = all_companies?.all_companies?.map((option) => option.company_name);
  const handleFormDataChange = (e) => {
    console.log("formData['company_name']", formData);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(formData, 'formNOdata');
  };
  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(invoice_descriptions());
  }, []);

  useEffect(() => {
    setservices_list(all_services.invoice_ser);
  }, [all_services]);

  useEffect(() => {
    setlist_company_id(all_companies.all_companies);
  }, [all_companies]);
  const [matched_xyz, setmatched_xyz] = useState(formData);

  const handleSumbit = () => {
    formData['company_id'] = matched_company_id?matched_company_id:'';
    formData.request_date = moment(new Date()).format('YYYY-MM-DD');
    formData.invoice_description = formValues;
    formData.invoice_amoint = formValues.reduce(function (acc, obj) {
      return acc + parseInt(obj.amount);
    }, 0);
    formData.remark = in_remarks;
    formData.request_by = req_by;
    formData.bill_type = 'Bill of Supply';
    dispatch(add_invoice(formData));
    handleClickClose();
  };
  console.log('formsforms', formData);
  // console.log('outside', matched_company_id);
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Autocomplete
              value={comp_name}
              name="company_name"
              fullWidth
              freeSolo
              display="flex"
              onInputChange={(event, newValue) => {
                setcomp_name(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  list_company_id.filter((item) => {
                    if (item.company_name === newValue) {
                      setmatched_company_id(item.id);
                    }
                  });
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
          {/* <Grid item lg={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Request Date"
                value={req_date}
                name="request_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setreq_date(newValue);
                  {
                    formData.request_date = moment(newValue).format('YYYY-MM-DD');
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} fullWidth />}
              />
            </LocalizationProvider>
          </Grid> */}
          <Grid item lg={6}>
            <Autocomplete
              value={req_by}
              name="request_by"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setreq_by(newValue);
                {
                  formData.request_by = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={requesters}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Request By" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              value={gst_no}
              fullWidth
              name="gst_number"
              onChange={(e) => {
                setgst_no(e.target.value);
                {
                  formData.gst_number = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="GST Number"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={bill_ent}
              name="billing_entity"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setbill_ent(newValue);
                {
                  formData.billing_entity = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={billing_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Billing Entity" />}
            />
          </Grid>
          {/* <Grid item xs={12} lg={4} md={4} xl={4}>
            <Autocomplete
              value={bill_types}
              fullWidth
              name="bill_type"
              display="flex"
              onInputChange={(event, newValue) => {
                setbill_types(newValue);
                {
                  formData.bill_type = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={bill_type_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Billing Type" />}
            />
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              value={in_remarks}
              fullWidth
              name="remark"
              onChange={(e) => {
                setin_remarks(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {formValues.map((element, index) => (
            <>
              <Grid item xs={6} md={6}>
                <Autocomplete
                  value={element.description}
                  name="description"
                  fullWidth
                  freeSolo
                  display="flex"
                  onInputChange={(_, newValue) => handleChange(index, 'description', newValue)}
                  id="controllable-states-demo"
                  options={services_list?.map((row) => row.service)}
                  sx={{ margin: 1 }}
                  renderInput={(params) => <TextField {...params} label="Invoice Description" />}
                />
              </Grid>
              <Grid item xs={2} md={4}>
                <TextField
                  value={element.amount}
                  fullWidth
                  name="amount"
                  type={'number'}
                  onChange={(e) => {
                    let newFormValues = [...formValues];
                    newFormValues[index][e.target.name] = e.target.value;
                    setFormValues(newFormValues);
                  }}
                  label="Invoice Amount"
                  variant="outlined"
                  sx={{ margin: 1 }}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <IconButton>
                  <Button type="button" onClick={() => addFormFields()}>
                    <i className="fa fa-plus" />
                  </Button>
                </IconButton>
                {index ? (
                  <IconButton>
                    <Button type="button" onClick={() => removeFormFields(index)}>
                      <i className="fa fa-times" area-hidden="true" />
                    </Button>
                  </IconButton>
                ) : (
                  ''
                )}
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={handleSumbit} variant="contained">
          Add Inovice
        </Button>
      </Box>
    </>
  );
}
