import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { isStartupFino,istalenteye } from 'src/config';
import { appName } from 'src/config';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  console.log("app",appName)
  return (
    <>
    {appName==="StartupFino" &&
    <RouterLink to="/">
      <img src="/static/Logo.png" sx={{ width: 100, height: 100, ...sx }} />
    </RouterLink>
    }
    {appName==="PeopleFino" &&
    <RouterLink to="/">
      <img src="/static/PeopleFino-logo3.png" 
      sx={{ width: 100, height: 100, ...sx,}} 
      />
    </RouterLink>
    }
    </>
  );
}
