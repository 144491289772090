import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function AnnualReturn() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution17092021_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">17-09-2021</TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">1.09</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC5.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC5">
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution17092021_1__COPYOFBOARDORSHARE
            </TableCell>
            <TableCell align="center">17-09-2021 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">1.29</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC6.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC6">
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution14092021_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">14-09-2021 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">1.15</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC7.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC7">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution21062021_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">21-06-2021 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">1.76</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC8.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC8">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution07042021_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">08-04-2021 </TableCell>
            <TableCell align="center"> Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">0.52</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC9.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC9">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution26032021_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">26-03-2021 </TableCell>
            <TableCell align="center"> Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center"> 0.83</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC10.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC10">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form MGT-7- 06032021_signed_FORMMGT7060320 21SIGNED.pdf
            </TableCell>
            <TableCell align="center">06-03-2021 </TableCell>
            <TableCell align="center">
              Form MGT - 7 - Form for filing annual return by a company.{' '}
            </TableCell>
            <TableCell align="center"> 2.51</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC11.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC11">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution12062020_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">12-06-2020 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center"> 0.35</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC12.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC12">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution06032020_COPYOFBOARDORSHAREHO
            </TableCell>
            <TableCell align="center">06-03-2020 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">0.79</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC13.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC13">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution27022020_COPYOFBOARDORSHAREHO
            </TableCell>
            <TableCell align="center">27-02-2020 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">0.50</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC14.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC14">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution24022020_COPYOFBOARDORSHAREHO
            </TableCell>
            <TableCell align="center">24-02-2020 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">2.07</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC15.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC15">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution27012020_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">27-01-2020 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">0.39</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC16.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC16">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form MGT-7- 31122019_signed_FORMMGT731122019 SIGNED.pdf
            </TableCell>
            <TableCell align="center">31-12-2019 </TableCell>
            <TableCell align="center">
              Form MGT - 7 - Form for filing annual return by a company.{' '}
            </TableCell>
            <TableCell align="center">2.43</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC17.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC17">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution20122019_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">20-12-2019 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution</TableCell>
            <TableCell align="center">0.22</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC18.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC18">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution30082019_COPYOFBOARDORSHAREHO
            </TableCell>
            <TableCell align="center">30-08-2019 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">0.36</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC19.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC19">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Board or Shareholdersresolution17082019_COPYOFBOARDORSHAREHOL
            </TableCell>
            <TableCell align="center">17-08-2019 </TableCell>
            <TableCell align="center">Copy of Board or Shareholders resolution </TableCell>
            <TableCell align="center">0.44</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC20.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC20">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form MGT-7- 29122018_signed_FORMMGT72912201 8SIGNED.pdf
            </TableCell>
            <TableCell align="center">29-12-2018 </TableCell>
            <TableCell align="center">
              Form MGT - 7 - Form for filing annual return by a company.{' '}
            </TableCell>
            <TableCell align="center">1.89</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC21.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC21">
                Download
              </a>
            </TableCell>
          </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
