import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { intialTdsRecieptFetching, updateSingleReciept , deletetdsreturn } from 'src/redux/actions/tds';
import { getCompanyId } from 'src/utils/common';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputLabel, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { dateFormat } from 'src/utils/common';
import Label from 'src/components/Label';
import { CollectionsOutlined } from '@mui/icons-material';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { isStartupFino, istalenteye } from 'src/config';

//import { toast } from 'react-toastify';




export default function ReturnFilling({ year, companyId }) {
  const state = useSelector((state) => state.orgProfileReducer);
  const tdsState = useSelector((state) => state.tdsReducer);
  const dispatch = useDispatch();
  const [data, setData] = useState(tdsState.tdsReceipts);

  useEffect(() => {
    dispatch(intialTdsRecieptFetching(companyId));
  }, []);

  useEffect(() => {
    if (tdsState.tdsReceipts == null) {
      setData(null);
    } else {
      setData(
        tdsState.tdsReceipts.sort(function (a, b) {
          if (givePrority(a.quarter) == givePrority(b.quarter)) {
            if (a.form_type === 'Revised' && b.form_type === 'Orignal') {
              return 1;
            } else {
              return -1;
            }
          }
          return givePrority(a.quarter) - givePrority(b.quarter);
        })
      );
    }
  }, [tdsState.tdsReceipts]);
  

  const handleAckChange = (index, event) => {
    console.log('hello');
    const list = [...data];
    list[index].acknowledgement = event.target.files[0];
    setData(list);
    console.log(data);
  };
  const handleReturnChange = (index, event) => {
    const list = [...data];
    list[index].return_file = event.target.files[0];
    setData(list);
    console.log(data);
  };

  const handleUpdate = (index) => {
    console.log('acknowledgement', data[index]);
    var bodyFormData = new FormData();
    if (data[index].acknowledgement != null) {
      bodyFormData.append('acknowledgement', data[index].acknowledgement);
    }
    if (data[index].return_file != null) {
      bodyFormData.append('return_file', data[index].return_file);
    }
    //dispatch(updateSingleReciept(data[index].id, bodyFormData));
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  if (data == null) {
    return <div></div>;
  } else {
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            },
            '& td': {
              fontSize: '0.7rem'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Quarter</TableCell>
              <TableCell>Form Name</TableCell>
              <TableCell>Statement Type</TableCell>
              <TableCell>Token Number</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Date of Filing</TableCell>
              <TableCell>Delay Days</TableCell>
              <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>Edit / Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => {
              if (year == row.finance_year) {
                return (
                  <Row
                    row={row}
                    i={i}
                    handleAckChange={handleAckChange}
                    //handleUpdate={handleUpdate}
                    handleReturnChange={handleReturnChange}

                  />

                );
              }
              {/*<TableCell>
                {data.map((row) => {
 
                })}
              </TableCell>*/}
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
const Delete = (props) => {
  const { row } = props;
//const { row } = props;
  const dispatch = useDispatch();
  //console.log("rows filling are", row);
  //const [newrows, setNewrows] = useState(row);
  //console.log("newrows are", newrows);
  // console.log("Challandata are",challansData);
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletetdsreturn(row.id,companyId))
        //console.log("newrows are after delete ", newrows);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}  >Delete</Button>
    </>
  );
}
const   Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rowsirrr are", row);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={handleClose} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
    <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <TdsreturnUpadte row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const TdsreturnUpadte = (props) => {
  const { row } = props;
  const [value, setValue] = useState(row.date_fill);
  const [formData, setFormData] = useState({
      finance_year: '',
      quarter: '',
      form_type: '',
      form_name: '',
      token_num: '',
      date_fill: '',
      return_status: ''
  });
  // const [formData, setFormData] = useState({
  //   finance_year: "",
  //   quarter: "",
  //   form_type: "",
  //   form_name: "",
  //   token_num: "",
  //   date_fill: "",
  //   return_status: "",
  //   return_file: "",
  //   acknowledgement: ""

  // });
  const formtype = ["Orignal", "Revised"];
  const formname = ["26Q", "24Q", "27Q", "27EQ"];
  const returnstatus = ["Processed with defaults", "Processed without defaults"];
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const quartermonth = ["Apr-June", "July-Sep", "Oct-Dec", "Jan-Mar"];

  const [quat, setQuat] = useState(row.quarter);
  const [finace, setFinace] = useState(row.finance_year);
  const [retstat, setRetstat] = useState(row.return_status);
  const [fromna, setFormna] = useState(row.form_name);
  const [formty, setFormty] = useState(row.form_type);
  const [tokno, setTokno] = useState(row.token_num);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log("HEllo WORLD");
  }
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    //console.log('acknowledgement', data[index]);
    // const bodyFormData = new FormData();
    formData[`${name}`] = valuee

  }
  const dis = (e) => {
    const bodyFormData = new FormData();
    // formData['complianceID']  = row.complianceID;
    // formData['companyId'] = companyId

    console.log('formData', formData)

    for (let key in formData) {
      if (formData[key] !== '' ) {
        bodyFormData.append(key, formData[key])
      }
    }

    // console.log('form data =>', formData);
    // console.log('form data = >', bodyFormData);
      dispatch(updateSingleReciept(row.id,bodyFormData,companyId));

  };



  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

        {/*tds return : Financial Year*/}
        <Autocomplete
          value={finace} name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        {/*tds return : Quarter */}
        <Autocomplete
          value={quat} name="quarter"
          onChange={(event, newValue) => {
            setQuat(newValue);
            {
              formData.quarter = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={quartermonth}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Quarter" required />}
        />
        {/*tds return : Form Type */}
        <Autocomplete
          value={formty} name="form_type"
          onChange={(event, newValue) => {
            setFormty(newValue);
            {
              formData.form_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={formtype}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="From Type" required />}
        />

        {/*tds return : Form Name */}
        <Autocomplete
          value={fromna} name="form_name"
          onChange={(event, newValue) => {
            setFormna(newValue);
            {
              formData.form_name = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={formname}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="From Name" required />}
        />
        {/*tds return : token number */}
        <TextField
          value={tokno} name="token_num"
          onChange={(e) => {
            setTokno(e.target.value);
            {
              formData.token_num = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Token Number" variant="outlined" sx={{ margin: 1 }} required />

        {/*tds return : Date Of filling */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Filling"
            value={value} name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>

        {/*tds return : Tds return status */}
        <Autocomplete
          value={retstat} name="return_status"
          onChange={(event, newValue) => {
            setRetstat(newValue);
            {
              formData.return_status = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={returnstatus}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Return Status" required />}
        />

        {/*tds return : Tds return file */}

        <h5>TDS Return</h5>
        <Box  textAlign="right" >
            <a href={row.return_file} target="_blank">
             TDS Return 
            </a>
          </Box>

        <TextField required name='return_file' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />

        {/*tds return : Tds Ack. file */}

        <h5>TDS Return Acknowledgment</h5>
        <Box  textAlign="right" >
            <a href={row.acknowledgement} target="_blank">
            Acknowledgement
            </a>
          </Box>

        <TextField required name='acknowledgement' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>

      {/*tds return : Tds Save */}

      <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={dis} variant="contained">Update</Button></Box>
    </>
  );

};



const Input = styled('input')({
  display: 'none'
});

const styles = {
  hidden: {
    display: 'none'
  },
  importLabel: {
    color: 'white'
  }
};

export const calDelay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const DiffTime = Math.abs(d2 - d1);
  const DiffDays = Math.ceil(DiffTime / (1000 * 60 * 60 * 24));
  console.log(DiffDays);
  if (d2 > d1) {
    return DiffDays;
  }
  return 0;
};

const givePrority = (data) => {
  switch (data) {
    case 'Apr-June':
      return 0;
    case 'July-Sep':
      return 1;
    case 'Oct-Dec':
      return 2;
    default:
      return 3;
  }
};
const Row = (props) => {
  const { row, i, handleAckChange, handleReturnChange } = props;
  const [open, setOpen] = React.useState(false);
  const tdsState = useSelector((state) => state.tdsReducer);

  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (tdsState.dueDates != null) {
      const dueDateObj = tdsState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };
  const delay =
    tdsState.dueDates.length > 0
      ? calDelay(getExpiryDate(row.complianceID, row.quarter, row.finance_year), row.date_fill)
      : 0;
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.quarter}</TableCell>
        <TableCell>{row.form_name}</TableCell>
        <TableCell>{row.form_type}</TableCell>
        <TableCell>{row.token_num}</TableCell>
        {/* <TableCell>{dateFormat(tdsState.dueDates[row.complianceID])}</TableCell> */}
        <TableCell>
          {tdsState.dueDates.length > 0
            ? dateFormat(getExpiryDate(row.complianceID, row.quarter, row.finance_year))
            : '-'}
        </TableCell>
        <TableCell>{dateFormat(row.date_fill)}</TableCell>
        <TableCell>
          <Label
            variant="ghost"
            color={(delay > 0 && row.form_type !== 'Revised' && 'error') || 'success'}
          >
            {row.form_type === 'Revised' ? 0 : delay}
          </Label>
        </TableCell>
        <TableCell>
        <Box textAlign="center"  sx={{display: isStartupFino||istalenteye ? 'none': 'flex'}}>
                    <Update row={row} />
                    <ConfirmProvider>
                    <Delete row={row} />
                    </ConfirmProvider>
                  </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                More Return Details
              </Typography> */}
              <Table
                size="small"
                aria-label="purchases"
                sx={{
                  '& th': {
                    background: 'white',
                    color: 'black'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Return Status</TableCell>
                    
                   
                    <TableCell  colSpan={2}>Return Upload</TableCell>
                    <TableCell colSpan={2} >Acknowledgement</TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: row.return_status === 'Processed with defaults' ? 'red' : 'green'
                      }}
                    >
                      {row.return_status}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {tdsState.tdsReceipts[i].return_file === null && (
                          
                          <h5>
                          Not Uploaded
                        </h5>
                          
      )}

                      {tdsState.tdsReceipts && tdsState.tdsReceipts[i].return_file && (
                        <TableCell>
                        <Link
                          variant="a"
                          href={row.return_file}
                          target="_blank"
                          // underline="none"
                        >
                          View Returns
                        </Link>
                      </TableCell>
                      )}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {tdsState.tdsReceipts && tdsState.tdsReceipts[i].acknowledgement === null && (
                       
                        <h5>
                        Not Uploaded
                      </h5> 
                      )}
                        


                      {tdsState.tdsReceipts && tdsState.tdsReceipts[i].acknowledgement && (
                        <TableCell>
                        <Link
                          variant="a"
                          href={row.acknowledgement}
                          target="_blank"
                          // underline="none"
                        >
                         View Acknowledgement
                        </Link>
                      </TableCell>
                      )}
                    </TableCell>
                    {/*<TableCell>
                      <Button
                        variant="contained"
                        component="span"
                        onClick={(event) => handleUpdate(i)}
                      >
                        Update
                      </Button>
                      </TableCell>*/}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

