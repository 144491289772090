import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function CompanyCharge() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              Charge ID :(100463072 )
              <br /> <br /> <br />
              STATE BANK OF INDIA
              <br />
              ₹120.00 Lakhs
              <br /> <br />
              Attachment Description :<br />
              Term Deposit Advice
              <br />
              List of attachments:
              <br />
              FD for FC in 2021.pdf
              <br />
              FD for CEl Limit.pdf
              <br />
              Download Charge Form:
              <br />
              20100463072_ChargeForm
            </TableCell>
            <TableCell align="center">Creation Date :17-05-2021 </TableCell>
            <TableCell align="center">Modified : False</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left"></TableCell>
            <TableCell component="th" scope="row">
              Consortium Finance : NO
            </TableCell>
            <TableCell align="center">Consortium Finance : NO</TableCell>
          </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
