import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Grid, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
export const AddRegsitration = ({ companyId }) => {
  const [registration, setRegistration] = useState('GST');
  const [formData, setFormData] = useState({
    creadNum: '',
    username: '',
    password: '',
    date_of_registration: null
  });

  const handleChange = (event) => {
    setRegistration(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '50%',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  const handleAddRegistration = () => {
    const data = [
      {
        creadName: registration,
        creadNum: formData.creadNum,
        isRegister: true,
        date_of_registration: formData.date_of_registration?.toISOString().slice(0, 10),
        username: formData.username,
        password: formData.password
      }
    ];
    HttpWrapper('POST', `/company/company-book/${companyId}/`, false, data)
      .then((res, err) => {
        handleClose();
        toast.success('Registration Added Successfully');
      })
      .catch((err) => {
        toast.error('Not Able To Add Registration');
      });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddRegistration();
          }}
        >
          <Box sx={style}>
            <FormControl fullWidth>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Number"
                  name="creadNum"
                  value={formData.creadNum}
                  onChange={handleFormDataChange}
                />
                <TextField
                  fullWidth
                  label="User Name"
                  name="username"
                  value={formData.username}
                  onChange={handleFormDataChange}
                />
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleFormDataChange}
                  type="password"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="year"
                    views={['year', 'month', 'day']}
                    label="Date of Registration"
                    name="date_of_registration"
                    value={formData.date_of_registration}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    onChange={(newValue) => {
                      setFormData({ ...formData, date_of_registration: newValue });
                    }}
                    // disabled={!orgProfileInfo.haveGst}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
                <Button fullWidth size="large" type="submit" variant="contained">
                  Add
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </form>
      </Modal>

      <Box sx={{ m: 2, minWidth: 200 }}>
        {/* <FormControl> */}
        <Grid container spacing={2}>
        {/* <Grid item md={2}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={registration}
              label="Add Registration"
              onChange={handleChange}
            >
              <MenuItem value={'GST'}>GST</MenuItem>
              <MenuItem value={'PF'}>PF</MenuItem>
              <MenuItem value={'ESIC'}>ESIC</MenuItem>
            </Select>
          </Grid> */}
          <Grid item md={2} center>
            {/* <Button variant="contained" onClick={handleClick}>
              Add
            </Button> */}
          </Grid>
        </Grid>
        {/* </FormControl> */}
      </Box>
    </>
  );
};
