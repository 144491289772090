import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadLink from 'react-download-link';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardHeader } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { alpha, styled } from '@mui/material/styles';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
  // color: theme.palette.primary.darker,
  //   backgroundColor: theme.palette.primary.lighter
}));

export default function AuditedFinancial() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      const tmp =
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDocs.Document;
      setdata(tmp);
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  const categories = Array.from(new Set(data.map((row) => row.DocumentCategory)));
  const countOfCat = categories.map((category) => {
    return {
      name: category,
      count: data.reduce((acc, cur) => (cur.DocumentCategory === category ? ++acc : acc), 0)
    };
  });
  const docs = categories.map((catogery, index1) => {
    return (
      <>
        <Card key={index1} id={catogery}>
          <CardHeader
            style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
            title={catogery}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell align="center">Filing Date</TableCell>
                  {/* <TableCell align="center">Description</TableCell> */}
                  <TableCell align="center">Size MB</TableCell>
                  <TableCell align="center">Download</TableCell>
                  <TableCell align="center">Attachments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index2) => {
                  if (row.DocumentCategory === catogery) {
                    return (
                      <TableRow
                        key={index2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.DocumentName}
                        </TableCell>
                        <TableCell align="center">{row.DocumentFillingDate}</TableCell>
                        {/* <TableCell align="center">
                      {row.}
                    </TableCell> */}
                        <TableCell align="center">{row.DocumentSize}</TableCell>
                        <TableCell align="center">
                          <a
                            target="_blank"
                            href={row.DocumentLink}
                            // download="ROC1"
                          >
                            Download
                          </a>
                        </TableCell>
                        {row.AllAttachmentsZippedLink != null ? (
                          <TableCell align="center">
                            <a
                              target="_blank"
                              href={row.AllAttachmentsZippedLink}
                              // download="ROC1"
                            >
                              Download
                            </a>
                          </TableCell>
                        ) : (
                          <TableCell align="center">NA</TableCell>
                        )}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <br />
      </>
    );
  });
  return (
    <>
      <Grid container spacing={3}>
        {countOfCat.map((row) => {
          return (
            <Grid item xs={12} sm={6} md={3}>
              <a href={`#${row.name}`} style={{ textDecoration: 'none', color: 'white' }}>
                <RootStyle
                  style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
                  sx={{ height: '150px' }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography>{row.name}</Typography>
                      <Typography variant="h5">{row.count}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Iconify
                        icon="carbon:ibm-watson-orders"
                        width={25}
                        height={25}
                        style={{ color: '#a8dadc' }}
                      />
                    </Grid>
                  </Grid>
                </RootStyle>
              </a>
            </Grid>
          );
        })}
      </Grid>
      <br />
      {docs}
    </>
  );
}
