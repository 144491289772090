import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';


export const get_due_dates_by_period = (last,first) => (dispatch)=>{
  toast.info('API Call is in Progress', {autoClose: false})
  trackPromise(
    HttpWrapper('GET', `/compliance/due-dates-page/?is_applicable=true&period_end_date__lte=${last}&period_start_date__gte=${first}&due_date_applicable=true`)
    .then((res) => {
      toast.dismiss()
      dispatch({
        type: constants.SET_ALL_FILLSTAT,
        payload: res.data
      });
    })
    .catch((err) => {
      toast.dismiss()
      toast.error('Some error with API')
      dispatch({
        type: constants.SET_ALL_FILLSTAT,
        payload: []
      });
    })
  );
  
  }
  export const get_due_dates_by_period_2 = (last,first) => (dispatch)=>{
    toast.info('API Call is in Progress', {autoClose: false})
    HttpWrapper('GET', `/compliance/due-dates-page/?is_applicable=true&period_end_date__lte=${last}&period_start_date__gte=${first}&due_date_applicable=true`)
      .then((res) => {
        toast.dismiss()
        dispatch({
          type: constants.SET_ALL_FILLSTAT,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_ALL_FILLSTAT,
          payload: []
        });
      })
    } 
    export const get_all_due_dates_company_id = (company_id,opt_value) => (dispatch)=>{
      toast.success(`Requesting Filling status for ${opt_value}`,{autoClose: false});
      HttpWrapper('GET', `/compliance/due-dates-page/?company_id=${company_id}&due_date_applicable=true`)
      
      .then((res) => {
        toast.dismiss()
        dispatch({
          type: constants.SET_ALL_FILLSTAT,
          payload: res.data,
        });
        console.log("hello i am called calling")
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_ALL_FILLSTAT,
          payload: []
        });
      })
      ;
    
    }   


  export const allfillstat_patch = (due_date_id, data) => (dispatch) => {
    console.log(data);
    HttpWrapper('PATCH', `/compliance/due-dates/${due_date_id}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Updated Internal Filling');
        dispatch(get_due_dates_by_period())
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // export const allfillstat_gets_Data = () => (dispatch)=>{
  //   HttpWrapper('GET',`http://api.startupfino.in/compliance/due-dates-page/?due_date_applicable=true&is_applicable=true&limit=20+20&offset=0`)
  //   .then((res) => {
  //     dispatch({
  //       type: constants.SET_ALL_FILLSTAT,
  //       payload: res.data
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: constants.SET_ALL_FILLSTAT,
  //       payload: []
  //     });
  //   });
  
  // }


 