import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { getToken } from 'src/utils/common';
import MUIDataTable from 'mui-datatables';
import RemoveIcon from '@mui/icons-material/Remove';

const ExpandButton = ({
  areAllRowsExpanded,
  buttonClass,
  expandableRowsHeader,
  expandedRows,
  iconClass,
  iconIndeterminateClass,
  isHeaderCell,
  onExpand
}) => {
  return (
    <>
      {isHeaderCell &&
      !areAllRowsExpanded() &&
      areAllRowsExpanded &&
      expandedRows.data.length > 0 ? (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}
        >
          <RemoveIcon id="expandable-button" className={iconIndeterminateClass} />
        </IconButton>
      ) : (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}
        >
          <KeyboardArrowRightIcon id="expandable-button" className={iconClass} />
        </IconButton>
      )}
    </>
  );
};

const MailSender = () => {
  const authState = useSelector((state) => state.authReducer);
  console.log('AccessKey', localStorage.getItem('token'));

  const [companyName, setCompanyName] = useState('');
  const [contacts, setContacts] = useState([{ name: '', number: '', email: '' }]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [BoolSend, setbool] = useState(false);
  const [datas, setDatas] = useState([]);

  const handleAddContact = () => {
    setContacts([...contacts, { name: '', number: '', email: '' }]);
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const handleProceed = () => {
    // Open the confirmation dialog
    setDialogOpen(true);
  };

  const handleConfirmSendMail = () => {
    setbool(false);
    setDialogOpen(false);
    // Prepare data for the API request
    const requestData = {
      company_name: companyName,
      directors_details: contacts.map((contact) => ({
        name: contact.name,
        email: contact.email,
        phone: contact.number
      }))
    };

    // Make the API request
    axios
      .post('https://api.startupfino.in/email-service/company_details/', requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        }
      })
      .then((response) => {
        console.log('API Response Success:', response.data);

        // Close the confirmation dialog
        setDialogOpen(false);
        refdata();
        toast.success('Added Successfully');

        // You may want to add additional logic here based on the API response
      })
      .catch((error) => {
        console.error('API Error:', error);

        // You may want to handle errors and provide feedback to the user
      });
  };

  const canProceed =
    companyName.trim() !== '' &&
    contacts.every(
      (contact) =>
        contact.name.trim() !== '' && contact.number.trim() !== '' && contact.email.trim() !== ''
    );
  const [sentEmails, setSentEmails] = useState([]);
  const refdata = () => {
    axios
      .get('https://api.startupfino.in/email-service/company_details_list/', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then((response) => {
        setSentEmails(response.data.results);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };
  useEffect(() => {
    // Fetch sent emails data from the API
    axios
      .get('https://api.startupfino.in/email-service/company_details_list/', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then((response) => {
        setSentEmails(response.data.results);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  }, []);
  console.log('sentEmails', sentEmails);
  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    expandableRows: true,
    renderExpandableRow: (rowData, tableMeta) => {
      console.log('tableMeta', tableMeta);
      const colSpan = rowData.length + 1;
      return (
        <>
          <TableCell colSpan={colSpan} sx={{borderRadius:'30px'}}>
              <Table
                sx={{
                  m:1,
                  width:550,
                  '& th': {
                    fontSize: '1rem',
                    border:'1px solid #000000',
                  },
                  '& td': {
                    fontSize: '1rem',
                    border:'1px solid #000000',
                  }
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {console.log(
                    'xyzsentEmailssentEmails',
                    sentEmails[tableMeta.dataIndex].directors_details.map((item) => item.name)
                  )}
                  {sentEmails[tableMeta.dataIndex].directors_details.map((item) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.phone}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>

          </TableCell>
        </>
      );
    }
  };
  const columns = [
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'send_on',
      label: 'Sent At',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'no_direct',
      label: 'Number of Director',
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  useEffect(() => {
    const data = sentEmails?.map((email) => {
      return {
        name: email.company_name,
        send_on: moment(email.modified_at).format('DD-MM-YYYY'),
        no_direct: email.directors_details?.length
      };
    });
    setDatas(data);
  }, [sentEmails]);
  console.log('data', sentEmails);
  return (
    <>
      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={(e) => {
          setbool(true);
        }}
      >
        Add company
      </Button>
      {BoolSend && (
        <Grid container spacing={2}>
          {/* Company Name TextField */}
          <Grid item xs={12}>
            <TextField
              label="Company Name"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>

          {/* Contacts Section */}
          {contacts.map((contact, index) => (
            <Grid container item spacing={2} key={index}>
              {/* Name TextField */}
              <Grid item xs={4}>
                <TextField
                  label="Name"
                  fullWidth
                  value={contact.name}
                  onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                />
              </Grid>

              {/* Number TextField */}
              <Grid item xs={3}>
                <TextField
                  label="Number"
                  fullWidth
                  value={contact.number}
                  onChange={(e) => handleContactChange(index, 'number', e.target.value)}
                />
              </Grid>

              {/* Email TextField */}
              <Grid item xs={3}>
                <TextField
                  label="Email"
                  fullWidth
                  value={contact.email}
                  onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                />
              </Grid>

              {/* Add and Remove Buttons */}
              <Grid item xs={1}>
                {index === 0 ? (
                  <IconButton onClick={handleAddContact}>
                    <Add />
                  </IconButton>
                ) : (
                  <>
                    <IconButton onClick={() => handleAddContact()}>
                      <Add />
                    </IconButton>
                    <IconButton onClick={() => handleRemoveContact(index)}>
                      <Delete />
                    </IconButton>
                  </>
                )}
              </Grid>

              {/* Divider */}
              {index < contacts.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          ))}

          {/* Proceed Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProceed}
              disabled={!canProceed}
            >
              Proceed to Send Mail
            </Button>
          </Grid>

          {/* Confirmation Dialog */}
          <Dialog
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            PaperProps={{
              style: {
                maxWidth: 'lg' // or 'lg' or 'xl' based on your preference
              }
            }}
          >
            <DialogTitle>Kindly Verify the Details For : {companyName}</DialogTitle>
            <DialogContent>
              <div style={{ marginBottom: '3px' }}>
                <strong>Company Name:</strong> {companyName}
              </div>
              {contacts.map((contact, index) => (
                <div key={index}>
                  {index == 0 && <Divider />}
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <div>
                      <strong>Director Name:</strong> {contact.name}
                    </div>
                    <Box display="flex" flexDirection={'row'}>
                      <div style={{ marginRight: '10px', marginTop: '3px' }}>
                        <strong>Number:</strong> {contact.number}
                      </div>
                      <div style={{ marginLeft: '5px', marginTop: '3px' }}>
                        <strong>Email:</strong> {contact.email}
                      </div>
                    </Box>
                  </Box>
                  <Divider />
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} variant="contained" color="success">
                Cancel
              </Button>
              <Button onClick={handleConfirmSendMail} variant="contained" color="error">
                Yes, I confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
      <Typography variant="h4" gutterBottom>
        Sent Emails
      </Typography>
      <MUIDataTable options={options} columns={columns} data={datas} />
    </>
  );
};

export default MailSender;
