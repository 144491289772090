import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';

export const allcompdemData_get = (a) => (dispatch)=>{
    HttpWrapper('GET', `/company/consolidated-data/?limit=${a}`)
    .then((res) => {
      dispatch({
        type: constants.SET_ALL_COMPDEMDATA,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_ALL_COMPDEMDATA,
        payload: []
      });
    });
  
  }