// material
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography } from '@mui/material';
// utils
import Iconify from '../../../../components/Iconify';
import { calIntrestSum } from '../MainPageComponents/util';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
  //color: theme.palette.primary.darker,
  //backgroundColor: theme.palette.primary.darker
}));

export default function TotalIncome({ data, year }) {
  return (
    <>
      <a href="#totalInterest" style={{ textDecoration: 'none', color: 'white' }}>
        <RootStyle style={{ backgroundColor: '#6ede8a', color: 'white' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Typography>
                Total Paid
                <br />
                Penalties
              </Typography>
              <Typography variant="h5">₹ {Math.trunc(calIntrestSum(data, year))}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Iconify icon="dashicons:money-alt" width={25} height={25} />
            </Grid>
          </Grid>
        </RootStyle>
      </a>
    </>
  );
}
