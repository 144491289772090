import React from 'react';
import { Stack, TextField, Modal, Button } from '@mui/material';
import { useState } from 'react';
import validator from 'validator';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getNotificationsData } from 'src/redux/actions/profile';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const AddNotification = ({ open, handleClose, companyId }) => {
  const [role, setRole] = useState([
    {
      userName: '',
      userName: '',
      userRole: '',
      userMobile: ''
    }
  ]);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleRoleDataChange = (e) => {
    const { name, value } = e.target;
    const roleData = [...role];
    roleData[0][name] = value;
    setRole(roleData);
    if (name === 'userEmail') {
      setEmailError(!validator.isEmail(value));
    }

    if (name === 'userMobile') {
      setPhoneError(!validator.isMobilePhone(value, 'en-IN'));
    }
  };
  const dispatch = useDispatch();
  const handleAddRole = () => {
    HttpWrapper('POST', `/company/notifications/${companyId}/`, false, role)
      .then((res, err) => {
        handleClose();
        dispatch(getNotificationsData(companyId));
        toast.success('Role Added Successfully');
      })
      .catch((err) => {
        toast.error('Not Able To Add Role');
      });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddRole();
          }}
        >
          <Stack spacing={3} sx={style}>
            <TextField
              fullWidth
              label="Name"
              name="userName"
              value={role[0].userName}
              onChange={handleRoleDataChange}
              required
            />
            <TextField
              fullWidth
              label="Role"
              name="userRole"
              value={role[0].userRole}
              onChange={handleRoleDataChange}
              required
            />
            <TextField
              error={emailError}
              type="email"
              fullWidth
              label="Email"
              name="userEmail"
              value={role[0].userEmail}
              onChange={handleRoleDataChange}
              required
            />
            <TextField
              error={phoneError}
              fullWidth
              type="tel"
              label="WhatsApp Mobile Number"
              name="userMobile"
              value={role[0].userMobile}
              onChange={handleRoleDataChange}
              required
            />
            <Button fullWidth size="large" type="submit" variant="contained">
              Next Step
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
