import Constants from '../../utils/constants';
const intialState = {
  rocData: null,
  companyBasicInfo: null,
  kycInfo: null,
  directors: null,
  signatories: null,
  balanceSheetInfo: null,
  corporateCompanyInfo: null,
  financialInfo: null,
  schedulesBreakupInfo: null,
  ownershipInfo: null,
  borrowingsInfo: null,
  companyChargesInfo: null,
  credtRatingsInfo: null,
  profitLossInfo: null,
  cashFlowInfo: null,
  ratioAnalysisInfo: null,
  revenueInfo: null,
  financeCostInfo: null,
  expenseInfo: null,
  contingementInfo: null,
  schedulesBorrowingsInfo: null,
  loansAdvancesInfo: null,
  debtorsInfo: null,
  miscDisclosureInfo: null,
  relatedPartyInfo: null,
  auditorsDetailInfo: null,
  auditorsReportInfo: null,
  caroInfo: null,
  establishmentInfo: null,
  employeeInfo: null,
  chargeSearchInfo: null
};

export const rocReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_COMPANY_BASIC_INFO:
      return { ...state, companyBasicInfo: action.payload };
    case Constants.SET_KYC_INFO:
      return { ...state, kycInfo: action.payload };
    case Constants.SET_CORPORATE_INFO:
      return { ...state, corporateCompanyInfo: action.payload };
    case Constants.SET_FINANCIAL_INFO:
      return { ...state, financialInfo: action.payload };
    case Constants.SET_SCHEDULE_BREAKUP_INFO:
      return { ...state, schedulesBreakupInfo: action.payload };
    case Constants.SET_OWNERSHIP_INFO:
      return { ...state, ownershipInfo: action.payload };
    case Constants.SET_BORROWINGS_INFO:
      return { ...state, borrowingsInfo: action.payload };
    case Constants.SET_COMPANY_CHARGES_INFO:
      return { ...state, companyChargesInfo: action.payload };
    case Constants.SET_CREDIT_RATINGS_INFO:
      return { ...state, credtRatingsInfo: action.payload };
    case Constants.SET_PROFITLOSS_INFO:
      return { ...state, profitLossInfo: action.payload };
    case Constants.SET_BALANCESHEET_INFO:
      return { ...state, balanceSheetInfo: action.payload };
    case Constants.SET_CASH_FLOW_INFO:
      return { ...state, cashFlowInfo: action.payload };
    case Constants.SET_RATIO_ANALYSIS_INFO:
      return { ...state, ratioAnalysisInfo: action.payload };
    case Constants.SET_REVENUE_INFO:
      return { ...state, revenueInfo: action.payload };
    case Constants.SET_FINANCE_COST_INFO:
      return { ...state, financeCostInfo: action.payload };
    case Constants.SET_EXPENSE_INFO:
      return { ...state, expenseInfo: action.payload };
    case Constants.SET_CONTINGEMENT_INFO:
      return { ...state, contingementInfo: action.payload };
    case Constants.SET_SCHEDULES_BORROWINGS_INFO:
      return { ...state, schedulesBorrowingsInfo: action.payload };
    case Constants.SET_LOANS_ADVANCES_INFO:
      return { ...state, loansAdvancesInfo: action.payload };
    case Constants.SET_DEBTORS_INFO:
      return { ...state, debtorsInfo: action.payload };
    case Constants.SET_MISC_DISCLOSURE_INFO:
      return { ...state, miscDisclosureInfo: action.payload };
    case Constants.SET_RELATED_PARTY_INFO:
      return { ...state, relatedPartyInfo: action.payload };
    case Constants.SET_AUDITORS_DETAIL_INFO:
      return { ...state, auditorsDetailInfo: action.payload };
    case Constants.SET_AUDITORS_REPORT_INFO:
      return { ...state, auditorsReportInfo: action.payload };
    case Constants.SET_CARO_INFO:
      return { ...state, caroInfo: action.payload };
    case Constants.SET_ESTABLISHMENT_INFO:
      return { ...state, establishmentInfo: action.payload };
    case Constants.SET_EMPLOYEE_TREND:
      return { ...state, employeeInfo: action.payload };
    case Constants.SET_CHARGE_SEARCH_INFO:
      return { ...state, chargeSearchInfo: action.payload };
    case Constants.SET_CHARGE_DETAIL_INFO:
      return { ...state, chargeDetailInfo: action.payload };
    case Constants.SET_DIRECTORS:
      return { ...state, directors: action.payload };
    case Constants.SET_SIGNATORIES:
      return { ...state, signatories: action.payload };
    case Constants.SET_ROC_DATA:
      return { ...state, rocData: action.payload };
    default:
      return state;
  }
};
