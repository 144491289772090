import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useSearchParams } from 'react-router-dom';
import { addpf, deletepfchallan, updatepfChallan } from 'src/redux/actions/pf';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { dateFormat } from 'src/utils/common';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { calDelay } from '../TdsTables/ReturnFilling';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino,istalenteye } from 'src/config';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

export default function PfChallan(props) {
  const { year } = props;
  const pfState = useSelector((state) => state.pfReducer);
  const [challansData, setChallansData] = useState(pfState.challans);
  const [open, setOpen] = useState(false);
  // const pfState = useSelector((state) => state.pfReducer);
  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (pfState.dueDates != null) {
      const dueDateObj = pfState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setChallansData(pfState.challans);
  }, [pfState.challans]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Month</TableCell>
            <TableCell>Amount</TableCell>

            <TableCell>Due Date</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Delay Days</TableCell>
            <TableCell>Challan Receipt</TableCell>
            <TableCell>Acknowledgement </TableCell>
            <TableCell>ECR Copy</TableCell>
            <TableCell sx={{ display: isStartupFino||istalenteye ? 'none' : '' }}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(challansData).length > 0 &&
            challansData
              .sort(function (a, b) {
                //console.log("ok")
                console.log('amonth', a.month, 'bmonth', b.month);
                var months = [
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                  'January',
                  'February',
                  'March'
                ];
                return months.indexOf(a.month) - months.indexOf(b.month);
              })
              .map((row, i) => {
                if (row.finance_year === year) {
                  const delay =
                    pfState.dueDates.length > 0
                      ? calDelay(
                          getExpiryDate(row.complianceID, row.month, row.finance_year),
                          row.payment_dates
                        )
                      : 0;
                  return (
                    <React.Fragment>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                          >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{row.month}</TableCell>
                        <TableCell>{row.pf_amount}</TableCell>
                        <TableCell>
                          {pfState?.dueDates?.length > 0
                            ? dateFormat(
                                getExpiryDate(row.complianceID, row.month, row.finance_year)
                              )
                            : '-'}
                        </TableCell>
                        <TableCell>{dateFormat(row.payment_dates)}</TableCell>
                        <TableCell>
                          <Label color={delay === 0 ? 'success' : 'error'}>{delay}</Label>
                        </TableCell>
                        <TableCell>
                          {row.challan_receipt != null && (
                            <Label variant="ghost" color={'info'}>
                              <Link
                                href={row.challan_receipt}
                                color="inherit"
                                underline="hover"
                                target="_blank"
                              >
                                Receipt
                              </Link>
                            </Label>
                          )}
                          {row.challan_receipt == null && (
                            <Label variant="ghost" color={'info'}>
                              NA
                            </Label>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.acknowledgement != null && (
                            <Label variant="ghost" color={'info'}>
                              <Link
                                href={row.acknowledgement}
                                color="inherit"
                                underline="hover"
                                target="_blank"
                              >
                                Acknowledgement
                              </Link>
                            </Label>
                          )}
                          {row.acknowledgement == null && (
                            <Label variant="ghost" color={'info'}>
                              NA
                            </Label>
                          )}
                        </TableCell>
                        <TableCell>

                        {row.ecr_copy != null && (
                            <Label variant="ghost" color={'info'}>
                              <Link
                                href={row.ecr_copy}
                                color="inherit"
                                underline="hover"
                                target="_blank"
                              >
                                ECR copy
                              </Link>
                            </Label>
                          )}
                          {row.ecr_copy == null && (
                            <Label variant="ghost" color={'info'}>
                              NA
                            </Label>
                          )}
                        </TableCell>
                        <TableCell sx={{ display: isStartupFino||istalenteye ? 'none' : '' }}>
                          <Box textAlign="center">
                            <Update row={row} />
                            <ConfirmProvider>
                              <Delete row={row} />
                            </ConfirmProvider>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              {/* <Typography variant="h6" gutterBottom component="div">
                                More Return Details
                              </Typography> */}
                              <Table
                                size="small"
                                aria-label="purchases"
                                sx={{
                                  '& th': {
                                    background: 'white',
                                    color: 'black'
                                  }
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>TRRN</TableCell>
                                    <TableCell>CRN</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{row.pf_type}</TableCell>
                                    <TableCell>{row.trrn}</TableCell>
                                    <TableCell>{row.crn}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                }
              })}
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {challansData
                .filter((item) => item.finance_year === year)
                .map((item) => parseInt(item.pf_amount))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Row = (props) => {
  const { row } = props;
};
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: 'This will permanently delete ' })
      .then(() => {
        dispatch(deletepfchallan(row.id,row.companyId));
        console.log('newrows are after delete ', row.id,row.companyId);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>
        Delete
      </Button>
    </>
  );
};
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log('rows are', row);
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>
        Edit
      </Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            //sx={boxDefault}
          >
            Edit Data
            <CloseRoundedIcon
              onClick={handleClose}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <PfChallanUpdate row={row} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const PfChallanUpdate = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row,handleClose } = props;
  const [value, setValue] = useState(row.payment_dates);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    pf_type: '',
    pf_amount: '',
    trrn: '',
    crn: '',
    payment_dates: ''
    // challan_receipt: '',
    // acknowledgement: '',
    // ecr_copy:''
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = ['2021-22', '2022-23', '2023-24'];

  const [finace, setFinace] = useState(row.finance_year);
  const [mon, setMon] = useState(row.month);
  const [pfType, setPfType] = useState(row.pf_type);
  const [pfamount, setPfamount] = useState(row.pf_amount);
  const [trrnno, setTrrnno] = useState(row.trrn);
  const [crnno, setCrnno] = useState(row.crn);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();
    name = e.target.name;
    valuee = e.target.files[0];
    // setFormData({ ...formData, [name]: valuee });
    formData[`${name}`] = valuee;
    console.log('File name', formData);
  };
  const dis = (e) => {
    // formData['complianceID'] = complianceId
    formData['companyId'] = row.companyId;
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    console.log('form data', formData);
    dispatch(updatepfChallan(row.id, bodyFormData,companyId));
    handleClose();
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required />}
        />
        <TextField
          value={pfamount}
          required
          name="pf_amount"
          type="number"
          onChange={(e) => {
            setPfamount(e.target.value);
            {
              formData.pf_amount = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="PF Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <Autocomplete
          value={pfType}
          name="pf_type"
          onChange={(event, newValue) => {
            setPfType(newValue);
            {
              formData.pf_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={['ECR', 'Arrear']}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="PF Type" required />}
        />
        <TextField
          value={trrnno}
          required
          name="trrn"
          type="number"
          onChange={(e) => {
            setTrrnno(e.target.value);
            {
              formData.trrn = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="TRRN Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          name="crn"
          value={crnno}
          required
          type="number"
          onChange={(e) => {
            setCrnno(e.target.value);
            {
              formData.crn = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="CRN Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date "
            value={value}
            name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <h4>Challan Receipt</h4>
        <Box textAlign="right">
          <a href={row.challan_receipt} target="_blank">
            Challan Receipt
          </a>
        </Box>
        <TextField
          required
          name="challan_receipt"
          onChange={handleSelectFile}
          type="file"
          hidden
          sx={{ margin: 1 }}
        />
        <h4>Acknowledgement</h4>
        <Box textAlign="right">
          <a href={row.acknowledgement} target="_blank">
            Acknowledgement
          </a>
        </Box>
        <TextField
          required
          name="acknowledgement"
          onChange={handleSelectFile}
          type="file"
          hidden
          sx={{ margin: 1 }}
        />
        <h4>ECR Copy </h4>
        <Box textAlign="right">
          <a href={row.ecr_copy} target="_blank">
            ECR copy
          </a>
        </Box>
        <TextField
          required
          name="ecr_copy"
          onChange={handleSelectFile}
          type="file"
          hidden
          sx={{ margin: 1 }}
        />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
