import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  Typography,
  Chip
} from '@mui/material'; 
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import MUIDataTable from 'mui-datatables';
import { allfillstat_patch, get_due_dates_by_period,get_due_dates_by_period_2,get_all_due_dates_company_id } from 'src/redux/actions/fillstatAction';
import { CurrencyYenTwoTone, RawOff } from '@mui/icons-material';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment, { months } from 'moment';
import { chunk } from "lodash";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';



export default function Fillingstatus() {

  const filling_data = useSelector((state) => state.allFillStatreducers);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  console.log("all_companies_fill",all_companies)
  console.log("filling_data", filling_data)
  const [extra, setExtra] = useState(null);
  const [tabdata, setTabdata] = useState([]);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [selectedID, setID] = useState('');
  const [value, setValue] = useState(new Date());
  const [opt_value, setOpt_value] = useState(" ");
  const [open, setOpen] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [counter, setCounter] = useState(0)
  var companies_id;
  //const [count,setCount]=useState();
  let data_total = filling_data.comp_fillstat.length

  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const years = ['2020-21', '2021-22', '2022-23'];

  console.log("data_total", data_total)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    //let a = 0;
    const now = new Date();
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
    console.log("fday", firstDay);
    console.log("lday", lastDay); // 👉️ Sat Oct 01 2022 ...    
    dispatch(get_due_dates_by_period(lastDay, firstDay));
    dispatch(getAllCompanies())
    setTabdata(filling_data);
  }, []);

  const handleChange = (event) => {
    //dispatch(setFinYear(event.target.value));
    setYear(event.target.value);
    //api_data(event.target.value)
  };
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const handleCheckbox = (e, id) => {
    setOpen(true)
    setID(id)
    // var formData = new FormData();
    // const current_date = moment(new Date().toLocaleDateString()).format('YYYY-MM-DD');
    // formData.append('internal_filling', current_date);
    // dispatch(allfillstat_patch(id, formData));
  };
  const columns = [
    {
      name: 'name',
      label: 'Company name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'company',
      label: 'Compliance Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'city',
      label: 'Frequency',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'period',
      label: 'Period',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'fdate',
      label: 'Filling Date',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'duedate',
      label: 'Due Date',
      options: {
        filter: true,
        sort: true
      }
    },
    // {
    //   name: "extdate",
    //   label: "Extended Due Date",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   }
    // },
    {
      name: 'ifill_Date',
      label: 'Internal Filling',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row) => {
          //console.log("row",row)
          return (
            <>
              {row.date_of_filling === null && row.internal_filling === null && (
                <Checkbox
                  defaultChecked={false}
                  onClick={(e) => {
                    handleCheckbox(e, row.id);
                  }}
                />
              )}

              {row.date_of_filling !== null && (
                <Typography>{moment(row.date_of_filling).format('DD-MM-YYYY')}</Typography>
              )}
              {row.date_of_filling === null && row.internal_filling !== null && (
                <Typography>{moment(row.internal_filling).format('DD-MM-YYYY')}</Typography>
              )}
            </>
          );
        },

      }
    },
    {
      name: 'fstatus',
      label: 'Filling Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data) => {
          return (
            <>
              {data === "Filled" && (
                <Chip label="Filled" color="success" variant="outlined" />
              )}
              {data === "Pending" && (
                <Chip label="Pending" color="error" variant="outlined" />
              )}
            </>
          );
        }
      }
    }
  ];
  function fill_api() {

  }

  useEffect(() => {
    const data = filling_data?.comp_fillstat?.map((row) => {
  
      return {
        name: row.company_id.company_name,
        company: row.compliance_name,
        city: row.frequency,
        state: row.state === null ? 'NA' : row.state,
        period: row.period,
        fdate: row.date_of_filling === null ? 'NA' : row.date_of_filling,
        duedate: row.current_due_date,
        ifill_Date: row,
        fstatus: row.internal_filling == null && row.date_of_filling == null ? "Pending" : "Filled"
      };
    });
    setDatas(data);
    console.log("helper", filling_data)
    console.log("data_totalzz", data_total)
  }, [filling_data]);


  const changePage = (page) => {
    console.log("page", page)
    isLoading(true);
    xhrRequest(page).then(res => {
      isLoading(false),
        page(page),
        datas(res.data),
        count(res.total)
    });

  }
  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [10, 500, 1000],
    //count, // Use total number of items
  };
  const opts = all_companies.all_companies.map((option) => option.company_name);
  let arrays =[opts]
  console.log("arrays",arrays);
  console.log("opts", opts)
  console.log("date-sending", value)

  //console.log("total",filling_data.comp_fillstat.count)
  // const xhrRequest = (page) => { 

  //   return new Promise((resolve, reject) => {
  //     const total = comp_fillstat.count.length;
  //     const datas = chunk(animals, 5)[page].map(animal => Object.values(animal));
  //     setTimeout(() => {
  //       resolve({
  //         datas,
  //         total
  //       });
  //     }, 2500);
  //   });
  // };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    let b = 0;
    dispatch(get_due_dates_by_period(b));
    console.log("closed")
  };
  const submit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    const current_date = moment(new Date().toLocaleDateString()).format('YYYY-MM-DD');

    formData.append('internal_filling', current_date);
    dispatch(allfillstat_patch(selectedID, formData));
    setOpen(false);

  };
  console.log("setvalus", setValue)
  return (
    <>
      <Box>
        <h4>Total Data : {data_total}</h4>
      </Box>
      <br></br>


      <Box sx={{
        display: 'flex',
        flexDirection: 'row'
      }}>
        {/* <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          // multiple
          value={year}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
          )}
          MenuProps={MenuProps}
        >
          {years.map((y) => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Starting Date"
            value={value}
            minDate={new Date('2021-01-01')}
            maxDate={new Date('2023-12-31')}
            views={['year', 'month']}
            onChange={(newValue) => {
              setValue(newValue)
              console.log("newValue", newValue)
              const now = newValue
              const firstDay_change = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
              const lastDay_change = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
              console.log("daying", firstDay_change); // 👉️ Mon Oct 31 2022 ...
              console.log("daying", lastDay_change); // 👉️ Mon Oct 31 2022 ...
              setIsflag(true)
              dispatch(get_due_dates_by_period_2(lastDay_change, firstDay_change));
            }}

            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <Autocomplete
          value={opt_value} 
          fullWidth
          name="finance_year"
          onChange={(event, opt_value) => {
            setOpt_value(opt_value);
            console.log("india_opt_value",opt_value)
            const opts_id = all_companies.all_companies.map((i) => {
              if (opt_value == i.company_name) {
                companies_id = i.id;
                dispatch(get_all_due_dates_company_id(companies_id,opt_value));
                console.log("companxyzq,",companies_id)
              }
            })
          }}
          id="controllable-states-demo"
          options={opts}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Company Name" />}
        />

      </Box>

      <MUIDataTable title={'Filling Status'} data={datas} columns={columns} options={options} />
      <Dialog open={open} onClose={handleClose}
      >
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Data Once Update will not be reverted again.
          </DialogContentText>
          <DialogContentText>
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error"
            onClick={handleClose}
          >
            Cancel</Button>
          <Button
            onClick={submit}
          >
            Yes,I am sure</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
