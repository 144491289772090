import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchCashFlowInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function CashFlow() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .CashFlowStatement.CashFlowStatementDirectMethod
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  const fields = {
    NetCashFlowsFromUsedInOperatingActivities: 'Operating Activities',
    NetCashFlowsFromUsedInInvestingActivities: 'Investing Activities',
    NetCashFlowsFromUsedInFinancingActivities: 'Financing Activities',
    EffectOfExchangeRateChangesOnCashAndCashEquivalents: 'Effect of Exchange Rate',
    CashAndCashEquivalentsCashFlowStatement: 'Cash and Cash Equivalent at End'
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(fields).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return yearToDataMap[year][key] != null ? (
                  <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>
                ) : (
                  <TableCell key={index}>NA</TableCell>
                );
              })}
              {/* <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>;
              })} */}
            </TableRow>
          ))}
        </TableBody>

        {/* <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Operating Activities
            </TableCell>
            <TableCell align="center">-291.08</TableCell>
            <TableCell align="center">4,814.35</TableCell>
            <TableCell align="center">8,183.64</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Investing Activities
            </TableCell>
            <TableCell align="center">220.67</TableCell>
            <TableCell align="center">1,952.97</TableCell>
            <TableCell align="center"> -10,594.84</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Financing Activities
            </TableCell>
            <TableCell align="center">-254.01</TableCell>
            <TableCell align="center">-1,611.00</TableCell>
            <TableCell align="center"> -428.05</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Effect of Exchange Rate
            </TableCell>
            <TableCell align="center">-0.03</TableCell>
            <TableCell align="center">75.88</TableCell>
            <TableCell align="center"> 140.57</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Cash and Cash Equivalent at End
            </TableCell>
            <TableCell align="center">756.36</TableCell>
            <TableCell align="center"> 6,023.47</TableCell>
            <TableCell align="center"> 3,324.79</TableCell>
          </TableRow>
        </TableBody> */}
      </Table>
    </TableContainer>
  );
}
