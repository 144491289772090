import React, { useEffect } from 'react';
import {
  TableBody,
  TableCell,
  Table,
  TableContainer,
  Button,
  TableRow,
  TableHead,
  TextField,
  FormControl,
  Form
} from '@mui/material';
import Paper from '@mui/material/Paper';
import validator from 'validator';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsData, updateSingleRole } from 'src/redux/actions/profile';
import { AddNotification } from './AddNotification';

export const Notifications = ({ companyId }) => {
  const dispatch = useDispatch();

  const profileState = useSelector((state) => state.profileReducer);
  const [roles, setRoles] = useState(profileState.notifications);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const addRoles = () => {
    const role = {
      userName: '',
      userRole: '',
      userEmail: '',
      userMobile: ''
    };
    setRoles((old_arr) => [...old_arr, role]);
  };

  const removeRole = (index) => {
    const rolesList = [...roles];
    rolesList.splice(index, 1);
    setRoles(rolesList);
  };

  const handleRoleDataChange = (e, index) => {
    const { name, value } = e.target;
    const rolesData = [...roles];
    rolesData[index][name] = value;
    setRoles(rolesData);
    if (name === 'email') {
      setEmailError(!validator.isEmail(value));
    }

    if (name === 'phone') {
      setPhoneError(!validator.isMobilePhone(value, 'en-IN'));
    }
  };

  const handleUpdate = (e, role) => {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append('name', role.name);
    bodyFormData.append('email', role.email);
    bodyFormData.append('role', role.role);
    bodyFormData.append('phone', role.phone);

    dispatch(updateSingleRole(role.id, bodyFormData));
  };
  useEffect(() => {
    dispatch(getNotificationsData(companyId));
  }, []);

  useEffect(() => {
    console.log('state is', profileState.notifications);
    setRoles(profileState.notifications);
  }, [profileState.notifications]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Email Id</TableCell>
              <TableCell align="center">Mobile Number</TableCell>
              <TableCell align="center">Edit </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role, i) => {
              return (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                  <TableCell align="center">
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={role.name}
                      onChange={(e) => handleRoleDataChange(e, i)}
                      required
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      fullWidth
                      label="Role"
                      name="role"
                      value={role.role}
                      onChange={(e) => handleRoleDataChange(e, i)}
                      required
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      error={emailError}
                      type="email"
                      fullWidth
                      label="Email"
                      name="email"
                      value={role.email}
                      onChange={(e) => handleRoleDataChange(e, i)}
                      required
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      error={phoneError}
                      fullWidth
                      type="tel"
                      label="WhatsApp Mobile Number"
                      name="phone"
                      value={role.phone}
                      onChange={(e) => handleRoleDataChange(e, i)}
                      required
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      onClick={(e) => handleUpdate(e, role)}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableCell>
            <Button onClick={handleOpen} variant="contained" justify="center">
              <i className="fa fa-plus" />
            </Button>
          </TableCell>
        </Table>

        <br />
        {/* <Button variant="contained" sx={{ m: 2, minWidth: 200 }}>
            Save
          </Button> */}
      </TableContainer>
      <AddNotification companyId={companyId} open={open} handleClose={handleClose} />
    </>
  );
};
