import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { masterdoc_get, masterdoc_post } from 'src/redux/actions/masterdocAction';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { Typography } from '@material-ui/core';
import { getCompanyId } from 'src/utils/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { doc } from 'prettier';
import { istalenteye, isStartupFino } from 'src/config';
import Label from 'src/components/Label';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { appName } from 'src/config';

const Masterdoc = () => {
  console.log('THE SITE', istalenteye === isStartupFino ? 'none' : '', isStartupFino);
  const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  const dispatch = useDispatch();
  const [docfiled, setDocfiled] = useState([
    { filename: 'Certificate of Incorporation', filevalue: [], uploadfilename: [] },
    { filename: 'MOA', filevalue: [], uploadfilename: [] },
    { filename: 'AOA', filevalue: [], uploadfilename: [] },
    { filename: 'PAN Card of Company', filevalue: [], uploadfilename: [] },
    { filename: 'TAN Allotment Letter', filevalue: [], uploadfilename: [] },
    { filename: 'GST Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'Startup India Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'MSME Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'EPF Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'ESIC Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'Professional Tax Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'Trade Mark Certificate', filevalue: [], uploadfilename: [] },
    { filename: 'Previous Accounting Backup', filevalue: [], uploadfilename: [] },
    { filename: 'Bank Statement', filevalue: [], uploadfilename: [] },
    { filename: 'TDS Challan', filevalue: [], uploadfilename: [] },
    { filename: 'TDS Return Acknowledgement', filevalue: [], uploadfilename: [] },
    { filename: 'TDS Return Working', filevalue: [], uploadfilename: [] },
    { filename: 'GST Working', filevalue: [], uploadfilename: [] },
    { filename: 'ROC Form and Challan', filevalue: [], uploadfilename: [] },
    { filename: 'Customer Aggrement', filevalue: [], uploadfilename: [] },
    { filename: 'Vendor Agreement', filevalue: [], uploadfilename: [] },
    { filename: 'Employee Agreement', filevalue: [], uploadfilename: [] }
  ]);
  const [docfiled2, setDocfiled2] = useState([
    { filename: 'Certificate of Incorporation', filevalue: '', uploadfilename: '' },
    { filename: 'MOA', filevalue: '', uploadfilename: '' },
    { filename: 'AOA', filevalue: '', uploadfilename: '' },
    { filename: 'PAN Card of Company ', filevalue: '' },
    { filename: 'GST Certificate ', filevalue: '' },
    { filename: 'EPF Certificate ', filevalue: '' },
    { filename: 'ESIC Certificate ', filevalue: '' },
    { filename: 'Professional Tax Certificate ', filevalue: '' },
    { filename: 'Shop and Establishment Certificate ', filevalue: '' },
    { filename: 'LWF Certificate or Receipt ', filevalue: '' },
    { filename: 'Employee Offer Letter ', filevalue: '' },
    { filename: 'Employee Form-11 ', filevalue: '' },
    { filename: 'Employee Form-2 ', filevalue: '' },
    { filename: 'Employee Form- F ', filevalue: '' },
    { filename: 'PF Returns and Challans ', filevalue: '' },
    { filename: 'ESIC Returns and Challans ', filevalue: '' },
    { filename: 'Labour Law Registers ', filevalue: '' },
    { filename: 'POSH Return and Policy  ', filevalue: '' },
    { filename: 'PT Returns and Challans ', filevalue: '' },
    { filename: 'LWF Returns and Challans  ', filevalue: '' },
    { filename: 'Shop and Establishment Returns ', filevalue: '' }
  ]);
  var list1 = [];
  var list2 = [];
  const [a, seta] = useState([]);
  const [b, setb] = useState([]);
  const [c, setc] = useState([]);
  const [groupvar, setGroupvar] = useState([]);

  const [content, setContent] = useState([{ document_fn: [] }]);
  console.log('master', masterdoc_get);
  const company_docs = useSelector((state) => state.allCompaniesdocsreducers);
  console.log('company_docs', company_docs);
  useEffect(() => {
    list1 = [];
    list2 = [];
    setContent(company_docs);
    let arr_for = company_docs?.companies_doc;
    let doc_arr = docfiled;
    let doc_arr2 = docfiled2;
    console.log('doc arr', doc_arr);
    console.log('rr', arr_for);
    let comp_doc_1 = Array.from(arr_for);
    console.log('100', comp_doc_1);

    //console.log("list val", company_docs.companies_doc[2].document_name);
    for (var i = 0; i < comp_doc_1?.length; i++) {
      list1.push(company_docs?.companies_doc[i]?.document_name);
    }
    console.log('list', list1);
    if(appName==="StartupFino"){
      for (var i = 0; i < doc_arr.length; i++) {
        list2.push(docfiled[i]?.filename);
      }
    }
    if(appName==="PeopleFino"){
      for (var i = 0; i < doc_arr2.length; i++) {
        list2.push(docfiled2[i]?.filename);
      }
    }

    seta([...list1]);
    setb([...list2]);
    setc([...list1, ...list2]);
    console.log('list1', list1);
    console.log('list2', list2);
    console.log('list3', c);
  }, [company_docs, docfiled,docfiled2]);

  let uniqueChars = [];
  let chars = ['A', 'B', 'A', 'C', 'B'];

  c.forEach((i) => {
    if (!uniqueChars.includes(i)) {
      uniqueChars.push(i);
    }
  });

  console.log('uniquev', uniqueChars);

  useEffect(() => {
    dispatch(masterdoc_get(companyId));
  }, []);
  const [flag, setFlag] = useState(true);
  const handleAddfiled = () => {
    setDocfiled([...docfiled, { filename: '', filevalue: [], uploadfilename: [] }]);
    setFlag(false);
  };
  const handleAddfiled_People = () => {
    setDocfiled2([...docfiled2, { filename: '', filevalue: [], uploadfilename: [] }]);
    setFlag(false);
  };
  console.log('Content', content);
  const handleSelectFile = (e, index, row) => {
    console.log('sl', content.document_fn);
    const list = [...docfiled];
    list[index].filevalue.push(e.target.files[0]);
    list[index].uploadfilename.push(e.target.files[0].name);
    console.log('listing', list);
    setDocfiled(list);
    // Upload data
    var data = new FormData();
    data.append('companyId', companyId);
    data.append('document', e.target.files[0]);
    data.append('document_name', docfiled[index].filename);
    console.log('bodyformdata', data);
    dispatch(masterdoc_post(data, companyId));
  };
  const handleSelectFile_People = (e, index, row) => {
    console.log('sl', content.document_fn);
    const list = [...docfiled2];
    list[index].filevalue.push(e.target.files[0]);
    list[index].uploadfilename.push(e.target.files[0].name);
    console.log('listing', list);
    setDocfiled2(list);
    // Upload data
    var data = new FormData();
    data.append('companyId', companyId);
    data.append('document', e.target.files[0]);
    data.append('document_name', docfiled2[index].filename);
    console.log('bodyformdata', data);
    dispatch(masterdoc_post(data, companyId));
  };
  const handleChangeInput = (index, event) => {
    const vals = [...docfiled];
    vals[index][event.target.name] = event.target.value;
    console.log(index, event.target.name);
    console.log('vals', vals);
    setDocfiled(vals);
  };
  const handleremovefiled = (index) => {
    const values = [...docfiled];
    values.splice(index, 1);
    setDocfiled(values);
  };
  const handleChangeInput_People = (index, event) => {
    const vals = [...docfiled2];
    vals[index][event.target.name] = event.target.value;
    console.log(index, event.target.name);
    console.log('vals', vals);
    setDocfiled2(vals);
  };
  const handleremovefiled_People = (index) => {
    const values = [...docfiled2];
    values.splice(index, 1);
    setDocfiled2(values);
  };

  const [alluploaded, setAlluploaded] = useState(false);
  const filename = [];
  let cont_len = content;
  console.log('cont_len', cont_len.companies_doc);
  return (
    <>
      <Grid sx={{ flexGrow: 1, mt: '10px' }} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" sx={{ m: 2 }}>
            Documents
          </Typography>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <Grid key={1} item>
            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  minWidth: 650,
                  '& th': {
                    fontSize: '1rem',
                    background: 'hsl(149,100%,33%)',
                    color: 'white'
                  },
                  '& td': {
                    fontSize: '0.7rem'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell >Company Documents</TableCell>
                    <TableCell sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}>
                      Upload
                    </TableCell>
                    <TableCell>File</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody> 
                  {/* part1 - StartupFino */}
                  {appName==="StartupFino" &&
                  <>
                  {content?.companies_doc?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {}
                        <>
                          <TableCell><h3>{row.document_name}</h3></TableCell>
                          <TableCell
                            sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                          >

                            {row.document===null &&
                            <Button
                            variant="contained"
                            component="label"
                            //sx={{ m: 2 }}
                            size="small"
                            //disabled={row.filevalue === '' ? false : true}
                          >
                            Upload File
                            <input
                              //ref={inputRef}
                              name={row.filevalue}
                              multiple
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleSelectFile(e, index, row);
                              }}
                            />
                          </Button>                            
                            }
                            {row.document!=null &&
                            <Button
                            variant="contained"
                            component="label"
                            //sx={{ m: 2 }}
                            size="small"
                            //disabled={row.filevalue === '' ? false : true}
                          >
                            Update File
                            <input
                              //ref={inputRef}
                              name={row.filevalue}
                              multiple
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleSelectFile(e, index, row);
                              }}
                            />
                          </Button>                            
                            
                            }                            

                          </TableCell>
                          <TableCell>
                            <Button  target="_blank" href={row.document} sx={{color:"#00000095"}} startIcon={<RemoveRedEyeIcon color="black" href={row.document} target="_blank" />}>
                              View File
                            </Button>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                  </>
                  }
                  {/* part1 - PeopleFino */}
                  {appName==="PeopleFino" &&
                  <>
                  {content?.companies_doc?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {}
                        <>
                          <TableCell><h3>{row.document_name}</h3></TableCell>
                          <TableCell
                            sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                          >

                            {row.document===null &&
                            <Button
                            variant="contained"
                            component="label"
                            //sx={{ m: 2 }}
                            size="small"
                            //disabled={row.filevalue === '' ? false : true}
                          >
                            Upload File
                            <input
                              //ref={inputRef}
                              name={row.filevalue}
                              multiple
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleSelectFile_People(e, index, row);
                              }}
                            />
                          </Button>                            
                            }
                            {row.document!=null &&
                            <Button
                            variant="contained"
                            component="label"
                            //sx={{ m: 2 }}
                            size="small"
                            //disabled={row.filevalue === '' ? false : true}
                          >
                            Update File
                            <input
                              //ref={inputRef}
                              name={row.filevalue}
                              multiple
                              type="file"
                              hidden
                              onChange={(e) => {
                                handleSelectFile_People(e, index, row);
                              }}
                            />
                          </Button>                            
                            
                            }                            

                          </TableCell>
                          <TableCell>
                            <Button  target="_blank" href={row.document} sx={{color:"#00000095"}} startIcon={<RemoveRedEyeIcon color="black" href={row.document} target="_blank" />}>
                              View File
                            </Button>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}                  
                  </>
                  }
                  {/* part2 StartupFino */}
                  {appName==="StartupFino" &&
                  <>
                  {(!istalenteye || !isStartupFino) &&
                    b.map((row, index) => {
                      if (a.filter((row2) => row2 == row).length == 0) {
                        return (
                          <>
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>
                                <h3>{row}</h3>
                                {docfiled.length > index && flag == false && index > 21 && (
                                  <TableCell>
                                    <TextField
                                      id="outlined-basic"
                                      name="filename"
                                      label="Other Documents"
                                      variant="outlined"
                                      value={docfiled[index].filename}
                                      onChange={(event) => handleChangeInput(index, event)}
                                    />
                                  </TableCell>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  //sx={{ m: 2 }}
                                  size="small"
                                  //disabled={row.filevalue === '' ? false : true}
                                >
                                  {console.log('new row', row.filevalue)}
                                  Upload File
                                  <input
                                    //ref={inputRef}
                                    name={row.filevalue}
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      handleSelectFile(e, index);
                                    }}
                                  />
                                </Button>
                              </TableCell>
                              <TableCell>
                              </TableCell>

                              {docfiled.length - 1 == index && (
                                <TableCell
                                  sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                                >
                                  <Button onClick={handleAddfiled}>Add Other Documents</Button>
                                  {flag === false && index > 21 && (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        handleremovefiled(index);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  )}

                                </TableCell>
                              )}
                            </TableRow>
                          </>
                        );
                        console.log('row.filename', row);
                      }
                    })}                  
                  </>

                  }

                  {/* part2 PeopleFino */}
                  {appName==="PeopleFino" && 
                  <>
                  {(!istalenteye || !isStartupFino) &&
                    b.map((row, index) => {
                      if (a.filter((row2) => row2 == row).length == 0) {
                        return (
                          <>
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>
                                <h3>{row}</h3>
                                {docfiled2.length > index && flag == false && index > 20 && (
                                  <TableCell>
                                    <TextField
                                      id="outlined-basic"
                                      name="filename"
                                      label="Other Documents"
                                      variant="outlined"
                                      value={docfiled2[index].filename}
                                      onChange={(event) => handleChangeInput_People(index, event)}
                                    />
                                  </TableCell>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  //sx={{ m: 2 }}
                                  size="small"
                                  //disabled={row.filevalue === '' ? false : true}
                                >
                                  {console.log('new row', row.filevalue)}
                                  Upload File
                                  <input
                                    //ref={inputRef}
                                    name={row.filevalue}
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      handleSelectFile_People(e, index);
                                    }}
                                  />
                                </Button>
                              </TableCell>
                              <TableCell>
                              </TableCell>

                              {docfiled2.length - 1 == index && (
                                <TableCell
                                  sx={{ display: istalenteye === isStartupFino ? 'none' : '' }}
                                >
                                  <Button onClick={handleAddfiled_People}>Add Other Documents</Button>
                                  {flag === false && index > 20 && (
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        handleremovefiled_People(index);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  )}

                                </TableCell>
                              )}
                            </TableRow>
                          </>
                        );
                        console.log('row.filename', row);
                      }
                    })}
                  
                  </>
                  }    

                  {}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Masterdoc;
// b.map((row2)=> {
//   if( a.filter((row)=>row.name==row2.name).length==0)
//   return(
//   )
// })
