import Constants from 'src/utils/constants';
const intialState = {
  comp_notifications: []
};
export const allNotificationreducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_NOTIFICATIONS:
      return { ...state, comp_notifications: action.payload };
    default:
      return state;
  }
};
