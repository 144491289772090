import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Select, MenuItem ,Box} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { base_url } from '../../../config';
import { createCompany } from 'src/redux/actions/companyOnBoarding';
import { isStartupFino } from '../../../config';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios  from 'axios';
//component

export const BasicProfileForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);
  const state = useSelector((state) => state.authReducer);
  const companyRedux = useSelector((state) => state.orgProfileReducer);
  const companyId_selected = useSelector((state) => state.orgProfileReducer.companyId);

  console.log("companyRedux",companyRedux)
  const [age, setAge] = React.useState('');

  const [orgProfileInfo, setOrgProfileInfo] = useState({
    organizationName: '',
    dateOfIncorporation: new Date(),
    companyType: 'privateCompany'
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrgProfileInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const parseDate = (yourDate) => {
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split('T')[0];
  };

  const handleGetStarted = async () => {
    const date = parseDate(orgProfileInfo.dateOfIncorporation);
    let source;
    if (isStartupFino) {
      source = 'StartupFino';
    } else {
      source = 'Compliance Book';
    }
    const body = {
      company_name: orgProfileInfo.organizationName,
      company_type: orgProfileInfo.companyType,
      date_of_inc: date,
      source: source
    };
    dispatch(createCompany(body));


};  
  console.log("localStorage.getItem('refer222')",localStorage.getItem('referralId'),companyId_selected)
  useEffect(() => {
    if (companyRedux.companyData.length > 0) {
      navigate('/extendedProfile', { replace: true });
      console.log("companyReduxcompanyRedux",companyRedux.companyData[0]?.id)
    }
    if (localStorage.getItem('referralId')) {
      console.log("localStorage.getItem('refer505')", localStorage.getItem('referralId'), companyRedux.companyData);
      const data = {
        company_id: companyRedux.companyData[0]?.id,
        refer_id: localStorage.getItem('referralId')
      };
      localStorage.setItem('company_id',companyRedux.companyData[0]?.id)
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.startupfino.in/company/invitation-accepted/',
        headers: {
          'Authorization': localStorage.getItem('token'), // Use stored token here
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios.request(config)
      .then(response => {
        console.log(JSON.stringify(response.data));
        toast.success("Welcome To startupfino")
      })
      .catch(error => {
        console.log('Error making API call:', error);
      });

    }
  }, [companyRedux.companyData]);
  const handleGoBack = () => {
    setcurrentPage(1);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleGetStarted();
      }}
    >
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Organization name"
          name="organizationName"
          value={orgProfileInfo.organizationName}
          onChange={handleChange}
          required
        />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Company Type</InputLabel>

          <Select
            fullWidth
            label="Company Type"
            name="companyType"
            variant="outlined"
            id="demo-simple-select-label"
            onChange={handleChange}
            value={orgProfileInfo.companyType}
            required
          >
            <MenuItem value="Private Company">Private Company</MenuItem>
            <MenuItem value="Public Company">Public Company</MenuItem>
            <MenuItem value="LLP">LLP</MenuItem>
            <MenuItem value="Partnership Firm">Partnership Firm</MenuItem>
            <MenuItem value="Sole Proprietorship">Sole Proprietorship</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Date of incorporation"
            name="dateOfIncorporation"
            value={orgProfileInfo.dateOfIncorporation}
            renderInput={(params) => <TextField {...params} helperText={null} />}
            onChange={(newValue) => {
              setOrgProfileInfo({ ...orgProfileInfo, dateOfIncorporation: newValue });
            }}
            inputFormat="dd/MM/yyyy"
          />
        </LocalizationProvider>

          <Box display="flex" alignItems="center" justifyContent="center">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ fontFamily: 'Public Sans', fontWeight: 400, fontSize: '16px', width: '80%' }}
            >
              Next Step
            </LoadingButton>
          </Box>
      </Stack>
    </form>
  );
};
