import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const task_all_get = () => (dispatch)=>{
    HttpWrapper('GET', `/task-manager/api/task-management/?limit=20&offset=20`)
    .then((res) => {
      dispatch({
        type: constants.SET_TASK_MANAGE,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_TASK_MANAGE,
        payload: []
      });
    });
  }
  export const task_all_get_limit = (page) => (dispatch)=>{

    HttpWrapper('GET', `/task-manager/api/task-management/?limit=20&offset=${((page+1)*20)}`)
    .then((res) => {
      dispatch({
        type: constants.SET_TASK_MANAGE,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_TASK_MANAGE,
        payload: []
      });
    });
  }
  export const task_Names_get = () => (dispatch)=>{
    HttpWrapper('GET', `/task-manager/api/task-name/`)
    .then((res) => {
      dispatch({
        type: constants.SET_TASK_NAME,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_TASK_NAME,
        payload: []
      });
    });
  }

  export const team_Mates_get = () => (dispatch)=>{
    HttpWrapper('GET', `/task-manager/api/team-mates/`)
    .then((res) => {
      dispatch({
        type: constants.SET_TEAM,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_TEAM,
        payload: []
      });
    });
  }

  export const add_Task = (data) => (dispatch) => {
    HttpWrapper('POST', `/task-manager/api/task-management/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Add Successfully');
        dispatch(task_all_get());
        dispatch(client_mail_list());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const add_Task_name = (data) => (dispatch) => {
    HttpWrapper('POST', `/task-manager/api/task-name/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Add Successfully');
        dispatch(task_Names_get());
        dispatch(task_all_get());
        dispatch(client_mail_list());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const deletetask = (data) => (dispatch) => {
    HttpWrapper('DELETE', `/task-manager/api/task-management/${data}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Delete Successfully');
        dispatch(task_all_get());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const deletetaskName = (data) => (dispatch) => {
    HttpWrapper('DELETE', `/task-manager/api/task-name/${data}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Delete Successfully');
        dispatch(task_Names_get());
        dispatch(task_all_get());
        dispatch(client_mail_list());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const updatetask = (id, data) => (dispatch) => {
    console.log(data);
    HttpWrapper('PATCH', `/task-manager/api/task-management/${id}/`, true, data)
      .then((res, err) => {
        toast.success('Update Successfully');
        dispatch(task_all_get());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const updatetaskName = (id, data) => (dispatch) => {
    console.log(data);
    HttpWrapper('PATCH', `/task-manager/api/task-name/${id}/`, true, data)
      .then((res, err) => {
        toast.success('Update Successfully');
        dispatch(task_Names_get());
        dispatch(task_all_get());
        dispatch(client_mail_list());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const client_mail_list = () => (dispatch)=>{
    HttpWrapper('GET', `/task-manager/api/client-mail/`)
    .then((res) => {
      dispatch({
        type: constants.SET_CLIENT_MAIL,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err)
    });
  }