import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { useState } from 'react';
import { getAllDueDates } from 'src/redux/actions/dueDates';
import { useSearchParams } from 'react-router-dom';
import { dateFormat } from 'src/utils/common';
import { getStatus } from './AllDueDates';
import Label from 'src/components/Label';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { TapAndPlay } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { updateDueDate } from 'src/redux/actions/dueDates';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';

export const All_due = () => {
    const dueDatesState = useSelector((state) => state.dueDatesReducer);
    console.log("i am", dueDatesState)
    const dispatch = useDispatch();
    const [dueDates, setdueDates] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedID, setID] = useState('');
    const [reason, setReason] = useState('');
    const [edit, setEdit] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

    useEffect(() => {
        dispatch(getAllDueDates(companyId));
    }, []);

    useEffect(() => {
        setdueDates(
            dueDatesState?.allDueDates?.sort(function (a, b) {
                return new Date(a.current_due_date) - new Date(b.current_due_date);
            })
        );
    }, [dueDatesState.allDueDates]);
    const handleClickOpen = (id) => {
        setID(id);
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
        setReason('');
    };

    const handleChange = (event) => {
        event.preventDefault();
        setReason(event.target.value);
    };

    const submitReason = (e) => {
        e.preventDefault();
        if (reason.trim().length === 0) {
            toast.error('Please enter valid reason');
        } else {
            const bodyFormData = new FormData();
            bodyFormData.append('due_date_applicable', false);
            bodyFormData.append('reason_for_non_applicable', reason);
            dispatch(updateDueDate(selectedID, bodyFormData, 1));
            setOpen(false);
            setReason('');
        }
    };
    const handleDateUpdate = () => {
        const date_of_filling = moment(dof).format('YYYY-MM-DD');
        const bodyFormData = new FormData();
        bodyFormData.append('date_of_filling', date_of_filling);
        dispatch(updateDueDate(selectedID, bodyFormData, 1));
        setEdit(false)
    }
    const toggleEdit = (id) => {
        setEdit(!edit);
        setID(id);
    };




    const columns = [
        {
            name: "law",
            label: "Law",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "freq",
            label: "Frequency",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "pred",
            
            label: "Period",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "coml_name",
            label: "Compliance Name",
            options: {
                filter: true,
                sort: true,
                // customBodyRender:(datasx,datasy, dataIndex, rowIndex) => {
                //     let text = datasx;
                //     const myArray = text.split("$");
                //     let compli_name = myArray[0];
                //     let disc = myArray[1];
                //     if(myArray.length>0){
                //         return(
                //             <Tooltip title={disc}><Box component="span" sx={{ display: 'block' }}>{compli_name}</Box></Tooltip>
                //         )
                //     }
                // }


            }
        },
        {
            name: "due_date",
            label: "Due Date",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "fill_date",
            label: "Filing Date",
            options: {
                filter: false,
                sort: true,
                // customBodyRender: 

            }
        },
        {
            name: "stat",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    if (value === "Not Applicalbe") {
                        return (
                            <Label variant="ghost" color="error">
                                Not Applicable
                            </Label>
                        )
                    }
                    else if (value === "On Time") {
                        return (
                            <Label variant="ghost" color="success">
                                On time
                            </Label>
                        )

                    }
                    else if (value === "Upcoming") {
                        return (
                            <Label variant="ghost" color="secondary">
                                Upcoming
                            </Label>
                        )

                    }
                    else if (value === "Overdue") {
                        return (
                            <Label variant="ghost" color="error">
                                Overdue
                            </Label>
                        )

                    }
                    else if (value === "Delayed") {
                        return (
                            <Label variant="ghost" color="default">
                                Delayed
                            </Label>
                        )

                    }
                }
            }

        },

        {
            name: "Act",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            }

        },
    ];

    // const data = [
    //     ["Joe James", "Test Corp", "Yonkers", "NY"],
    //     ["John Walsh", "Test Corp", "Hartford", "CT"],
    //     ["Bob Herm", "Test Corp", "Tampa", "FL"],
    //     ["James Houston", "Test Corp", "Dallas", "TX"],
    // ];

    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        rowsPerPageOptions: [10, 500, 1000]
    };
    
    const [datas, setDatas] = useState([]);
    function due_date_apiData() {
        const data = dueDates?.map((row, index) => {
            return ({
                law: row.book_name,
                freq: row.frequency,

                pred: row.period + "(" + row.fin_year + ")",
                coml_name: row.ref_id === 'NA' ? row.compliance_name :  row.compliance_name+ ' (' + row.sub_section.slice(0,4) + ')',                //coml_name:row.description,
                //coml_name:row.description,
                //  coml_name:
                //  <Tooltip title={row.description}><Box component="span" sx={{ display: 'block' }}>{row.compliance_name}</Box></Tooltip>, 
                due_date: dateFormat(row.current_due_date),
                fill_date: row.date_of_filling !== null ? dateFormat(row.date_of_filling) : <></>,
                stat:
                    row.due_date_applicable === false ? "Not Applicalbe" :
                        getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 1 &&
                            row.due_date_applicable === true ? "On Time" :
                            getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 2 && row.due_date_applicable === true ?
                                "Upcoming" :
                                getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 0 && row.due_date_applicable === true ?
                                    "Overdue" :

                                    getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 4 && row.due_date_applicable === true ?
                                        "Delayed" : ""

                ,
                Act: row.date_of_filling == null && edit == false ?
                    <>
                        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <Tooltip title="Update">
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={(e) => toggleEdit(row.id)}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Not applicable">
                                <IconButton
                                    aria-label="not-app"
                                    // color="error"
                                    onClick={(e) => {
                                        handleClickOpen(row.id);
                                    }}
                                >
                                    <RemoveCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                    :
                    row.book_name.includes('book') == true && edit === false ?
                        <>
                            <Tooltip title="Update">
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={(e) => toggleEdit(row.id)}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        :
                        <>
                        </>



            });
        })
        setDatas(data)
        console.log(data)
    }

    useEffect(() => {
        due_date_apiData()
    }, [dueDatesState])


    return (
        <>
            <MUIDataTable
                title={"All Due Dates"}
                data={datas}
                columns={columns}
                options={options}

            />
            <Dialog open={open} onClose={handleClose}>


                <DialogTitle>Reason for Not Applicable</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please give reason for not consider this compliance
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label="Reason"
                        multiline
                        maxRows={4}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={reason}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitReason}>Submit</Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

