import { usePromiseTracker } from "react-promise-tracker";
import { Circles } from 'react-loader-spinner';


const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress && <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
        <Circles type="Watch" color="#5664d2" height="100vh" width="100" />
        </div>
    };

export default LoadingIndicator;