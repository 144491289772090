import React from 'react';
import { IncomeTaxDashboard } from 'src/sections/@dashboard/app/IncomeTaxDashboard';

const IncomeTax = () => {
  return (
    <div className="Incometax">
      <IncomeTaxDashboard />
    </div>
  );
};

export default IncomeTax;
