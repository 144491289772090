import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
//import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
//import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';


import { deleteincometaxIb4, updateIncometaxIb4 } from 'src/redux/actions/incometax';
export default function Ib4(props) {
  const { year } = props;
  const incomeState = useSelector((state) => state.incometaxReducer);
  if (incomeState.demand == null) {
    return <></>;
  }
  const data = incomeState.demand.filter(
    (item) => parseInt(item.finance_year.split('-')[0]) <= parseInt(year.split('-')[0])
  );
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>FY</TableCell>
            <TableCell>Date of Demand Raised</TableCell>
            <TableCell>Month of Demand Raised</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Demand Amount</TableCell>
            <TableCell>Notice Copy </TableCell>
            <TableCell sx={{display: isStartupFino ? 'none': ''}}>Edit / Delete </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.fin_year}</TableCell>
                <TableCell>{row.demand_date}</TableCell>
                <TableCell>{row.demand_month}</TableCell>
                <TableCell>{row.section}</TableCell>
                <TableCell>{row.demand_amount}</TableCell>
                <TableCell>
                  <a href={row.notice} target="_blank">
                    Link
                  </a>
                </TableCell>
                <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                  <Box textAlign="center">
                    <Update row={row} />
                    <ConfirmProvider>
                      <Delete row={row} />
                    </ConfirmProvider>
                  </Box>
                </TableCell>

              </TableRow>
            );
          })}
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {data
                .map((item) => parseInt(item.demand_amount))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deleteincometaxIb4(row.id))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rowsirrr are", row);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <Outstanding_Demand_Update row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const Outstanding_Demand_Update = (props) => {
  const { row } = props;
  const [value, setValue] = useState(row.demand_date);
  const [valuez, setValuez] = useState(row.payment_date);
  const [formData, setFormData] = useState({
    finance_year: '',
    period: '',
    section: '',
    demand_date: '',
    demand_amount: '',
    payment_date: '',
  });
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const periode = ["Apr-March"];
  const [perd, setPerd] = useState(row.period);
  const [finace, setFinace] = useState(row.finance_year);
  const [sect, setSect] = useState(row.section);
  const [amt, setAmt] = useState(row.demand_amount);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    // console.log("HEllo WORLD");
  }
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    // setFormData({ ...formData, [name]: valuee });
    formData[`${name}`] = valuee
    // console.log('File name', formData)
  }
  const dis = (e) => {
    // formData['complianceId'] = complianceId
    formData['companyId'] = companyId
    formData.complianceID = row.complianceID;
    //formData['complianceId']=row.complianceID;
    var bodyFormData = new FormData();
    if (formData.payment_date !== 'null') {
      formData['companyId'] = companyId
      //toast.success('Add Successfully');
      formData['complianceID']= row.complianceID;
      var bodyFormData = new FormData();
      for (let key in formData) {
        if (key, formData[key] !== '') {
          bodyFormData.append(key, formData[key])
        }
      }
      console.log('form data', formData);

      dispatch(updateIncometaxIb4(row.id, bodyFormData,companyId));

    }
    if (formData.payment_date == 'null' || formData.payment_date == 'Invalid date') {
      console.log("date removed");
      formData['companyId'] = companyId
      //toast.success('Add Successfully');
      formData.complianceID = row.complianceID;
      var bodyFormData = new FormData();
      for (let key in formData) {
        console.log("main key", key);

        if (key, formData[key] !== '0') {
          if (key == "payment_date") {
            formData.payment_date = "null"
            console.log("ok", formData.payment_date)

          }
          else {
            bodyFormData.append(key, formData[key])
          }
        }

      }

      console.log('form data', formData);
      dispatch(updateIncometaxIb4(row.id, bodyFormData));


    }
  }

  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

        <Autocomplete
          value={finace} name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            { formData.finance_year = newValue };
            { handleFormDataChange };
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Finance year" />}
        />
        <Autocomplete
          value={perd} name="period"
          defaultValue={'Apr-March'}
          onChange={(event, newValue) => {
            setPerd(newValue);
            { formData.period = newValue };
            { handleFormDataChange };
          }}
          id="controllable-states-demo"
          options={periode}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Period" />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date Of Demand"
            value={value} name="demand_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              { 
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.demand_date = newValue
              };
              { handleFormDataChange };
            }}
            renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
          value={sect} name="section"
          onChange={(e) => {
            setSect(e.target.value);
            { formData.section = e.target.value };
            { handleFormDataChange };
          }}
          label="Section" variant="outlined" required sx={{ margin: 1 }} />
        <TextField value={amt} name="demand_amount" onChange={(e) => {
          setAmt(e.target.value);
          { formData.demand_amount = e.target.value };
          { handleFormDataChange };
        }} label="Amount" variant="outlined" required sx={{ margin: 1 }} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label=" Demand Payment Date"
            value={valuez} name="payment_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValuez(newValue);
              { 
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.payment_date = newValue };
              { handleFormDataChange };
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>

        <h5>Notice Copy</h5>
        <Box textAlign="right" >
          <a href={row.notice} target="_blank">
            Notice
          </a>
        </Box>

        <TextField required name='notice' onChange={handleSelectFile} type="file" sx={{ margin: 1 }} />
      </Box>
      <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={dis} variant="contained">SAVE</Button></Box>
    </>
  );

};

