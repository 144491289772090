import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createChallan } from 'src/redux/actions/tds';
import FormData from 'form-data';
import { useConfirm } from 'material-ui-confirm';
export default function TdsTableOne(props) {
  const dispatch = useDispatch();
  const { tdsChallans, year, companyId } = props;
  const [rows, setRows] = useState(prepareData());
  const confirm = useConfirm();
  const handleChange = (e, index) => {
    confirm({ description: `This will permanently change` })
      .then(() => {
        const { name, value } = e.target;
        console.log('index', index, name, year);
        var data = new FormData();
        data.append('companyId', companyId);
        data.append('month', Object.keys(tdsChallans[index])[0]);
        data.append('tds_type', name);
        data.append('amount', 0);
        data.append('finance_year', year);
        dispatch(createChallan(data));
      })
      .catch(() => {
        /* ... */
      });
  };

  useEffect(() => {
    console.log('tdsChallans', tdsChallans);
  }, [tdsChallans]);

  const checkNonzero = (filled, is_zero, is_non_zero) => {
    if (filled === true && is_non_zero === true) {
      return false;
    } else if (filled === true && is_non_zero === false && is_zero === true) {
      return true;
    } else {
      return false;
    }
  };

  const checkNonRegularCompliance = (filled, is_zero, is_non_zero) => {
    if (filled === true) {
      return false;
    // } else if (filled === true && is_non_zero === false && is_zero === true) {
    //   return true;
    } else {
      return true;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell align="right">
              24Q
              <br />
              TDS on Salaries
            </TableCell>
            <TableCell align="right">
              26Q <br />
              TDS on Other than Salaries
            </TableCell>
            <TableCell align="right">
              27Q
              <br />
              TDS on Non Residents
            </TableCell>
            <TableCell align="right">
              27EQ
              <br />
              TCS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tdsChallans &&
            tdsChallans.map((row, i) => {
              return (
                <TableRow
                  key={Object.keys(row)[0]}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Object.keys(row)[0]}
                  </TableCell>
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={row[Object.keys(row)[0]]['24Q']['filled']}
                          checked={checkNonzero(
                            row[Object.keys(row)[0]]['24Q']['filled'],
                            row[Object.keys(row)[0]]['24Q']['is_zero'],
                            row[Object.keys(row)[0]]['24Q']['is_non_zero']
                          )}
                          name="24Q"
                          onChange={(e) => handleChange(e, i)}
                        />
                      }
                      label="Zero TDS"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={row[Object.keys(row)[0]]['26Q']['filled']}
                          checked={checkNonzero(
                            row[Object.keys(row)[0]]['26Q']['filled'],
                            row[Object.keys(row)[0]]['26Q']['is_zero'],
                            row[Object.keys(row)[0]]['26Q']['is_non_zero']
                          )}
                          name="26Q"
                          onChange={(e) => handleChange(e, i)}
                        />
                      }
                      label="Zero TDS"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={row[Object.keys(row)[0]]['27Q']['filled']}
                          checked={checkNonRegularCompliance(
                            row[Object.keys(row)[0]]['27Q']['filled'],
                            row[Object.keys(row)[0]]['27Q']['is_zero'],
                            row[Object.keys(row)[0]]['27Q']['is_non_zero']
                          )}
                          name="27Q"
                          onChange={(e) => handleChange(e, i)}
                        />
                      }
                      label="Zero TDS"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={row[Object.keys(row)[0]]['27EQ']['filled']}
                          checked={checkNonRegularCompliance(
                            row[Object.keys(row)[0]]['27EQ']['filled'],
                            row[Object.keys(row)[0]]['27EQ']['is_zero'],
                            row[Object.keys(row)[0]]['27EQ']['is_non_zero']
                          )}
                          name="27EQ"
                          onChange={(e) => handleChange(e, i)}
                        />
                      }
                      label="Zero TDS"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const prepareData = () => {
  const data = [];
  const year = ['2021-22', '2022-23', '2023-24'];
  const month = [
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March'
  ];
  for (let i = 0; i < year.length; i++) {
    for (let j = 0; j < month.length; j++) {
      const tmp_data = {
        year: year[i],
        month: month[j],
        onSalary: false,
        onOtherThanSalary: false,
        onNri: true,
        onTcs: true
      };
      data.push(tmp_data);
    }
  }
  return data;
};
