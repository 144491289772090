import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import equal from 'fast-deep-equal';
class BasicChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [props.score],
      options: {
        chart: {
          height: 300,
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%'
            }
          }
        },
        labels: [props.title],
        colors: ['#00AB55']
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.score, prevProps.score)) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.setState({ series: [this.props.score] });
    }
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="radialBar"
              height={250}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BasicChart;
