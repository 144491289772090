import Constants from 'src/utils/constants';

const intialState = {
  gstFillings_3b: [],
  gstFillings_1r: [],
  gst_9: [],
  gst_9c: [],
  gst_demand: [],
  overview: [],
  dueDates: [],
  error: null,
  gstFreq: []
};

export const gstReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_GST_FILLING_3B:
      return { ...state, gstFillings_3b: action.payload };
    case Constants.SET_GST_FILLING_1R:
      return { ...state, gstFillings_1r: action.payload };
    case Constants.SET_GST_DUE_DATES:
      return { ...state, dueDates: action.payload };
    case Constants.SET_GST_DEMAND:
      return { ...state, gst_demand: action.payload };
    case Constants.SET_GST_OVERVIEW:
      return { ...state, overview: action.payload };
    case Constants.SET_GST_ERROR:
      return { ...state, error: action.payload };
    case Constants.SET_GST_9:
      return { ...state, gst_9: action.payload };
    case Constants.SET_GST_9C:
      return { ...state, gst_9c: action.payload };
    case Constants.SET_GST_FREQ:
      return { ...state, gstFreq: action.payload };
    default:
      return state;
  }
};
