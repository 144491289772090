import Constants from 'src/utils/constants';
const intialState = {
  invoice_manage: [],
  invoice_ser:[],
  invoice_subscription:[]
};
export const invoice_reducers= (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_INVOICE_MANAGE:
      return { ...state, invoice_manage: action.payload };
    case Constants.SET_INVOICE_SERVICES:
      return { ...state, invoice_ser: action.payload }
      case Constants.SET_INVOICE_SUBS:
        return { ...state, invoice_subscription: action.payload }  
    default:
      return state;
  }
};