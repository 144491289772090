import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { caroInfo } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Caro() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .AuditorsDetailReportAndCaro.AuditorsDetail
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  const fields = {
    FixedAssetsRelatedDisclosures: 'Fixed Assets Related Disclosures',
    QuantitativeDetailsOfFixedAssets: 'Quantitative details of Fixed Assets',
    PhysicalVerificationOfFixedAssets: 'Physical Verification of Fixed Assets',
    InventoriesRelatedDisclosures: 'Inventories Related Disclosures',
    LoansRelatedDisclosures: 'Loans Related Disclosures',
    DisclosureAboutLoansGrantedToPartiesCoveredUnderSection189OfCompaniesAct:
      'Disclosure about loans granted to parties covered under section 189 of companies act',
    DisclosureRegardingReceiptOfLoansGranted: 'Disclosure regarding receipt of loans granted',
    TermsOfRecoveryOfLoansGranted: 'Terms of recovery of Loans granted',
    DepositsAccepted: 'Deposits accepted',
    MaintenanceOfCostRecords: 'Maintenance of Cost Records',
    StatutoryDues: ' Statutory Dues',
    PaymentOfUndisputedStatutoryDues: ' Payment of Undisputed Statutory Dues',
    DisputedStatutoryDues: 'Disputed statutory dues',
    DefaultInRepaymentOfFinancialDues: 'Default in repayment of financial dues'
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fields).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return yearToDataMap[year][key] != null ? (
                  <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>
                ) : (
                  <TableCell key={index}>NA</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
