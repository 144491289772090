import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
// import Box from '@mui/material/Box';
import { Button, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSearchParams } from 'react-router-dom';
import { getAllComplaince } from 'src/redux/actions/allcomplaince';
import { deletepreference, getAllPreference, updatepreference } from 'src/redux/actions/preference';
import { PopperUnstyled } from '@mui/base';
import { create_preference } from 'src/redux/actions/preference';
import { getVaultData } from 'src/redux/actions/loginVault';
import { getCompanyData, setCompanyId } from 'src/utils/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { includes, stubFalse } from 'lodash';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { getGSTState } from 'src/utils/common';
import { letterSpacing } from '@mui/system';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { istalenteye, isStartupFino } from 'src/config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(1);
  const vault_data = useSelector((state) => state.vaultReducer?.vault_data);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
    dispatch(getVaultData(companyId));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {!istalenteye && (
        <Box sx={{ flexGrow: 2, bgcolor: 'background.paper', display: 'flex', height: 500 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              Preferences
            </Typography>
            <Tab label="PF Book" {...a11yProps(3)} />
            {/* <Tab label="GST Book" {...a11yProps(1)} /> */}
            <Tab label="TDS Book" {...a11yProps(1)} />
            <Tab label="Income Tax Book" {...a11yProps(2)} />
            <Tab label="GST Book" {...a11yProps(0)} />
            <Tab label="ESI Book" {...a11yProps(4)} />
            <Tab label="ROC Book" {...a11yProps(6)} />
            <Tab label="State Law" {...a11yProps(5)} />
            <Tab label="Central Law" {...a11yProps(7)} />
          </Tabs>
          <TabPanel value={value} index={1}>
          <Tabcontentpf name={'PF'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tabcontenttds name={'TDS'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Tabcontentit name={'Income'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={4}>
          <Tabcontentgst name={'GST'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Tabcontentesi name={'ESI'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Tabcontentroc name={'ROC'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Tabcontentothers name={'SL'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Tabcontentcentral name={'CL'} vault_data={vault_data} />
          </TabPanel>
        </Box>
      )}
      {istalenteye && (
        <Box sx={{ flexGrow: 2, bgcolor: 'background.paper', display: 'flex', height: 500 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              Preferences
            </Typography>
            <Tab label="PF Book" {...a11yProps(0)} />
            <Tab label="ESI Book" {...a11yProps(1)} />
            <Tab label="State Law" {...a11yProps(5)} />
            <Tab label="Central Law" {...a11yProps(7)} />
            
          </Tabs>
          <TabPanel value={value} index={1}>
            <Tabcontentpf name={'PF'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tabcontentesi name={'ESI'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Tabcontentothers name={'SL'} vault_data={vault_data} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Tabcontentcentral name={'CL'} vault_data={vault_data} />
          </TabPanel>
        </Box>
      )}
    </>
  );
}

const Tabcontent = (props) => {
  const name_comp = props;
  console.log('Name =', name_comp.name);
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  //console.log("i m complianceiD", all_complaince.all_complaince[3].id)
  const dispatch = useDispatch();

  useEffect(() => {
    setContent(all_complaince.all_complaince);
    // console.log(all_complaince);
  }, [all_complaince]);

  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
  }, []);

  useEffect(() => {
    console.log('All preference ', all_preference);
  }, [all_preference]);

  // console.log(...content);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    if (newInputValue.length > 0) {
      setIsboxclosed(true);
    } else {
      setIsboxclosed(false);
    }
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;
  //console.log("opts",opts)

  //console.log("mappy",mapp)
  return (
    <>
      <Autocomplete
        value={pref_val}
        fullWidth
        label="Search"
        id="controllable-states-demo"
        options={opts}
        onChange={(event, pref_val) => setPref_val(pref_val)}
        sx={{ margin: 1 }}
        onInputChange={handleInputChange}
        renderInput={(params) => <TextField {...params} label=" Search Compliance Name" required />}
      />
      {console.log('checker', isboxclosed, 'selected opt', pref_val)}
      {isboxclosed &&
        all_complaince.all_complaince.map((i) => {
          if (pref_val == i.complianceName && count == 0) {
            compliance_ids = i.id;
            startdt = i.created_at;
            startdt = moment(startdt).format('YYYY-MM-DD');
            comp_nums = i.comp_number;
            count = count + 1;
            console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
          }
        })}

      {isboxclosed && (
        <Popbox
          setIsboxclosed={setIsboxclosed}
          pref_val={pref_val}
          name_comp={name_comp}
          companyId={companyId}
          compliance_ids={compliance_ids}
          //index={index}
        />
      )}

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        {/* <BasicTabs/> */}
        <Stack spacing={2}>
          {all_preference.all_preference
            .filter((x) => x.compliance_name.includes(stringcmp))
            .map((index) => (
              <Box sx={{ display: 'flex', background: '#F4F5F4' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    m: 2
                  }}
                >
                  <Typography variant="h4"> {index.compliance_name}</Typography>
                  <Typography variant="h6"> Starting Date : {index.starting_date}</Typography>
                  <Typography variant="h6"> {index.comp_number}</Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', ml: 60 }}
                >
                  <Update index={index} />
                  <ConfirmProvider>
                    <Delete index={index} />
                  </ConfirmProvider>
                </Box>
              </Box>
            ))}
        </Stack>
      </Box>
    </>
  );
};
const Tabcontentgst = (props) => {
  const { vault_data } = props;
  const name_comp = props
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const gst_data = vault_data?.filter((item) => item.creadName === 'GST');
  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);
  const dispatch = useDispatch();
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
    dispatch(getVaultData(companyId));
  }, []);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes('GST'))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', flexDirection: 'row' }}>
        {/* <Autocomplete
          value={pref_val}
          fullWidth
          label="Search"
          id="controllable-states-demo"
          options={opts}
          onChange={(event, pref_val) => setPref_val(pref_val)}
          sx={{ margin: 1 }}
          //onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField {...params} label=" Search Compliance Name" required />
          )}
        /> */}
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
            }
          })}

        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
            }
          })}

        {isboxclosed && (
          <Popbox
            all_complaince={all_complaince}
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            //index={index}
          />
        )}
        <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
          {' '}
          Add Preference
        </Button>
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <BasicTabsgst
          gst_data={gst_data}
          all_preference={all_preference?.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontenttds = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const tds_data = vault_data?.filter((item) => item.creadName === 'TAN');

  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
  }, []);
  useEffect(() => {
    dispatch(getAllPreference(companyId));
  }, []);
  useEffect(() => {
    console.log('All preference ', all_preference);
  }, [all_preference]);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };

  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(name))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', flexDirection: 'row' }}>
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            all_complaince={all_complaince}
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            //index={index}
          />
        )}
        {tds_data.length === 0 && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
            {' '}
            Add Preference
          </Button>
        )}

      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <Tdstabs
          tds_data={tds_data[0]}
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontentit = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const it_data = vault_data?.filter((item) => item.creadName === 'PAN');
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
  }, []);
  useEffect(() => {
    dispatch(getAllPreference(companyId));
  }, []);
  useEffect(() => {
    console.log('All preference ', all_preference);
  }, [all_preference]);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}

        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            all_complaince={all_complaince}
            //index={index}
          />
        )}
        {it_data.length === 0 && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
            {' '}
            Add Preference
          </Button>
        )}

      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <Ittabs
          it_data={it_data[0]}
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontentpf = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const pf_data = vault_data?.filter((item) => item.creadName === 'EPF');
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
    dispatch(getVaultData(companyId));
  }, []);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}

        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
            }
          })}

        {isboxclosed && (
          <Popbox
            all_complaince={all_complaince}
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            //index={index}
          />
        )}
        {pf_data?.length === 0 && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
            {' '}
            Add Preference
          </Button>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <Pftabs
          pf_data={pf_data?.[0]}
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontentcentral = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const dispatch = useDispatch();
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
  }, []);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            all_complaince={all_complaince}
          />
        )}
 
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <OthersCentraltabs
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontentothers = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);
  console.log("all_preference",all_preference)
  const st_data = vault_data;
  const dispatch = useDispatch();
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
  }, []);
  useEffect(() => {
    dispatch(getAllPreference(companyId));
  }, []);
  useEffect(() => {
    console.log('All preference ', all_preference);
  }, [all_preference]);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  let state_law = all_complaince?.all_complaince?.filter((item) => item.category.includes('Labour'));
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', flexDirection: 'row' }}>
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            all_complaince={state_law}
            //index={index}
          />
        )}
        <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
          {' '}
          Add Preference
        </Button>
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <BasicTabsother
          stringcmp={stringcmp}
          st_data={st_data[0]}
          all_preference={all_preference.all_preference}
          state_law={state_law}
          companyId={companyId}
          all_complaince={all_complaince.all_complaince}
        />
      </Box>
    </>
  );
};
const Tabcontentesi = (props) => {
  const { vault_data } = props;
  const name_comp = props;
  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const esi_data = vault_data?.filter((item) => item.creadName === 'ESIC');
  console.log(esi_data);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
  }, []);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', flexDirection: 'row' }}>
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            all_complaince={all_complaince}
          />
        )}
        {!istalenteye && <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
          {' '}
          Add Preference
        </Button>}
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <BasicTabsesi
          stringcmp={stringcmp}
          esi_data={esi_data[0]}
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Tabcontentroc = (props) => {
  const { vault_data } = props;
  const name_comp = props;

  const [content, setContent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const navigate = useNavigate();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);

  const roc_data = vault_data?.filter((item) => item.creadName === 'CIN');

  const dispatch = useDispatch();
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
  }, []);
  useEffect(() => {
    dispatch(getAllPreference(companyId));
  }, []);
  useEffect(() => {
    console.log('All preference ', all_preference);
  }, [all_preference]);

  const [pref_val, setPref_val] = useState();
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log(searchedVal);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  let stringcmp = name_comp.name;
  const [isboxclosed, setIsboxclosed] = useState(false);
  const handleInputChange = (event, newInputValue) => {
    setIsboxclosed(true);
  };
  const [complianceId, setComplianceId] = useState('');
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(stringcmp))
    .map((option) => option.complianceName);
  var compliance_ids, startdt, comp_nums;
  let count = 0;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        {/*<Autocomplete
          value={pref_val}
          fullWidth
          label="Search"
          id="controllable-states-demo"
          options={opts}
          onChange={(event, pref_val) => setPref_val(pref_val)}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label=" Search Compliance Name" required />} />*/}
        {console.log('checker', isboxclosed, 'selected opt', pref_val)}
        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed &&
          all_complaince.all_complaince.map((i) => {
            if (pref_val == i.complianceName && count == 0) {
              compliance_ids = i.id;
              startdt = i.created_at;
              startdt = moment(startdt).format('YYYY-MM-DD');
              comp_nums = i.comp_number;
              count = count + 1;
              console.log('comp id', compliance_ids, 'start', startdt, 'comp_num', comp_nums);
            }
          })}

        {isboxclosed && (
          <Popbox
            setIsboxclosed={setIsboxclosed}
            pref_val={pref_val}
            name_comp={name_comp}
            companyId={companyId}
            compliance_ids={compliance_ids}
            all_complaince={all_complaince}
            //index={index}
          />
        )}
        {roc_data.length === 0 && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleInputChange}>
            {' '}
            Add Preference
          </Button>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          my: 2,
          overflow: 'auto',
          maxHeight: 400
        }}
      >
        <Roctabs
          roc_data={roc_data[0]}
          all_preference={all_preference.all_preference}
          all_complaince={all_complaince.all_complaince}
          companyId={companyId}
        />
      </Box>
    </>
  );
};
const Delete = (props) => {
  const { index } = props;
  console.log('Row on delete', index);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: 'This will permanently delete ' })
      .then(() => {
        dispatch(deletepreference(index.id));
        console.log('delete Success');
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()} sx={{ my: 4 }}>
        {' '}
        Delete
      </Button>
    </>
  );
};
const Update = (props) => {
  const { index } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [formData, setFormData] = useState({
    compliance_name: '',
    comp_number: '',
    starting_date: ''
    // gst_copy: ''
  });
  const dispatch = useDispatch();

  const [value, setValue] = useState(index.starting_date);
  const [cname, setCName] = useState(index.compliance_name);
  const [cnumber, setCNumber] = useState(index.comp_number);
  // console.log("formdata",formData);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dis = (e) => {
    console.log('form data', formData);
    // formData['compliance_name'] = index.compliance_name;
    formData['is_active'] = true;
    formData['company_id'] = index.company_id;
    formData['compliance_id'] = index.compliance_id;
    // formData['starting_date'] = index.starting_date;
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    console.log('form data', bodyFormData);
    dispatch(updatepreference(index.id, bodyFormData));
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen('paper')} sx={{ my: 4, mr: 2 }}>
        {' '}
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{index.compliance_name}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Box sx={{ m: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
              <TextField
                sx={{ my: 2 }}
                label="Compliance Name"
                variant="outlined"
                value={cname}
                onChange={(e) => {
                  setCName(e.target.value);
                  {
                    formData.compliance_name = e.target.value;
                  }
                }}
                required
              />
              <TextField
                sx={{ my: 2 }}
                label="Compliance Number"
                variant="outlined"
                value={cnumber}
                onChange={(e) => {
                  setCNumber(e.target.value);
                  {
                    formData.comp_number = e.target.value;
                  }
                }}
                required
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Starting Date"
                  value={value}
                  name="starting_date"
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setValue(newValue);
                    {
                      newValue = moment(newValue).format('YYYY-MM-DD');
                      formData.starting_date = newValue;
                    }
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
                />
              </LocalizationProvider>
              <Box
                container
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                sx={{ margin: 1 }}
              >
                <Button onClick={dis} variant="contained">
                  SAVE
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Popbox = (props) => {
  const { all_complaince, setIsboxclosed, pref_val, name_comp, companyId, compliance_ids } = props;
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setIsboxclosed(false);
  };

  return (
    <>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            //sx={boxDefault}
          >
            {pref_val}

            <CloseRoundedIcon onClick={handleClose} style={{ color: '#ff5252' }} boxShadow={1} />
          </Box>
        </DialogTitle>

        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {name_comp.name.includes('GST') && (
              <Gst_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('ESI') && (
              <Esic_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('PF') && (
              <Pf_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('Income') && (
              <It_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('TDS') && (
              <Tds_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('ROC') && (
              <Roc_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('SL') && (
              <State_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
            {name_comp.name.includes('CL') && (
              <Cl_pref
                all_complaince={all_complaince}
                companyId={companyId}
                pref_val={pref_val}
                compliance_ids={compliance_ids}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Cl_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  //const [starting_date, setStarting_date] = useState();
  const states = [
'Andaman and Nicobar',
'Andhra Pradesh',
'Arunachal Pradesh',
'Assam',
'Bihar',
'Chandigarh',
'Chattisgarh',
'Dadra and Nagar Haveli & Daman and Diu',
'Delhi',
'Goa',
'Gujarat',
'Haryana',
'Himanchal Pradesh',
'Jammu and Kashmir',
'Jharkhand',
'Karnataka',
'Kerala',
'Ladakh',
'Lakshyadeep',
'Madhya Pradesh',
'Maharahtra',
'Manipur',
'Meghalaya',
'Mizoram',
'Nagaland',
'Oddisaa',
'Puducherry',
'Punjab',
'Rajasthan',
'Sikkim',
'Tamil Nadu',
'Telangana',
'Tripura',
'Uttar Pradesh',
'Uttarakhand',
'West Bengal'
  ];

  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    const bodyFormData = new FormData();
    console.log('companyid', companyId);
    formData['company_id'] = companyId;
    formData['is_active'] = true;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'POSH Return'
    );
    console.log('Data => ', data[0].id);
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;
    //formDatap['complianceId']=''
    formData.starting_date = moment(formData.starting_date).format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
            />
          </LocalizationProvider>

          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
const State_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [state, setState] = useState();
  //const [starting_date, setStarting_date] = useState();
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  const [value, setValue] = useState(company_data.date_of_inc);
  const states = [
    'Andaman and Nicobar',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chattisgarh',
    'Dadra and Nagar Haveli & Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himanchal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshyadeep',
    'Madhya Pradesh',
    'Maharahtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Oddisaa',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];

  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: '',
    state: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    let compliance_datas;

    // if (state !== 'Delhi') {
      
    // } else {
    //   compliance_datas = all_complaince.filter(
    //     (item) =>
    //       item.category === 'Labour Registers' && item.state==='Delhi'
    //   );
    // }
    compliance_datas = all_complaince.filter(
      (item) => item.category === 'Labour Registers' && (item.state===state || item.state==='All States')
    );
    console.log('compliance_datas', compliance_datas)
    compliance_datas.forEach(compliance_data => {
      const preference = {
        compliance_name: compliance_data.complianceName,
        compliance_id: compliance_data.id,
        comp_number: 'NA',
        state: state,
        starting_date: moment(value).format('YYYY-MM-DD'),
        is_active: true,
        company_id: companyId
      };
      dispatch(create_preference(preference, companyId, false));
      
    });

    
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
            />
          </LocalizationProvider>
          <Autocomplete
            id="combo-box-demo"
            options={states}
            onChange={(event, newValue) => {
              setState(newValue);
            }}
            //sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />

          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};

const Roc_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    const bodyFormData = new FormData();
    console.log('companyid', companyId);
    formData['company_id'] = companyId;
    formData['is_active'] = true;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'MGT-7 Form'
    );
    console.log('Data => ', data[0].id);
    //formDatap['complianceId']=''
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;
    formData.starting_date = moment(formData.starting_date).format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            value={comp_number}
            name="company number"
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="CIN Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
            />
          </LocalizationProvider>

          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};

const Gst_pref = (props) => {
  const [state, setState] = useState();
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  const states = [
    'Andaman and Nicobar',
'Andhra Pradesh',
'Arunachal Pradesh',
'Assam',
'Bihar',
'Chandigarh',
'Chattisgarh',
'Dadra and Nagar Haveli & Daman and Diu',
'Delhi',
'Goa',
'Gujarat',
'Haryana',
'Himanchal Pradesh',
'Jammu and Kashmir',
'Jharkhand',
'Karnataka',
'Kerala',
'Ladakh',
'Lakshyadeep',
'Madhya Pradesh',
'Maharahtra',
'Manipur',
'Meghalaya',
'Mizoram',
'Nagaland',
'Oddisaa',
'Puducherry',
'Punjab',
'Rajasthan',
'Sikkim',
'Tamil Nadu',
'Telangana',
'Tripura',
'Uttar Pradesh',
'Uttarakhand',
'West Bengal'
  ];

  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [gststate, setgststate] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: '',
    state: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    // console.log(name);
    // console.log(valuee);
    // console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    const bodyFormData = new FormData();
    // console.log('companyid', companyId)
    formData['company_id'] = companyId;
    formData['compliance_id'] = compliance_ids;
    // console.log("complianceids", compliance_ids)
    // formData['compliance_name'] = pref_val
    formData['is_active'] = true;
    // console.log("Gst state",gststate," => ",formData.state);
    console.log('New Gst state', gststate);
    console.log('Complaince', all_complaince.all_complaince);
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === gststate && item.frequency === 'Monthly'
    );
    console.log('data => ', data[0].id);
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;

    // setgststate(formData.state);

    //formDatap['complianceId']=''
    formData.starting_date = moment(formData.starting_date).format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            required
            value={comp_number}
            name="company number"
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="GST Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
            />
          </LocalizationProvider>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={states}
            onChange={(event, newValue) => {
              setState(newValue);
              setgststate('GSTR_1_'.concat(newValue));
              {
                formData.state = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            //sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
const Esic_pref = (props) => {
  const [state, setState] = useState();
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  const states = [
    'Andaman and Nicobar',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chattisgarh',
    'Dadra and Nagar Haveli & Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himanchal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshyadeep',
    'Madhya Pradesh',
    'Maharahtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Oddisaa',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];
  console.log("pref_val",pref_val)
  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: '',
    state: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    const bodyFormData = new FormData();
    formData['company_id'] = companyId;
    formData['is_active'] = true;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'ESIC_Challan'
    );
    console.log('Data => ', data[0].id);
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;

    //formDatap['complianceId']=''
    formData.starting_date = moment(formData.starting_date).format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            required
            value={comp_number}
            name="company number"
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="ESI Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
            />
          </LocalizationProvider>
          <Autocomplete
            id="combo-box-demo"
            options={states}
            onChange={(event, newValue) => {
              setState(newValue);
              {
                formData.state = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            //sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
const Pf_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    console.log('companyid', companyId);
    formData['company_id'] = companyId;
    // //console.log(index)
    // formData['compliance_id'] = compliance_ids;
    // formData['compliance_name'] = pref_val;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'EPF_Challan'
    );
    console.log('Data => ', data[0].id);
    //formDatap['complianceId']=''
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;
    formData['is_active'] = true;
    const bodyFormData = new FormData();
    //formDatap['complianceId']=''
    formData.starting_date = moment(formData.starting_date).format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            value={comp_number}
            name="company number"
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="Compliance Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
            />
          </LocalizationProvider>

          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
const It_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    // complianceName: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    const bodyFormData = new FormData();
    console.log('companyid', companyId);
    formData['company_id'] = companyId;
    // formData['compliance_id'] = compliance_ids;
    // formData['compliance_name'] = pref_val;
    formData['is_active'] = true;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'Income Tax Return' && item.frequency === 'Yearly'
    );
    console.log('Data => ', data[0].id);
    //formDatap['complianceId']=''
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;
    //formDatap['complianceId']=''
    formData['starting_date'] = moment().format('YYYY-MM-DD');
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            value={comp_number}
            name="company number"
            defaultValue={pref_val}
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="PAN Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
            />
          </LocalizationProvider>
          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
const Tds_pref = (props) => {
  const { all_complaince, companyId, pref_val, compliance_ids } = props;
  const [value, setValue] = useState(null);
  //const [starting_date, setStarting_date] = useState();
  const [comp_number, setComp_number] = useState();
  const [formData, setFormData] = useState({
    starting_date: '',
    comp_number: ''
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    // console.log('HEllo WORLD');
  };
  const dispatch = useDispatch();
  const dis = (e) => {
    console.log('form data', formData);
    console.log('companyid', companyId);
    formData['company_id'] = companyId;
    // formData['compliance_id'] = compliance_ids;
    // formData['compliance_name'] = pref_val;
    // console.log("all_complaince => ", all_complaince);
    formData['is_active'] = true;
    const data = all_complaince.all_complaince.filter(
      (item) => item.complianceName === 'TDS Challan_26Q' && item.frequency === 'Monthly'
    );
    console.log('Data => ', data[0].id);
    //formDatap['complianceId']=''
    formData['compliance_id'] = data[0].id;
    formData['compliance_name'] = data[0].complianceName;
    formData['starting_date'] = moment().format('YYYY-MM-DD');
    const bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(create_preference(bodyFormData, companyId));
  };

  return (
    <>
      <form>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <TextField
            value={comp_number}
            name="company number"
            defaultValue={pref_val}
            onChange={(e) => {
              setComp_number(e.target.value);
              {
                formData.comp_number = e.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="Compliance Number"
            variant="outlined"
            sx={{ margin: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Starting Date"
              value={value}
              name="starting_date"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.starting_date = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
            />
          </LocalizationProvider>
          <br></br>
          <Button onClick={dis} variant="contained" color="primary">
            Add Preference
          </Button>
        </Box>
      </form>
    </>
  );
};
function HtabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

HtabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProp(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function BasicTabsother(props) {
  const [value, setValue] = React.useState(0);
  const { st_data, all_preference, state_law, companyId, all_complaince } = props;
  let law_name = state_law?.map((item) => {
    return item.complianceName;
  });

  law_name = law_name.filter((item, i, ar) => ar.indexOf(item) === i);

  const state_pref = all_preference?.filter(
    (item) => law_name.includes(item.compliance_name) === true
  );
  let states_list = state_pref.map((item) => {
    return item.state;
  });

  states_list = states_list.filter((item, i, ar) => ar.indexOf(item) === i && item !== null);

  const [selectedState, setStateName] = useState(states_list[0]);

  const handleChange = (event, newValue) => {
    setStateName(states_list[newValue]);
    setValue(newValue);
  };


  return (
    <>
      {/* {all_preference.all_preference.filter((x) => x.compliance_name.includes(stringcmp)).map((index,i) => */}
      <Box sx={{ width: 1000 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {states_list.map((item, index) => {
              return <Tab label={item} {...a11yProp(index)} />;
            })}
          </Tabs>
        </Box>
          <HtabPanel value={value} index={value}>
            <OthersStatetabs
              st_data={st_data}
              all_preference={state_pref}
              all_complaince={all_complaince}
              companyId={companyId}
              selectedState={selectedState}
            />
          </HtabPanel>
      </Box>
      {/* )} */}
    </>
  );
}
function BasicTabsesi(props) {
  const [value, setValue] = React.useState(0);
  const { esi_data, all_preference, all_complaince, companyId, stringcmp } = props;
  
  const esi_pref = all_preference.filter((item) => item.compliance_name === 'ESIC_Challan');
  console.log("esi_pref",esi_pref)
  let states_list = esi_pref.map((item) => {
    let state_name = item.state === null ? 'NA' : item.state;
    return state_name;
  });
  console.log("esi states",states_list)
  states_list = states_list.filter((item) => item !== 'NA')
  states_list = states_list.filter((item, i, ar) => ar.indexOf(item) === i);
  const [stateName, setStatename] = useState(states_list[0]);

  const handleChange = (event, newValue) => {
    console.log('newValue', states_list[newValue]);
    setValue(newValue);
    setStatename(states_list[newValue]);
  };

  return (
    <>
      {/* {all_preference.all_preference.filter((x) => x.compliance_name.includes(stringcmp)).map((index,i) => */}
      <Box sx={{ width: 1000 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {states_list.map((item, index) => {
              return <Tab label={item} {...a11yProp(index)} />;
            })}
          </Tabs>
        </Box>
        <HtabPanel value={value} index={value}>
          <Esitabs
            esi_data={esi_data}
            all_preference={esi_pref}
            all_complaince={all_complaince}
            companyId={companyId}
            stateName={stateName}
          />
        </HtabPanel>
      </Box>
      {/* )} */}
    </>
  );
}
function BasicTabsgst(props) {
  const [value, setValue] = React.useState(0);
  const { gst_data, all_preference, all_complaince, companyId } = props;
  const gst_pref = all_preference.filter((item) => item.compliance_name.includes('GST'));
  let states_list = gst_pref.map((item) => {
    const state_name = item.compliance_name.split('_');
    return state_name.at(-1);
  });
  let login_value_states_list = []


  if(gst_data !== undefined){
    login_value_states_list = gst_data.length > 0 ? gst_data?.map((item) => getGSTState(item.creadNum)) : [];
    states_list = [...new Set([...states_list, ...login_value_states_list])]
  }

  states_list = states_list.filter((item, i, ar) => ar.indexOf(item) === i);

  states_list = states_list.filter((item) => item.includes('GST') === false);

  const [selectedState, setStateName] = useState(states_list[0]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStateName(states_list[newValue]);
  };
  return (
    <>
      {/* {all_preference.all_preference.filter((x) => x.compliance_name.includes(stringcmp)).map((index,i) => */}
      <Box sx={{ width: 1000 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {states_list.map((item, index) => {
              return <Tab label={item} {...a11yProp(index)} />;
            })}
          </Tabs>
        </Box>
        <HtabPanel value={value} index={value}>
          <Gsttabs
            all_complaince={all_complaince}
            all_preference={gst_pref}
            state_name={selectedState}
            gst_data={gst_data}
            companyId={companyId}
          />
        </HtabPanel>
      </Box>
      {/* )} */}
    </>
  );
}

const OthersStatetabs = (props) => {
  const { st_data, all_preference, all_complaince, companyId, selectedState } = props;
  var d = new Date();
  d.setMonth(3);
  const dispatch = useDispatch();
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  console.log('date month', month1);
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  //var valuz = new Date(22-Apr);
  const [prof_tax, setProf_tax] = useState(false);
  const [lab_fund, setLab_fund] = useState(false);
  const [lab_reg, setLab_reg] = useState(false);
  const [lab_fil, setLab_fil] = useState(false);
  const [man_ret, setMenRet] = useState(false);
  const [bonus, setBonus] = useState(false);
  const [stdate_1, setStdate_1] = useState(company_data.date_of_inc);
  const [stdate_2, setStdate_2] = useState(company_data.date_of_inc);
  const [stdate_3, setStdate_3] = useState(company_data.date_of_inc);
  const [stdate_4, setStdate_4] = useState(company_data.date_of_inc);
  const [stdate_5, setStdate_5] = useState(company_data.date_of_inc);
  const [stdate_6, setStdate_6] = useState(company_data.date_of_inc);
  console.log("statelaw",selectedState)

  let labour_complianes = all_complaince.filter((item) => item.category === 'Labour Registers' && (item.state===selectedState || item.state==='All States'));

  labour_complianes = labour_complianes.map((item) => {return item.complianceName})

  let labour_filling_compliances = all_complaince.filter((item) => item.category === 'Labour Fillings' && (item.state===selectedState || item.state==='All States'));
  labour_filling_compliances = labour_filling_compliances.map((item) => {return item.complianceName});
  console.log('Labour Filling', labour_filling_compliances);

  let profe_tax_allow = all_complaince.filter((item) => item.complianceName === 'Professional Tax Payment')
  profe_tax_allow = profe_tax_allow.map((item) => {return item.state})

  profe_tax_allow = profe_tax_allow.filter((item, i, ar) => ar.indexOf(item) === i && item !== null);

  let labour_wel_fund_allow = all_complaince.filter((item) => item.complianceName === 'Labour Welfare Fund Payment')

  labour_wel_fund_allow = labour_wel_fund_allow.map((item) => {return item.state})

  labour_wel_fund_allow = labour_wel_fund_allow.filter((item, i, ar) => ar.indexOf(item) === i && item !== null);

  useEffect(() => {
    const profdata = all_preference.filter(
      (item) => item.compliance_name === 'Professional Tax Payment' && item.state === selectedState
    );
    if (profdata.length > 0) {
      setStdate_1(profdata[0].starting_date);
      setProf_tax(profdata[0].is_active);
    } else {
      setProf_tax(false);
      setStdate_1(company_data.date_of_inc);
    }

    const labourdata = all_preference.filter(
      (item) =>
        item.compliance_name === 'Labour Welfare Fund Payment' && item.state === selectedState
    );
    if (labourdata.length > 0) {
      setStdate_2(labourdata[0].starting_date);
      setLab_fund(labourdata[0].is_active);
    } else {
      setLab_fund(false);
      setStdate_2(company_data.date_of_inc);
    }

    const labour_fill_data = all_preference.filter(
      (item) => labour_filling_compliances.includes(item.compliance_name)  && item.state === selectedState
    );
    console.log('Labour Filling 2', labour_fill_data);
    if (labour_fill_data.length > 0) {
      setStdate_4(labour_fill_data[0].starting_date);
      setLab_fil(labour_fill_data[0].is_active);
    } else {
      setLab_fil(false);
      setStdate_4(company_data.date_of_inc);
    }

    const labour_regdata = all_preference.filter(
      (item) =>
        labour_complianes.includes(item.compliance_name)  &&
        item.state === selectedState
    );
    if (labour_regdata.length > 0) {
      setStdate_3(labour_regdata[0].starting_date);
      setLab_reg(labour_regdata[0].is_active);
    } else {
      setLab_reg(false);
      setStdate_3(company_data.date_of_inc);
    }

    const man_retdata = all_preference.filter(
      (item) => item.compliance_name === 'Maternity Benefits Return' && item.state === selectedState
    );
    if (man_retdata.length > 0) {
      setStdate_5(man_retdata[0].starting_date);
      setMenRet(man_retdata[0].is_active);
    } else {
      setMenRet(false);
      setStdate_5(company_data.date_of_inc);
    }

    const data = all_preference.filter(
      (item) =>
        item.compliance_name === 'Payment of Bonus Act Return' && item.state === selectedState
    );
    if (data.length > 0) {
      setStdate_6(data[0].starting_date);
      setBonus(data[0].is_active);
    } else {
      setBonus(false);
      setStdate_6(company_data.date_of_inc);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    if (event.target.name === 'prof') {
      setProf_tax(event.target.checked);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Professional Tax Payment' && item.state === selectedState
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, stdate_1);
      } else {
        create_new_preference('Professional Tax Payment');
      }
    } else if (event.target.name === 'labour') {
      setLab_fund(event.target.checked);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Labour Welfare Fund Payment' && item.state === selectedState
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, stdate_2);
      } else {
        create_new_preference('Labour Welfare Fund Payment');
      }
    } else if (event.target.name === 'labour_reg') {
      setLab_reg(event.target.checked);
      const data = all_preference.filter(
        (item) =>
        labour_complianes.includes(item.compliance_name)  &&
        item.state === selectedState
      );
      if (data.length > 0) {
        data.forEach(element => {
          update_preference(element, event.target.checked, stdate_3);
        });
        
      } else {
        create_new_preference('Labour Registers', true);
      }
    } else if (event.target.name === 'labour_fil') {
      setLab_fil(event.target.checked);
      const labour_filling_pref_data = all_preference.filter(
        (item) => labour_filling_compliances.includes(item.compliance_name)  && item.state === selectedState
      );
      console.log('Labour Filling 2', labour_filling_pref_data);
      if (labour_filling_pref_data.length > 0) {
        labour_filling_pref_data.forEach(element => {
          update_preference(element, event.target.checked, stdate_4);
        });
      } else {
        create_new_preference('Labour Fillings', true);
      }
    } else if (event.target.name === 'man_ret') {
      setMenRet(event.target.checked);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Maternity Benefits Return' && item.state === selectedState
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, stdate_5);
      } else {
        create_new_preference('Maternity Benefits Return');
      }
    } else if (event.target.name === 'bonus') {
      setBonus(event.target.checked);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Payment of Bonus Act Return' && item.state === selectedState
      );
      const data_labour = all_preference.filter(
        (item) =>
          item.compliance_name === 'Labour Law Register (The Payment of Bonus Act 1965)' &&
          item.state === selectedState
      );

      if (data.length > 0) {
        update_preference(data[0], event.target.checked, stdate_6);
        update_preference(data_labour[0], event.target.checked, stdate_6);
      } else {
        create_new_preference('Payment of Bonus Act Return');
        create_new_preference('Labour Law Register (The Payment of Bonus Act 1965)');
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === 'prof') {
      setStdate_1(value);
      const mbmin_data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Professional Tax Payment' && item.state === selectedState
      );
      if (mbmin_data.length > 0) {
        update_preference(mbmin_data[0], prof_tax, value);
      }
    } else if (name === 'labour') {
      setStdate_2(value);
      const msmin_data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Labour Welfare Fund Payment' && item.state === selectedState
      );
      if (msmin_data.length > 0) {
        update_preference(msmin_data[0], lab_fund, value);
      }
    } else if (name === 'labour_reg') {
      setStdate_3(value);
      const lab_reg_datas = all_preference.filter(
        (item) =>
          labour_complianes.includes(item.compliance_name)  &&
          item.state === selectedState
      );
      if (lab_reg_datas.length > 0) {
        lab_reg_datas.forEach(element => {
          update_preference(element, lab_reg, value);
        });
      }
    }else if (name === 'man_ret') {
      setStdate_5(value);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Maternity Benefits Return' && item.state === selectedState
      );
      if (data.length > 0) {
        update_preference(data[0], man_ret, value);
      }
    } else if (name === 'labour_fil') {
      setStdate_4(value);
      const data = all_preference.filter(
        (item) =>
        labour_filling_compliances.includes(item.compliance_name)  && item.state === selectedState
      );
      if (data.length > 0) {
        update_preference(data[0], lab_fil, value);
      }
    } else if (name === 'bonus') {
      setStdate_6(value);
      const data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Payment of Bonus Act Return' && item.state === selectedState
      );
      const data_labour = all_preference.filter(
        (item) =>
          item.compliance_name === 'Labour Law Register (The Payment of Bonus Act 1965)' &&
          item.state === selectedState
      );
      if (data_labour.length > 0) {
        update_preference(data[0], bonus, value);
        update_preference(data_labour[0], bonus, value);
      }
    }
  };

  const create_new_preference = (compliance_name, is_category=false) => {
    if(!is_category){
      let compliance_data = all_complaince.filter((item) => item.complianceName === compliance_name);

      if (compliance_data.length > 0) {
        compliance_data = all_complaince.filter(
          (item) => item.complianceName === compliance_name && item.state === selectedState
        );
      }
      compliance_data = compliance_data[0];
      const preference = {
        compliance_name: compliance_data.complianceName,
        compliance_id: compliance_data.id,
        comp_number: 'NA',
        starting_date: company_data.date_of_inc,
        state: selectedState,
        is_active: true,
        company_id: companyId
      };
      dispatch(create_preference(preference, companyId, false));
    }else{
      let filter_compliance_data = all_complaince.filter((item) => item.category === compliance_name && (item.state===selectedState || item.state==='All States'));

      filter_compliance_data.forEach(compliance_data => {
        const preference = {
          compliance_name: compliance_data.complianceName,
          compliance_id: compliance_data.id,
          comp_number: 'NA',
          starting_date: company_data.date_of_inc,
          state: selectedState,
          is_active: true,
          company_id: companyId
        };
        dispatch(create_preference(preference, companyId, false));
      });
    }
    
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  const delete_preference = (e) => {
    
    e.preventDefault();
    const profdata = all_preference.filter(
      (item) => item.compliance_name === 'Professional Tax Payment' && item.state === selectedState
    );
    if (profdata.length > 0) {
      dispatch(deletepreference(profdata[0].id));
    }

    const labourdata = all_preference.filter(
      (item) =>
        item.compliance_name === 'Labour Welfare Fund Payment' && item.state === selectedState
    );
    if (labourdata.length > 0) {
      dispatch(deletepreference(labourdata[0].id));
    }

    const labour_fill_data = all_preference.filter(
      (item) => labour_filling_compliances.includes(item.compliance_name) && item.state === selectedState
    );
    if (labour_fill_data.length > 0) {
      labour_fill_data.forEach(element => {
        dispatch(deletepreference(element.id));
      });
    }

    const labour_regdata = all_preference.filter(
      (item) =>
      labour_complianes.includes(item.compliance_name) &&
        item.state === selectedState
    );
    if (labour_regdata.length > 0) {
      labour_regdata.forEach(element => {
        dispatch(deletepreference(element.id));
      });
    }

    const man_retdata = all_preference.filter(
      (item) => item.compliance_name === 'Maternity Benefits Return' && item.state === selectedState
    );
    if (man_retdata.length > 0) {
      dispatch(deletepreference(man_retdata[0].id));
    }

    const data = all_preference.filter(
      (item) =>
        item.compliance_name === 'Payment of Bonus Act Return' && item.state === selectedState
    );
    if (data.length > 0) {
      dispatch(deletepreference(data[0].id));
    }
  };

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          Date of Registration :{moment(company_data.date_of_inc).format('DD-MM-YYYY')}
        </Typography>
        {/* <Typography variant="h6" sx={{ ml: 20 }}> Date of Registration : </Typography> */}
      </Box>
      {selectedState === undefined ? (
        <>
          <Stack sx={{ width: '60%' }} spacing={2}>
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              State Law need a <strong>State</strong> please — <strong> Add Preference !</strong>{' '}
              from Above Button
            </Alert>
          </Stack>
        </>
      ) : (
        <>
          <Table>
            {profe_tax_allow.includes(selectedState) && (
              <TableHead>
                <TableCell>
                  <Checkbox name="prof" onChange={handleChange} checked={prof_tax} />
                </TableCell>
                <TableCell>Professional Tax</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                      defaultCalendarMonth={d}
                      value={stdate_1}
                      onChange={(newValue) => {
                        handleDateChange('prof', newValue);
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableHead>
            )}
            {labour_wel_fund_allow.includes(selectedState) && (
              <TableHead>
                <TableCell>
                  <Checkbox name="labour" onChange={handleChange} checked={lab_fund} />
                </TableCell>
                <TableCell>Labour Welfare Fund</TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                      defaultCalendarMonth={d}
                      value={stdate_2}
                      onChange={(newValue) => {
                        handleDateChange('labour', newValue);
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableHead>
            )}
            <TableHead>
              <TableCell>
                <Checkbox name="labour_reg" onChange={handleChange} checked={lab_reg} />
              </TableCell>
              <TableCell>Labour Registers</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    defaultCalendarMonth={d}
                    value={stdate_3}
                    onChange={(newValue) => {
                      handleDateChange('labour_reg', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="labour_fil" onChange={handleChange} checked={lab_fil} />
              </TableCell>
              <TableCell>Labour filings</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    defaultCalendarMonth={d}
                    value={stdate_4}
                    onChange={(newValue) => {
                      handleDateChange('labour_fil', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="man_ret" onChange={handleChange} checked={man_ret} />
              </TableCell>
              <TableCell>Maternity Benefits Returns</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    defaultCalendarMonth={d}
                    value={stdate_5}
                    onChange={(newValue) => {
                      handleDateChange('man_ret', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="bonus" onChange={handleChange} checked={bonus} />
              </TableCell>
              <TableCell>Bonus Act Returns/Registers</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    defaultCalendarMonth={d}
                    value={stdate_6}
                    onChange={(newValue) => {
                      handleDateChange('bonus', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            {/* <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button> */}
            <Button variant="contained" color="error" sx={{ m: 2 }} onClick={delete_preference}>
              {' '}
              Delete State
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
const Esitabs = (props) => {
  const { esi_data, all_preference, all_complaince, companyId, stateName } = props;
  const dispatch = useDispatch();
  var d = new Date();
  d.setMonth(3);
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  console.log("stateName",stateName)
  const preference_data = all_preference.filter((item) => item.state === stateName);
  const selected_state = stateName === 'NA' ? null : stateName;

  const starting_date =
    preference_data[0]?.date_of_registration === null
      ? company_data.date_of_inc
      : preference_data[0]?.date_of_registration;
  const [tdsdate_1, setTdsdate_1] = useState(starting_date);
  const [challan, setchallan] = React.useState(false);

  console.log('preference_data', preference_data);

  useEffect(() => {
    const data = all_preference.filter(
      (item) =>  (item.compliance_name === 'ESIC_Challan' || item.compliance_name.includes('ESIC')) && item.state === selected_state
    );
    if (data.length > 0) {
      setTdsdate_1(data[0].starting_date);
      setchallan(data[0].is_active);
    } else {
      setTdsdate_1(starting_date);
      setchallan(false);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    console.log("selected_state",stateName)

    if (event.target.name === 'Challan') {
      setchallan(event.target.checked);
      const data = preference_data.filter(
        (item) => (item.compliance_name === 'ESIC_Challan' || item.compliance_name.includes('ESIC')) && item.state === selected_state);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_1);
      } else {
        create_new_preference('ESIC_Challan',selected_state);
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === 'Challan') {
      const data = all_preference.filter(
        (item) =>  (item.compliance_name === 'ESIC_Challan' || item.compliance_name.includes('ESIC')) && item.state === selected_state
      );
      if (data.length > 0) {
        update_preference(data[0], challan, value);
      }
    }
  };

  const create_new_preference = (compliance_name,selected_state) => {
    console.log("compliance_name",compliance_name)
    console.log("all_compliance",all_complaince)
    const esi_ref = all_preference.filter((item) => item.compliance_name.includes('ESI'));
    console.log("esi_ref",esi_ref)
    
    const compliance_data = all_complaince.filter(
      (item) => item.compliance_name === compliance_name && item.state === stateName
    )[0];
    console.log("compliance_data",compliance_data)
    const something =all_complaince.filter(
      (item) => item.compliance_name === compliance_name
    );  
    console.log("something",something)

    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: esi_data?.creadNum,
      starting_date: starting_date,
      state: selected_state,
      is_active: true,
      company_id: companyId

    };

    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date = starting_date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  const delete_preference = (e) => {
    e.preventDefault();
    const data = all_preference.filter(
      (item) => item.compliance_name === 'ESIC_Challan' && item.state === selected_state
    );
    if (data.length > 0) {
      dispatch(deletepreference(data[0].id));
    }
  };

  return (
<>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          ESI Number :{esi_data?.creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration :{moment(starting_date).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      
      <Table>
      {stateName == undefined ? 
      <> 
      <br></br>
      <Stack sx={{ width: '60%'}} spacing={2} >

      <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        ESI Book need a <strong>State</strong> please — <strong> Add Preference !</strong> from Above Button 
      </Alert>
    </Stack>
    </> : 
    <>
             <TableHead>
          <TableCell>
            <Checkbox name="Challan" onChange={handleChange} checked={challan} />
          </TableCell>
          <TableCell>Challan</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_1}
                onChange={(newValue) => {
                  setTdsdate_1(newValue);
                  handleDateChange('Challan', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </>} 

      </Table>
      {stateName == undefined ? 
      <></>:<>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        {/* <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button> */}
        {!istalenteye && <Button variant="contained" color="error" sx={{ m: 2 }} onClick={delete_preference}>
          {' '}
          Delete State
        </Button>}
      </Box>
      </>}
    </>  );
};

const Roctabs = (props) => {
  const { roc_data, all_preference, all_complaince, companyId } = props;
  const dispatch = useDispatch();

  var d = new Date();
  d.setMonth(3);
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  //var valuz = new Date(22-Apr);
  const [mbm, setMbm] = React.useState(false);
  const [msm, setMsm] = React.useState(false);
  const [agm, setAgm] = React.useState(false);
  const [sat_reg, setSat_reg] = React.useState(false);
  const [rocdate_1, setRocdate_1] = useState(company_data.date_of_inc);
  const [rocdate_2, setRocdate_2] = useState(company_data.date_of_inc);
  const [rocdate_3, setRocdate_3] = useState(company_data.date_of_inc);
  const [rocdate_4, setRocdate_4] = useState(company_data.date_of_inc);

  useEffect(() => {
    console.log('usedeffect');
    const mb_min = all_preference.filter(
      (item) => item.compliance_name === 'Monthly Board Meeting Minutes'
    );
    console.log('leght', mb_min.length);
    if (mb_min.length > 0) {
      console.log('dateiz', new Date(mb_min[0].starting_date), mb_min[0].starting_date);
      setRocdate_1(mb_min[0].starting_date);
      setMbm(mb_min[0].is_active);
    }
    const ms_min = all_preference.filter(
      (item) => item.compliance_name === 'Monthly Shareholder Meeting Minutes'
    );
    if (ms_min.length > 0) {
      console.log('dateiz', new Date(ms_min[0].starting_date), ms_min[0].starting_date);
      setRocdate_2(ms_min[0].starting_date);
      setMsm(ms_min[0].is_active);
    }
    const agm_min = all_preference.filter((item) => item.compliance_name === 'Annual AGM');
    if (agm_min.length > 0) {
      console.log('dateiz', new Date(agm_min[0].starting_date), agm_min[0].starting_date);
      setRocdate_3(agm_min[0].starting_date);
      setAgm(agm_min[0].is_active);
    }
    const streg_min = all_preference.filter(
      (item) => item.compliance_name === 'Maintenance of Monthly Statutory Registers'
    );
    if (streg_min.length > 0) {
      console.log('dateiz', new Date(streg_min[0].starting_date), streg_min[0].starting_date);
      setRocdate_4(streg_min[0].starting_date);
      setSat_reg(streg_min[0].is_active);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    if (event.target.name === 'mbmin') {
      setMbm(event.target.checked);
      const mbmin_data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Monthly Board Meeting Minutes'
      );

      if (mbmin_data.length > 0) {
        update_preference(mbmin_data[0], event.target.checked, rocdate_1);
        console.log('update');
      } else {
        create_new_preference('Monthly Board Meeting Minutes');
        console.log('creating new');
      }
    } else if (event.target.name === 'msmin') {
      setMsm(event.target.checked);
      const msmin_data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Monthly Shareholder Meeting Minutes'
      );

      if (msmin_data.length > 0) {
        update_preference(msmin_data[0], event.target.checked, rocdate_2);
        console.log('update');
      } else {
        create_new_preference('Monthly Shareholder Meeting Minutes');
        console.log('creating new');
      }
    } else if (event.target.name === 'annual_agm') {
      setAgm(event.target.checked);
      const agm_data = all_preference.filter(
        (item) => item.compliance_name === 'Annual AGM'
      );
      if (agm_data.length > 0) {
        update_preference(agm_data[0], event.target.checked, rocdate_3);
        console.log('update');
      } else {
        create_new_preference('Annual AGM');
        console.log('creating new');
      }
    } else if (event.target.name === 'sat_register') {
      setSat_reg(event.target.checked);
      const streg_data = all_preference.filter(
        (item) =>
          item.compliance_name === 'Maintenance of Monthly Statutory Registers' 
      );
      if (streg_data.length > 0) {
        update_preference(streg_data[0], event.target.checked, rocdate_4);
        console.log('update');
      } else {
        create_new_preference('Maintenance of Monthly Statutory Registers');
        console.log('creating new');
      }
    }
  };
  const handleDateChange = (name, value) => {
    if (name === 'mbmin') {
      setRocdate_1(value);
      const mbmin_data = all_preference.filter(
        (item) => item.compliance_name === 'Monthly Board Meeting Minutes'
      );
      if (mbmin_data.length > 0) {
        update_preference(mbmin_data[0], mbm, value);
      }
    } else if (name === 'msmin') {
      setRocdate_2(value);
      const msmin_data = all_preference.filter(
        (item) => item.compliance_name === 'Monthly Shareholder Meeting Minutes'
      );
      if (msmin_data.length > 0) {
        update_preference(msmin_data[0], msm, value);
      }
    } else if (name === 'annual_agm') {
      setRocdate_3(value);
      const agm_data = all_preference.filter((item) => item.compliance_name === 'Annual AGM');
      console.log('agm_data', agm_data, value)
      if (agm_data.length > 0) {
        update_preference(agm_data[0], agm, value);
      }
    } else if (name === 'sat_register') {
      setRocdate_4(value);
      const sat_register = all_preference.filter(
        (item) => item.compliance_name === 'Maintenance of Monthly Statutory Registers'
      );
      if (sat_register.length > 0) {
        update_preference(sat_register[0], agm, value);
      }
    }
  };
  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: roc_data?.creadNum,
      starting_date: moment(company_data.date_of_inc).format('YYYY-MM-DD'),
      is_active: true,
      state: null,
      company_id: companyId
    };

    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          CIN Number :{roc_data?.creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration :{moment(company_data.date_of_inc).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableCell>
            <Checkbox name="mbmin" onChange={handleChange} checked={mbm} />
          </TableCell>
          <TableCell>Monthly Board Minutes</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Month"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={rocdate_1}
                onChange={(newValue) => {
                  handleDateChange('mbmin', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="msmin" onChange={handleChange} checked={msm} />
          </TableCell>
          <TableCell>Monthly Shareholder Minutes</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={rocdate_2}
                onChange={(newValue) => {
                  handleDateChange('msmin', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="annual_agm" onChange={handleChange} checked={agm} />
          </TableCell>
          <TableCell>Annual AGM</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={rocdate_3}
                onChange={(newValue) => {
                  handleDateChange('annual_agm', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="sat_register" onChange={handleChange} checked={sat_reg} />
          </TableCell>
          <TableCell>Statutory Registers</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={rocdate_4}
                onChange={(newValue) => {
                  handleDateChange('sat_register', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </Table>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button>
        <Button variant="contained" color="error" sx={{ m: 2 }}>
          {' '}
          Delete
        </Button>
      </Box> */}
    </>
  );
};
const OthersCentraltabs = (props) => {
  const { all_preference, all_complaince, companyId } = props;
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  const [clra_reg, setClra_reg] = useState(false);
  const [posh_fil, setPosh_fil] = useState(false);
  const [pay_grat, setPay_grat] = useState(false);
  const [fssai_fil, setFssai_fil] = useState(false);
  const [cldate_1, setCldate_1] = useState(company_data.date_of_inc);
  const [cldate_2, setCldate_2] = useState(company_data.date_of_inc);
  const [cldate_3, setCldate_3] = useState(company_data.date_of_inc);
  const [cldate_4, setCldate_4] = useState(company_data.date_of_inc);
  const dispatch = useDispatch();

  var d = new Date();
  d.setMonth(3);
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });

  useEffect(() => {
    const clra_data = all_preference.filter(
      (item) => item.compliance_name === 'Annual CLRA Return'
    );
    if (clra_data.length > 0) {
      setCldate_1(clra_data[0].starting_date);
      setClra_reg(clra_data[0].is_active);
    }
    const posh_data = all_preference.filter((item) => item.compliance_name === 'POSH Return');
    if (posh_data.length > 0) {
      setCldate_2(posh_data[0].starting_date);
      setPosh_fil(posh_data[0].is_active);
    }

    const grat_data = all_preference.filter(
      (item) => item.compliance_name === 'Labour Law Register (The Payment of Gratuity Act, 1972)'
    );
    if (grat_data.length > 0) {
      setCldate_3(grat_data[0].starting_date);
      setPay_grat(grat_data[0].is_active);
    }
    const fssai_data = all_preference.filter(
      (item) => item.compliance_name === 'FSSAI Annual Return'
    );
    if (fssai_data.length > 0) {
      setCldate_4(fssai_data[0].starting_date);
      setFssai_fil(fssai_data[0].is_active);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    console.log('law', all_complaince);

    if (event.target.name === 'clra') {
      setClra_reg(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'Annual CLRA Return');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked);
      } else {
        create_new_preference('Annual CLRA Return');
      }
    } else if (event.target.name === 'posh') {
      setPosh_fil(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'POSH Return');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked);
      } else {
        create_new_preference('POSH Return');
      }
    } else if (event.target.name === 'gratuity') {
      setPay_grat(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Labour Law Register (The Payment of Gratuity Act, 1972)'
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked);
      } else {
        create_new_preference('Labour Law Register (The Payment of Gratuity Act, 1972)');
      }
    } else if (event.target.name === 'fssai') {
      setFssai_fil(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'FSSAI Annual Return');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked);
      } else {
        create_new_preference('FSSAI Annual Return');
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === 'clra') {
      setCldate_1(value);
      const mbmin_data = all_preference.filter(
        (item) => item.compliance_name === 'Annual CLRA Return'
      );
      if (mbmin_data.length > 0) {
        update_preference(mbmin_data[0], clra_reg, value);
      }
    } else if (name === 'posh') {
      setCldate_2(value);
      const msmin_data = all_preference.filter((item) => item.compliance_name === 'POSH Return');
      if (msmin_data.length > 0) {
        update_preference(msmin_data[0], posh_fil, value);
      }
    } else if (name === 'gratuity') {
      setCldate_3(value);
      const gratact_data = all_preference.filter(
        (item) => item.compliance_name === 'Labour Law Register (The Payment of Gratuity Act, 1972)'
      );
      if (gratact_data.length > 0) {
        update_preference(gratact_data[0], pay_grat, value);
      }
    } else if (name === 'fssai') {
      setCldate_4(value);
      const fssi_data = all_preference.filter(
        (item) => item.compliance_name === 'FSSAI Annual Return'
      );
      if (fssi_data.length > 0) {
        update_preference(fssi_data[0], fssai_fil, value);
      }
    }
  };

  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: 'NA',
      starting_date: company_data.date_of_inc,
      is_active: true,
      company_id: companyId,
      state: null,
    };

    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date = company_data.date_of_inc) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  //var valuz = new Date(22-Apr);

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          Date of Registration :{moment(company_data?.date_of_inc).format('DD-MM-YYYY')}
        </Typography>
        {/* <Typography variant="h6" sx={{ ml: 20 }}> Date of Registration : </Typography> */}
      </Box>
      <Table>
        <TableHead>
          <TableCell>
            <Checkbox name="clra" onChange={handleChange} checked={clra_reg} />
          </TableCell>
          <TableCell>CLRA Filings and Registers</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={cldate_1}
                onChange={(newValue) => {
                  handleDateChange('clra', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="posh" onChange={handleChange} checked={posh_fil} />
          </TableCell>
          <TableCell>POSH filings</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={cldate_2}
                onChange={(newValue) => {
                  handleDateChange('posh', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="gratuity" onChange={handleChange} checked={pay_grat} />
          </TableCell>
          <TableCell>Payment of Gratuity Act filings</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={cldate_3}
                onChange={(newValue) => {
                  handleDateChange('gratuity', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="fssai" onChange={handleChange} checked={fssai_fil} />
          </TableCell>
          <TableCell>Payment of FSSAI filings</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={cldate_4}
                onChange={(newValue) => {
                  handleDateChange('fssai', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </Table>
    </>
  );
};

const Gsttabs = (props) => {
  const { all_complaince, all_preference, state_name, gst_data, companyId, } = props;
  console.log("statename_Gst",state_name)
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  const [complianceName, setComplinaceName] = useState('');
  const dispatch = useDispatch();
  let gst_details = [];
  if (gst_data !== undefined) {
    gst_details = gst_data?.map((item) => {
      item['state'] = getGSTState(item.creadNum);
      return item;
    });

    gst_details = gst_details?.filter((item) => item.state === state_name);
  }

  //var valuz = new Date(22-Apr);
  const [tdsdate_1, setTdsdate_1] = useState(company_data.date_of_inc);
  const [tdsdate_2, setTdsdate_2] = useState(company_data.date_of_inc);
  const [tdsdate_3, setTdsdate_3] = useState(company_data.date_of_inc);
  const [tdsdate_4, setTdsdate_4] = useState(company_data.date_of_inc);
  const [tdsdate_5, setTdsdate_5] = useState(company_data.date_of_inc);
  const [tdsdate_6, setTdsdate_6] = useState(company_data.date_of_inc);
  const [tdsdate_7, setTdsdate_7] = useState(company_data.date_of_inc);
  const [tdsdate_8, setTdsdate_8] = useState(company_data.date_of_inc);
  const [tdsdate_9, setTdsdate_9] = useState(company_data.date_of_inc);
  const [tdsdate_10, setTdsdate_10] = useState(company_data.date_of_inc);
  const [tdsdate_11, setTdsdate_11] = useState(company_data.date_of_inc);

  const [gst1r, setgst1r] = useState(true);
  const [gst3b, setgst3b] = useState(true);
  const [gst4, setgst4] = useState(false);
  const [gst5, setgst5] = useState(false);
  const [gst5a, setgst5a] = useState(false);
  const [gst6, setgst6] = useState(false);
  const [gst7, setgst7] = useState(false);
  const [gst8, setgs8] = useState(false);
  const [gst9, setgst9] = useState(false);
  const [gst9c, setgst9c] = useState(false);
  const [gstiff, setgstiff] = useState(false);

  useEffect(() => {
    const gst1r_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_1_${state_name}`
    );
    if (gst1r_data.length > 0) {
      setTdsdate_1(gst1r_data[0].starting_date);
      setgst1r(gst1r_data[0].is_active);
    } else {
      setTdsdate_1(company_data.date_of_inc);
      setgst1r(false);
    }

    const gst3b_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_3B_${state_name}`
    );
    if (gst3b_data.length > 0) {
      setTdsdate_2(gst3b_data[0].starting_date);
      setgst3b(gst3b_data[0].is_active);
    } else {
      setTdsdate_2(company_data.date_of_inc);
      setgst3b(false);
    }

    const gst4_data = all_preference.filter((item) => item.compliance_name === `GSTR-4`);
    if (gst4_data.length > 0) {
      setTdsdate_3(gst4_data[0].starting_date);
      setgst4(gst4_data[0].is_active);
    } else {
      setTdsdate_3(company_data.date_of_inc);
      setgst4(false);
    }

    const gst5_data = all_preference.filter((item) => item.compliance_name === `GSTR-5`);
    if (gst5_data.length > 0) {
      setTdsdate_4(gst5_data[0].starting_date);
      setgst5(gst5_data[0].is_active);
    } else {
      setTdsdate_4(company_data.date_of_inc);
      setgst5(false);
    }

    const gst5a_data = all_preference.filter((item) => item.compliance_name === `GSTR-5A`);
    if (gst5a_data.length > 0) {
      setTdsdate_5(gst5a_data[0].starting_date);
      setgst5a(gst5a_data[0].is_active);
    } else {
      setTdsdate_5(company_data.date_of_inc);
      setgst5a(false);
    }

    const gst6_data = all_preference.filter((item) => item.compliance_name === `GSTR-6`);
    if (gst6_data.length > 0) {
      setTdsdate_6(gst6_data[0].starting_date);
      setgst6(gst6_data[0].is_active);
    } else {
      setTdsdate_6(company_data.date_of_inc);
      setgst6(false);
    }

    const gst7_data = all_preference.filter((item) => item.compliance_name === `GSTR-7`);
    if (gst7_data.length > 0) {
      setTdsdate_7(gst7_data[0].starting_date);
      setgst7(gst7_data[0].is_active);
    } else {
      setTdsdate_7(company_data.date_of_inc);
      setgst7(false);
    }

    const gst8_data = all_preference.filter((item) => item.compliance_name === `GSTR-8`);
    if (gst8_data.length > 0) {
      setTdsdate_8(gst8_data[0].starting_date);
      setgs8(gst8_data[0].is_active);
    } else {
      setTdsdate_8(company_data.date_of_inc);
      setgs8(false);
    }

    const gst9_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_9_${state_name}`
    );
    if (gst9_data.length > 0) {
      setTdsdate_9(gst9_data[0].starting_date);
      setgst9(gst9_data[0].is_active);
    } else {
      setTdsdate_9(company_data.date_of_inc);
      setgst9(false);
    }

    const gst9c_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_9C_${state_name}`
    );
    if (gst9c_data.length > 0) {
      setTdsdate_10(gst9c_data[0].starting_date);
      setgst9c(gst9c_data[0].is_active);
    } else {
      setTdsdate_10(company_data.date_of_inc);
      setgst9c(false);
    }

    const gstiif_data = all_preference.filter((item) => item.compliance_name === `GST IFF`);
    if (gstiif_data.length > 0) {
      setTdsdate_11(gstiif_data[0].starting_date);
      setgstiff(gstiif_data[0].is_active);
    } else {
      setTdsdate_11(company_data.date_of_inc);
      setgstiff(false);
    }
  }, [all_preference, state_name, companyId]);

  const handleChange = (event) => {
    if (event.target.name === 'gst1r') {
      setgst1r(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR_1_${state_name}`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_1);
      } else {
        create_new_preference(`GSTR_1_${state_name}`);
      }
    } else if (event.target.name === 'gst3b') {
      setgst3b(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === `GSTR_3B_${state_name}`
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_2);
      } else {
        create_new_preference(`GSTR_3B_${state_name}`);
      }
    } else if (event.target.name === 'gst4') {
      setgst4(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-4`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_3);
      } else {
        create_new_preference(`GSTR-4`);
      }
    } else if (event.target.name === 'gst5') {
      setgst5(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-5`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_4);
      } else {
        create_new_preference(`GSTR-5`);
      }
    } else if (event.target.name === 'gst5a') {
      setgst5a(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-5A`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_5);
      } else {
        create_new_preference(`GSTR-5A`);
      }
    } else if (event.target.name === 'gst6') {
      setgst6(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-6`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_6);
      } else {
        create_new_preference(`GSTR-6`);
      }
    } else if (event.target.name === 'gst7') {
      setgst7(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-7`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_7);
      } else {
        create_new_preference(`GSTR-7`);
      }
    } else if (event.target.name === 'gst8') {
      setgs8(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-8`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_8);
      } else {
        create_new_preference(`GSTR-8`);
      }
    } else if (event.target.name === 'gst9') {
      setgst9(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GSTR_9_${state_name}`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_9);
      } else {
        create_new_preference(`GSTR_9_${state_name}`);
      }
    } else if (event.target.name === 'gst9c') {
      setgst9c(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === `GSTR_9C_${state_name}`
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_10);
      } else {
        create_new_preference(`GSTR_9C_${state_name}`);
      }
    } else if (event.target.name === 'gstiff') {
      setgstiff(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === `GST IFF`);
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_11);
      } else {
        create_new_preference(`GST IFF`);
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === 'gst1r') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR_1_${state_name}`);
      if (data.length > 0) {
        update_preference(data[0],gst1r, value);
      }
    } else if (name === 'gst3b') {
      const data = all_preference.filter(
        (item) => item.compliance_name === `GSTR_3B_${state_name}`
      );
      if (data.length > 0) {
        update_preference(data[0], gst3b, value);
      }
    } else if (name === 'gst4') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-4`);
      if (data.length > 0) {
        update_preference(data[0], gst4, value);
      }
    } else if (name === 'gst5') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-5`);
      if (data.length > 0) {
        update_preference(data[0], gst5, value);
      }
    } else if (name === 'gst5a') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-5A`);
      if (data.length > 0) {
        update_preference(data[0], gst5a, value);
      }
    } else if (name === 'gst6') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-6`);
      if (data.length > 0) {
        update_preference(data[0], gst6, value);
      }
    } else if (name === 'gst7') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-7`);
      if (data.length > 0) {
        update_preference(data[0], gst7, value);
      }
    } else if (name === 'gst8') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR-8`);
      if (data.length > 0) {
        update_preference(data[0], gst8, value);
      }
    } else if (name === 'gst9') {
      const data = all_preference.filter((item) => item.compliance_name === `GSTR_9_${state_name}`);
      if (data.length > 0) {
        update_preference(data[0], gst9, value);
      }
    } else if (name === 'gst9c') {
      const data = all_preference.filter(
        (item) => item.compliance_name === `GSTR_9C_${state_name}`
      );
      if (data.length > 0) {
        update_preference(data[0], gst9c, value);
      }
    } else if (name === 'gstiff') {
      const data = all_preference.filter((item) => item.compliance_name === `GST IFF`);
      if (data.length > 0) {
        update_preference(data[0], gstiff, value);
      }
    }
  };
  

  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];

    let starting_date;
    if (gst_details[0]?.date_of_registration) {
      starting_date = gst_details[0]?.date_of_registration;
    } else {
      starting_date = moment(company_data.date_of_inc).format('YYYY-MM-DD');
    }
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: gst_details[0]?.creadNum,
      starting_date: starting_date,
      is_active: true,
      company_id: companyId,
      state: state_name
    };
    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  const delete_preference = (e) => {
    e.preventDefault();
    const gst1r_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_1_${state_name}`
    );
    if (gst1r_data.length > 0) {
      dispatch(deletepreference(gst1r_data[0].id));
    }
    const gst3b_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_3B_${state_name}`
    );
    if (gst3b_data.length > 0) {
      dispatch(deletepreference(gst3b_data[0].id));
    }
    const gst9_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_9_${state_name}`
    );
    if (gst9_data.length > 0) {
      dispatch(deletepreference(gst9_data[0].id));
    }
    const gst9c_data = all_preference.filter(
      (item) => item.compliance_name === `GSTR_9C_${state_name}`
    );
    if (gst9c_data.length > 0) {
      dispatch(deletepreference(gst9c_data[0].id));
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          GST Numbers : {gst_details.length === 0 ? '' : gst_details[0].creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration :
          {gst_details.length === 0 || gst_details[0].date_of_registration === null
            ? moment(company_data.date_of_inc).format('DD-MM-YYYY')
            : moment(gst_details[0].date_of_registration).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      {state_name == undefined ? (
        <>
          <Stack sx={{ width: '60%' }} spacing={2}>
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              GST Book need a <strong>State</strong> please — <strong> Add Preference !</strong>{' '}
              from Above Button
            </Alert>
          </Stack>
        </>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableCell>
                <Checkbox name="gst1r" onChange={handleChange} checked={gst1r} />
              </TableCell>
              <TableCell>GSTR-1</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // label="Month"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_1}
                    onChange={(newValue) => {
                      setTdsdate_1(newValue);
                      handleDateChange('gst1r', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst3b" onChange={handleChange} checked={gst3b} />
              </TableCell>
              <TableCell>GSTR-3B</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_2}
                    onChange={(newValue) => {
                      setTdsdate_2(newValue);
                      handleDateChange('gst3b', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst4" onChange={handleChange} checked={gst4} />
              </TableCell>
              <TableCell>GSTR-4</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // label="Month"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_3}
                    onChange={(newValue) => {
                      setTdsdate_3(newValue);
                      handleDateChange('gst4', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst5" onChange={handleChange} checked={gst5} />
              </TableCell>
              <TableCell>GSTR-5</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_4}
                    onChange={(newValue) => {
                      setTdsdate_4(newValue);
                      handleDateChange('gst5', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst5a" onChange={handleChange} checked={gst5a} />
              </TableCell>
              <TableCell>GSTR-5A</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_5}
                    onChange={(newValue) => {
                      setTdsdate_5(newValue);
                      handleDateChange('gst5a', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst6" onChange={handleChange} checked={gst6} />
              </TableCell>
              <TableCell>GSTR-6</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    maxDate={new Date('2022-06-01')}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_6}
                    onChange={(newValue) => {
                      setTdsdate_6(newValue);
                      handleDateChange('gst6', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst7" onChange={handleChange} checked={gst7} />
              </TableCell>
              <TableCell>GSTR-7</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_7}
                    onChange={(newValue) => {
                      setTdsdate_7(newValue);
                      handleDateChange('gst7', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst8" onChange={handleChange} checked={gst8} />
              </TableCell>
              <TableCell>GSTR-8</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_8}
                    onChange={(newValue) => {
                      setTdsdate_8(newValue);
                      handleDateChange('gst8', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst9" onChange={handleChange} checked={gst9} />
              </TableCell>
              <TableCell>GSTR-9</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_9}
                    onChange={(newValue) => {
                      setTdsdate_9(newValue);
                      handleDateChange('gst9', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gst9c" onChange={handleChange} checked={gst9c} />
              </TableCell>
              <TableCell>GSTR-9C</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_10}
                    onChange={(newValue) => {
                      setTdsdate_10(newValue);
                      handleDateChange('gst9c', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableCell>
                <Checkbox name="gstiff" onChange={handleChange} checked={gstiff} />
              </TableCell>
              <TableCell>Monthly IFF Returns</TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    value={tdsdate_11}
                    onChange={(newValue) => {
                      setTdsdate_11(newValue);
                      handleDateChange('gstiff', newValue);
                    }}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableHead>
          </Table>
        </>
      )}
      {state_name === undefined ? (
        <></>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            <Button variant="contained" color="error" sx={{ m: 2 }} onClick={delete_preference}>
              {' '}
              Delete State
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const Tdstabs = (props) => {
  const { tds_data, all_preference, all_complaince, companyId } = props;
  const dispatch = useDispatch();
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  var d = new Date();
  d.setMonth(3);

  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  //var valuz = new Date(22-Apr);
  const [tdsdate_1, setTdsdate_1] = useState(company_data.date_of_inc);
  const [tdsdate_2, setTdsdate_2] = useState(company_data.date_of_inc);
  const [tdsdate_3, setTdsdate_3] = useState(company_data.date_of_inc);
  const [tdsdate_4, setTdsdate_4] = useState(company_data.date_of_inc);
  const [tdsdate_5, setTdsdate_5] = useState(company_data.date_of_inc);
  const [tdsdate_6, setTdsdate_6] = useState(company_data.date_of_inc);
  const [tds_24Q, settds_24Q] = React.useState(false);
  const [tds_26Q, settds_26Q] = React.useState(false);
  const [tds_27Q, settds_27Q] = React.useState(false);
  const [tds_24EQ, settds_24EQ] = React.useState(false);
  const [form16, setform16] = React.useState(false);
  const [form16A, setform16A] = React.useState(false);

  const date_formatter = (date) => {
    const new_date = moment(date).format('MM-DD-YYYY');
    return new_date;
  };

  useEffect(() => {
    const challan_24Q = all_preference.filter((item) => item.compliance_name === 'TDS Challan_24Q');
    if (challan_24Q.length > 0) {
      console.log('date', new Date(challan_24Q[0].starting_date), challan_24Q[0].starting_date);
      setTdsdate_2(challan_24Q[0].starting_date);
      settds_24Q(challan_24Q[0].is_active);
    }
    const challan_26Q = all_preference.filter((item) => item.compliance_name === 'TDS Challan_26Q');
    if (challan_26Q.length > 0) {
      setTdsdate_1(challan_26Q[0].starting_date);
      settds_26Q(challan_26Q[0].is_active);
    }
    const challan_27Q = all_preference.filter((item) => item.compliance_name === 'TDS Challan_27Q');
    if (challan_27Q.length > 0) {
      setTdsdate_3(challan_27Q[0].starting_date);
      settds_27Q(challan_27Q[0].is_active);
    }
    const challan_27EQ = all_preference.filter(
      (item) => item.compliance_name === 'TDS Challan_27EQ'
    );
    if (challan_27EQ.length > 0) {
      setTdsdate_4(challan_27EQ[0].starting_date);
      settds_24EQ(challan_27EQ[0].is_active);
    }
    const form_16_data = all_preference.filter(
      (item) => item.compliance_name === 'Form-16 Salary TDS Certificate'
    );
    if (form_16_data.length > 0) {
      setTdsdate_5(form_16_data[0].starting_date);
      setform16(form_16_data[0].is_active);
    }
    const form_16a_data = all_preference.filter(
      (item) => item.compliance_name === 'Form 16A TDS Certificate'
    );
    if (form_16a_data.length > 0) {
      setTdsdate_6(form_16a_data[0].starting_date);
      setform16A(form_16a_data[0].is_active);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    if (event.target.name === '24Q') {
      settds_24Q(event.target.checked);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_24Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_24Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], event.target.checked, tdsdate_2);
        update_preference(return_data[0], event.target.checked, tdsdate_2);
      } else {
        create_new_preference('TDS Challan_24Q');
        create_new_preference('TDS Return_24Q');
      }
    } else if (event.target.name === '26Q') {
      settds_26Q(event.target.checked);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_26Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_26Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], event.target.checked, tdsdate_1);
        update_preference(return_data[0], event.target.checked, tdsdate_1);
      } else {
        create_new_preference('TDS Challan_26Q');
        create_new_preference('TDS Return_26Q');
      }
    } else if (event.target.name === '27Q') {
      settds_27Q(event.target.checked);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_27Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_27Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], event.target.checked, tdsdate_3);
        update_preference(return_data[0], event.target.checked, tdsdate_3);
      } else {
        create_new_preference('TDS Challan_27Q');
        create_new_preference('TDS Return_27Q');
      }
    } else if (event.target.name === '27EQ') {
      settds_24EQ(event.target.checked);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_27EQ'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_27EQ'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], event.target.checked, tdsdate_4);
        update_preference(return_data[0], event.target.checked, tdsdate_4);
      } else {
        create_new_preference('TDS Challan_27EQ');
        create_new_preference('TDS Return_27EQ');
      }
    } else if (event.target.name === 'form 16') {
      setform16(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Form-16 Salary TDS Certificate'
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_5);
      } else {
        create_new_preference('Form-16 Salary TDS Certificate');
      }
    } else if (event.target.name === 'form 16A') {
      setform16A(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Form 16A TDS Certificate'
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_6);
      } else {
        create_new_preference('Form 16A TDS Certificate');
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === '24Q') {
      setTdsdate_2(value);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_24Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_24Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], tds_24Q, value);
        update_preference(return_data[0], tds_24Q, value);
      }
    } else if (name === '26Q') {
      setTdsdate_1(value);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_26Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_26Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], tds_24Q, value);
        update_preference(return_data[0], tds_24Q, value);
      }
    } else if (name === '27Q') {
      setTdsdate_3(value);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_27Q'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_27Q'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], tds_27Q, value);
        update_preference(return_data[0], tds_27Q, value);
      }
    } else if (name === '27EQ') {
      setTdsdate_4(value);
      const challan_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Challan_27EQ'
      );
      const return_data = all_preference.filter(
        (item) => item.compliance_name === 'TDS Return_27EQ'
      );
      if (challan_data.length > 0) {
        update_preference(challan_data[0], tds_24EQ, value);
        update_preference(return_data[0], tds_24EQ, value);
      }
    } else if (name === 'form 16') {
      setTdsdate_5(value);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Form-16 Salary TDS Certificate'
      );
      if (data.length > 0) {
        update_preference(data[0], form16, value);
      }
    } else if (name === 'form 16A') {
      setTdsdate_6(value);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Form 16A TDS Certificate'
      );
      if (data.length > 0) {
        update_preference(data[0], form16A, value);
      }
    }
  };

  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: tds_data?.creadNum,
      starting_date: company_data.date_of_inc,
      is_active: true,
      state: null,
      company_id: companyId
    };

    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          TAN Number : {tds_data?.creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration : {moment(company_data.date_of_inc).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableCell>
            <Checkbox name="26Q" onChange={handleChange} checked={tds_26Q} />
          </TableCell>
          <TableCell>26Q (TDS Other Than Salary)</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Month"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_1}
                onChange={(newValue) => {
                  handleDateChange('26Q', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="24Q" onChange={handleChange} checked={tds_24Q} />
          </TableCell>
          <TableCell>24Q (TDS Other than Salary)</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_2}
                onChange={(newValue) => {
                  handleDateChange('24Q', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="27Q" onChange={handleChange} checked={tds_27Q} />
          </TableCell>
          <TableCell>27Q (TDS Non Resident)</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Month"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_3}
                onChange={(newValue) => {
                  handleDateChange('27Q', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="27EQ" onChange={handleChange} checked={tds_24EQ} />
          </TableCell>
          <TableCell>27EQ (TCS)</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_4}
                onChange={(newValue) => {
                  handleDateChange('27EQ', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="form 16" onChange={handleChange} checked={form16} />
          </TableCell>
          <TableCell>Form 16</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_5}
                onChange={(newValue) => {
                  handleDateChange('form 16', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="form 16A" onChange={handleChange} checked={form16A} />
          </TableCell>
          <TableCell>Form 16A</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_6}
                onChange={(newValue) => {
                  handleDateChange('form 16A', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </Table>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button>
        <Button variant="contained" color="error" sx={{ m: 2 }}>
          {' '}
          Delete
        </Button>
      </Box> */}
    </>
  );
};
const Ittabs = (props) => {
  const { it_data, all_preference, all_complaince, companyId } = props;
  const dispatch = useDispatch();
  var d = new Date();
  d.setMonth(3);
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const starting_date = companyList.filter((item) => item.id === companyId)[0];

  //var valuz = new Date(22-Apr);
  const [tdsdate_1, setTdsdate_1] = useState(starting_date.date_of_inc);
  const [tdsdate_2, setTdsdate_2] = useState(starting_date.date_of_inc);
  const [tdsdate_3, setTdsdate_3] = useState(starting_date.date_of_inc);
  const [tdsdate_4, setTdsdate_4] = useState(starting_date.date_of_inc);
  const [tax, settax] = React.useState(false);
  const [transfer, settransfer] = React.useState(stubFalse);
  const [sft, setsft] = React.useState(false);
  const [itr, setitr] = React.useState(false);

  useEffect(() => {
    const itr_data = all_preference.filter((item) => item.compliance_name === 'Income Tax Return');
    if (itr_data.length > 0) {
      setTdsdate_4(itr_data[0].starting_date);
      setitr(itr_data[0].is_active);
    }
    const tpr_data = all_preference.filter(
      (item) => item.compliance_name === 'Transfer Pricing Report'
    );
    if (tpr_data.length > 0) {
      setTdsdate_2(tpr_data[0].starting_date);
      settransfer(tpr_data[0].is_active);
    }
    const tax_data = all_preference.filter(
      (item) => item.compliance_name === 'Income Tax Audit Report'
    );
    if (tax_data.length > 0) {
      setTdsdate_1(tax_data[0].starting_date);
      settax(tax_data[0].is_active);
    }
    const data = all_preference.filter((item) => item.compliance_name === 'Form 61A (SFT)');
    if (data.length > 0) {
      setTdsdate_3(data[0].starting_date);
      setsft(data[0].is_active);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    if (event.target.name === 'ITR') {
      setitr(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'Income Tax Return');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_4);
      } else {
        create_new_preference('Income Tax Return');
      }
    } else if (event.target.name === 'transfer pricing audit') {
      settransfer(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Transfer Pricing Report'
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_2);
      } else {
        create_new_preference('Transfer Pricing Report');
      }
    } else if (event.target.name === 'tax') {
      settax(event.target.checked);
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Income Tax Audit Report'
      );
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_1);
      } else {
        create_new_preference('Income Tax Audit Report');
      }
    } else if (event.target.name === 'SFT') {
      setsft(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'Form 61A (SFT)');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_3);
      } else {
        create_new_preference('Form 61A (SFT)');
      }
    }
  };

  const handleDateChange = (name, value) => {
    if (name === 'ITR') {
      const data = all_preference.filter((item) => item.compliance_name === 'Income Tax Return');
      if (data.length > 0) {
        update_preference(data[0], itr, value);
      }
    } else if (name === 'transfer pricing audit') {
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Transfer Pricing Report'
      );
      if (data.length > 0) {
        update_preference(data[0], transfer, value);
      }
    } else if (name === 'tax') {
      const data = all_preference.filter(
        (item) => item.compliance_name === 'Income Tax Audit Report'
      );
      if (data.length > 0) {
        update_preference(data[0], tax, value);
      }
    } else if (name === 'SFT') {
      const data = all_preference.filter((item) => item.compliance_name === 'Form 61A (SFT)');
      if (data.length > 0) {
        update_preference(data[0], sft, value);
      }
    }
  };

  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: it_data?.creadNum,
      starting_date: starting_date.date_of_inc,
      is_active: true,
      state: null,
      company_id: companyId
    };

    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      > 
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          PAN Number :{it_data?.creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration :{moment(starting_date.date_of_inc).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableCell>
            <Checkbox name="tax" onChange={handleChange} checked={tax} />
          </TableCell>
          <TableCell>Tax Audit</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Month"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_1}
                onChange={(newValue) => {
                  setTdsdate_1(newValue);
                  handleDateChange('tax', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="transfer pricing audit" onChange={handleChange} checked={transfer} />
          </TableCell>
          <TableCell>Transfer Pricing Audit</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_2}
                onChange={(newValue) => {
                  setTdsdate_2(newValue);
                  handleDateChange('transfer pricing audit', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="SFT" onChange={handleChange} checked={sft} />
          </TableCell>
          <TableCell>SFT Filings</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // label="Month"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_3}
                onChange={(newValue) => {
                  setTdsdate_3(newValue);
                  handleDateChange('SFT', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
        <TableHead>
          <TableCell>
            <Checkbox name="ITR" onChange={handleChange} checked={itr} />
          </TableCell>
          <TableCell>ITR</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_4}
                onChange={(newValue) => {
                  setTdsdate_4(newValue);
                  handleDateChange('ITR', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </Table>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button>
        <Button variant="contained" color="error" sx={{ m: 2 }}>
          {' '}
          Delete
        </Button>
      </Box> */}
    </>
  );
};
const Pftabs = (props) => {
  const { pf_data, all_preference, all_complaince, companyId } = props;
  const dispatch = useDispatch();
  var d = new Date();
  d.setMonth(3);
  const month1 = d.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  const orgState = useSelector((state) => state.orgProfileReducer);
  const companyList = getCompanyData() || orgState.companyData;
  const company_data = companyList.filter((item) => item.id === companyId)[0];
  //var valuz = new Date(22-Apr);
  const [tdsdate_1, setTdsdate_1] = useState(company_data.date_of_inc);
  const [challan, setchallan] = React.useState(false);
  const starting_date =
    pf_data?.date_of_registration === null
      ? company_data.date_of_inc
      : pf_data?.date_of_registration;

  useEffect(() => {
    const data = all_preference.filter((item) => item.compliance_name === 'EPF_Challan');
    if (data.length > 0) {
      setTdsdate_1(data[0].starting_date);
      setchallan(data[0].is_active);
    }
  }, [all_preference]);

  const handleChange = (event) => {
    if (event.target.name === 'Challan') {
      setchallan(event.target.checked);
      const data = all_preference.filter((item) => item.compliance_name === 'EPF_Challan');
      if (data.length > 0) {
        update_preference(data[0], event.target.checked, tdsdate_1);
      } else {
        create_new_preference('EPF_Challan');
      }
    }
  };

  const handleDateChange = (name, value) => {
    console.log('handle_date change')
    if (name === 'Challan') {
      const data = all_preference.filter((item) => item.compliance_name === 'EPF_Challan');
      console.log('handle_date change', data)
      if (data.length > 0) {
        update_preference(data[0], challan, value);
      }
    }
  };

  const create_new_preference = (compliance_name) => {
    const compliance_data = all_complaince.filter(
      (item) => item.complianceName === compliance_name
    )[0];
    const preference = {
      compliance_name: compliance_data.complianceName,
      compliance_id: compliance_data.id,
      comp_number: pf_data?.creadNum,
      starting_date: moment(starting_date).format('YYYY-MM-DD'),
      is_active: true,
      state: null,
      company_id: companyId
    };
    dispatch(create_preference(preference, companyId, false));
  };

  const update_preference = (data, status, date) => {
    data.is_active = status;
    data.starting_date = moment(date).format('YYYY-MM-DD');
    dispatch(updatepreference(data.id, data, false));
  };

  return (
    <>
      <Box
        sx={{ width: 1000, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}
      >
        <Typography variant="h6" sx={{ mr: 20 }}>
          {' '}
          PF Number :{pf_data?.creadNum}
        </Typography>
        <Typography variant="h6" sx={{ ml: 20 }}>
          {' '}
          Date of Registration :{moment(starting_date).format('DD-MM-YYYY')}
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableCell>
            <Checkbox name="Challan" onChange={handleChange} checked={challan} />
          </TableCell>
          <TableCell>Challan</TableCell>
          <TableCell>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                defaultCalendarMonth={d}
                value={tdsdate_1}
                onChange={(newValue) => {
                  setTdsdate_1(newValue);
                  handleDateChange('Challan', newValue);
                }}
              />
            </LocalizationProvider>
          </TableCell>
        </TableHead>
      </Table>
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <Button variant="contained" sx={{ m: 2 }}>
          {' '}
          Update
        </Button>
        <Button variant="contained" color="error" sx={{ m: 2 }}>
          {' '}
          Delete
        </Button>
      </Box> */}
    </>
  );
};
