import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadLink from 'react-download-link';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function AoaMoa() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association20052021_ALTEREDARTICLESOFASSOCI ATION20052021.pdf
            </TableCell>
            <TableCell align="center">20-05-2021 </TableCell>
            <TableCell align="center">AoA - Articles of Association </TableCell>
            <TableCell align="center"> 2.23</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC34.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC34">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of association20052021_ALTEREDMEMORANDUMOFA SSOCIATION20052021.pdf
            </TableCell>
            <TableCell align="center">20-05-2021</TableCell>
            <TableCell align="center"> MOA - Memorandum of Association </TableCell>
            <TableCell align="center"> 0.38</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC35.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC35">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 13042021_ALTEREDMEMORANDUMOFA SSCIATION13042021.pdf
            </TableCell>
            <TableCell align="center">13-04-2021</TableCell>
            <TableCell align="center"> Description not available </TableCell>
            <TableCell align="center"> 0.39</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC36.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC36">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of association31032021_ALTEREDMEMORANDUMOFA SSOCIATION31032021.pdf
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">MOA - Memorandum of Association </TableCell>
            <TableCell align="center">0.39</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC37.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC37">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association31032021_ALTEREDARTICLESOFASSOCI ATION31032021.pdf
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">AoA - Articles of Association </TableCell>
            <TableCell align="center"> 2.23</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC38.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC38">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association13032020_ALTEREDARTICLESOFASSOCI ATION13032020.pdf
            </TableCell>
            <TableCell align="center">13-03-2020</TableCell>
            <TableCell align="center"> AoA - Articles of Association </TableCell>
            <TableCell align="center">4.22</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC39.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC39">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 07012020_ALTEREDMEMORANDUMOFA SSCIATION07012020.pdf
            </TableCell>
            <TableCell align="center">07-01-2020 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.51</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC40.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC40">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 02072019_ALTEREDMEMORANDUMOFA SSCIATION02072019.pdf
            </TableCell>
            <TableCell align="center">02-07-2019 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center">1.04</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC41.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC41">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form SPICe MOA (INC-33)- 17012017_FORMSPICEMOAINC331701201 7.pdf
            </TableCell>
            <TableCell align="center">17-01-2017 </TableCell>
            <TableCell align="center">
              Form SPICe - Simplified Proforma for Incorporating Company Electronically (SPICe){' '}
            </TableCell>
            <TableCell align="center"> 0.80</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC42.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC42">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form SPICe AOA (INC-34)- 17012017_FORMSPICEAOAINC341701201 7.pdf
            </TableCell>
            <TableCell align="center">17-01-2017 </TableCell>
            <TableCell align="center">
              {' '}
              Form SPICe - Simplified Proforma for Incorporating Company Electronically (SPICe){' '}
            </TableCell>
            <TableCell align="center"> 1.34</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC43.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC43">
                Download
              </a>
            </TableCell>
          </TableRow>

          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
