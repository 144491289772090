import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchExpenseInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function OtherExpense() {
  const rocState = useSelector((state) => state.rocReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchExpenseInfo());
  }, []);
  if ((rocState.expenseInfo = !null)) {
    return <></>;
  }
  const data = rocState.expenseInfo[0];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">2017 - 2018</TableCell>
            <TableCell align="center">2018 - 2019</TableCell>
            <TableCell align="center"> 2019 - 2020</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Consumption of stores and spare parts
            </TableCell>
            <TableCell align="center">0.00</TableCell>
            <TableCell align="center">0.00</TableCell>

            <TableCell align="center">0.00</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Power and fuel
            </TableCell>
            <TableCell align="center">408.56</TableCell>
            <TableCell align="center">464.43</TableCell>

            <TableCell align="center"> 466.14</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Rent
            </TableCell>
            <TableCell align="center">422.48 </TableCell>
            <TableCell align="center">722.60</TableCell>

            <TableCell align="center"> 762.45</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Repairs to building &amp; Machinery
            </TableCell>
            <TableCell align="center">30.80 </TableCell>
            <TableCell align="center">22.16</TableCell>

            <TableCell align="center"> 23.14</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Travelling conveyance
            </TableCell>
            <TableCell align="center">1,396.25 </TableCell>
            <TableCell align="center">2,265.33</TableCell>

            <TableCell align="center"> 2,572.21</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Rates and taxes&nbsp;
            </TableCell>
            <TableCell align="center">22.50 </TableCell>
            <TableCell align="center">60.39</TableCell>

            <TableCell align="center"> 28.44</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Legal professional charges
            </TableCell>
            <TableCell align="center">394.40 </TableCell>
            <TableCell align="center">604.84</TableCell>

            <TableCell align="center"> 603.77</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Advertising Promotional
            </TableCell>
            <TableCell align="center"> 86.65 </TableCell>
            <TableCell align="center">121.87</TableCell>

            <TableCell align="center">154.79</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Provision for bad doubtful debts and Advances
            </TableCell>
            <TableCell align="center"> 15.22</TableCell>
            <TableCell align="center"> 52.10</TableCell>

            <TableCell align="center">25.06</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Payments to auditor
            </TableCell>
            <TableCell align="center">29.49</TableCell>
            <TableCell align="center"> 34.66</TableCell>

            <TableCell align="center"> 34.46</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Miscellaneous expenses
            </TableCell>
            <TableCell align="center">2,598.20</TableCell>
            <TableCell align="center"> 2,842.15</TableCell>

            <TableCell align="center"> 4,036.39</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Total Other Expense
            </TableCell>
            <TableCell align="center">5,404.55</TableCell>
            <TableCell align="center"> 7,190.53</TableCell>

            <TableCell align="center">8,706.85</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
