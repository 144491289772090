import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const invoice_subs_get = () => (dispatch)=>{
    console.log("called api get")
    HttpWrapper('GET', `/invoices-manager/api/subscription/`)
    .then((res) => {
      dispatch({
        type: constants.SET_INVOICE_SUBS,
        payload: res.data
      });
      console.log("getres",res.data)
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_INVOICE_SUBS,
        payload: []
      });
    });
  }


  export const add_subscr = (data) => (dispatch) => {
    HttpWrapper('POST', `/invoices-manager/api/subscription/`, false, data)
      .then((res, err) => {
        dispatch(invoice_subs_get());
        toast.success('Add Successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };  

  export const update_subscription = (id, data) => (dispatch) => {
    HttpWrapper('PATCH', `/invoices-manager/api/subscription/${id}/`, false, data)
      .then((res, err) => {
        toast.success('Update Successfully');
        dispatch(invoice_subs_get());
        console.log("res",res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const delete_subscription = (data) => (dispatch) => {
    HttpWrapper('DELETE', `/invoices-manager/api/subscription/${data}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Delete Successfully');
        dispatch(invoice_subs_get());
      })
      .catch((err) => {
        console.log(err);
      });
  };

