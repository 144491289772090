export const calQuarterTotal = (totalTaxData, year, row) => {
  let total = 0;
  if (
    JSON.parse(totalTaxData['gst_quaterly_data'])['paid_cash'][`('${year}', '${row}')`] != undefined
  ) {
    total =
      total + JSON.parse(totalTaxData['gst_quaterly_data'])['paid_cash'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['gst_quaterly_data'])['paid_credit'][`('${year}', '${row}')`] !=
    undefined
  ) {
    total =
      total + JSON.parse(totalTaxData['gst_quaterly_data'])['paid_credit'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][`('${year}', '${row}')`] !=
    undefined
  ) {
    total =
      total + JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][`('${year}', '${row}')`] != undefined
  ) {
    total =
      total + JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['tax_deduted_quaterly'])['tds_amount'][`('${year}', '${row}')`] !=
    undefined
  ) {
    total =
      total +
      JSON.parse(totalTaxData['tax_deduted_quaterly'])['tds_amount'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['tax_paid_quaterly'])['amount'][`('${year}', '${row}')`] != undefined
  ) {
    total =
      total + JSON.parse(totalTaxData['tax_paid_quaterly'])['amount'][`('${year}', '${row}')`];
  }
  if (
    JSON.parse(totalTaxData['tds_challan_quaterly'])['base_amount'][`('${year}', '${row}')`] !=
    undefined
  ) {
    total =
      total +
      JSON.parse(totalTaxData['tds_challan_quaterly'])['base_amount'][`('${year}', '${row}')`];
  }
  return total;
};

export const calOneBookTotal = (bookName, totalTaxData, year) => {
  let total = 0;
  const quaters = ['Q1', 'Q2', 'Q3', 'Q4'];
  switch (bookName) {
    case 'GST_CASH':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['gst_quaterly_data'])['paid_cash'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['gst_quaterly_data'])['paid_cash'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'GST_CREDIT':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['gst_quaterly_data'])['paid_credit'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['gst_quaterly_data'])['paid_credit'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'ESI':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'PF':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'TDS_DEDUCTED':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['tax_deduted_quaterly'])['tds_amount'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['tax_deduted_quaterly'])['tds_amount'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'TDS_PAID':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['tds_challan_quaterly'])['base_amount'][
            `('${year}', '${row}')`
          ] != undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['tds_challan_quaterly'])['base_amount'][
              `('${year}', '${row}')`
            ];
        }
      });
      return total;
    case 'INCOMETAX':
      quaters.forEach((row) => {
        if (
          JSON.parse(totalTaxData['tax_paid_quaterly'])['amount'][`('${year}', '${row}')`] !=
          undefined
        ) {
          total =
            total +
            JSON.parse(totalTaxData['tax_paid_quaterly'])['amount'][`('${year}', '${row}')`];
        }
      });
      return total;
    case 'ALL':
      return (
        calOneBookTotal('GST_CASH', totalTaxData, year) +
        calOneBookTotal('GST_CREDIT', totalTaxData, year) +
        calOneBookTotal('TDS_PAID', totalTaxData, year) +
        calOneBookTotal('PF', totalTaxData, year) +
        calOneBookTotal('ESI', totalTaxData, year) +
        calOneBookTotal('TDS_DEDUCTED', totalTaxData, year) +
        calOneBookTotal('INCOMETAX', totalTaxData, year)
      );
    case 'ALL_COMP':
      return(
        calOneBookTotal('PF', totalTaxData, year) +
        calOneBookTotal('ESI', totalTaxData, year) 
      ); 
    default:
      return 0;
  }
};

export const calIntrestSum = (interestData, year) => {
  let sum = 0;
  if (JSON.parse(interestData['gst_interest_data'])['intrest'][`${year}`] != undefined) {
    sum = sum + JSON.parse(interestData['gst_interest_data'])['intrest'][`${year}`];
  }
  if (JSON.parse(interestData['tds_demand_interest'])['interest_amount'][`${year}`] != undefined) {
    sum = sum + JSON.parse(interestData['tds_demand_interest'])['interest_amount'][`${year}`];
  }
  if (JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][`${year}`] != undefined) {
    sum = sum + JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][`${year}`];
  }
  if (JSON.parse(interestData['tax_demand_interest'])['interest_paid'][`${year}`] != undefined) {
    sum = sum + JSON.parse(interestData['tax_demand_interest'])['interest_paid'][`${year}`];
  }
  if (JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`] != undefined) {
    sum = sum + JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`];
  }
  return sum;
};

export const calOutstandingSum = (outstandingData, year) => {
  // const sum =
  //   (JSON.parse(outstandingData['gst_outstanding'])['demand_amount'][`${year}`] === undefined
  //     ? 0
  //     : JSON.parse(outstandingData['gst_outstanding'])['demand_amount'][`${year}`]) +
  //   (JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'][`${year}`] === undefined
  //     ? 0
  //     : JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'][`${year}`]) +
  //   (JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`] === undefined
  //     ? 0
  //     : JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`]) +
  //   (JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`] === undefined
  //     ? 0
  //     : JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`]) +
  //   (JSON.parse(outstandingData['tax_demand_interest'])['demand_amount'][`${year}`] === undefined
  //     ? 0
  //     : JSON.parse(outstandingData['tax_demand_interest'])['demand_amount'][`${year}`]);
  const sum =
    calSumTillYear(JSON.parse(outstandingData['gst_outstanding'])['demand_amount'], year) +
    calSumTillYear(JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'], year) +
    calSumTillYear(JSON.parse(outstandingData['pf_penalties_outstanding'])['total_penalty'], year) +
    calSumTillYear(JSON.parse(outstandingData['esi_outstanding'])['total_penalty'], year)+
  calSumTillYear(JSON.parse(outstandingData['tax_demand_outstanding'])['demand_amount'], year);
  return sum;
};

export const calSumTillYear = (obj, year) => {
  var sum = 0;
  console.log('obj', obj);
  for (var el in obj) {
    console.log('el is', el);
    if (parseInt(el.split('-')[0]) <= parseInt(year.split('-')[0])) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
};
