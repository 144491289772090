import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import { Box } from '@mui/material';
import { istalenteye, isStartupFino } from 'src/config';
import { appName } from 'src/config';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  paddingBottom: 5,
  padding: theme.spacing(0, 0, 0)
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        {istalenteye && (
          <Box display='flex' alignItems="center" justifyContent="center">
            <img src="/static/PeopleFino-logo3.png" style={{ width: '80%' }}></img>
          </Box>
        )}
        {!istalenteye && (
          <Box display='flex' alignItems="center" justifyContent="center">
          <img src="/static/Logo6.png" style={{ width: '80%' }}></img>
          </Box>
        )}
      </HeaderStyle>
      <Outlet />
    </>
  );
}
