import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchBalanceSheetInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function BalanceSheet() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed.BalanceSheet
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  // data.forEach((row) => {
  //   yearToDataMap[row.FinancialYear] = row;
  // });
  const fields = {
    // NetWorth: 'Net Worth',
    // Debt: ' Debt',
    NoncurrentLiabilities: 'Other Non-Current Liabilities',
    CurrentLiabilities: 'Current liabilities and provisions',
    DeferredTaxLiabilitiesNet: 'Deferred tax liability/(asset)',
    CapitalEmployed: 'Capital Employed',
    TangibleAssets: 'Tangible assets',
    IntangibleAssets: ' Intangible assets',
    Investments: 'Investments',
    LongTermLoansAndAdvances: 'Loans and advances',
    Inventories: 'Inventory',
    TradeReceivables: 'Receivables',
    CashAndBankBalances: 'Cash and bank balance',
    OtherCurrentAssets: 'Other Assets',
    // ApplicationsofFunds: ' Applications of Funds',
    CurrentLiabilities: 'Current Liabilities',
    CurrentAssets: 'Current Assets'
    // WorkingCapital: 'Working Capital'
  };
  return (
    <TableContainer component={Paper}>
      {console.log(data)}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(fields).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return yearToDataMap[year][key] != null ? (
                  <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>
                ) : (
                  <TableCell key={index}>NA</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
