import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
//import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
//import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormat } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
//import { updateIncometaxPay } from 'src/redux/actions/incometax';
import moment from 'moment';
import Link from '@mui/material/Link';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';




import Label from 'src/components/Label';
import { deleteincometaxpay, updateincometaxpay } from 'src/redux/actions/incometax';
export default function TaxPayment(props) {
  const { year } = props;
  const incomeState = useSelector((state) => state.incometaxReducer);
  if (incomeState.tax == null) {
    return <></>;
  }
  const data = incomeState.tax.filter((item) => item.finance_year === year);

  const getExpiryDate = (compliance_id, fin_year) => {
    if (incomeState.dueDates != null) {
      const dueDateObj = incomeState.dueDates.filter((item) => {
        return item.complianceID.id === compliance_id && item.fin_year === fin_year;
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Nature of Tax</TableCell>
            {/* <TableCell>Due Date of Payment</TableCell>
            
            <TableCell>Delay Days</TableCell> */}
            <TableCell>Date of Payment</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Challan No. </TableCell>
            <TableCell>BSR Code</TableCell>
            <TableCell>Upload Challan</TableCell>
            <TableCell sx={{display: isStartupFino ? 'none': ''}}>Edit / Delte</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            data.map((row) => {
              const delay =
                incomeState.dueDates.length > 0
                  ? calDelay(getExpiryDate(row.complianceID, row.finance_year), row.payment_dates)
                  : 0;
              return (
                <TableRow
                  //   key={row.name}

                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.nature_tax}</TableCell>
                  <TableCell>{dateFormat(row.payment_dates)}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.challan_num}</TableCell>
                  <TableCell>{row.bsr_code}</TableCell>
                  <TableCell>
                    <Link
                      variant="a"
                      href={row.upload_challan}
                      target="_blank"
                    // underline="none"
                    >
                      Challan
                    </Link>
                  </TableCell>
                  <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                    <Box textAlign="center">
                      <Update row={row} />
                      <ConfirmProvider>
                        <Delete row={row} />
                      </ConfirmProvider>
                    </Box>
                  </TableCell>

                </TableRow>
              );
            })}
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {data.map((item) => parseInt(item.amount)).reduce((prev, next) => prev + next, 0)}
            </TableCell>

          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deleteincometaxpay(row.id))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rowsirrr are", row);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <Income_Tax_Payment_update row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const Income_Tax_Payment_update = (props) => {
  const { row } = props
  const [value, setValue] = useState(row.payment_dates);
  const [formData, setFormData] = useState({
    finance_year: '',
    period: '',
    nature_tax: '',
    total_penalty: '',
    challan_num: '',
    bsr_code: '',
    amount: '',
    payment_dates: ''
  });

  const natur_tax = ["Advance Tax", "Self Assessment Tax"];
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const period = ["Apr-March"];

  const [perd, setPerd] = useState(row.period);
  const [finace, setFinace] = useState(row.finance_year);
  const [taxntr, setTaxntr] = useState(row.nature_tax);
  const [amt, setAmt] = useState(row.amount);
  const [chall_no, setChall_no] = useState(row.challan_num);
  const [bsrcd, setBsrcd] = useState(row.bsr_code);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log("HEllo WORLD");
  }

  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    // setFormData({ ...formData, [name]: valuee });
    formData[`${name}`] = valuee
    // console.log('File name')
  }
  const dis = (e) => {
    // formData['complianceId'] = complianceId
    const bodyFormData = new FormData();
    formData['companyId'] = companyId
    formData['complianceID'] = row.complianceID
    // formData.payment_dates = moment(formData.payment_dates).format('YYYY-MM-DD')
    // formData.month = moment(formData.payment_dates).format('MMMM')
    //formData['period']='Apr-March'

    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    console.log('form data =>', formData);
    console.log('form data = >', bodyFormData);
    //toast.success('Add Successfully');
    dispatch(updateincometaxpay(row.id, bodyFormData,companyId));
  };


  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

        {/*income tax payment : finance_year */}

        <Autocomplete
          value={finace} name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Finance year" />}
        />
        {/*income tax payment : Period */}

        <Autocomplete
          defaultValue={'Apr-March'}
          value={perd} name="period"
          onChange={(event, newValue) => {
            setPerd(newValue);
            {
              formData.period = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={period}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Period" />}
        />
        {/*income tax payment : Tax Nature */}

        <Autocomplete
          value={taxntr} name="nature_tax"
          onChange={(event, newValue) => {
            setTaxntr(newValue);
            {
              formData.nature_tax = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={natur_tax}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Tax Nature" />}
        />
        {console.log(formData)}

        {/*income tax payment : Date Of Payment */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date Of Payment"
            value={value} name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                // formData.penality_payment_dates = newValue;
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
          />

        </LocalizationProvider>

        {/*income tax payment : Amount */}

        <TextField
          value={amt} name="amount"
          onChange={(e) => {
            setAmt(e.target.value);
            {
              formData.amount = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Amount" variant="outlined" required sx={{ margin: 1 }} />

        {/*income tax payment : Challan Number */}

        <TextField
          value={chall_no} name="challan_num"
          onChange={(e) => {
            setChall_no(e.target.value);
            { formData.challan_num = e.target.value };
            { handleFormDataChange };
          }}
          label="Challan Number" variant="outlined" required sx={{ margin: 1 }} />

        {/*income tax payment : BSR code */}

        <TextField value={bsrcd} name="bsr_code" onChange={(e) => {
          setBsrcd(e.target.value);
          { formData.bsr_code = e.target.value };
          { handleFormDataChange };
        }} label="BSR code" variant="outlined" sx={{ margin: 1 }} />

        {/*income tax payment : Upload Challan*/}

        <h5>Upload Challan</h5>
        <Box textAlign="right" >
          <a href={row.upload_challan} target="_blank">
            Challan
          </a>
        </Box>

        <TextField required name='upload_challan' onChange={handleSelectFile} type="file" sx={{ margin: 1 }} />
      </Box>
      <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={dis} variant="contained">SAVE</Button></Box>
    </>
  );
};



