import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import MUIDataTable from 'mui-datatables';
import { allcompdemData_get } from 'src/redux/actions/allcompDemData_Action';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { calSumTillYear } from 'src/sections/@dashboard/app/MainPageComponents/util';
import { Button } from '@mui/material';
import { color } from '@mui/system';

export default function Companystats() {

  const com_dem_datas = useSelector((state) => state.allCompDemDatareducers);
  const[com_dem_data,setcom_dem_data]=useState()
  useEffect(() => {
    setcom_dem_data(com_dem_datas.comp_demandData);
  }, [com_dem_datas]);

  useEffect(() => {
  dispatch(allcompdemData_get(com_dem_datas.comp_demandData.count));
  }, [com_dem_datas]);

  console.log('filling_data', com_dem_datas); 
  console.log('new one',com_dem_data)
  const [page, setPage] = useState(0);
  let data_total = com_dem_data?.count;
  const [total,set_total]=useState(data_total)
  console.log("i AM total",data_total)
  console.log('data_total', data_total);

  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );

  const handleChange = (event) => {
    //dispatch(setFinYear(event.target.value));
    setYear(event.target.value);
    api_data(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  console.log("outer india",com_dem_data,total)
  const dispatch = useDispatch();

  const [datas, setDatas] = useState([]);
  const [gstOut_sum,setgstOut_sum]=useState([]);
  const [tdsOut_sum,settdsOut_sum]=useState([]);
  const [pfOut_sum,setpfOut_sum]=useState([]);
  const [esicOut_sum,setesicOut_sum]=useState([]);
  const [itOut_sum,setitOut_sum]=useState([]);

  const [gstInrst_sum,setgstInrst_sum]=useState([]);
  const [tdsInrst_sum,settdsInrst_sum]=useState([]);
  const [pfInrst_sum,setpfInrst_sum]=useState([]);
  const [esicInrst_sum,setesicInrst_sum]=useState([]);
  const [itInrst_sum,setitInrst_sum]=useState([]);

  const [paidGst_sum,      setpaidGst_sum]=useState([]);
  const [paidGst_ipsum,    setpaidGst_ipsum]=useState([]);
  const [paidTds_sum,      setpaidTds_sum]=useState([]);
  const [paidPf_sum,       setpaidPf_sum]=useState([]);
  const [paidEsci_sum,     setpaidEsic_sum]=useState([]);
  const [paidTds_dectsum,  setpaidTds_dectsum]=useState([]);
  const [paidIt_sum,       setpaidIt_sum]=useState([]);

  const [sum_all,setSumall]=useState([]);





  var flag = 1;
  let y = [];
  let z = [];

  function api_data(year) {
    let x = [];
    let totalGst_Outstd = [],
      totalTds_Outstd = [],
      totalPf_Outstd = [],
      totalEsi_Outstd = [],
      total_It_Outstd = [];
    let intrest_Gst = [],
      intrest_Tds = [],
      intrest_Pf = [],
      intrest_Esi = [],
      intrest_It = [];
    let Gst_paid_Cash = [],
      Gst_paid_ip = [],
      Tds_paid = [],
      Pf_paid = [],
      Esic_paid = [],
      Tds_deduce = [],
      It_paid = [];
    let sumof_all=[]
    //console.log("it_paid",(JSON.parse(com_dem_data.comp_demandData.results[1].computed_data['gst_interest_data'])['intrest']['2020-21'])+(JSON.parse(com_dem_data.comp_demandData.results[1].computed_data['gst_interest_data'])['intrest']['2022-23']))
    console.log('function', year);

    const data = com_dem_data?.results?.map((row, index) => {
      x.push(
        JSON.parse(com_dem_data.results[index].computed_data['gst_interest_data'])[
          'intrest'
        ][`${year}`]
      );
      // Outstanding Demand JSON
      totalGst_Outstd.push(
        calSumTillYear(
          JSON.parse(com_dem_data.results[index].computed_data['gst_outstanding'])[
            'demand_amount'
          ],
          year
        )
      );
      totalTds_Outstd.push(
        calSumTillYear(
          JSON.parse(
            com_dem_data.results[index].computed_data['tds_demand_outstanding']
          )['total_demand'],
          year
        )
      );
      totalPf_Outstd.push(
        calSumTillYear(
          JSON.parse(
            com_dem_data.results[index].computed_data['pf_penalties_outstanding']
          )['total_penalty'],
          year
        )
      );
      totalEsi_Outstd.push(
        calSumTillYear(
          JSON.parse(com_dem_data.results[index].computed_data['esi_outstanding'])[
            'total_penalty'
          ],
          year
        )
      );
      total_It_Outstd.push(
        calSumTillYear(
          JSON.parse(
            com_dem_data.results[index].computed_data['tax_demand_outstanding']
          )['demand_amount'],
          year
        )
      );

      // Interest Paid
      intrest_Gst.push(
        JSON.parse(com_dem_data.results[index].computed_data['gst_interest_data'])[
          'intrest'
        ][`${year}`]
      );
      intrest_Tds.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['tds_demand_interest']
        )['interest_amount'][`${year}`]
      );
      intrest_Pf.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['pf_penalties_interest']
        )['total_penalty'][`${year}`]
      );
      intrest_Esi.push(
        JSON.parse(com_dem_data.results[index].computed_data['esi_interest'])[
          'total_penalty'
        ][`${year}`]
      );
      intrest_It.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['tax_demand_interest']
        )['interest_paid'][`${year}`]
      );

      //Taxes Paid
      Gst_paid_Cash.push(
        JSON.parse(com_dem_data.results[index].computed_data['gst_total_yearly'])[
          'paid_cash'
        ][`${year}`]
      );
      Gst_paid_ip.push(
        JSON.parse(com_dem_data.results[index].computed_data['gst_total_yearly'])[
          'paid_credit'
        ][`${year}`]
      );
      Tds_paid.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['tds_challan_yearly_total']
        )['base_amount'][`${year}`]
      );
      Pf_paid.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['pf_challan_yearly_total']
        )['pf_amount'][`${year}`]
      );
      Esic_paid.push(
        JSON.parse(com_dem_data.results[index].computed_data['esi_yearly_total'])[
          'esi_paid_amt'
        ][`${year}`]
      );
      Tds_deduce.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['tax_deduted_yearly_total']
        )['tds_amount'][`${year}`]
      );
      It_paid.push(
        JSON.parse(
          com_dem_data.results[index].computed_data['tax_paid_yearly_total']
        )['amount'][`${year}`]
      );
      sumof_all.push(
        (totalGst_Outstd[index] == undefined ? 0 : totalGst_Outstd[index]) +
        (totalTds_Outstd[index] == undefined ? 0 : totalTds_Outstd[index]) +
        (totalPf_Outstd[index] == undefined ? 0 : totalPf_Outstd[index]) +
        (totalEsi_Outstd[index] == undefined ? 0 : totalEsi_Outstd[index]) +
        (total_It_Outstd[index] == undefined ? 0 : total_It_Outstd[index]) +
        (intrest_Gst[index] == undefined ? 0 : intrest_Gst[index]) +
        (intrest_Tds[index] == undefined ? 0 : intrest_Tds[index]) +
        (intrest_Pf[index] == undefined ? 0 : intrest_Pf[index]) +
        (intrest_Esi[index] == undefined ? 0 : intrest_Esi[index]) +
        (intrest_It[index] == undefined ? 0 : intrest_It[index]) +
        (Gst_paid_Cash[index] == undefined ? 0 : Gst_paid_Cash[index]) +
        (Gst_paid_ip[index] == undefined ? 0 : Gst_paid_ip[index]) +
        (Tds_paid[index] == undefined ? 0 : Tds_paid[index]) +
        (Pf_paid[index] == undefined ? 0 : Pf_paid[index]) +
        (Esic_paid[index] == undefined ? 0 : Esic_paid[index]) +
        (Tds_deduce[index] == undefined ? 0 : Tds_deduce[index]) +
        (It_paid[index] == undefined ? 0 : It_paid[index])
      )
      //console.log
      console.log('Tds', totalGst_Outstd);
      console.log('sumof_ALL', sumof_all);
      let gst_sum = 0,tds_sum=0,pf_sum=0,esic_sum=0,it_sum=0
      let inrst_it_sum=0,inrst_tds_sum=0,inrst_pf_sum=0,inrst_esic_sum=0,inrst_gst_sum=0
      let gst_pdcash=0,gst_ipcash=0,tds_pdcash=0,pf_pdcash=0,esic_pdcash=0,tds_dect=0,it_pdcash=0,sum_sum=0;
      //gst out   
      for (let i = 0; i < totalGst_Outstd.length; i += 1)
      {
        gst_sum = (totalGst_Outstd[i]==undefined?0 : totalGst_Outstd[i]) +gst_sum
      }
      //tds out
      for (let i = 0; i < totalTds_Outstd.length; i += 1)
      {
        tds_sum = (totalTds_Outstd[i]==undefined?0 : totalTds_Outstd[i]) +tds_sum
      }
      //pf
      for (let i = 0; i < totalPf_Outstd.length; i += 1)
      {
        pf_sum = (totalPf_Outstd[i]==undefined?0 : totalPf_Outstd[i]) +pf_sum
      }
      //esic
      for (let i = 0; i < totalEsi_Outstd.length; i += 1)
      {
        esic_sum = (totalEsi_Outstd[i]==undefined?0 : totalEsi_Outstd[i]) +esic_sum
      }
      //it
      for (let i = 0; i < total_It_Outstd.length; i += 1)
      {
        it_sum = (total_It_Outstd[i]==undefined?0 : total_It_Outstd[i]) +it_sum
      }
      //inrst gst
      for (let i = 0; i < intrest_Gst.length; i += 1)
      {
        inrst_gst_sum = (intrest_Gst[i]==undefined?0 : intrest_Gst[i]) +inrst_gst_sum
      }
      //it
      for (let i = 0; i < intrest_Tds.length; i += 1)
      {
        inrst_tds_sum = (intrest_Tds[i]==undefined?0 : intrest_Tds[i]) +inrst_tds_sum
      }
              //it
      for (let i = 0; i < intrest_Pf.length; i += 1)
      {
        inrst_pf_sum = (intrest_Pf[i]==undefined?0 : intrest_Pf[i]) +inrst_pf_sum
      }
              //it
      for (let i = 0; i < intrest_Esi.length; i += 1)
      {
        inrst_esic_sum = (intrest_Esi[i]==undefined?0 : intrest_Esi[i]) +inrst_esic_sum
      }
              //it
      for (let i = 0; i < intrest_It.length; i += 1)
      {
        inrst_it_sum = (intrest_It[i]==undefined?0 : intrest_It[i]) +inrst_it_sum
      }
      //tax

      for (let i = 0; i < Gst_paid_Cash.length; i += 1)
      {
        gst_pdcash = (Gst_paid_Cash[i]==undefined?0 : Gst_paid_Cash[i]) +gst_pdcash
      }
      for (let i = 0; i < Gst_paid_ip.length; i += 1)
      {
        gst_ipcash = (Gst_paid_ip[i]==undefined?0 : Gst_paid_ip[i]) +gst_ipcash
      }
      for (let i = 0; i < Tds_paid.length; i += 1)
      {
        tds_pdcash = (Tds_paid[i]==undefined?0 : Tds_paid[i]) +tds_pdcash
      }
      for (let i = 0; i < Pf_paid.length; i += 1)
      {
        pf_pdcash = (Pf_paid[i]==undefined?0 : Pf_paid[i]) +pf_pdcash
      }
      for (let i = 0; i < Esic_paid.length; i += 1)
      {
        esic_pdcash = (Esic_paid[i]==undefined?0 : Esic_paid[i]) +esic_pdcash
      }
      for (let i = 0; i < Tds_deduce.length; i += 1)
      {
        tds_dect = (Tds_deduce[i]==undefined?0 : Tds_deduce[i]) +tds_dect
      }
      for (let i = 0; i < It_paid.length; i += 1)
      {
        it_pdcash = (It_paid[i]==undefined?0 : It_paid[i]) +it_pdcash
      }
      for (let i = 0; i < sumof_all.length; i += 1)
      {
        sum_sum = (sumof_all[i]==undefined?0 : sumof_all[i]) + sum_sum
      }
      
      setgstOut_sum(gst_sum)
      settdsOut_sum(tds_sum)
      setpfOut_sum(pf_sum)
      setesicOut_sum(esic_sum)
      setitOut_sum(it_sum)
      setgstInrst_sum(inrst_gst_sum)
      settdsInrst_sum(inrst_tds_sum)
      setpfInrst_sum(inrst_pf_sum)
      setesicInrst_sum(inrst_esic_sum)
      setitInrst_sum(inrst_it_sum)
      setpaidGst_sum(gst_pdcash)
      setpaidGst_ipsum(gst_ipcash)
      setpaidTds_sum(tds_pdcash)
      setpaidPf_sum(pf_pdcash)
      setpaidEsic_sum(esic_pdcash)
      setpaidTds_dectsum(tds_dect)
      setpaidIt_sum(it_pdcash)
      setSumall(sum_sum)
      

      console.log("gst_sum",gst_sum)


      return {
        name: row.company_name,
        // Outstanding Demand

        out_Gst: totalGst_Outstd[index] == undefined ? 0 : totalGst_Outstd[index],
        out_Tds: totalTds_Outstd[index] == undefined ? 0 : totalTds_Outstd[index],
        out_Pf: totalPf_Outstd[index] == undefined ? 0 : totalPf_Outstd[index],
        out_Esi: totalEsi_Outstd[index] == undefined ? 0 : totalEsi_Outstd[index],
        out_It: total_It_Outstd[index] == undefined ? 0 : total_It_Outstd[index],

        //Interest Paid
        inrst_Gst: intrest_Gst[index] == undefined ? 0 : intrest_Gst[index],
        inrst_Tds: intrest_Tds[index] == undefined ? 0 : intrest_Tds[index],
        inrst_Pf: intrest_Pf[index] == undefined ? 0 : intrest_Pf[index],
        inrst_Esi: intrest_Esi[index] == undefined ? 0 : intrest_Esi[index],
        inrst_It: intrest_It[index] == undefined ? 0 : intrest_It[index],

        //Taxes Paid
        gst_paid_cash: Gst_paid_Cash[index] == undefined ? 0 : Gst_paid_Cash[index],
        gst_paid_ip: Gst_paid_ip[index] == undefined ? 0 : Gst_paid_ip[index],
        tds_paid: Tds_paid[index] == undefined ? 0 : Tds_paid[index],
        pf_paid: Pf_paid[index] == undefined ? 0 : Pf_paid[index],
        esic_paid: Esic_paid[index] == undefined ? 0 : Esic_paid[index],
        tds_deduct: Tds_deduce[index] == undefined ? 0 : Tds_deduce[index],
        it_paid: It_paid[index] == undefined ? 0 : It_paid[index],

        //SUMMATION
        sum:
          (totalGst_Outstd[index] == undefined ? 0 : totalGst_Outstd[index]) +
          (totalTds_Outstd[index] == undefined ? 0 : totalTds_Outstd[index]) +
          (totalPf_Outstd[index] == undefined ? 0 : totalPf_Outstd[index]) +
          (totalEsi_Outstd[index] == undefined ? 0 : totalEsi_Outstd[index]) +
          (total_It_Outstd[index] == undefined ? 0 : total_It_Outstd[index]) +
          (intrest_Gst[index] == undefined ? 0 : intrest_Gst[index]) +
          (intrest_Tds[index] == undefined ? 0 : intrest_Tds[index]) +
          (intrest_Pf[index] == undefined ? 0 : intrest_Pf[index]) +
          (intrest_Esi[index] == undefined ? 0 : intrest_Esi[index]) +
          (intrest_It[index] == undefined ? 0 : intrest_It[index]) +
          (Gst_paid_Cash[index] == undefined ? 0 : Gst_paid_Cash[index]) +
          (Gst_paid_ip[index] == undefined ? 0 : Gst_paid_ip[index]) +
          (Tds_paid[index] == undefined ? 0 : Tds_paid[index]) +
          (Pf_paid[index] == undefined ? 0 : Pf_paid[index]) +
          (Esic_paid[index] == undefined ? 0 : Esic_paid[index]) +
          (Tds_deduce[index] == undefined ? 0 : Tds_deduce[index]) +
          (It_paid[index] == undefined ? 0 : It_paid[index])
      };
    });
    setDatas(data);
  }
  useEffect(() => {
    api_data(year);
    //setDatas(data)
  }, [com_dem_data]);

  const [tabdata, setTabdata] = useState([]);
  const columns = [
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101
          }
        })
      }
    },
    {
      name: 'out_Gst',
      label: `Total GST Outstanding (${gstOut_sum})` ,
      align: 'center',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'out_Tds',
      label: `Total TDS Outstanding (${tdsOut_sum})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'out_Pf',
      label: `Total PF Outstanding (${Math.trunc(pfOut_sum)})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'out_Esi',
      label: `Total ESIC Outstanding (${Math.trunc(esicOut_sum)})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'out_It',
      label: `Total IT Outstanding  (${Math.trunc(itOut_sum)})` ,
      options: {
        filter: true, 
        sort: true
      }
    },
    // { INTEREST P A I D }
    {
      name: 'inrst_Gst',
      label: `Total GST Interest Paid  (${Math.trunc(gstInrst_sum)})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inrst_Tds',
      label: `Total TDS Interest Paid  (${Math.trunc(tdsInrst_sum)})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inrst_Pf',
      label: `Total PF Interest Paid  (${Math.trunc(pfInrst_sum)})` ,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inrst_Esi',
      label: `Total Esi Interest Paid  (${Math.trunc(esicInrst_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'inrst_It',
      label: `Total Income Tax Interest Paid  (${Math.trunc(itInrst_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    // {T A X E S  P A I D}
    {
      name: 'gst_paid_cash',
      label: `Total GST Paid in Cash  (${Math.trunc(paidGst_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'gst_paid_ip',
      label: `Total GST Via inputs  (${Math.trunc(paidGst_ipsum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'tds_paid',
      label: `Total TDS Paid  (${Math.trunc(paidTds_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'pf_paid',
      label: `Total PF Paid  (${Math.trunc(paidPf_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'esic_paid',
      label: `Total ESIC Paid  (${Math.trunc(paidEsci_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'tds_deduct',
      label: `Total TDS Deducted  (${Math.trunc(paidTds_dectsum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'it_paid',
      label: `Total Income Tax Paid  (${Math.trunc(paidIt_sum)})`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'sum',
      label: `Summation  (${Math.trunc(sum_all)})`,
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  // const data = [
  //   { name: "Demo Account Private Limited", company: "1500", city: "5602", state: "22500", period: "4000", fdate: "65000", duedate: "35600", extdate: "100500", total:"1200000"},
  //   { name: "Vitrak Private Limited", company: "3500", city: "8900", state: "22500", period: "1000", fdate: "78000", duedate: "1200", extdate: "96300",total:"840000" },
  //   { name: "Agile Private Limited", company: "15500", city: "99602", state: "45500", period: "2000", fdate: "65000", duedate: "600", extdate: "5700",total:"650000" },

  // ];
  console.log('data chec', com_dem_data);
  // const data = com_dem_data.comp_demandData.results.map((row) => {
  //   return ({ name:row.company_name, company: row.computed_data.esi_interest, city: "5602", state: "22500", period: "4000", fdate: "65000", duedate: "35600", extdate: "100500", total: "1200000" })
  // })

  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [10, 500, 1000],
    // onTableChange: (action, tableState) => {
    //   if (action == 'filterChange') {
    //     console.log('tables', tableState);
    //   }
    // },
    //count, // Use total number of items
  };
  const years = ['2020-21', '2021-22', '2022-23'];

  const [counter, setCounter] = useState(0);

  return (
    <>
      <Box>
        <h4>Total Data : {data_total}</h4>
      </Box>
      <br></br>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          // multiple
          value={year}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
          )}
          MenuProps={MenuProps}
        >
          {years.map((y) => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <MUIDataTable title={'Company Stats'} data={datas} columns={columns} options={options} />
    </>
  );
}
