import React from 'react';
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useState } from 'react';
import { Paper } from '@mui/material';
import { fCurrency, fNumber } from 'src/utils/formatNumber';
import { calQuarterTotal, calOneBookTotal } from './util';
import { isStartupFino, istalenteye } from 'src/config';
export const TotalTaxTable = ({ totalTaxData, year }) => {
  const quaters = ['Q1', 'Q2', 'Q3', 'Q4'];
  return (
    <>
      {!istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {!istalenteye && (
                  <>
                    <TableCell>Quaterly</TableCell>
                    <TableCell>GST Paid In Cash</TableCell>
                    <TableCell>GST Paid Via GST Inputs</TableCell>
                    <TableCell>TDS Paid</TableCell>
                    <TableCell>PF Paid</TableCell>
                    <TableCell>ESI Paid</TableCell>
                    <TableCell>TDS Deducted</TableCell>
                    <TableCell>Income Tax Paid</TableCell>
                    <TableCell>Total Taxes Paid</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(totalTaxData).length > 0 &&
                quaters.map((row, index) => {
                  const total = 0;
                  return (
                    <TableRow key={index} hover>
                      <TableCell>{row}</TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['gst_quaterly_data'])['paid_cash'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['gst_quaterly_data'])['paid_credit'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['tds_challan_quaterly'])['base_amount'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['tax_deduted_quaterly'])['tds_amount'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['tax_paid_quaterly'])['amount'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>{fCurrency(calQuarterTotal(totalTaxData, year, row))}</TableCell>
                    </TableRow>
                  );
                })}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    fontWeight: 2,
                    fontWeight: 'bold'
                  }
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('GST_CASH', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('GST_CREDIT', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('TDS_PAID', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('PF', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('ESI', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('TDS_DEDUCTED', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('INCOMETAX', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('ALL', totalTaxData, year))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {istalenteye && (
                  <>
                    <TableCell>Quaterly</TableCell>
                    <TableCell>PF Paid</TableCell>
                    <TableCell>ESI Paid</TableCell>
                    <TableCell>Total Paid Deductions</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(totalTaxData).length > 0 &&
                quaters.map((row, index) => {
                  const total = 0;
                  return (
                    <TableRow key={index} hover>
                      <TableCell>{row}</TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][
                            `('${year}', '${row}')`
                          ]
                        )}
                      </TableCell>
                      <TableCell>
                        {fCurrency(
                          JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][
                            `('${year}', '${row}')`
                          ]                        
                        )}
                      </TableCell>
                      <TableCell>{fCurrency(
                          JSON.parse(totalTaxData['esi_quaterly'])['esi_paid_amt'][
                            `('${year}', '${row}')`
                          ]     +
                          JSON.parse(totalTaxData['pf_challan_quaterly'])['pf_amount'][
                            `('${year}', '${row}')`
                          ]
                        )}</TableCell>
                    </TableRow>
                  );
                })}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    fontWeight: 2,
                    fontWeight: 'bold'
                  }
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('PF', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('ESI', totalTaxData, year))}
                </TableCell>
                <TableCell>
                  {Object.keys(totalTaxData).length > 0 &&
                    fCurrency(calOneBookTotal('ALL_COMP', totalTaxData, year))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

    </>
  );
};
