import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';


export const triggerReminder = () => (dispatch) => {
  HttpWrapper('GET', `/auth/reminder/`, false, {})
    .then((res) => {
      if(res.status === '200'){
        toast.success('WhatsApp Message sent')
    }
    })
    .catch((error) => {
      toast.error('Facing error in APi. Pls check with developer')
    });
};


export const triggerMissDeadline = () => (dispatch) => {
  HttpWrapper('GET', `/auth/missedDeadline/`, false, {})
    .then((res) => {
      if(res.status === '200'){
        toast.success('WhatsApp Message sent')
    }
    })
    .catch((error) => {
      toast.error('Facing error in APi. Pls check with developer')
    });
};