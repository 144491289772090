import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import { addGst } from 'src/redux/actions/gstBook';
import { PropaneSharp } from '@mui/icons-material';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import { addesi, addesidemand } from 'src/redux/actions/esiBook';

function DropContentEsi(props) {
  const {challans} = props;
  const [complianceId, setComplianceId] = useState('');
  const handleChange = (event) => {
    setComplianceId(event.target.value);
  };

  return (
    <div>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1, overflow: 'scroll' }}
      >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Compliance</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={complianceId}
            onChange={handleChange}
            autoWidth
            label="Compliance"
          >
            <MenuItem value={20}>ESIC Challan</MenuItem>
            <MenuItem value={21}>ESIC Demand</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {
          {
            20: <EsiChallan challans={challans} />,
            21: <EsiDemand challans={challans}/>
          }[complianceId]
        }
        {/* <Fields complianceId={complianceId} /> */}
      </Box>
    </div>
  );
}
const EsiChallan = (props) => {
//   const { complianceId } = PropaneSharp
  const {challans} = props;
  // console.log("HELLO+>>>>>", challans);
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    esi_type: '',
    esi_paid_amt: '',
    challan_num: '',
    payment_dates: '',
    receipt: '',
    contribution_history:'',
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [esiType, setEsiType] = useState();
  const [esiamount, setEsiamount] = useState();
  const [challanno, setChallanno] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const esi_no = searchParams.get('esi_no');



  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData)
  }
  const dis = (e) => {
    console.log('form data', formData);
    formData['complianceID'] = "22fb1e74-4170-44a9-a48e-c13ccf88e732"
    formData['companyId'] = companyId
    formData["esi_num"]= esi_no;
    formData.payment_dates = moment(formData.payment_dates).format('YYYY-MM-DD')
    var bodyFormData = new FormData();
    for(let key in formData){
      if(key,formData[key] !== ''){
        bodyFormData.append(key,formData[key])
      }
    }
    console.log('form data', formData);
    dispatch(addesi(bodyFormData));
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required/>}
        />
        <TextField
          value={esiamount}
          required
          name="esi_paid_amt"
          type="number"
          onChange={(e) => {
              setEsiamount(e.target.value);
              {
              formData.esi_paid_amt = e.target.value;
            }
            {
              handleFormDataChange;
            }
        }}
          label="ESI Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <Autocomplete
          value={esiType}
          name="esi_type"
          onChange={(event, newValue) => {
            setEsiType(newValue);
            {
              formData.esi_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={["Original","Supplement"]}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="ESI Type" required/>}
        />
        <TextField
          name="challan_num"
          required
          onChange={(e) => {
            setChallanno(e.target.value);
            {
              formData.challan_num = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          value={challanno}
          label="Challan Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date "
            value={value}
            name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
          />
        </LocalizationProvider>
        <h4>Payment Challan Receipt</h4>
        <TextField required name='receipt' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <h4>Contribution History</h4>
        <TextField required name='contribution_history' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
const EsiDemand = (props) => {
  const {challans} = props;
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    total_penalty: '',
    date_fill: ''
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2011-12',
    '2012-13',
    '2013-14',
    '2014-15',
    '2015-16',
    '2016-17',
    '2017-18',
    '2018-19',
    '2019-20',
    '2020-21',
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [total_penalty, setTotal_penalty] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const esi_no = searchParams.get('esi_no');

  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dis = (e) => {
    console.log('form data', formData);
    formData['complianceID'] = "9216387f-f91c-4df8-bdd0-94f925442288";
    formData['companyId'] = companyId
    formData["esi_num"]= esi_no;

    formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
    var bodyFormData = new FormData();
    for(let key in formData){
      if(key,formData[key] !== ''){
        bodyFormData.append(key,formData[key])
      }
    }
    console.log('form data', bodyFormData);
    dispatch(addesidemand(bodyFormData))
    // dispatch(addGst(formData))
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required/>}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required/>}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Penalty Payment Date"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
            required
          value={total_penalty}
          type="number"
          name="total_penalty"
          onChange={(e) => {
            setTotal_penalty(e.target.value);
            {
              formData.total_penalty = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Penalty Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button type="submit" onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};


export default DropContentEsi;
