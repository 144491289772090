// component
import Iconify from '../../components/Iconify';
import { getCompanyId } from 'src/utils/common';
import { isStartupFino,istalenteye } from 'src/config';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Iconify icon={name} width={24} height={24} style={{ marginLeft: '-90%' }} />
);

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: `/dashboard/main?companyid=`,
    icon: getIcon('carbon:dashboard'),
    visible: true
  },
  {
    title: 'Task Manager',
    icon: getIcon('gg:calendar-due'),
    visible: !isStartupFino && !istalenteye,
    children: [
      {
        title: 'Task Creation',
        path: `/dashboard/taskCreation?companyid=`,
        icon: getIcon('majesticons:paper-fold-text-line'),
        visible: !isStartupFino
      },
      {
        title: 'Task Names',
        path: `/dashboard/tasknames?companyid=`,
        icon: getIcon('akar-icons:person-add'),
        visible: !isStartupFino
      },
      {
        title: 'Team Name',
        path: `/dashboard/overDueDates?companyid=`,
        icon: getIcon('bi:person-check'),
        visible: !isStartupFino
      }
    ]
  },

  {
    title: 'Invoice Mangement',
    path: `/dashboard/billing-module?companyid=`,
    icon: getIcon('uil:invoice'),
    visible: !isStartupFino && !istalenteye,
  },
  {
    title: 'Monthly Compliance List',
    path: `/dashboard/comp_list?companyid=`,
    icon: getIcon('mdi:office-building-marker-outline'),
    visible: !isStartupFino && !istalenteye,
  },
  {
    title: 'Master Documents',
    path: `/dashboard/masterdoc?companyid=`,
    icon: getIcon('icomoon-free:drawer'),
    visible: true
  },
  {
    title: 'Filling Status',
    path: `/dashboard/fillingstatus?companyid=`,
    icon: getIcon('fluent:form-24-regular'),
    visible: !isStartupFino && !istalenteye
  },
  {
    title: 'Company Stats',
    path: `/dashboard/companystats?companyid=`,
    icon: getIcon('icomoon-free:stats-dots'),
    visible: !isStartupFino && !istalenteye 
  },
  {
    title: 'Companies List',
    path: `/dashboard/companylist?companyid=`,
    icon: getIcon('codicon:book'),
    visible: !isStartupFino && !istalenteye
  },
  {
    title: 'Console View',
    path: `/dashboard/console_view?companyid=`,
    icon: getIcon('fa6-solid:table-list'),
    visible: !isStartupFino && !istalenteye
  },
  {
    title: 'notification-center',
    path: `/dashboard/notification-center?companyid=`,
    icon: getIcon('mdi:office-building-marker-outline'),
    visible: !isStartupFino && !istalenteye
  },
  {
    title: 'notification-Mail',
    path: `/dashboard/notification-mail?companyid=`,
    icon: getIcon('tabler:mail'),
    visible: !isStartupFino && !istalenteye
  },
  {
  title: 'Due Dates',
    icon: getIcon('gg:calendar-due'),
    visible: true,
    children: [
      {
        title: 'All due Dates',
        path: `/dashboard/allDueDates?companyid=`,
        icon: getIcon('fontisto:date'),
        visible: true
      },
      {
        title: 'All due filter Dates',
        path: `/dashboard/all_DueDates?companyid=`,
        icon: getIcon('fontisto:date'),
        visible: !isStartupFino && !istalenteye
      },
      {
        title: 'Overdue Date',
        path: `/dashboard/overDueDates?companyid=`,
        icon: getIcon('clarity:date-solid-alerted'),
        visible: true
      },
      {
        title: 'Upcoming Due Dates',
        path: `/dashboard/upcomingDueDates?companyid=`,
        icon: getIcon('healthicons:i-schedule-school-date-time'),
        visible: true
      }
    ]
  },
  {
    title: 'Login Vault',
    path: `/dashboard/loginvault?companyid=`,
    icon: getIcon('arcticons:sqrllogin'),
    visible: true
  },
  {
    title: 'Profile',
    path: `/dashboard/profile?companyid=`,
    icon: getIcon('arcticons:sqrllogin'),
    visible: true
  },
  {
    title: 'Preference',
    path: `/dashboard/preference?companyid=`,
    icon: getIcon('carbon:dashboard'),
    visible: true
  },
  {
    title: 'Compliance Books',
    icon: getIcon('ph:books-bold'),
    visible: true,
    children: [
      {
        title: 'ROC Book',
        path: `/dashboard/roc?companyid=`,
        icon: getIcon('mdi:office-building-marker-outline'),
        visible: !istalenteye
      },
      {
        title: 'GST Book',
        path: `/dashboard/gst/?companyid=`,
        icon: getIcon('carbon:book'),
        visible: !istalenteye
      },
      {
        title: 'TDS Book',
        path: `/dashboard/tds?companyid=`,
        icon: getIcon('majesticons:paper-fold-text-line'),
        visible: !istalenteye
      },
      {
        title: 'Income Tax Book',
        path: `/dashboard/incometax?companyid=`,
        icon: getIcon('codicon:book'),
        visible: !istalenteye
      },
      {
        title: 'PF Book',
        path: `/dashboard/pf?companyid=`,
        icon: getIcon('bi:person-check'),
        visible: true
      },
      {
        title: 'ESI Book',
        path: `/dashboard/esi?companyid=`,
        icon: getIcon('akar-icons:person-add'),
        visible: true
      }
      // {
      //   title: 'Due Diligence Book',
      //   path: '/dashboard/dueDiligence',
      //   icon: getIcon('bi:card-list')
      // }
    ]
  },
  {
    title: 'Other Books',
    icon: getIcon('ph:books-bold'),
    visible: true,
    path: `/dashboard/otherbooks?companyid=`,
  },
  {
    title: 'Download',
    path: `/dashboard/reports?companyid=`,
    icon: getIcon('carbon:report'),
    visible: true
  },
  {
    title: 'Feedback',
    path: `/dashboard/feedback?companyid=`,
    icon: getIcon('codicon:feedback'),
    visible: !isStartupFino && !istalenteye
  }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill')
  // }
];
export default sidebarConfig;
