import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import { useSelector } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, IconButton } from '@mui/material';
import { add_subscr } from 'src/redux/actions/invoice_subscription_action';
import moment from 'moment';
export default function Subscription_Add(props) {
  const { handleClickClose, add } = props;
  const dispatch = useDispatch();
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const all_services = useSelector((state) => state.invoice_reducers);
  console.log(all_services, 'all_services');

  //opts
  const status_opts = ['Active', 'Inactive','Hold'];


  // useStates
  const [comp_name, setcomp_name] = useState();
  const [onboard_date, setonboard_date] = useState(null);
  const [start_date, setstart_date] = useState(null);
  const [stat, setstat] = useState(null);
  const [amt, setamt] = useState();
  const [list_company_id, setlist_company_id] = useState();

  const [matched_company_id, setmatched_company_id] = useState();


  const [formData, setFormData] = useState({
    company_name: '',
    date_of_onboarding: '',
    start_from: '',
    amount: '',
    status: ''
  });
  let name, valuee;
  const opts = all_companies?.all_companies?.map((option) => option.company_name);
  const handleFormDataChange = (e) => {
    console.log("formData['company_name']", formData);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(formData, 'formNOdata');
  };
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  useEffect(() => {
    setlist_company_id(all_companies.all_companies);
  }, [all_companies]);

  const handleSumbit = () => {
    formData['company_id'] = matched_company_id?matched_company_id:'';
    dispatch(add_subscr(formData));
    handleClickClose();
  };
  console.log('formsforms', formData);
  // console.log('outside', matched_company_id);
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              value={comp_name}
              name="company_name"
              fullWidth
              freeSolo
              display="flex"
              onInputChange={(event, newValue) => {
                setcomp_name(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  list_company_id.filter((item) => {
                    if (item.company_name === newValue) {
                      setmatched_company_id(item.id);
                    }
                  });
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of Onbourding"
                value={onboard_date}
                name="date_of_onboarding"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setonboard_date(newValue);
                  {
                    formData.date_of_onboarding = moment(newValue).format('YYYY-MM-DD');
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Subscription Start from"
                value={start_date}
                name="start_from"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setstart_date(newValue);
                  {
                    formData.start_from = moment(newValue).format('YYYY-MM-DD');
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6} md={6} xl={6}>
            <TextField
              value={amt}
              fullWidth
              name="amount"
              type='number'
              onChange={(e) => {
                setamt(e.target.value);
                {
                  formData.amount = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Amount"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item lg={6}>
            <Autocomplete
              value={stat}
              name="status"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setstat(newValue);
                {
                  formData.status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={status_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Status of Company " />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={handleSumbit} variant="contained">
          Add Inovice
        </Button>
      </Box>
    </>
  );
}
