import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { istalenteye } from 'src/config';
const moment = require('moment');


export const getAllDueDates = (comapny_id) => (dispatch) => {!istalenteye &&(
  trackPromise(
    HttpWrapper('GET', `/compliance/due-dates/?company_id=${comapny_id}&is_applicable=true`, false, {})
      .then((res) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: []
        });
      })
  ))
  {istalenteye &&
    trackPromise(
    HttpWrapper('GET', `/compliance/due-dates/?company_id=${comapny_id}&is_applicable=true&due_date_applicable=true&book_name=PF Book&book_name=ESIC Book`, false, {})
      .then((res) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: []
        });
      })
  );
 }
};

export const getFilterDueDate = (comapny_id, start_date, end_date) => (dispatch) => {
  trackPromise(
    HttpWrapper('GET', `/compliance/due-dates/?company_id=${comapny_id}&current_due_date__gte=${start_date}&current_due_date__lte=${end_date}&is_applicable=true&due_date_applicable=true`, false, {})
      .then((res) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_ALL_DUE_DATES,
          payload: []
        });
      })
  );
};

export const getOverDueDates = (comapny_id) => (dispatch) => {
  const date = new Date();

  const current_date = moment(date).format('YYYY-MM-DD');
  {!istalenteye && trackPromise(
    HttpWrapper(
      'GET',
      `/compliance/due-dates/?company_id=${comapny_id}&current_due_date__lt=${current_date}&is_applicable=true&due_date_applicable=true`,
      false,
      {}
    )
      .then((res) => {
        dispatch({
          type: constants.SET_OVER_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_OVER_DUE_DATES,
          payload: []
        });
      })
  );}
  {istalenteye && trackPromise(
    HttpWrapper(
      'GET',
      `/compliance/due-dates/?company_id=${comapny_id}&current_due_date__lt=${current_date}&is_applicable=true&due_date_applicable=true&book_name=PF Book&book_name=ESIC Book`,
      false,
      {}
    )
      .then((res) => {
        dispatch({
          type: constants.SET_OVER_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_OVER_DUE_DATES,
          payload: []
        });
      })
  );}
};
export const getUpcomingDueDates = (comapny_id) => (dispatch) => {
  const date = new Date();
  const current_date = moment(date).format('YYYY-MM-DD');
  {!istalenteye && trackPromise(
    HttpWrapper(
      'GET',
      `/compliance/due-dates/?company_id=${comapny_id}&current_due_date__gte=${current_date}&is_applicable=true&due_date_applicable=true`,
      false,
      {}
    )
      .then((res) => {
        dispatch({
          type: constants.SET_UPCOMING_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_UPCOMING_DUE_DATES,
          payload: []
        });
      })
  );}
  {istalenteye &&trackPromise(
    HttpWrapper(
      'GET',
      `/compliance/due-dates/?company_id=${comapny_id}&current_due_date__gte=${current_date}&is_applicable=true&due_date_applicable=true&book_name=PF Book&book_name=ESIC Book`,
      false,
      {}
    )
      .then((res) => {
        dispatch({
          type: constants.SET_UPCOMING_DUE_DATES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: constants.SET_UPCOMING_DUE_DATES,
          payload: []
        });
      })
  );}
  
};

export const setFinYear = (year) => (dispatch) => {
  dispatch({
    type: constants.SET_YEAR,
    payload: year
  });
};

export const updateDueDate = (id, data, type) => (dispatch) => {
  trackPromise(
    HttpWrapper('PATCH', `/compliance/due-dates/${id}/`, true, data)
      .then((res) => {
        toast.success('Updated Successfully');
        if (type == 1) {
          dispatch(getAllDueDates(res.data.company_id));
        } else if (type == 2) {
          dispatch(getOverDueDates(res.data.company_id));
        } else if (type == 3) {
          dispatch(getUpcomingDueDates(res.data.company_id));
        }
      })
      .catch((err) => {
        toast.error('Encounter some error');
      })
  );
};