// material
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// component
import Iconify from '../../../../components/Iconify';

import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../../components/charts';
import { Link } from 'react-router-dom';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
  //color: theme.palette.primary.darker,
  //backgroundColor: theme.palette.primary.darker
}));

export default function NewUser(props) {
  const { dueDatesState, year, companyId } = props;
  const getStatus = (dueDate, extendedDueDate, filling_date) => {
    const expiry = extendedDueDate === null ? dueDate : extendedDueDate;
    //2->upcoming
    // 1->ontime
    // 0->overdue
    //4->delayed
    if (filling_date === null) {
      if (new Date(expiry) >= new Date()) {
        return 2;
      } else {
        return 0;
      }
    } else {
      if (new Date(filling_date) >= new Date(expiry)) {
        return 4;
      } else {
        return 1;
      }
    }
  };
  return (
    <>
      <Link to={`/dashboard/overDueDates?companyid=${companyId}`} style={{ textDecoration: 'none', color: 'white' }}>
        <RootStyle style={{ backgroundColor: '#ffba08', color: 'white' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Typography>
                Missed Due <br /> Dates
              </Typography>
              <Typography variant="h5">
              {dueDatesState?.overDueDates?.length > 0
                  ? dueDatesState?.overDueDates?.filter(
                      (row) =>
                        getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) == 0
                    ).length
                  : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Iconify icon="carbon:user-avatar-filled" width={25} height={25} />
            </Grid>
          </Grid>
        </RootStyle>
      </Link>
    </>
  );
}
