import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
// import { setToken, setCompanyData, setCompanyId } from '../../utils/common';

export const getAllPreference = (companyId) => (dispatch) => {
    // companyId = '6c743b53-3e95-4d15-9c09-5539c4026c53';
    HttpWrapper('GET', `/compliance/preference/?company_id=${companyId}`, false, {})
      .then((res) => {
        console.log(res)
        dispatch({
          type: Constants.SET_ALL_PREFERENCE,
          payload: res.data
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.SET_ALL_PREFERENCE,
          payload: error.message
        });
      });
  };
  
    
  export const updatepreference = (id, data, formData=true) => (dispatch) => {
    console.log(data);
    HttpWrapper('PATCH', `/compliance/preference/${id}/`, formData, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Updated Preference Data');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  
  export const deletepreference = (id) => (dispatch) => {
    HttpWrapper('DELETE', `/compliance/preference/${id}/`, true, id)
      .then((res, err) => {
        console.log('res', res.id);
        toast.success('Delete Successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  export const create_preference = (data,companyId, formData=true) => (dispatch) => {
    HttpWrapper('POST', `/compliance/preference/`, formData, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Add Successfully');
        dispatch(getAllPreference(companyId))
      })
      .catch((err) => {
        console.log(err);
      });
  };
