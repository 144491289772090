import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Typography, Chip } from '@mui/material';
import { Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSearchParams } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import Label from 'src/components/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MUIDataTable from 'mui-datatables';
import {
  allfillstat_patch,
  get_due_dates_by_period,
  get_due_dates_by_period_2,
  get_all_due_dates_company_id
} from 'src/redux/actions/fillstatAction';
import { CurrencyYenTwoTone, RawOff } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment, { months } from 'moment';
import { chunk } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import {
  add_Task,
  task_all_get,
  task_all_get_limit,
  team_Mates_get,
  task_Names_get,
  deletetask,
  updatetask,
  client_mail_list
} from 'src/redux/actions/task_creation_Action';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import { red } from '@mui/material/colors';
import { Duedate } from 'src/components/task_creations/DueDate';
import { number } from 'prop-types';

export default function Taskcreation() {
  const task_Data = useSelector((state) => state.taskreducers.task_management.results);
  console.log("taskData",task_Data)
  const teams = useSelector((state) => state.team_mates_reducers);
  const filling_data = useSelector((state) => state.allFillStatreducers);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const task_name = useSelector((state) => state.team_name_reducers);

  const [tasks_data, settasks_data] = useState([]);
  const [teamates_data, setteamates_data] = useState([]);
  const [task_names, setTask_names] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const task_owner = searchParams.get('task_owner');
  const task_assignee = searchParams.get('task_assignee');
  const due_date = searchParams.get('due_date');

  useEffect(() => {
    dispatch(task_all_get());
    dispatch(team_Mates_get());
    dispatch(task_Names_get());
    dispatch(client_mail_list());
  }, []);
  useEffect(() => {
    settasks_data(task_Data);
  }, [task_Data]);
  console.log('task', task_Data);
  useEffect(() => {
    setteamates_data(teams.team_mate);
  }, [teams]);
  useEffect(() => {
    setTask_names(task_name.task_name);
  }, [task_name]);

  const [extra, setExtra] = useState(null);
  const [tabdata, setTabdata] = useState([]);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [selectedID, setID] = useState('');
  const [value, setValue] = useState(new Date());
  const [opt_value, setOpt_value] = useState(' ');
  const [open, setOpen] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [counter, setCounter] = useState(0);
  var companies_id;
  let data_total = filling_data.comp_fillstat.length;

  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const years = ['2020-21', '2021-22', '2022-23'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    //let a = 0;
    const now = new Date();
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
    dispatch(get_due_dates_by_period(lastDay, firstDay));
    dispatch(getAllCompanies());
    setTabdata(filling_data);
  }, []);

  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const handleCheckbox = (e, id) => {
    setOpen(true);
    setID(id);
  };
  const columns = [
    {
      name: 'task_id',
      label: 'Task ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Company name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'task_name',
      label: 'Task Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'task_Desc',
      label: 'Task Description',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'task_own',
      label: 'Task Owner',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'task_assn',
      label: 'Task Assigne',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'date_task',
      label: 'Date of Task',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Typography>{moment(data).format('DD-MM-YYYY')}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'due_date',
      label: 'Due Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Duedate data={data} />
            </>
          );
        }
      }
    },
    {
      name: 'bill_info',
      label: 'Billing Info',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bill_amt',
      label: 'Biling Amount',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status_task',
      label: 'Status of Task',
      options: {
        filter: true,
        sort: true,
        filterList: ['Initiated', 'Completed','In the Process'],
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              {data === 'Completed' && (
                <Chip label={data} color="success" sx={{ color: 'white' }} />
              )}
              {data === 'Initiated' && <Chip label={data} color="info" sx={{ color: 'white' }} />}
              {data === 'In the Process' && (
                <Chip label={data} color="secondary" sx={{ color: 'white' }} />
              )}
              {data === 'Data is Pending from Client' && (
                <Chip label={data} sx={{ background: 'orange', color: 'white' }} />
              )}
              {data === 'Not Started' && (
                <Chip label={data} sx={{ background: 'black', color: 'white' }} />
              )}
              {data === 'Cancelled' && <Chip label={data} color="error" sx={{ color: 'white' }} />}
            </>
          );
        }
      }
    },
    {
      name: 'invoice_num',
      label: 'Invoice ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'remarks',
      label: 'Remarks',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'update',
      label: 'Update/Delete',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box textAlign="center">
                <Update row={data} />
                <br />
                <br />
                {/* <ConfirmProvider>
                  
                </ConfirmProvider> */}
                <Delete row={data} />
              </Box>
            </>
          );
        }
      }
    }
  ];
  function fill_api() {}
  let dummy_task_data = tasks_data;
  // console.log("tasks_data--->>",tasks_data);
  // console.log("dummy_task_data--->>",dummy_task_data);
  let tasks_data_owner = dummy_task_data?.filter((x) => x.task_owner?.includes(task_owner));
  if (tasks_data_owner?.length != 0) {
    dummy_task_data = tasks_data_owner;
  }
  let tasks_data_ass = dummy_task_data?.filter((x) => x.task_assignee.includes(task_assignee));
  if (tasks_data_ass?.length != 0) {
    dummy_task_data = tasks_data_ass;
  }
  let tasks_data_due_date = dummy_task_data?.filter(
    (x) => moment(x.due_date).format('DD-MM-YYYY') === due_date
  );
  if (tasks_data_due_date?.length != 0) {
    dummy_task_data = tasks_data_due_date;
  }
  let comp_task = dummy_task_data?.filter((x) => x.task_status.includes('Completed'));
  let pend_task = dummy_task_data?.filter((x) => !x.task_status.includes('Cancelled'));
  useEffect(() => {
    let i = 0;
    const data = dummy_task_data?.map((row) => {
      return {
        task_id: row.sr_id,
        name: row.company_name,
        task_name: row.task_name,
        task_Desc: row.task_description,
        task_own: row.task_owner,
        task_assn: row.task_assignee,
        date_task: row.date_of_task,
        due_date: row.due_date,
        bill_info: row.billing_info,
        bill_amt: row.billing_amount,
        status_task: row.task_status,
        remarks: row.remark,
        update: row.id,
        invoice_num: row.invoice_num
      };
    });
    setDatas(data);
  }, [tasks_data]);

  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [10, 100, 1000, 1500, 2000],
    rowsPerPage: 10,
    setFilterChipProps: (colIndex, colName, data) => {
      console.log(colIndex, colName, data);
      return {
        variant: 'outlined',
        className: 'testClass123'
      };
    }
  };
  const opts = all_companies.all_companies.map((option) => option.company_name);
  let arrays = [opts];

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    let b = 0;
    dispatch(get_due_dates_by_period(b));
  };
  const [opene, setOpene] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };

  const handleClosee = () => {
    setOpene(false);
  };
  const submit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    const current_date = moment(new Date().toLocaleDateString()).format('YYYY-MM-DD');

    formData.append('internal_filling', current_date);
    dispatch(allfillstat_patch(selectedID, formData));
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          container
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="right"
          sx={{ margin: 1 }}
        >
          <Button onClick={handleClickOpene('paper')} variant="contained" sx={{ mr: 2, width: 40 }}>
            ADD
          </Button>
          <Dialog
            open={opene}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <Box
                component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Add Data
                <CloseRoundedIcon
                  onClick={() => setOpene(false)}
                  style={{ color: '#ff5252' }}
                  boxShadow={1}
                />
              </Box>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <AddData
                  companys_name={opts}
                  task_names={task_names}
                  teamates_data={teamates_data}
                  tasks_data={tasks_data}
                  handleClosee={handleClosee}
                  all_companies={all_companies}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          {/* <h4>Total Number of task = {dummy_task_data.length}</h4> */}
          <Typography variant="h6" gutterBottom>
            Total Number of task: {dummy_task_data?.length}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" gutterBottom>
            Completed task: {comp_task?.length}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Pending task: {pend_task?.length - comp_task?.length}
          </Typography>
        </Grid>
      </Grid>
      <MUIDataTable title={'Task Creation'} data={datas} columns={columns} options={options} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <DialogContentText>Data Once Update will not be reverted again.</DialogContentText>
          <DialogContentText>Are you sure ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={submit}>Yes,I am sure</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const AddData = (props) => {
  const { companys_name, tasks_data, teamates_data, task_names, handleClosee, all_companies } =
    props;
  const client_emails = useSelector((state) => state.team_mates_reducers.client_emails);
  const client_emails_list = client_emails.map((item) => item.client_mail);
  const tsk_name = task_names.map((option) => option.task_name);
  const team_name = teamates_data.map((option) => option.name);
  const tsk_data = tasks_data.map((option) => option.sr_id);
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    company_name: '',
    task_name: '',
    task_description: '',
    task_owner: '',
    task_assignee: '',
    due_date: '',
    billing_info: '',
    task_status: '',
    remark: '',
    billing_amount: '',
    client_mails: ''
  });
  const [Cname, setCname] = useState();
  const [Fname, setFname] = useState();
  const [Tdes, setTdes] = useState();
  const [Binfo, setBinfo] = useState();
  const [Tstat, setTstat] = useState();
  const [remark, setRemark] = useState();
  const [Bamount, setBamount] = useState();
  const [Town, setTown] = useState();
  const [Tassign, setTassign] = useState();
  const [clientMail, setClientMail] = useState([]);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

  const handleFormDataChange = (e) => {
    console.log("formData['company_name']", formData['company_name']);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    const company_ids = all_companies.all_companies.filter(
      (item) => item.company_name === formData['company_name']
    );
    if (company_ids.length > 0) {
      formData['company_id'] = company_ids[0]['id'];
    }

    if (formData.client_mails.length > 0) {
      const mail_list_last_item = formData.client_mails[formData.client_mails.length - 1];
      formData.client_mails = mail_list_last_item.join(',');
    }

    formData.due_date = moment(formData.due_date).format('YYYY-MM-DD');
    let temp_name = formData.task_name;
    const opts = task_names.filter((x) => x.task_name.includes(temp_name));
    formData['task_id'] = opts[0].id;
    let temp_ass = formData.task_assignee;
    const opts_ass = teamates_data.filter((x) => x.name.includes(temp_ass));
    formData['task_assignee_id'] = opts_ass[0].id;
    let temp_own = formData.task_owner;
    const opts_own = teamates_data.filter((x) => x.name.includes(temp_own));
    formData['task_owner_id'] = opts_own[0].id;
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(add_Task(bodyFormData));
    handleClosee();
    Swal.fire('Successfully!', 'Task has been successfully added', 'success');
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Cname}
              name="company_name"
              freeSolo
              onInputChange={(event, newValue) => {
                setCname(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={companys_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Fname}
              name="task_name"
              onChange={(event, newValue) => {
                setFname(newValue);
                {
                  formData.task_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={tsk_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              value={Tdes}
              name="task_description"
              onChange={(e) => {
                setTdes(e.target.value);
                {
                  formData.task_description = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Task Description"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Town}
              name="task_owner"
              onChange={(event, newValue) => {
                setTown(newValue);
                {
                  formData.task_owner = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Owner" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tassign}
              name="task_assignee"
              onChange={(event, newValue) => {
                setTassign(newValue);
                {
                  formData.task_assignee = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Assigne" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                value={value}
                name="due_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setValue(newValue);
                  {
                    formData.due_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Binfo}
              name="billing_info"
              onChange={(event, newValue) => {
                setBinfo(newValue);
                {
                  formData.billing_info = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={['Billiable', 'Non Billiable', 'Reimbursement']}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Bill Type" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="billing_amount"
              onChange={(e) => {
                setBamount(e.target.value);
                {
                  formData.billing_amount = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Bamount}
              label="Biling Amount"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tstat}
              name="task_status"
              onChange={(event, newValue) => {
                setTstat(newValue);
                {
                  formData.task_status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={[
                'Completed',
                'Initiated',
                'In the Process',
                'Data is Pending from Client',
                'Not Started',
                'Cancelled'
              ]}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Status of Task" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={remark}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              multiple
              name="client_mails"
              freeSolo
              options={client_emails_list}
              onChange={(event, newValue) => {
                setClientMail([...clientMail, newValue]);
                {
                  formData.client_mails = [...clientMail, newValue];
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Client Mail" placeholder="Enter mail id" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
const Delete = (props) => {
  const { row } = props;
  const task_data_list = useSelector((state) => state.taskreducers.task_management.results);
  const selected_task = task_data_list?.filter((item) => item.id === row)[0];
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    const masterPassword = 'Fino$1992';
    Swal.fire({
      title: `Enter Password to delete task id ${selected_task.sr_id}`,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value === masterPassword) {
        dispatch(deletetask(selected_task.sr_id));
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };
  return (
    <>
      <IconButton aria-label="delete" onClick={() => handleDelete()} color="error">
        <DeleteIcon />
      </IconButton>
    </>
  );
};
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete" color="primary">
        <ModeEditIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Data
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Taskupdate row={row} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Taskupdate = (props) => {
  const { row, handleClose } = props;
  const task_data_list = useSelector((state) => state.taskreducers.task_management.results);
  const teams_list = useSelector((state) => state.team_mates_reducers.team_mate);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const task_name_list = useSelector((state) => state.team_name_reducers.task_name);

  const companys_name = all_companies.all_companies.map((option) => option.company_name);
  const task_names = task_name_list.map((option) => option.task_name);
  const main_task = task_data_list?.filter((item) => item.id === row)[0];
  const team_names = teams_list.map((option) => option.name);
  const selected_task = main_task;
  const [formData, setFormData] = useState({
    company_name: '',
    task_name: '',
    task_description: '',
    task_owner: '',
    task_assignee: '',
    due_date: '',
    billing_info: '',
    task_status: '',
    remark: '',
    billing_amount: '',
    task_assignee_id: '',
    task_owner_id: '',
    invoice_num: ''
  });
  const [Cname, setCname] = useState(null);
  const [taskName, setTakeName] = useState(null);
  const [Tdes, setTdes] = useState(null);
  const [Binfo, setBinfo] = useState(null);
  const [Tstat, setTstat] = useState(null);
  const [remark, setRemark] = useState(undefined);
  const [Bamount, setBamount] = useState(null);
  const [Town, setTown] = useState(null);
  const [Tassign, setTassign] = useState(null);
  const [dueDate, setDueDate] = useState('');
  const [invoiceId, setInvoiceId] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setCname(selected_task?.company_name);
    setTakeName(selected_task.task_name);
    setTdes(selected_task.task_description);
    setBinfo(selected_task.billing_info);
    setTstat(selected_task.task_status);
    setRemark(selected_task.remark);
    setBamount(selected_task.billing_amount);
    setTown(selected_task.task_owner);
    setTassign(selected_task.task_assignee);
    setDueDate(selected_task.due_date);
    setInvoiceId(selected_task.invoice_num);
  }, [main_task]);
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        if (key === 'due_date') {
          bodyFormData.append(key, moment(formData[key]).format('YYYY-MM-DD'));
        } else {
          if (key === 'task_owner') {
            const task_owner_id = teams_list.filter((item) => item.name === formData[key]);
            const objName = 'task_owner_id';
            bodyFormData.append(objName, task_owner_id[0].id);
          } else if (key === 'task_assignee') {
            const task_assignee_id = teams_list.filter((item) => item.name === formData[key]);
            const objName = 'task_assignee_id';
            bodyFormData.append(objName, task_assignee_id[0].id);
          } else if (key === 'task_name') {
            const task_name_id = task_name_list.filter((item) => item.task_name === formData[key]);
            const objName = 'task_id';
            bodyFormData.append(objName, task_name_id[0].id);
          }
          bodyFormData.append(key, formData[key]);
        }
      }
    }
    dispatch(updatetask(selected_task.sr_id, bodyFormData));
    handleClose();
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Cname || ''}
              name="company_name"
              onChange={(event, newValue) => {
                setCname(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              options={companys_name}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={taskName}
              name="task_name"
              onChange={(event, newValue) => {
                setTakeName(newValue);
                {
                  formData.task_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={task_names}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Name" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              value={Tdes}
              name="task_description"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setTdes(e.target.value);
                {
                  formData.task_description = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Task Description"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Town}
              name="task_owner"
              onChange={(event, newValue) => {
                setTown(newValue);
                {
                  formData.task_owner = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_names}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Owner" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tassign}
              name="task_assignee"
              onChange={(event, newValue) => {
                setTassign(newValue);
                {
                  formData.task_assignee = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={team_names}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Task Assigne" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                value={dueDate}
                name="due_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setDueDate(newValue);
                  {
                    formData.due_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Binfo}
              name="billing_info"
              onChange={(event, newValue) => {
                setBinfo(newValue);
                {
                  formData.billing_info = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={['Billiable', 'Non Billiable', 'Reimbursement']}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Billing Info" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="billing_amount"
              onChange={(e) => {
                setBamount(e.target.value);
                {
                  formData.billing_amount = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Bamount}
              label="Biling Amount"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tstat}
              name="task_status"
              onChange={(event, newValue) => {
                setTstat(newValue);
                {
                  formData.task_status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={[
                'Completed',
                'Initiated',
                'In the Process',
                'Data is Pending from Client',
                'Not Started',
                'Cancelled'
              ]}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Status of Task" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="invoice_num"
              onChange={(e) => {
                setInvoiceId(e.target.value);
                {
                  formData.invoice_num = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={invoiceId}
              label="Invoice ID"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={remark}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
