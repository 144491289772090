import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HttpWrapper from 'src/utils/HttpWrapper';
// component
import Iconify from '../../../components/Iconify';
import { getCompanyId } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import Constants from '../../../utils/constants';
import { setToken } from 'src/utils/common';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password1: Yup.string().required('Password is required'),
    password2: Yup.string()
      .required('please confirm Password')
      .oneOf([Yup.ref('password1'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password1: '',
      password2: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      console.log(actions);
      HttpWrapper('POST', '/dj-rest-auth/registration/', false, values, true)
        .then((res, err) => {
          console.log('hello');
          setToken(res.data.access_token);
          navigate('/basicProfile', { replace: true });
          return dispatch({
            type: Constants.SET_USER_LOGIN_SUCCESS,
            payload: res.data
          });
        })
        .catch((err) => {
          return dispatch({
            type: Constants.SET_USER_LOGIN_FAILURE,
            payload: err.response.data
          });
        });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack> */}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password1')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password1 && errors.password1)}
            helperText={touched.password1 && errors.password1}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('password2')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password2 && errors.password2)}
            helperText={touched.password2 && errors.password2}
          />

          <Button fullWidth size="large" type="submit" variant="contained">
            Register
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
