import React from 'react';
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useState } from 'react';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { fCurrency } from 'src/utils/formatNumber';
import { useEffect } from 'react';
import { calIntrestSum } from './util';
import { isStartupFino, istalenteye } from 'src/config';

export const InterestTable = ({ interestData, year }) => {
  console.log('Interestingz', interestData);
  return (
    <>
      {!istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Interest Paid</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>GST</TableCell>
                <TableCell>
                  {JSON.parse(interestData['gst_interest_data'])['intrest'][`${year}`] === undefined
                    ? 0
                    : JSON.parse(interestData['gst_interest_data'])['intrest'][`${year}`]}
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>TDS</TableCell>
                <TableCell>
                  {JSON.parse(interestData['tds_demand_interest'])['interest_amount'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['tds_demand_interest'])['interest_amount'][`${year}`]}
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>PF</TableCell>
                <TableCell>
                  {JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][`${year}`]}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>ESI</TableCell>
                <TableCell>
                  {JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`]}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>IncomeTax</TableCell>
                <TableCell>
                  {JSON.parse(interestData['tax_demand_interest'])['interest_paid'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['tax_demand_interest'])['interest_paid'][`${year}`]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>
                  <Label color="success">{fCurrency(calIntrestSum(interestData, year))}</Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Interest Paid</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>PF</TableCell>
                <TableCell>{
                  JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][
                    `${year}`
                  ] === undefined
                    ? 0
                    : JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][
                        `${year}`
                      ]
                  }</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>ESI</TableCell>
                <TableCell>
                  {JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>
                  <Label color="success">
                    {JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][
                      `${year}`
                    ] === undefined
                      ? 0
                      : JSON.parse(interestData['pf_penalties_interest'])['total_penalty'][
                          `${year}`
                        ]
                        +
                        JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(interestData['esi_interest'])['total_penalty'][`${year}`]
                        }
                  </Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
