import Constants from 'src/utils/constants';
const intialState = {
  task_name: []
};
export const team_name_reducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_TASK_NAME:
      return { ...state, task_name: action.payload };
    default:
      return state;
  }
};