import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import { addGst } from 'src/redux/actions/gstBook';
import { PropaneSharp } from '@mui/icons-material';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import { addpf, addpfdemand } from 'src/redux/actions/pf';
import Alert from '@mui/material/Alert';


function DropContent(props) {
  const {handleClose}=props
  const [complianceId, setComplianceId] = useState('');
  const handleChange = (event) => {
    setComplianceId(event.target.value);
  };

  return (
    <div>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1, overflow: 'scroll' }}
      >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Compliance</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={complianceId}
            onChange={handleChange}
            autoWidth
            label="Compliance"
          >
            <MenuItem value={20}>PF Challan</MenuItem>
            <MenuItem value={21}>PF Demand </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {
          {
            20: <PfChallan handleClose={handleClose} />,
            21: <PfDemand  handleClose={handleClose}/>
          }[complianceId]
        }
        {/* <Fields complianceId={complianceId} /> */}
      </Box>
    </div>
  );
}
const PfChallan = (props) => {
  const {handleClose}=props
//   const { complianceId } = PropaneSharp
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    pf_type: '',
    pf_amount: '',
    trrn: '',
    crn: '',
    payment_dates: '',
    challan_receipt: '',
    acknowledgement: '',  
    ecr_copy:''
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [pfType, setPfType] = useState();
  const [pfamount, setPfamount] = useState();
  const [trrnno, setTrrnno] = useState();
  const [crnno, setCrnno] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  // const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  console.log("companyId",companyId);
  // console.log("companyid",companyid);
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData)
  }
  const dis = (e) => {
    // formData['complianceID'] = complianceId
    formData['complianceID'] = "60af05a5-0a24-449e-87d1-c49b5a90862f"
    formData['companyId'] = companyId
    
    formData.payment_dates = moment(formData.payment_dates).format('YYYY-MM-DD')
    var bodyFormData = new FormData();
    for(let key in formData){
      if(key,formData[key] !== ''){
        bodyFormData.append(key,formData[key])
      }
    }
    console.log('form data', formData);
    dispatch(addpf(bodyFormData,companyId));
    handleClose()
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
        <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
        <MenuItem value={"Monthly"}>Monthly</MenuItem>
        <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
        <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
        <MenuItem value={"Yearly"}>Yearly</MenuItem>
        </Select> */}
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required/>}
        />
        <TextField
          value={pfamount}
          required
          name="pf_amount"
          type="number"
          onChange={(e) => {
              setPfamount(e.target.value);
              {
              formData.pf_amount = e.target.value;
            }
            {
              handleFormDataChange;
            }
        }}
          label="PF Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <Autocomplete
          value={pfType}
          name="pf_type"
          onChange={(event, newValue) => {
            setPfType(newValue);
            {
              formData.pf_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={["ECR","Arrear"]}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="PF Type" required/>}
        />
        <TextField
          value={trrnno}
          required
          name="trrn"
          type="number"
          onChange={(e) => {
            setTrrnno(e.target.value);
            {
              formData.trrn = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="TRRN Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          name="crn"
          value={crnno}
          required
          type="number"
          onChange={(e) => {
            setCrnno(e.target.value);
            {
              formData.crn = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="CRN Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date "
            value={value}
            name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
          />
        </LocalizationProvider>
        <h4>Challan Receipt</h4>
        <TextField required name='challan_receipt' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <h4>Acknowledgement</h4>
        <TextField required name='acknowledgement' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <h4>ECR Copy </h4>
        <TextField required name='ecr_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>
      {
      finace === null ||
      finace === undefined ||
      mon === undefined ||
      mon === null ||
      pfamount === undefined ||
      pfamount === null ||
      pfType === null ||
      pfType === undefined ||
      trrnno === undefined ||
      trrnno === '' ||
      value === undefined ||
      value === null ||
      crnno === undefined ||
      crnno === '' ||
      value === undefined ||
      value === null 
      ? (
        <>
          <Alert severity="error" alignItems="center">
            {' '}
            Fill all fileds with (*)
          </Alert>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained" disabled>
              SAVE
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained">
              SAVE
            </Button>
          </Box>
        </>
      )}

    </>
  );
};
const PfDemand = (props) => {
  const {handleClose}=props
//   const { complianceId } = PropaneSharp
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    total_penalty: '',
    date_fill: '',
    trrn: '',

  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2011-12',
    '2012-13',
    '2013-14',
    '2014-15',
    '2015-16',
    '2016-17',
    '2017-18',
    '2018-19',
    '2019-20',
    '2020-21',
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [trrnno, setTrrnno] = useState();
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [penalty, setPenalty] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dis = (e) => {
    formData['complianceID'] = "adfb32c1-ee6b-4ec3-b15a-532d53b6d0f4"
    formData['companyId'] = companyId
    var bodyFormData = new FormData();
    for(let key in formData){
      if(key,formData[key] !== ''){
        bodyFormData.append(key,formData[key])
      }
    }
    console.log('form data', bodyFormData);
    dispatch(addpfdemand(bodyFormData,companyId))
    handleClose()
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required/>}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required/>}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Penalty Payment Date"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD')
                formData.date_fill = newValue;
                formData["penality_payment_dates"] = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
            required
          value={penalty}
          type="number"
          name="total_penalty"
          onChange={(e) => {
            setPenalty(e.target.value);
            {
              formData.total_penalty = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Penalty Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
      
      <TextField
          value={trrnno}
          required
          name="trrn"
          type="number"
          onChange={(e) => {
            setTrrnno(e.target.value);
            {
              formData.trrn = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="TRRN Number"
          variant="outlined"
          sx={{ margin: 1 }}
        />
      </Box>  
      {
      finace === null ||
      finace === undefined ||
      mon === undefined ||
      mon === null ||
      trrnno === undefined ||
      trrnno === '' ||
      penalty === undefined ||
      penalty === '' 
      ? (
        <>
          <Alert severity="error" alignItems="center">
            {' '}
            Fill all fileds with (*)
          </Alert>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained" disabled>
              SAVE
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained">
              SAVE
            </Button>
          </Box>
        </>
      )}

    </>
  );
};


export default DropContent;
