import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';

export const fetchTdsDeducted = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/incometaxbook/taxdeducted/all/${companyId}/`, false, {})
    .then((res) => {
      const data = res.data.filter((demand) => demand.companyId === companyId);
      dispatch({
        type: Constants.SET_TDS_DEDUCTED,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_TDS_DEDUCTED,
        payload: []
      });
    });
};

export const fetchTaxPayment = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/incometaxbook/taxpaid/?companyId=${companyId}`, false, {})
    .then((res) => {
      const data = res.data.filter((demand) => demand.companyId === companyId);
      dispatch({
        type: Constants.SET_TAX,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_TAX,
        payload: []
      });
    });
};

export const order_tax_deduction_data = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/incometaxbook/trigger-tax-deducatin/${companyId}/`, false, {})
    .then((res) => {
      toast.success('Data Order Successfully!!!')
    })
    .catch((error) => {
      toast.error('Some error in fetching data')
    });
};

export const fetchFormsFilled = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/incometaxbook/taxform/?companyId=${companyId}`, false, {})
    .then((res) => {
      const data = res.data.filter((demand) => demand.companyId === companyId);
      dispatch({
        type: Constants.SET_FORMS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_FORMS,
        payload: []
      });
    });
};

export const fetchDemand = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/incometaxbook/demand/`, false, {})
    .then((res) => {
      const data = res.data.filter((demand) => demand.companyId === companyId);
      dispatch({
        type: Constants.SET_INCOME_TAX_DEMAND,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_INCOME_TAX_DEMAND,
        payload: []
      });
    });
};

export const fetchIncomeDueDates = () => (dispatch) => {
  HttpWrapper('GET', `/compliance/due-date-book/Income/`, false, {})
    .then((res) => {
      const data = res.data;
      dispatch({
        type: Constants.SET_INCOME_TAX_DUE_DATES,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_INCOME_TAX_DUE_DATES,
        payload: []
      });
    });
};
export const addincometax = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/incometaxbook/taxform/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(fetchTaxPayment(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addincometaxpay = (data) => (dispatch) => {
  HttpWrapper('POST', `/incometaxbook/taxpaid/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addoutsandmd = (data) => (dispatch) => {
  HttpWrapper('POST', `/incometaxbook/demand/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteincometaxpay = (id,data) => (dispatch) => {
  HttpWrapper('DELETE', `/incometaxbook/taxpaid/all/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateincometaxpay = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/incometaxbook/taxpaid/all/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(fetchTaxPayment(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteincometaxform  = (id,data) => (dispatch) => {
  HttpWrapper('DELETE', `/incometaxbook/taxform/all/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateincometaxform = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/incometaxbook/taxform/all/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(fetchFormsFilled(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateIncometaxIb4 = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/incometaxbook/demand/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(fetchDemand(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteincometaxIb4  = (id,data) => (dispatch) => {
  HttpWrapper('DELETE', `/incometaxbook/demand/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateincometaxdemand = (id) => (dispatch) => {
  HttpWrapper('PATCH', `/incometaxbook/demand/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
    })
    .catch((err) => {
      console.log(err);
    });
};


