import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const getVaultData = (company_id) => (dispatch) => {
  trackPromise(
  HttpWrapper('GET', `/company/company-book/${company_id}/`, false, {})
      .then((res, err) => {
        return dispatch({
          type: Constants.SET_LOGIN_VAULT_DATA,
          payload: res.data
        });
      })
      .catch((err) => {
        return dispatch({
          type: Constants.SET_LOGIN_VAULT_DATA,
          payload: []
        });
      })
  );
};

export const updateVaultData = (id, data,companyId) => (dispatch) => {
  trackPromise(
    HttpWrapper('PATCH', `/company/company-book/compliance/${id}/`, false, data)
      .then((res, err) => {
        toast.success('Updated login data');
        dispatch(getVaultData(companyId));
      })
      .catch((err) => {
        toast.error(' NOT Updated login data');
      })
  );
};

export const addVaultData = (id, data) => (dispatch) => {
  trackPromise(
    HttpWrapper('POST', `/company/company-book/${id}/`, false, data)
      .then((res, err) => {
        toast.success('Added login data');
        dispatch(getVaultData(id));
      })
      .catch((err) => {})
  );
};

export const deleteVaultData = (data,company_id) => (dispatch) => {
  HttpWrapper('DELETE', `/company/company-book/compliance/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.error('Delete Successfully');
      dispatch(getVaultData(company_id));
    })
    .catch((err) => {
      console.log(err);
    });
};