import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import moment from 'moment';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import { deleteesidemand, updateesiDemand } from 'src/redux/actions/esiBook';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { isStartupFino,istalenteye } from 'src/config';

export const OutstandingPenalties = ({ data }) => {
  const filter_data = data?.filter((item) => item.penalty_payment == null);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>FY</TableCell>
            <TableCell>Month</TableCell>
            <TableCell>Total Penalty </TableCell>
            <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filter_data.map((row, i) => {
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                <TableCell>{row.finance_year}</TableCell>
                <TableCell>{row.month}</TableCell>
                <TableCell>
                  <Label color="error">{row.total_penalty}</Label>
                </TableCell>
                <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>
                  <Box textAlign="center" sx={{ display: 'flex' }}>
                    <Update row={row} />
                    <ConfirmProvider>
                      <Delete row={row} />
                    </ConfirmProvider>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deleteesidemand(row.id))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rows are", row);
  // const handleDelete = () => {
  //   confirm({ description: "This will permanently delete " })
  //     .then(() => {
  //       setNewrows(null);
  //       console.log("newrows are after delete ",newrows);
  //     })
  //     .catch(() => console.log("Deletion cancelled."));
  // };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <EsiDemandUpdate row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const EsiDemandUpdate = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row } = props;
  const [value, setValue] = useState(row.payment_dates);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    total_penalty: '',
    date_fill: ''
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2011-12',
    '2012-13',
    '2013-14',
    '2014-15',
    '2015-16',
    '2016-17',
    '2017-18',
    '2018-19',
    '2019-20',
    '2020-21',
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState(row.finance_year);
  const [mon, setMon] = useState(row.month);
  const [total_penalty, setTotal_penalty] = useState(row.total_penalty);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dis = (e) => {
    console.log('form data', formData);
    // formData['complianceID'] = "9216387f-f91c-4df8-bdd0-94f925442288";
    // formData['companyId'] = companyId
    var bodyFormData = new FormData();
    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    console.log('form data', bodyFormData);
    dispatch(updateesiDemand(row.id, bodyFormData))
    // dispatch(addGst(formData))
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Penalty Payment Date"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
          required
          value={total_penalty}
          type="number"
          name="total_penalty"
          onChange={(e) => {
            setTotal_penalty(e.target.value);
            {
              formData.total_penalty = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Penalty Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
      </Box>
      {
      finace === null ||
      finace === undefined ||
      mon === undefined ||
      mon === null ||
      total_penalty === undefined ||
      total_penalty === '' 
      ? (
        <>
          <Alert severity="error" alignItems="center">
            {' '}
            Fill all fileds with (*)
          </Alert>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained" disabled>
              UPDATE
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained">
              UPDATE
            </Button>
          </Box>
        </>
      )}

    </>
  );
};
