import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchDirectors } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function DirectorsDetail() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      let tmp = [];
      tmp = tmp.concat(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .CreditRatingDetail.CreditRating
      );
      setdata(tmp);
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Agency</TableCell>
            <TableCell align="center">Rating Date</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Instrument</TableCell>
            <TableCell align="center">Amount (₹. Lakhs)</TableCell>
            <TableCell align="center">Registration Number</TableCell>
            <TableCell align="center">Rating</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.RatingAgency}
                </TableCell>
                <TableCell align="center">{row.RatingAssignedDate}</TableCell>
                <TableCell align="center">{row.RatingAssigned}</TableCell>
                <TableCell align="center">{row.RatingInstrumentNamet}</TableCell>
                <TableCell align="center">{row.RatingCurrency}</TableCell>
                <TableCell align="center">{row.RatingPeriod}</TableCell>
                <TableCell align="center">{row.RatingRoundingoff}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
