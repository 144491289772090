import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Typography, Chip } from '@mui/material';
import { Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSearchParams } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import Label from 'src/components/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MUIDataTable from 'mui-datatables';
import {
  allfillstat_patch,
  get_due_dates_by_period,
  get_due_dates_by_period_2,
  get_all_due_dates_company_id
} from 'src/redux/actions/fillstatAction';
import { CurrencyYenTwoTone, RawOff } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment, { months } from 'moment';
import { chunk } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import {
  add_Task,
  task_all_get,
  team_Mates_get,
  task_Names_get,
  deletetask,
  updatetask,
  client_mail_list,
  add_Task_name,
  deletetaskName,
  updatetaskName
} from 'src/redux/actions/task_creation_Action';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import { red } from '@mui/material/colors';
import { Duedate } from 'src/components/task_creations/DueDate';

export default function TaskName() {
  const task_Data = useSelector((state) => state.taskreducers);
  const teams = useSelector((state) => state.team_mates_reducers);
  const filling_data = useSelector((state) => state.allFillStatreducers);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const task_name = useSelector((state) => state.team_name_reducers);

  const [tasks_data, settasks_data] = useState([]);
  const [teamates_data, setteamates_data] = useState([]);
  const [task_names, setTask_names] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const task_owner = searchParams.get('task_owner');
  const task_assignee = searchParams.get('task_assignee');
  const due_date = searchParams.get('due_date');

  useEffect(() => {
    dispatch(task_all_get());
    dispatch(team_Mates_get());
    dispatch(task_Names_get());
    dispatch(client_mail_list());
  }, []);
  useEffect(() => {
    settasks_data(task_Data.task_management);
  }, [task_Data]);
  useEffect(() => {
    setteamates_data(teams.team_mate);
  }, [teams]);
  useEffect(() => {
    setTask_names(task_name.task_name);
  }, [task_name]);

  const [extra, setExtra] = useState(null);
  const [tabdata, setTabdata] = useState([]);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [selectedID, setID] = useState('');
  const [value, setValue] = useState(new Date());
  const [opt_value, setOpt_value] = useState(' ');
  const [open, setOpen] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [counter, setCounter] = useState(0);
  var companies_id;
  let data_total = filling_data.comp_fillstat.length;

  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const years = ['2020-21', '2021-22', '2022-23'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    //let a = 0;
    const now = new Date();
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
    dispatch(get_due_dates_by_period(lastDay, firstDay));
    dispatch(getAllCompanies());
    setTabdata(filling_data);
  }, []);

  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const handleCheckbox = (e, id) => {
    setOpen(true);
    setID(id);
  };
  const columns = [
    {
      name: 'task_name',
      label: 'Task Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'update',
      label: 'Update/Delete',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Update row={data} />
                </Grid>
                <Grid item xs={3}>
                  <Delete row={data} />
                </Grid>
              </Grid>
            </>
          );
        }
      }
    }
  ];
  function fill_api() {}
  let dummy_task_data = task_names;
  useEffect(() => {
    let i = 0;
    const data = dummy_task_data?.map((row) => {
      return {
        task_name: row.task_name,
        update: row
      };
    });
    setDatas(data);
  }, [tasks_data]);

  const changePage = (page) => {
    isLoading(true);
    xhrRequest(page).then((res) => {
      isLoading(false), page(page), datas(res.data), count(res.total);
    });
  };
  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [1000, 1500, 2000],
    rowsPerPage: 1000,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: window.screen.availHeight
  };
  const opts = all_companies.all_companies.map((option) => option.company_name);
  let arrays = [opts];

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    let b = 0;
    dispatch(get_due_dates_by_period(b));
  };
  const [opene, setOpene] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };

  const handleClosee = () => {
    setOpene(false);
  };
  const submit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    const current_date = moment(new Date().toLocaleDateString()).format('YYYY-MM-DD');

    formData.append('internal_filling', current_date);
    dispatch(allfillstat_patch(selectedID, formData));
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          {' '}
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleClickOpene('paper')} variant="contained" sx={{ width: 40 }}>
            ADD
          </Button>
          <br />
        </Grid>
      </Grid>
      <Dialog
        open={opene}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Add Data
            <CloseRoundedIcon
              onClick={() => setOpene(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <AddData handleClosee={handleClosee} />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <MUIDataTable title={'Task Creation'} data={datas} columns={columns} options={options} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <DialogContentText>Data Once Update will not be reverted again.</DialogContentText>
          <DialogContentText>Are you sure ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={submit}>Yes,I am sure</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const AddData = (props) => {
  const { handleClosee } = props;
  const [formData, setFormData] = useState({
    task_name: ''
  });
  const [Fname, setFname] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(add_Task_name(bodyFormData));
    handleClosee();
    Swal.fire('Successfully!', 'Task Name has been successfully added', 'success');
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="task_name"
              onChange={(e) => {
                setFname(e.target.value);
                {
                  formData.task_name = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Fname}
              label="Task Name"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    const masterPassword = 'Fino$1992';
    Swal.fire({
      title: `Enter Password to delete`,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value === masterPassword) {
        dispatch(deletetaskName(row.id));
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };
  return (
    <>
      <IconButton aria-label="delete" onClick={() => handleDelete()} color="error">
        <DeleteIcon />
      </IconButton>
    </>
  );
};
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete" color="primary">
        <ModeEditIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            // m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Data
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Taskupdate row={row} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Taskupdate = (props) => {
  const { row, handleClose } = props;
  console.log("row",row);
  const [formData, setFormData] = useState({
    task_name: ''
  });
  const [taskName, setTakeName] = useState(row.task_name);
  const dispatch = useDispatch();
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    dispatch(updatetaskName(row.id,bodyFormData));
    handleClose();
    Swal.fire('Successfully!', 'Task Name has been successfully Updated', 'success');
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="task_name"
              onChange={(e) => {
                setTakeName(e.target.value);
                {
                  formData.task_name = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={taskName}
              label="Task Name"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
