import React from 'react';
import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
  Box,
  Typography
} from '@mui/material';
import BasicChartSmall from 'src/sections/@dashboard/app/BasicChartSmall';
import BasicChart from 'src/sections/@dashboard/app/BasicChart';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function YearDropDown(props) {
  const { year, state } = props;
  // const { dashBoardScore, year } = props;
  // const gst_filling_score =
  //   parseInt((dashBoardScore?.[year]?.gst_filling?.filled / dashBoardScore?.[year]?.gst_filling?.count) * 100) || 0;

  return (
    <div>
      <Card>
        <CardHeader title="GST Dashboard" />
        {/* dropdown */}
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              // multiple
              value={props.year}
              onChange={props.handleYearChange}
              input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
              )}
              MenuProps={MenuProps}
            >
              {props.years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Card>
    </div>
  );
}
