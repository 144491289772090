import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { setToken, setCompanyData, setCompanyId } from '../../utils/common';
import { toast } from 'react-toastify';

export const getAllCompanies = () => (dispatch) => {
    // companyId = '6c743b53-3e95-4d15-9c09-5539c4026c53';
    HttpWrapper('GET', `/company/all-companies/`, false, {})
      .then((res) => {
        console.log(res)
        dispatch({
          type: Constants.SET_ALL_COMPANIES,
          payload: res.data
    });

    setCompanyData(res.data)

    return dispatch({
      type: Constants.SET_COMPANT_DATA,
      payload: res.data
    });
  })
  .catch((error) => {
    dispatch({
      type: Constants.SET_ALL_COMPANIES,
      payload: error.message
    });
  });
}  
export const updateStatus = (data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/company/company/${companyId}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Changed Company Status');
      dispatch(getAllCompanies(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

