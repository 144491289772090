import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';
//import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
//import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import moment from 'moment';
//import { Box } from '@mui/system';

import { useSelector } from 'react-redux';
import { dateFormat } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import Label from 'src/components/Label';
import Link from '@mui/material/Link';
import { deleteincometaxform, updateincometaxform } from 'src/redux/actions/incometax';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';


//import { deleteincometaxpay } from 'src/redux/actions/incometax';
//import { updateIncometaxFilled } from 'src/redux/actions/incometax';
export default function FormsFilled(props) {
  const { year } = props;
  const incomeState = useSelector((state) => state.incometaxReducer);
  if (incomeState.forms == null) {
    return <></>;
  }
  const data = incomeState.forms.filter((item) => item.finance_year === year);
  const getExpiryDate = (compliance_id, fin_year) => {
    if (incomeState.dueDates != null) {
      const dueDateObj = incomeState.dueDates.filter((item) => {
        return item.complianceID.id === compliance_id && item.fin_year === fin_year;
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };
  console.log('income data', data);
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Form Name</TableCell>
            <TableCell>Due Date of Filing</TableCell>
            <TableCell>Date of Filing</TableCell>
            <TableCell>Delay days</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Interest Paid </TableCell>
            <TableCell>Download Acknowledgement</TableCell>
            <TableCell>Download Form</TableCell>
            <TableCell sx={{display: isStartupFino ? 'none': ''}}>Edit / Delete</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const delay =
              incomeState.dueDates.length > 0
                ? calDelay(getExpiryDate(row.complianceID, row.finance_year), row.date_fill)
                : 0;
            return (
              <TableRow
                //   key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.form_name}</TableCell>
                <TableCell>
                  {incomeState?.dueDates?.length > 0
                    ? dateFormat(getExpiryDate(row.complianceID, row.finance_year))
                    : '-'}
                </TableCell>
                <TableCell>{dateFormat(row.date_fill)}</TableCell>
                <TableCell>
                  <Label color={delay > 0 ? 'error' : 'success'}>{delay}</Label>
                </TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.interest_paid}</TableCell>
                <TableCell>
                  <a href={row.acknowledgement} target="_blank">
                    Link
                  </a>
                </TableCell>
                <TableCell>
                  <a href={row.form} target="_blank">
                    Link
                  </a>

                  {/* <a href={row.form} target="_blank">
                    Link
                  </a> */}
                </TableCell>
                <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                  <Box textAlign="center" sx={{ display: 'flex' }}>
                    <Update row={row} />
                    <ConfirmProvider>
                      <Delete row={row} />
                    </ConfirmProvider>
                  </Box>
                </TableCell>

              </TableRow>

            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deleteincometaxform(row.id))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen('paper')} sx={{ mr: 2 }} variant="contained">Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <Income_Tax_Return_Update row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const Income_Tax_Return_Update = (props) => {
  const { row } = props
  console.log("row ajifdfaijsdfjas",row);
  const [value, setValue] = useState(row.date_fill);
  const [formData, setFormData] = useState({
    finance_year: '',
    period: '',
    form_name: '',
    interest_paid: '',
    date_fill: '',
    status: '',
  });
  const itstatus = ["Original", "Revised"];
  const form_names = ["ITR", "Tax Audit Report", "TP Audit Report"];
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const period = ["Apr-March"];
  const [itstat, setItstat] = useState(row.status);
  const [itinterest, setInterest] = useState(row.interest_paid);
  const [perd, setPerd] = useState(row.period);
  const [finace, setFinace] = useState(row.finance_year);
  const [formtyp, setFormtyp] = useState(row.form_name);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log("HEllo WORLD");
  }
  const dis = (e) => {
    formData['complianceID'] = row.complianceID;
    // formData['companyId'] = companyId
    var bodyFormData = new FormData();
    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    console.log('form filled income tax return:', formData);
    dispatch(updateincometaxform(row.id, bodyFormData,companyId));
  };

  const handleSelectFile = (e) => {
    e.preventDefault();
    name = e.target.name;
    valuee = e.target.files[0];
    formData[`${name}`] = valuee
  }
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">



        <Autocomplete
          value={finace} name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Finance year" />}
        />
        {/*income tax return : Period */}
        <Autocomplete
          value={perd} name="period"
          defaultValue={'Apr-March'}
          onChange={(event, newValue) => {
            setPerd(newValue);
            {
              formData.period = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={period}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Period" />}
        />


        <Autocomplete
          value={formtyp} name="form_name"
          onChange={(event, newValue) => {
            setFormtyp(newValue);
            {
              formData.form_name = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={form_names}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Form Type" />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date Of Filling"
            value={value} name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>

        <TextField
          value={itinterest} name="interest_paid"
          onChange={(e) => {
            setInterest(e.target.value);
            { 
              formData.interest_paid = e.target.value; 
            }
            { 
              handleFormDataChange;
            };
          }}
          label="Interest Paid" variant="outlined" sx={{ margin: 1 }} />
            
        <Autocomplete
          value={itstat} name="status"
          onChange={(event, newValue) => {
            setItstat(newValue);
            {
              formData.status = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={itstatus}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} required label="Status" />}
        />

        {/*income tax return : It status*/}

        <h5>Acknowledgement </h5>
        <Box textAlign="right" >
          <a href={row.acknowledgement} target="_blank">
            Acknowledgement
          </a>
        </Box>
        <TextField required name='acknowledgement' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />

        {/*income tax return : It status*/}

        <h5>Form </h5>
        <Box textAlign="right" >
          <a href={row.form} target="_blank">
            Form
          </a>
        </Box>

        <TextField required name='form' onChange={handleSelectFile} type="file" sx={{ margin: 1 }} />
      </Box>

      {/*income tax return : Save Button */}

      <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={dis} variant="contained">SAVE</Button></Box>
    </>
  );
};

