import React from 'react';
import { TotalTaxTable } from './MainPageComponents/TotalTaxTable';
import { Card, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import { OutstandinTaxTable } from './MainPageComponents/OutstandingTaxTable';
import { InterestTable } from './MainPageComponents/InterestTable';
import { Chart, MonthChart } from './MainPageComponents/Chart';
import { AllComplianceScore } from './MainPageComponents/AllComplianceScore';
import { TotalComplianceDue } from './MainPageComponents/TotalComplianceDue';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import { ActionsRequired } from './MainPageComponents/ActionsRequired';
import { AllCards } from './MainPageComponents/AllCards';
import NewOrders from './dashboardcharts/NewOrders';
import TotalIncome from './dashboardcharts/TotalIncome';
import TotalExpense from './dashboardcharts/TotalExpense';
import NewUser from './dashboardcharts/NewUser';
import { getGSTOverview } from 'src/redux/actions/gstBook';

import {
  getOverDueDates,
  getAllDueDates,
  getUpcomingDueDates,
  setFinYear
} from 'src/redux/actions/dueDates';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDashboardData, fetchDashboardScore } from 'src/redux/actions/dashboard';
import { useSearchParams } from 'react-router-dom';

const years = ['2021-22', '2022-23', '2023-24'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const MainDashBoard = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.orgProfileReducer);
  const gstOverview = useSelector((state) => state.gstReducer.overview);
  const dashBoardData = useSelector((state) => state.dashBoardReducer.data);
  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);
  const dueDatesState = useSelector((state) => state.dueDatesReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("companyid")
  const[stas_year,setStats_year]=useState([])
  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const [score, setScore] = useState({});

  // useEffect(() => {
  //   if (gstOverview) {
  //     dispatch(getGSTOverview(companyData.companyId));
  //   }
  // }, [gstOverview]);

  const handleChange = (event) => {
    dispatch(setFinYear(event.target.value));
    setYear(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchDashboardData(companyId));
    dispatch(fetchDashboardScore(companyId));
    dispatch(getAllDueDates(companyId));
    dispatch(getUpcomingDueDates(companyId));
    dispatch(getOverDueDates(companyId));
  }, []);

  if (dashBoardData == null) {
    return <></>;
  }
  return (
    <>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            // multiple
            value={year}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
            )}
            MenuProps={MenuProps}
          >
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <br />
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <NewOrders data={dashBoardData} year={year} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalIncome data={dashBoardData} year={year} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TotalExpense data={dashBoardData} year={year} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <NewUser dueDatesState={dueDatesState} year={year} companyId={companyId} />
          </Grid>
        </Grid>
      </div>
      {/* <AllCards /> */}
      <br />
      <Typography variant="h5" m={2}>
        Company Overview
      </Typography>
      <Grid container justifyContent="space-around">
        <Grid item md={7}>
          <AllComplianceScore dashBoardScore={dashBoardScore} year={year} />
        </Grid>
        <Grid item md={4}>
          <ActionsRequired year={year} />
        </Grid>
      </Grid>
      <br />
      {/* <MonthChart />
      <br /> */}
      <TotalComplianceDue dueDatesState={dueDatesState} year={year} companyId={companyId} />
      <br />
      <br />
      <Card id="totalTax">
        <TotalTaxTable totalTaxData={dashBoardData} year={year} />
      </Card>
      <br />
      <Grid container justifyContent="space-around">
        <Grid xs={5} id="totalOutstanding">
          <OutstandinTaxTable outstandingData={dashBoardData} year={year} />
        </Grid>
        <Grid xs={5} id="totalInterest">
          <InterestTable interestData={dashBoardData} year={year} />
        </Grid>
      </Grid>
      <br />
    </>
  );
};
