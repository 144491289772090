import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchFinancialHighlightsInfo } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function FinancialHighlights() {
  const rocState = useSelector((state) => state.rocReducer);
  const [dataBorrowing, setDataBorrowing] = useState(null);
  const [dataProfitAndLoss, setdataProfitAndLoss] = useState(null);
  const [dataCaro, setdataCaro] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setDataBorrowing(null);
      setdataProfitAndLoss(null);
      setdataCaro(null);
    } else {
      setDataBorrowing(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed.BalanceSheet
      );
      setdataProfitAndLoss(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .StatementOfProfitAndLoss
      );
      setdataCaro(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .AuditorsDetailReportAndCaro.AuditorsReport
      );
    }
  }, [rocState.rocData]);
  if (dataBorrowing == null) {
    return <></>;
  }
  delete dataBorrowing['Currency'];
  delete dataBorrowing['SectionHeader'];
  delete dataCaro['Currency'];
  delete dataCaro['SectionHeader'];
  delete dataProfitAndLoss['Currency'];
  delete dataProfitAndLoss['SectionHeader'];
  const years = Object.keys(dataBorrowing);

  const fields = {
    ShareholdersFunds: 'Shareholders funds',
    LongTermBorrowings: 'Long-Term Borrowings',
    RevenueFromOperations: 'Revenue from operations',
    ProfitLossForPeriodFromCountinuingOperations: 'Profit (loss) for period (PAT)',
    BasicEarningPerEquityShare: 'Basic EPS',
    DetailsOfSuchRemark: 'Adverse Remark by Auditor in CARO'
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fields).map((key, index1) => (
            <TableRow key={index1}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index2) => {
                if (index1 < 2) {
                  return dataBorrowing[year][key] != null ? (
                    <TableCell key={index2}>{dataBorrowing[year][key]}</TableCell>
                  ) : (
                    <TableCell>NA</TableCell>
                  );
                } else if (index1 <= 4) {
                  return dataProfitAndLoss[year][key] != null ? (
                    <TableCell key={index2}>{dataProfitAndLoss[year][key]}</TableCell>
                  ) : (
                    <TableCell>NA</TableCell>
                  );
                } else {
                  return dataCaro[year][key] != null ? (
                    <TableCell key={index2}>{dataCaro[year][key]}</TableCell>
                  ) : (
                    <TableCell key={index2}>NA</TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
