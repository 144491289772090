import Constants from 'src/utils/constants';
const intialState = {
    all_comp: []
};
export const allcmpmonthreducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_MONTH_COMP:
      return { ...state, all_comp: action.payload };
    default:
      return state;
  }
};