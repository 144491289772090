import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
export const getPfChallans = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/pfbook/challan/all/?companyId=${companyId}`, false, {})
    .then((res) => {
      console.log("ok we are called")
      const data = res.data.filter((demand) => demand.companyId === companyId);
      dispatch({
        type: Constants.SET_PF_CHALLANS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_PF_CHALLANS,
        payload: []
      });
    });
};

export const getPfDemands = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/pfbook/penalty/?companyId=${companyId}`, false, {})
    .then((res) => {
      console.log(res);
      const data = res.data.filter((demand) => demand.companyId === companyId);
      console.log(data);
      dispatch({
        type: Constants.SET_PF_DEMANDS,
        payload: [...data]
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_PF_DEMANDS,
        payload: []
      });
    });
};
export const addpf = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/pfbook/challan/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(getPfChallans(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addpfdemand = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/pfbook/penalty/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(getPfDemands(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deletepfdemand = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/pfbook/penalty/get/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
      dispatch(getPfDemands(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deletepfchallan = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/pfbook/challan/getChallan/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
      dispatch(getPfChallans(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updatepfDemand = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/pfbook/penalty/get/${id}/`, true, data)
    .then((res, err) => {
      console.log('res_pf_Data', res.data);
      toast.success('Updated Challan Data');
      dispatch(getPfDemands(companyId));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updatepfChallan = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/pfbook/challan/getChallan/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(getPfChallans(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchDueDates = () => (dispatch) => {
  HttpWrapper('GET', `/compliance/due-date-book/PF/`, false, {})
    .then((res) => {

      const data = res.data;
      dispatch({
        type: Constants.SET_PF_DUE_DATES,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_PF_DUE_DATES,
        payload: []
      });
    });
};
