// material
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// component
import Iconify from '../../../../components/Iconify';
import { calOneBookTotal } from '../MainPageComponents/util';
import { isStartupFino, istalenteye } from 'src/config';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
  // color: theme.palette.primary.darker,
  //   backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

const CHART_DATA = [
  {
    name: 'Team A',
    type: 'column',
    data: [23, 11, 22, 27, 13, 22]
  }
];
// ----------------------------------------------------------------------

const TOTAL = 35673;

export default function NewOrders({ data, year }) {
  return (
    <>
      <a href="#totalTax" style={{ textDecoration: 'none', color: 'white' }}>
        <RootStyle style={{ backgroundColor: '#2c7da0', color: 'white' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              {!istalenteye && (
                <Typography>
                  Total Tax <br /> Paid
                </Typography>
              )}
              {istalenteye && (
                <Typography>
                  Total Paid <br /> Deductions
                </Typography>
              )}
              <Typography variant="h5">
                ₹ {Math.trunc(calOneBookTotal('ALL', data, year))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Iconify
                icon="carbon:ibm-watson-orders"
                width={25}
                height={25}
                style={{ color: '#a8dadc' }}
              />
            </Grid>
          </Grid>
        </RootStyle>
      </a>
    </>
  );
}
