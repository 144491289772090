import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAge } from 'src/utils/common';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function DirectorSingle({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Age</TableCell>
            <TableCell align="center">{getAge(data.DirectorDateOfBirth)}</TableCell>
            <TableCell align="center">No. of directorship</TableCell>
            <TableCell align="center">{data.DirectorCurrentDirectorshipCount}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              PAN Number
            </TableCell>
            <TableCell align="center">{data.DirectorPANNumber}</TableCell>
            <TableCell align="center">Total Capitalization</TableCell>
            <TableCell align="center">{data.DirectorCapitalization}</TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Disqualified by RoC u/s 164(2)
            </TableCell>
            <TableCell align="center">
              {data.DisqualifiedUs1642 && data.DisqualifiedUs1642 == true ? 'YES' : 'NO'}
            </TableCell>
            <TableCell align="center">DIN Deactivated </TableCell>
            <TableCell align="center">
              {data.DinDeactivated && data.DinDeactivated == true ? 'YES' : 'NO'}
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              E-mail
            </TableCell>
            <TableCell align="center">{data.DirectorEmail} </TableCell>
            <TableCell align="center">Average Capitalization</TableCell>
            <TableCell align="center">{data.DirectorAvgCapitalization}</TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Designation
            </TableCell>
            <TableCell align="center">{data.DirectorDesignation}</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell align="center">{data.DirectorPresentAddress}</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Nationality
            </TableCell>
            <TableCell align="center">{data.DirectorNationality}</TableCell>
          </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>

      {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Current Company</b>
            </TableCell>
            <TableCell align="center">
              <b>Status</b>
            </TableCell>
            <TableCell align="center">
              <b>Appointment Date</b>
            </TableCell>
            <TableCell align="center">
              <b>Industry</b>
            </TableCell>
            <TableCell align="center">
              <b>Age of Company(Years)</b>
            </TableCell>
            <TableCell align="center">
              <b>State</b>
            </TableCell>
            <TableCell align="center">
              <b>No. of Common Directors</b>
            </TableCell>
            <TableCell align="center">
              <b>No. of Directors Disqualified u/s 164(2)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <b>ASPIRE SYSTEMS (INDIA) PRIVATE LIMITED</b>
            </TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">26-08-2020</TableCell>
            <TableCell align="center">ELECTRICI TY; GAS; STEAM AND HOT WATER SUPPLY</TableCell>
            <TableCell align="center">23.2 Years</TableCell>
            <TableCell align="center">Tamil Nadu</TableCell>
            <TableCell align="center">2</TableCell>
            <TableCell align="center">NIL</TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <b>RAPIDVALUE IT SERVICES PRIVATE LIMITED</b>
            </TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">26-08-2020</TableCell>
            <TableCell align="center">COMPUTE R AND RELATED ACTIVITIES</TableCell>
            <TableCell align="center">13.2 Years</TableCell>
            <TableCell align="center">Kerala</TableCell>
            <TableCell align="center">2</TableCell>
            <TableCell align="center">NIL</TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <b>PEARLTRI FOODS PRIVATE LIMITED</b>
            </TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">01-03-2021</TableCell>
            <TableCell align="center">MANUFACT URE OF FOOD PRODUCT S AND BEVERAGE S</TableCell>
            <TableCell align="center">13.4 Years</TableCell>
            <TableCell align="center">Karnataka</TableCell>
            <TableCell align="center">1</TableCell>
            <TableCell align="center">NIL</TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <b>SYNERGITA SOFTWARE PRIVATE LIMITED</b>
            </TableCell>
            <TableCell align="center">Active</TableCell>
            <TableCell align="center">16-12-2020</TableCell>
            <TableCell align="center">COMPUTE R AND RELATED ACTIVITIES</TableCell>
            <TableCell align="center">11.11 Years</TableCell>
            <TableCell align="center">Tamil Nadu</TableCell>
            <TableCell align="center">1</TableCell>
            <TableCell align="center">NIL</TableCell>
          </TableRow>
        </TableBody>
      </Table> */}
    </TableContainer>
  );
}
