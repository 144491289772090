import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import moment from 'moment';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Label from 'src/components/Label';
import { deletegstfilling, updategstfilling } from 'src/redux/actions/gstBook';
import { dateFormat } from 'src/utils/common';
import Link from '@mui/material/Link';
import { base } from '../../../../utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';

export const GSTR9 = (props) => {
  
  const { gst9,gstNos } = props;

  if (gst9 != null && gst9.length > 0) {
    gst9.sort(function (a, b) {
      return new Date(a.due_data.due_date) - new Date(b.due_data.due_date);
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Return Type</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Date of Filing</TableCell>
            <TableCell align="center">Delay Days</TableCell>
            <TableCell align="center">GSTR 9</TableCell>
            <TableCell align="center" sx={{display: isStartupFino ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gst9 &&
          gst9?.map((row, i) => {
            const delay = calDelay(row.due_date, row.date_Filling);
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                <TableCell align="center">{row.type}</TableCell>

                <TableCell align="center">
                {row.due_data.due_date ? dateFormat(row.due_data.due_date) : 'NA'}
                </TableCell>
                <TableCell align="center">
                  {row.date_fill ? dateFormat(row.date_fill) : 'NA'}
                </TableCell>
                <TableCell align="center">
                  <Label color={delay > 0 ? 'error' : 'success'}>{delay > 0 ? delay : 0}</Label>
                </TableCell>

                <TableCell align="center">
                  <Link variant="a" href={'/' + row.copy} target="_blank">
                    GST Return
                  </Link>
                </TableCell>
                <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                  <Box textAlign="center" sx={{ display: 'flex' }}>
                    <Update row={row} gstNos={gstNos} />
                    <ConfirmProvider>
                      <Delete row={row} gstNos={gstNos} />
                    </ConfirmProvider>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props) => {
  const { row , gstNos} = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletegstfilling(row.id,companyId))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row ,gstNos} = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <GSTR9Update row={row} gstNos={gstNos} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const GSTR9Update = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row ,gstNos} = props;
  const [value, setValue] = useState(row.date_fill);
  const [formData, setFormData] = useState({
    finance_year: '',
    date_fill: '',
    gst_copy: ''
  });
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState(row.finance_year);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    // setFormData({ ...formData, [name]: valuee });
    formData[`${name}`] = valuee
    console.log('File name', formData)
  }
  const dis = (e) => {
    formData['complianceID'] = row.complianceID_id;
    formData['companyId'] = companyId
    formData['intrest'] = "0"
    formData['paid_cash'] = 0
    formData['paid_credit'] = 0
    formData['gst_num'] = gstNos

    // formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
    // if(formData.gst_copy == '')
    // {
    //   formData.gst_copy = row.gst_copy;
    // }
    var bodyFormData = new FormData();
    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    console.log('form data', formData);
    dispatch(updategstfilling(row.id, bodyFormData,companyId))
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
          <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
          <MenuItem value={"Monthly"}>Monthly</MenuItem>
          <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
          <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
          <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select> */}
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Filing"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <h4>GST Return Upload</h4>
        <Box textAlign="right" >
          <a href={row.gst_copy} target="_blank">
            GST Return Upload
          </a>
        </Box>
        <TextField required name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
