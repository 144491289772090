import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { base_url } from 'src/config';


export const masterdoc_post = (data,companyId) => (dispatch) => {
    HttpWrapper('POST', `/company/document-list/`,true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(masterdoc_get(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
  };
export const masterdoc_get=(companyId)=>(dispatch)=>{
  HttpWrapper('GET', `/company/document-list/?companyId=${companyId}`)
  .then((res) => {
    dispatch({
      type: constants.SET_COMPANY_DOCS,
      payload: res.data
    });
  })
  .catch((err) => {
    dispatch({
      type: constants.SET_COMPANY_DOCS,
      payload: []
    });
  });

}
  