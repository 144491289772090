import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const consoleView_get = () => (dispatch)=>{
    HttpWrapper('GET', `/company/all-login-vault/`)
    .then((res) => {
      dispatch({
        type: constants.SET_CONSOLE_VIEW,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_CONSOLE_VIEW,
        payload: []
      });
    });
  
  }
  export const console_del = (data) => (dispatch) => {
    HttpWrapper('DELETE', `/company/company-book/compliance/${data}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.error('Delete Successfully');
        dispatch(consoleView_get());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const consol_update = (id, data) => (dispatch) => {
    trackPromise(
      HttpWrapper('PATCH', `/company/company-book/compliance/${id}/`, false, data)
        .then((res, err) => {
          toast.success('Updated login data');
          dispatch({
            type: constants.SET_IS_UPDATED,
            payload: true
          });
          dispatch(consoleView_get());
        })
        .catch((err) => {})
    );
  };