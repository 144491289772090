import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchLoansAdvancesInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function LoansAndServices() {
  const rocState = useSelector((state) => state.rocReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLoansAdvancesInfo());
  }, []);
  if ((rocState.loansAdvancesInfo = !null)) {
    return <></>;
  }
  const data = rocState.loansAdvancesInfo[0];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">2017 - 2018</TableCell>
            <TableCell align="center">2018 - 2019</TableCell>
            <TableCell align="center"> 2019 - 2020</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Unsecured - Long - Good
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Unsecured - Long - Doubtful
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Long-Term Loans and Advances
            </TableCell>
            <TableCell align="center"> 996.17</TableCell>
            <TableCell align="center">1,468.86 </TableCell>
            <TableCell align="center">1,992.74</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Short-Term Loans and Advances
            </TableCell>
            <TableCell align="center">829.08</TableCell>
            <TableCell align="center"> 1,290.90</TableCell>
            <TableCell align="center"> 1,726.08</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Total Loans and Advances
            </TableCell>
            <TableCell align="center">1,825.24</TableCell>
            <TableCell align="center"> 2,759.76</TableCell>
            <TableCell align="center"> 3,718.82</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
