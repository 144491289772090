import { Card, CardHeader, Container, Grid, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
// ----------------------------------------------------------------------
import * as React from 'react';
import { useEffect } from 'react';
import Employees from './TdsTables/Employees';
import Auditors from './TdsTables/ROC/Auditors';
import AuditorsReport from './TdsTables/ROC/AuditorsReport';
import BasicInfo from './TdsTables/ROC/BasicInfo';
import Borrowingsummary from './TdsTables/ROC/Borrowingsummary';
import Caro from './TdsTables/ROC/Caro';
import ChargeSearch from './TdsTables/ROC/ChargeSearch';
import CompanyCharge from './TdsTables/ROC/CompanyCharge';
import CompanyCharges from './TdsTables/ROC/CompanyCharges';
import CorporateInfo from './TdsTables/ROC/CorporateInfo';
import CreditRatings from './TdsTables/ROC/CreditRatings';
import DirectorsDetail from './TdsTables/ROC/DirectorsDetail';
import Establishment from './TdsTables/ROC/Establishment';
import BalanceSheet from './TdsTables/ROC/Financial Statements/BalanceSheet';
import CashFlow from './TdsTables/ROC/Financial Statements/CashFlow';
import ProfitAndLoss from './TdsTables/ROC/Financial Statements/ProfitAndLoss';
import FinancialHighlights from './TdsTables/ROC/FinancialHighlights';
import KycInfo from './TdsTables/ROC/KycInfo';
import OwnershipDetails from './TdsTables/ROC/OwnershipDetails';
import PastDetails from './TdsTables/ROC/PastDirectors';
import RatioAnalysis from './TdsTables/ROC/RatioAnalysis';
import RelatedParty from './TdsTables/ROC/RelatedParty';
import SchedulesBreakup from './TdsTables/ROC/SchedulesBreakup';
import Borrowings from './TdsTables/ROC/SchedulesBreakup/Borrowings';
import Contingement from './TdsTables/ROC/SchedulesBreakup/Contingement';
import Debtors from './TdsTables/ROC/SchedulesBreakup/Debtors';
import FinanceCost from './TdsTables/ROC/SchedulesBreakup/FinanceCost';
import LoansAndServices from './TdsTables/ROC/SchedulesBreakup/LoansAndServices';
import Misc from './TdsTables/ROC/SchedulesBreakup/Misc';
import OtherExpense from './TdsTables/ROC/SchedulesBreakup/OtherExpense';
import Revenue from './TdsTables/ROC/SchedulesBreakup/Revenue';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AuditedFinancial from './TdsTables/ROC/ROC Docs/AuditedFinancial';
import AnnualReturn from './TdsTables/ROC/ROC Docs/AnnualReturn';
import Incorporation from './TdsTables/ROC/ROC Docs/Incorporation';
import PrivatePlacement from './TdsTables/ROC/ROC Docs/PrivatePlacement';
import AoaMoa from './TdsTables/ROC/ROC Docs/AoaMoa';
import Charge from './TdsTables/ROC/ROC Docs/Charge';
import OtherDownloadable from './TdsTables/ROC/ROC Docs/OtherDownloadable';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchRocData } from 'src/redux/actions/roc';
import { DirectorDetailed } from './TdsTables/ROC/Directoors Info/DirectorDetailed';
// ----------------------------------------------------------------------
import BasicChart from './BasicChart';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function RocDashboard() {
  const [value, setValue] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const rocState = useSelector((state) => state.rocReducer);
  const orgState = useSelector((state) => state.orgProfileReducer);
  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);
  const companyId = searchParams.get('companyid');
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('query', searchParams.get('page'));
    if (searchParams.get('page') != null) {
      setValue(parseInt(searchParams.get('page')));
    }
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchRocData(companyId));
  }, []);

  const roc_book_score =
    parseInt(
      ((dashBoardScore?.roc_book?.filled - dashBoardScore?.roc_book?.delay) /
        dashBoardScore?.roc_book?.count) *
        100
    ) || 0;

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={6} md={3}>
          <BasicChart title="ROC" score={roc_book_score} />
        </Grid> */}
        {/* <Grid container item md={9}>
            <Grid md={3}>
              <BasicChartSmall title="1234567" score={50} />
            </Grid>
            <Grid md={3}>
              <BasicChartSmall title="1234568" score={50} />
            </Grid>
            <Grid md={3}>
              <BasicChartSmall title="1234569" score={50} />
            </Grid>
            <Grid md={3}>
              <BasicChartSmall title="1234561" score={50} />
            </Grid>
          </Grid> */}
      </Grid>
      <Box sx={{ maxWidth: { xs: 320, sm: 1200 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Company Highlights" {...a11yProps(0)} />
          <Tab label="Financial Highlights" {...a11yProps(1)} />
          <Tab label="Schedules Breakup" {...a11yProps(2)} />
          {/* <Tab label="Ownership Details" {...a11yProps(3)} /> */}
          <Tab label="Borrowings & Charges" {...a11yProps(3)} />
          <Tab label="Company Charges" {...a11yProps(4)} />
          <Tab label="Financial Statements" {...a11yProps(5)} />
          <Tab label="Ratio Analysis" {...a11yProps(6)} />
          <Tab label="Schedules Breakup" {...a11yProps(7)} />
          <Tab label="Related Party Information" {...a11yProps(8)} />
          <Tab label="Auditor Details" {...a11yProps(9)} />
          <Tab label="Directors Details" {...a11yProps(10)} />
          {/* <Tab label="Establishments & Employees" {...a11yProps(12)} /> */}
          <Tab label="Charge Search Report" {...a11yProps(11)} />
          {/* <Tab label="Company Charge Details" {...a11yProps(14)} /> */}
          <Tab label="Directors Information" {...a11yProps(12)} />
          <Tab label="ROC Docs" {...a11yProps(13)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="1.1 Basic Information"
            />
            <BasicInfo />
          </Card>
          <br />
          {/* <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="1.2 KYC Information"
            />
            <KycInfo />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="1.3 Corporate Information"
            />
            <CorporateInfo />
          </Card> */}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="2.1 Financial Highlights"
            />
            <FinancialHighlights />
          </Card>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="3.1 Principal Products and Services"
            />
            <SchedulesBreakup />
          </Card>
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="4.1 Ownership Details"
            />
            <OwnershipDetails />
          </Card>
        </TabPanel> */}
        <TabPanel value={value} index={3}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="5.1 Borrowings Summary"
            />
            <Borrowingsummary />
          </Card>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="6.1 Company Charges"
            />
            <CompanyCharges />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="6.2 Credit Ratings-More than 12 Months"
            />
            <CreditRatings />
          </Card>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="7.1 Profit & Loss Statement"
            />
            <ProfitAndLoss />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="7.2 Balance Sheet"
            />
            <BalanceSheet />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="7.3 Cash Flow Statement (Direct Method)"
            />
            <CashFlow />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="7.4 Cash Flow Statement (InDirect Method)"
            />
            <CashFlow />
          </Card>
        </TabPanel>

        <TabPanel value={value} index={6}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.1 Ratio Analysis"
            />
            <RatioAnalysis />
          </Card>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.2 Revenue From Operations"
            />
            <Revenue />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.3 Finance Cost"
            />
            <FinanceCost />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.4 Other Expense"
            />
            <OtherExpense />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.5 Contingent Liabilities and Commitments"
            />
            <Contingement />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.6 Borrowings"
            />
            <Borrowings />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.7 Loans And Advances"
            />
            <LoansAndServices />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.8 Debtors Analysis"
            />
            <Debtors />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="8.9 Debtors AnalysisMisc. disclosures related to Profit (Loss)"
            />
            <Misc />
          </Card>
        </TabPanel>

        <TabPanel value={value} index={8}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="9.1 RelatedParty Details"
            />
            <RelatedParty />
          </Card>
        </TabPanel>
        <TabPanel value={value} index={9}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="10.1 Auditors Details"
            />
            <Auditors />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="10.2 Auditors Report"
            />
            <AuditorsReport />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="10.3 CARO"
            />
            <Caro />
          </Card>
        </TabPanel>

        <TabPanel value={value} index={10}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="11.1 Current Directors"
            />
            <DirectorsDetail />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="11.2 Past Directors"
            />
            <PastDetails />
          </Card>
        </TabPanel>

        {/* <TabPanel value={value} index={12}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="12.1 Establishment"
            />
            <Establishment />
          </Card>
          <br />
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="12.2 Employees Trend"
            />
            <Employees />
          </Card>
        </TabPanel> */}

        <TabPanel value={value} index={11}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title="13.1 Bank-Wise Open Charges "
            />
            <ChargeSearch />
          </Card>
        </TabPanel>
        {/* <TabPanel value={value} index={14}>
          <Card>
            <CardHeader
              style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
              title=" 14.1 Company Charge Details "
            />
            <CompanyCharge />
          </Card>
        </TabPanel> */}
        <TabPanel value={value} index={12}>
          <DirectorDetailed />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <AuditedFinancial />
          <br />
        </TabPanel>
      </Box>
    </>
  );
}
