import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import { Stack, TextField, FormControlLabel, Grid } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from '@mui/lab';
import { Switch } from '@mui/material';
import { Button, Box, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { base_url, isStartupFino, istalenteye } from 'src/config';
import validator from 'validator';
import { getCompanyId } from 'src/utils/common';
import { appName } from 'src/config';
//component

export const ExtendedProfileForm = (props) => {
  const {handleProgress}=props
  // states
  const navigate = useNavigate();
  const company_state = useSelector((state) => state.orgProfileReducer);
  const auth_state = useSelector((state) => state.authReducer);
  console.log('auth_state',auth_state)
  const [currentPage, setcurrentPage] = useState(1);
  const [imageUrl, setImageUrl] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [roles, setRoles] = useState([
    {
      userName: '',
      userRole: '',
      userEmail: '',
      userMobile: ''
    }
  ]);
  const [orgProfileInfo, setOrgProfileInfo] = useState({
    panNo: '',
    tarNo: '',
    gstNo: [''],
    gstDate: [new Date()],
    cinNo: '',
    haveGst: true,
    selectedLogo: null,
    epfNo: [''],
    haveEpf: true,
    epfDate: [new Date()],
    esicNo: [''],
    haveEsic: true,
    esicDate: [new Date()],
    gstUserNames: [],
    gstPasswords: [],
    epfUserNames: [],
    epfPasswords: [],
    esicUserNames: [],
    esicPassowrds: [],
    pfUserName: '',
    pfPassword: '',
    incometaxUserName: '',
    incomeTaxPassword: '',
    tracesPassword: '',
    tracesUserName: '',
    tanUserName: '',
    tanPassword: '',
    startupUserName: '',
    startupPassword: '',
    rbiUserName: '',
    rbiPassword: '',
    pfUserName: '',
    pfPassword: '',
    professionalTaxNo: [''],
    professionalTaxDate: [new Date()],
    haveProfessionaltax: true,
    professionalTaxUsernames: [],
    professionalTaxPasswords: [],
    shopEstbNo: [''],
    shopEstbDate: [new Date()],
    haveshopEstb: true,
    shopEstbUsernames: [],
    shopEstbPasswords: [],

    LabourWelfNo: [''],
    LabourWelfDate: [new Date()],
    haveLabourWelf: true,
    LabourWelfUsernames: [],
    LabourWelfPasswords: [],

    AddOtherRegNo: [''],
    AddOtherRegName: [''],
    AddOtherRegDate: [new Date()],
    AddOtherRegUsernames: [],
    AddOtherRegPasswords: [],
    haveAddOtherReg: false
  });

  const show_cin =
    company_state.companyData[0].company_type === 'Private Company' ||
    company_state.companyData[0].company_type === 'Public Company'
      ? true
      : false;

  // input change functions

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrgProfileInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoleDataChange = (e, index) => {
    const { name, value } = e.target;
    const rolesData = [...roles];
    rolesData[index][name] = value;
    setRoles(rolesData);
    if (name === 'userEmail') {
      setEmailError(!validator.isEmail(value));
    }

    if (name === 'userMobile') {
      setPhoneError(!validator.isMobilePhone(value, 'en-IN'));
    }
  };

  function handleAddGstNo() {
    const values = orgProfileInfo.gstNo;
    values.push('');
    const gstdates = orgProfileInfo.gstDate;
    gstdates.push(new Date());
    const gstUserNames = orgProfileInfo.gstUserNames;
    gstUserNames.push('');
    const gstPasswords = orgProfileInfo.gstPasswords;
    gstPasswords.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      gstNo: values,
      gstDate: gstdates,
      gstUserNames: gstUserNames,
      gstPasswords: gstPasswords
    });
  }

  function handleRemoveGstNo(i) {
    const values = orgProfileInfo.gstNo;
    const dates = orgProfileInfo.gstDate;
    values.splice(i, 1);
    dates.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      gstNo: values,
      gstDate: dates,
      gstUserNames: orgProfileInfo.gstUserNames.splice(i, 1),
      gstPasswords: orgProfileInfo.gstPasswords.splice(i, 1)
    });
  }

  function handleAddEsicNo() {
    const esicnos = orgProfileInfo.esicNo;
    esicnos.push('');
    const esicdates = orgProfileInfo.esicDate;
    esicdates.push(new Date());
    const esicUserNames = orgProfileInfo.esicUserNames;
    esicUserNames.push('');
    const esicPassowrds = orgProfileInfo.esicPassowrds;
    esicPassowrds.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      esicNo: esicnos,
      esicDate: esicdates,
      esicUserNames: esicUserNames,
      esicPassowrds: esicPassowrds
    });
  }
  function handleRemoveEsicNo(i) {
    const esicnos = orgProfileInfo.esicNo;
    const esicdates = orgProfileInfo.esicDate;
    esicnos.splice(i, 1);
    esicdates.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      esicNo: esicnos,
      esicDate: esicdates,
      esicUserNames: orgProfileInfo.esicUserNames.splice(i, 1),
      esicPassowrds: orgProfileInfo.esicPassowrds.splice(i, 1)
    });
  }
  function handleMultipleChangeInput(i, event) {
    const { name, value } = event.target;
    const values = orgProfileInfo[name];
    values[i] = value;
    setOrgProfileInfo({ ...orgProfileInfo, [name]: values });
  }
  function handleAddProfessionalTaxNo() {
    const pftxnos = orgProfileInfo.professionalTaxNo;
    pftxnos.push('');
    const pftxdates = orgProfileInfo.professionalTaxDate;
    pftxdates.push(new Date());
    const pftxUsernames = orgProfileInfo.professionalTaxUsernames;
    pftxUsernames.push('');
    const pftxPassword = orgProfileInfo.professionalTaxPasswords;
    pftxPassword.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      professionalTaxNo: pftxnos,
      professionalTaxDate: pftxdates,
      professionalTaxUsernames: pftxUsernames,
      professionalTaxPasswords: pftxPassword
    });
  }
  function handleRemoveProfessionalTaxNo(i) {
    const pftxnos = orgProfileInfo.professionalTaxNo;
    const pftxdates = orgProfileInfo.professionalTaxDate;
    pftxnos.splice(i, 1);
    pftxdates.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      professionalTaxNo: pftxnos,
      professionalTaxDate: pftxdates,
      professionalTaxUsernames: orgProfileInfo.professionalTaxUsernames.splice(i, 1),
      professionalTaxPasswords: orgProfileInfo.professionalTaxPasswords.splice(i, 1)
    });
  }
  function handleAddShopEstb() {
    const shpestbno = orgProfileInfo.shopEstbNo;
    shpestbno.push('');
    const shpestbdates = orgProfileInfo.shopEstbDate;
    shpestbdates.push(new Date());
    const shpestbUserNames = orgProfileInfo.shopEstbUsernames;
    shpestbUserNames.push('');
    const shpestbPassowrds = orgProfileInfo.shopEstbPasswords;
    shpestbPassowrds.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      shopEstbNo: shpestbno,
      shopEstbDate: shpestbdates,
      shopEstbUsernames: shpestbUserNames,
      shopEstbPasswords: shpestbPassowrds
    });
  }
  function handleRemoveShopEstb(i) {
    const shopestbnos = orgProfileInfo.shopEstbNo;
    const shopestbdates = orgProfileInfo.shopEstbDate;
    shopestbnos.splice(i, 1);
    shopestbdates.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      shopEstbNo: shopestbnos,
      shopEstbDate: shopestbdates,
      shopEstbUsernames: orgProfileInfo.shopEstbUsernames.splice(i, 1),
      shopEstbPasswords: orgProfileInfo.shopEstbPasswords.splice(i, 1)
    });
  }
  function handleAddLabourWelfare() {
    const labwelfnos = orgProfileInfo.LabourWelfNo;
    labwelfnos.push('');
    const labwelfdates = orgProfileInfo.LabourWelfDate;
    labwelfdates.push(new Date());
    const labwelfUserNames = orgProfileInfo.LabourWelfUsernames;
    labwelfUserNames.push('');
    const labwelfPassowrds = orgProfileInfo.LabourWelfPasswords;
    labwelfPassowrds.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      LabourWelfNo: labwelfnos,
      LabourWelfDate: labwelfdates,
      LabourWelfUsernames: labwelfUserNames,
      LabourWelfPasswords: labwelfPassowrds
    });
  }
  function handleRemoveLabourWelfare(i) {
    const labwelfnos = orgProfileInfo.LabourWelfNo;
    const labwelfdates = orgProfileInfo.LabourWelfDate;
    labwelfnos.splice(i, 1);
    labwelfdates.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      LabourWelfNo: labwelfnos,
      LabourWelfDate: labwelfdates,
      LabourWelfUsernames: orgProfileInfo.LabourWelfUsernames.splice(i, 1),
      LabourWelfPassowrds: orgProfileInfo.LabourWelfPasswords.splice(i, 1)
    });
  }
  function handleAddOtheRegistration() {
    const addotherName = orgProfileInfo.AddOtherRegName;
    addotherName.push('');
    const addotherNo = orgProfileInfo.AddOtherRegNo;
    addotherNo.push('');
    const addotherdates = orgProfileInfo.AddOtherRegDate;
    addotherdates.push(new Date());
    const addotherUsername = orgProfileInfo.AddOtherRegUsernames;
    addotherUsername.push('');
    const addotherPass = orgProfileInfo.AddOtherRegPasswords;
    addotherPass.push('');
    setOrgProfileInfo({
      ...orgProfileInfo,
      AddOtherRegName: addotherName,
      AddOtherRegNo: addotherNo,
      AddOtherRegDate: addotherdates,
      AddOtherRegUsernames: addotherUsername,
      AddOtherRegPasswords: addotherPass
    });
  }
  function handleRemoveOtherReg(i) {
    const othername = orgProfileInfo.AddOtherRegName;
    const otheregNo = orgProfileInfo.AddOtherRegNo;
    const otheregDate = orgProfileInfo.AddOtherRegDate;
    othername.splice(i, 1);
    otheregNo.splice(i, 1);
    otheregDate.splice(i, 1);
    setOrgProfileInfo({
      ...orgProfileInfo,
      AddOtherRegName: othername,
      AddOtherRegNo: otheregNo,
      AddOtherRegDate: otheregDate,
      AddOtherRegUsernames: orgProfileInfo.AddOtherRegUsernames.splice(i, 1),
      AddOtherRegPasswords: orgProfileInfo.AddOtherRegPasswords.splice(i, 1)
    });
  }

  //submit and go back

  const prepareData = () => {
    // const enUSFormatter = new Intl.DateTimeFormat('en-US');
    const data = [
      {
        creadName: 'PAN',
        creadNum: orgProfileInfo.panNo,
        isRegister: true,
        username: orgProfileInfo.incometaxUserName,
        password: orgProfileInfo.incomeTaxPassword,
        bookName: 'Income Tax'
      },
      {
        creadName: 'EPF',
        creadNum: orgProfileInfo.epfNo,
        isRegister: orgProfileInfo.haveEpf,
        username: orgProfileInfo.pfUserName,
        password: orgProfileInfo.pfPassword,
        // date_of_registration: orgProfileInfo.epfDate.toISOString().slice(0, 10),
        bookName: 'PF Book'
      },
      {
        creadName: 'CIN',
        creadNum: orgProfileInfo.cinNo,
        isRegister: true,
        bookName: 'ROC'
      },
      {
        creadName: 'TAN',
        creadNum: orgProfileInfo.tarNo,
        isRegister: true,
        username: orgProfileInfo.tanUserName,
        password: orgProfileInfo.tanPassword,
        bookName: 'TAN'
      },
      {
        creadName: 'TAN',
        creadNum: orgProfileInfo.tarNo,
        isRegister: true,
        username: orgProfileInfo.tracesUserName,
        password: orgProfileInfo.tracesPassword,
        bookName: 'Trace'
      },
      {
        creadName: 'Startup',
        creadNum: 0,
        isRegister: true,
        username: orgProfileInfo.startupUserName,
        password: orgProfileInfo.startupPassword,
        bookName: 'NA'
      },
      {
        creadName: 'RBI',
        creadNum: 0,
        isRegister: true,
        username: orgProfileInfo.rbiUserName,
        password: orgProfileInfo.rbiPassword,
        bookName: 'NA'
      },
      {
        creadName: 'Professional Tax',
        creadNum: 0,
        isRegister: true,
        username: orgProfileInfo.pfUserName,
        password: orgProfileInfo.pfPassword
      }
    ];
    for (let i = 0; i < orgProfileInfo.gstNo.length; i++) {
      data.push({
        creadName: 'GST',
        bookName: 'GST Book',
        creadNum: orgProfileInfo.gstNo[i],
        isRegister: orgProfileInfo.haveGst,
        date_of_registration: orgProfileInfo.gstDate[i].toISOString().slice(0, 10),
        username: orgProfileInfo.gstUserNames[i],
        password: orgProfileInfo.gstPasswords[i]
      });
    }

    for (let i = 0; i < orgProfileInfo.esicNo.length; i++) {
      data.push({
        creadName: 'ESIC',
        bookName: 'ESIC BOOK',
        creadNum: orgProfileInfo.esicNo[i],
        isRegister: orgProfileInfo.haveEsic,
        date_of_registration: orgProfileInfo.esicDate[i].toISOString().slice(0, 10),
        username: orgProfileInfo.esicUserNames[i],
        password: orgProfileInfo.esicPassowrds[i]
      });
    }

    return data;
  };

  const handleGetStarted = async () => {
    if (currentPage == 1) {
      setcurrentPage(2);
      handleProgress(2);
    } else if (currentPage == 2) {
      const body = prepareData();
      console.log('data is', body);
      console.log(company_state.companyId);
      try {
        console.log('okay', auth_state.accessToken);
        let res = await axios({
          method: 'POST',
          url: `${base_url}/company/company-book/${company_state.companyId}/`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth_state.accessToken}`
          },
          data: body
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      setcurrentPage(3);
      handleProgress(3)
    } else {
      try {
        console.log(auth_state.accessToken);
        axios({
          method: 'POST',
          url: `${base_url}/company/notifications/${company_state.companyId}/`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth_state.accessToken}`
          },
          data: roles
        })
          .then((res) => {
            console.log('notification', res);
            if (res.status === 200) {
              //navigate(`/dashboard/main?companyid=${getCompanyId()}`, { replace: true });
              navigate('/companydocuments', { replace: true });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleSkip = async () => {
    const body = prepareData();
    console.log('data is', body);
    console.log(company_state.companyId);
    try {
      console.log(auth_state.accessToken);
      let res = await axios({
        method: 'POST',
        url: `${base_url}/company/company-book/${company_state.companyId}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_state.accessToken}`
        },
        data: body
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    setcurrentPage(3);
    handleProgress(3);
  };
  const handleGoBack = () => {
    setcurrentPage(1);
    handleProgress(1)
  };

  useEffect(() => {
    if (orgProfileInfo.selectedLogo) {
      setImageUrl(URL.createObjectURL(orgProfileInfo.selectedLogo));
    }
  }, [orgProfileInfo.selectedLogo]);

  // Adding Roles Form
  const addRoles = () => {
    const role = {
      userName: '',
      userRole: '',
      userEmail: '',
      userMobile: ''
    };
    setRoles((old_arr) => [...old_arr, role]);
  };

  const removeRole = (index) => {
    const rolesList = [...roles];
    rolesList.splice(index, 1);
    setRoles(rolesList);
  };

  //function for getting user credential

  const getUserGstCredentials = orgProfileInfo.gstNo.map((gst, idx) => {
    return (
      <>
        {!istalenteye && (
          <Stack spacing={3} key={idx}>
            <Stack>
              <Typography variant="h7">Credential for GST number: {gst}</Typography>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="User Name"
                name="gstUserNames"
                value={orgProfileInfo.gstUserNames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
              <TextField
                fullWidth
                label="Password"
                name="gstPasswords"
                type={'password'}
                value={orgProfileInfo.gstPasswords[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Stack>
          </Stack>
        )}
      </>
    );
  });

  const getUserEpfCredentials = orgProfileInfo.epfNo.map((epf, idx) => {
    return (
      <Stack spacing={3} key={idx}>
        <Stack>
          <Typography variant="h7">Credential for EPF number: {epf}</Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            fullWidth
            label="User Name"
            name="epfUserNames"
            value={orgProfileInfo.epfUserNames[idx]}
            onChange={(e) => handleMultipleChangeInput(idx, e)}
          />
          <TextField
            fullWidth
            label="Password"
            name="epfPasswords"
            type={'password'}
            value={orgProfileInfo.epfPasswords[idx]}
            onChange={(e) => handleMultipleChangeInput(idx, e)}
          />
        </Stack>
      </Stack>
    );
  });

  const getUserEsicCredentials = orgProfileInfo.esicNo.map((esic, idx) => {
    return (
      <Stack spacing={2} key={idx}>
        <Typography variant="h7" mt={1}>
          Credential for ESIC number: {esic}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="User Name"
                name="esicUserNames"
                value={orgProfileInfo.esicUserNames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Password"
                name="esicPassowrds"
                type={'password'}
                value={orgProfileInfo.esicPassowrds[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  });
  const getUserProfTaxCredentials = orgProfileInfo.professionalTaxNo.map((pftx, idx) => {
    return (
      <Stack spacing={2} key={idx}>
        <Typography variant="h7" mt={1}>
          Credential for Professional Tax number: {pftx}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="User Name"
                name="professionalTaxUsernames"
                value={orgProfileInfo.professionalTaxUsernames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Password"
                name="professionalTaxPasswords"
                type={'password'}
                value={orgProfileInfo.professionalTaxPasswords[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  });
  const getUserShopEstbCredentials = orgProfileInfo.shopEstbNo.map((shpestb, idx) => {
    return (
      <Stack spacing={2} key={idx}>
        <Typography variant="h7" mt={1}>
          Credential for Shop and Establishment number: {shpestb}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="User Name"
                name="shopEstbUsernames"
                value={orgProfileInfo.shopEstbUsernames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Password"
                name="shopEstbPasswords"
                type={'password'}
                value={orgProfileInfo.shopEstbPasswords[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  });
  const getUserOtherRegCredentials = orgProfileInfo.AddOtherRegNo.map((otherRegno, idx) => {
    return (
      <Stack spacing={2} key={idx}>
        <Typography variant="h7" mt={1}>
          Credential for {orgProfileInfo.AddOtherRegName[idx]}: {otherRegno}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="User Name"
                name="AddOtherRegUsernames"
                value={orgProfileInfo.AddOtherRegUsernames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Password"
                name="AddOtherRegPasswords"
                type={'password'}
                value={orgProfileInfo.AddOtherRegPasswords[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  });
  const getUserLabourWelfareCredentials = orgProfileInfo.LabourWelfNo.map((labwelf, idx) => {
    return (
      <Stack spacing={2} key={idx}>
        <Typography variant="h7" mt={1}>
          Credential for Labour Welfare number: {labwelf}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="User Name"
                name="LabourWelfUsernames"
                value={orgProfileInfo.LabourWelfUsernames[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="Password"
                name="LabourWelfPasswords"
                type={'password'}
                value={orgProfileInfo.LabourWelfPasswords[idx]}
                onChange={(e) => handleMultipleChangeInput(idx, e)}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleGetStarted();
      }}
    >
      <Stack spacing={2}>
        {/*... diffrent no's ...*/}
        {currentPage == 1 && (
          <Stack spacing={2}>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
              Setup Your Organization Profile
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Pan Number"
                name="panNo"
                value={orgProfileInfo.panNo}
                onChange={handleChange}
                required={!istalenteye}
              />
              {appName === 'StartupFino' && (
                <TextField
                  fullWidth
                  label="Tan Number"
                  name="tarNo"
                  value={orgProfileInfo.tarNo}
                  onChange={handleChange}
                  required={!istalenteye}
                />
              )}
            </Stack>
            {appName === 'StartupFino' && (
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {show_cin && (
                  <TextField
                    fullWidth
                    label="Enter your CIN number"
                    name="cinNo"
                    value={orgProfileInfo.cinNo}
                    onChange={handleChange}
                    required={!istalenteye}
                  />
                )}

                {/* <>
                                  <input
                                    accept="image/*"
                                    type="file"
                                    id="select-image"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      setOrgProfileInfo({ ...orgProfileInfo, selectedLogo: e.target.files[0] });
                                    }}
                                  />
                                  <label htmlFor="select-image">
                                    <Button variant="contained" color="primary" component="span">
                                      Upload Image
                                    </Button>
                                  </label>
                                  {imageUrl && orgProfileInfo.selectedLogo && (
                                    <Box mt={2} textAlign="center">
                                      <img src={imageUrl} alt={orgProfileInfo.selectedLogo.name} height="100px" />
                                    </Box>
                                  )}
                                </> */}
              </Stack>
            )}
            {!istalenteye && (
              <FormControlLabel
                control={
                  <Switch
                    checked={orgProfileInfo.haveGst}
                    onChange={(e) => {
                      setOrgProfileInfo({ ...orgProfileInfo, haveGst: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label="Is your business registered under GST?"
              />
            )}
            {orgProfileInfo.gstNo.map((gst, idx) => {
              return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} key={idx} width="500px" >
                  {!istalenteye && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <TextField
                          fullWidth
                          label="Enter your GST Number"
                          name="gstNo"
                          value={gst}
                          onChange={(e) => handleMultipleChangeInput(idx, e)}
                          required
                          disabled={!orgProfileInfo.haveGst}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            openTo="year"
                            views={['year', 'month', 'day']}
                            label="Date of Registration"
                            name="gstDate"
                            value={orgProfileInfo.gstDate[idx]}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                            onChange={(newValue) => {
                              let tmp = orgProfileInfo.gstDate;
                              tmp[idx] = newValue;
                              setOrgProfileInfo({ ...orgProfileInfo, gstDate: tmp });
                            }}
                            disabled={!orgProfileInfo.haveGst}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button type="button" onClick={() => handleAddGstNo()}>
                          <i className="fa fa-plus" />
                        </Button>

                        {idx != 0 && (
                          <Button type="button" onClick={() => handleRemoveGstNo(idx)}>
                            <i className="fa fa-times" area-hidden="true" />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Stack>
              );
            })}

            <FormControlLabel
              control={
                <Switch
                  checked={orgProfileInfo.haveEpf}
                  onChange={(e) => {
                    setOrgProfileInfo({ ...orgProfileInfo, haveEpf: e.target.checked });
                  }}
                  color="primary"
                />
              }
              label="Is your business registered under EPF?"
            />
            {orgProfileInfo.epfNo.map((epf, idx) => {
              return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} key={idx} width="500px">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Enter your EPF Number"
                        name="epfNo"
                        value={epf}
                        onChange={(e) => handleMultipleChangeInput(idx, e)}
                        //required
                        disabled={!orgProfileInfo.haveEpf}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          width="950px"
                          openTo="year"
                          views={['year', 'month', 'day']}
                          label="Date of coverage"
                          name="epfDate"
                          value={orgProfileInfo.epfDate[idx]}
                          renderInput={(params) => <TextField {...params} helperText={null} />}
                          onChange={(newValue) => {
                            let tmp = orgProfileInfo.epfDate;
                            tmp[idx] = newValue;
                            setOrgProfileInfo({ ...orgProfileInfo, epfDate: tmp });
                          }}
                          disabled={!orgProfileInfo.haveEpf}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button type="button" onClick={() => handleAddEpfNo()}>
                            <i className="fa fa-plus" />
                          </Button>

                          {idx != 0 && (
                            <Button type="button" onClick={() => handleRemoveEpfNo(idx)}>
                              <i className="fa fa-times" area-hidden="true" />
                            </Button>
                          )}
                        </Grid> */}
                  </Grid>
                </Stack>
              );
            })}
            <FormControlLabel
              control={
                <Switch
                  checked={orgProfileInfo.haveEsic}
                  onChange={(e) => {
                    setOrgProfileInfo({ ...orgProfileInfo, haveEsic: e.target.checked });
                  }}
                  color="primary"
                />
              }
              label="Is your business registered under ESIC?"
            />
            {orgProfileInfo.esicNo.map((esic, idx) => {
              return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} key={idx} width="500px">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Enter your ESIC Number"
                        name="esicNo"
                        value={esic}
                        onChange={(e) => handleMultipleChangeInput(idx, e)}
                        required
                        disabled={!orgProfileInfo.haveEsic}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          openTo="year"
                          views={['year', 'month', 'day']}
                          label="Date of coverage"
                          name="esicDate"
                          value={orgProfileInfo.esicDate[idx]}
                          renderInput={(params) => <TextField {...params} helperText={null} />}
                          onChange={(newValue) => {
                            let tmp = orgProfileInfo.esicDate;
                            tmp[idx] = newValue;
                            setOrgProfileInfo({ ...orgProfileInfo, esicDate: tmp });
                          }}
                          disabled={!orgProfileInfo.haveEsic}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Button
                        type="button"
                        onClick={() => handleAddEsicNo()}
                        disabled={!orgProfileInfo.haveEsic}
                      >
                        <i className="fa fa-plus" />
                      </Button>
                      {idx != 0 && (
                        <Button
                          type="button"
                          onClick={() => handleRemoveEsicNo(idx)}
                          disabled={!orgProfileInfo.haveEsic}
                        >
                          <i className="fa fa-times" area-hidden="true" />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              );
            })}

            {/* ProfessionalTax         */}

            {appName === 'PeopleFino' && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={orgProfileInfo.haveProfessionaltax}
                      onChange={(e) => {
                        setOrgProfileInfo({
                          ...orgProfileInfo,
                          haveProfessionaltax: e.target.checked
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Is your business registered under Professional Tax?"
                />
                {orgProfileInfo.professionalTaxNo.map((pftx, idx) => {
                  return (
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      key={idx}
                      width="500px"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            fullWidth
                            label="Enter your Professional Tax"
                            name="professionalTaxNo"
                            value={pftx}
                            onChange={(e) => handleMultipleChangeInput(idx, e)}
                            required
                            disabled={!orgProfileInfo.haveProfessionaltax}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              openTo="year"
                              views={['year', 'month', 'day']}
                              label="Date of coverage"
                              name="professionalTaxDate"
                              value={orgProfileInfo.professionalTaxDate[idx]}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                              onChange={(newValue) => {
                                let tmp = orgProfileInfo.professionalTaxDate;
                                tmp[idx] = newValue;
                                setOrgProfileInfo({ ...orgProfileInfo, professionalTaxDate: tmp });
                              }}
                              disabled={!orgProfileInfo.haveProfessionaltax}
                              inputFormat="dd/MM/yyyy"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button
                            type="button"
                            onClick={() => handleAddProfessionalTaxNo()}
                            disabled={!orgProfileInfo.haveProfessionaltax}
                          >
                            <i className="fa fa-plus" />
                          </Button>
                          {idx != 0 && (
                            <Button
                              type="button"
                              onClick={() => handleRemoveProfessionalTaxNo(idx)}
                              disabled={!orgProfileInfo.haveProfessionaltax}
                            >
                              <i className="fa fa-times" area-hidden="true" />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}

                {/* Shop and Establishment        */}

                <FormControlLabel
                  control={
                    <Switch
                      checked={orgProfileInfo.haveshopEstb}
                      onChange={(e) => {
                        setOrgProfileInfo({ ...orgProfileInfo, haveshopEstb: e.target.checked });
                      }}
                      color="primary"
                    />
                  }
                  label="Is your business registered under Shop and Establishment?"
                />
                {orgProfileInfo.shopEstbNo.map((shpetb, idx) => {
                  return (
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      key={idx}
                      width="500px"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            fullWidth
                            label="Enter your Shop and Establishment Number"
                            name="shopEstbNo"
                            value={shpetb}
                            onChange={(e) => handleMultipleChangeInput(idx, e)}
                            required
                            disabled={!orgProfileInfo.haveshopEstb}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              openTo="year"
                              views={['year', 'month', 'day']}
                              label="Date of coverage"
                              name="shopEstbDate"
                              value={orgProfileInfo.shopEstbDate[idx]}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                              onChange={(newValue) => {
                                let tmp = orgProfileInfo.shopEstbDate;
                                tmp[idx] = newValue;
                                setOrgProfileInfo({ ...orgProfileInfo, shopEstbDate: tmp });
                              }}
                              disabled={!orgProfileInfo.haveshopEstb}
                              inputFormat="dd/MM/yyyy"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button
                            type="button"
                            onClick={() => handleAddShopEstb()}
                            disabled={!orgProfileInfo.haveshopEstb}
                          >
                            <i className="fa fa-plus" />
                          </Button>
                          {idx != 0 && (
                            <Button
                              type="button"
                              onClick={() => handleRemoveShopEstb(idx)}
                              disabled={!orgProfileInfo.haveshopEstb}
                            >
                              <i className="fa fa-times" area-hidden="true" />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}
                {/* Labour Welfare Login       */}

                <FormControlLabel
                  control={
                    <Switch
                      checked={orgProfileInfo.haveLabourWelf}
                      onChange={(e) => {
                        setOrgProfileInfo({ ...orgProfileInfo, haveLabourWelf: e.target.checked });
                      }}
                      color="primary"
                    />
                  }
                  label="Is your business registered under Labour Welfare Login?"
                />
                {orgProfileInfo.LabourWelfNo.map((labwlf, idx) => {
                  return (
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                      key={idx}
                      width="500px"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            fullWidth
                            label="Enter your Labour Welfare Login"
                            name="LabourWelfNo"
                            value={labwlf}
                            onChange={(e) => handleMultipleChangeInput(idx, e)}
                            required
                            disabled={!orgProfileInfo.haveLabourWelf}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              openTo="year"
                              views={['year', 'month', 'day']}
                              label="Date of coverage"
                              name="LabourWelfDate"
                              value={orgProfileInfo.LabourWelfDate[idx]}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                              onChange={(newValue) => {
                                let tmp = orgProfileInfo.LabourWelfDate;
                                tmp[idx] = newValue;
                                setOrgProfileInfo({ ...orgProfileInfo, LabourWelfDate: tmp });
                              }}
                              disabled={!orgProfileInfo.haveLabourWelf}
                              inputFormat="dd/MM/yyyy"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Button
                            type="button"
                            onClick={() => handleAddLabourWelfare()}
                            disabled={!orgProfileInfo.haveLabourWelf}
                          >
                            <i className="fa fa-plus" />
                          </Button>
                          {idx != 0 && (
                            <Button
                              type="button"
                              onClick={() => handleRemoveLabourWelfare(idx)}
                              disabled={!orgProfileInfo.haveLabourWelf}
                            >
                              <i className="fa fa-times" area-hidden="true" />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}
              </>
            )}

            <FormControlLabel
              control={
                <Switch
                  checked={orgProfileInfo.haveAddOtherReg}
                  onChange={(e) => {
                    setOrgProfileInfo({ ...orgProfileInfo, haveAddOtherReg: e.target.checked });
                  }}
                  color="primary"
                />
              }
              label="Do you want to Add Other Registration?"
            />
            {orgProfileInfo.AddOtherRegNo.map((addOtheReg, idx) => {
              return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} key={idx} width="500px">
                  <Grid container spacing={2}>
                    <TextField
                      label="Enter your Registration Name"
                      name="AddOtherRegName"
                      fullWidth
                      sx={{ mr: 4, ml: 2 }}
                      value={orgProfileInfo.AddOtherRegName[idx]}
                      onChange={(e) => handleMultipleChangeInput(idx, e)}
                      required
                      disabled={!orgProfileInfo.haveAddOtherReg}
                    />
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Enter Registration Number/ID"
                        name="AddOtherRegNo"
                        value={addOtheReg}
                        onChange={(e) => handleMultipleChangeInput(idx, e)}
                        required
                        disabled={!orgProfileInfo.haveAddOtherReg}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          openTo="year"
                          views={['year', 'month', 'day']}
                          label="Date of Registration"
                          name="AddOtherRegDate"
                          value={orgProfileInfo.AddOtherRegDate[idx]}
                          renderInput={(params) => <TextField {...params} helperText={null} />}
                          onChange={(newValue) => {
                            let tmp = orgProfileInfo.AddOtherRegDate;
                            tmp[idx] = newValue;
                            setOrgProfileInfo({ ...orgProfileInfo, AddOtherRegDate: tmp });
                          }}
                          disabled={!orgProfileInfo.haveAddOtherReg}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Button
                        type="button"
                        onClick={() => handleAddOtheRegistration()}
                        disabled={!orgProfileInfo.haveAddOtherReg}
                      >
                        <i className="fa fa-plus" />
                      </Button>
                      {idx != 0 && (
                        <Button
                          type="button"
                          onClick={() => handleRemoveOtherReg(idx)}
                          disabled={!orgProfileInfo.haveAddOtherReg}
                        >
                          <i className="fa fa-times" area-hidden="true" />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              );
            })}
          </Stack>
        )}

        {/* ....userData.... */}

        {currentPage == 3 &&
          roles.map((role, i) => {
            return (
              <Stack spacing={2} key={i}>
                <Typography variant="h5" align={'center'} sx={{mt: 1, mb: 2 }}  style={{fontWeight:500}}>
                  List of Users who wants the Notifications from us!
                </Typography>
                <TextField
                  fullWidth
                  label="Name"
                  name="userName"
                  value={role.userName}
                  onChange={(e) => handleRoleDataChange(e, i)}
                  required
                />
                <TextField
                  fullWidth
                  label="Role"
                  name="userRole"
                  value={role.userRole}
                  onChange={(e) => handleRoleDataChange(e, i)}
                  required
                />
                <TextField
                  error={emailError}
                  type="email"
                  fullWidth
                  label="Email"
                  name="userEmail"
                  value={role.userEmail}
                  onChange={(e) => handleRoleDataChange(e, i)}
                  required
                />
                <TextField
                  error={phoneError}
                  fullWidth
                  type="tel"
                  label="WhatsApp Mobile Number"
                  name="userMobile"
                  value={role.userMobile}
                  onChange={(e) => handleRoleDataChange(e, i)}
                  required
                />

                <Button type="button" onClick={() => addRoles()}>
                  <i className="fa fa-plus" />
                </Button>

                {i != 0 && (
                  <Button type="button" onClick={() => removeRole(i)}>
                    <i className="fa fa-times" area-hidden="true" />
                  </Button>
                )}
              </Stack>
            );
          })}
        {currentPage == 2 && (
          <Stack spacing={3}>
            <Typography variant="h5" sx={{mt: 2, mb: 2 }}>
              Setup Your Organization Credentials
            </Typography>
            {orgProfileInfo.haveGst && getUserGstCredentials}
            {orgProfileInfo.haveEpf && getUserEpfCredentials}
            {orgProfileInfo.haveEsic && getUserEsicCredentials}
            {appName === 'PeopleFino' &&
              orgProfileInfo.haveProfessionaltax &&
              getUserProfTaxCredentials}
            {appName === 'PeopleFino' && orgProfileInfo.haveshopEstb && getUserShopEstbCredentials}
            {appName === 'PeopleFino' &&
              orgProfileInfo.haveLabourWelf &&
              getUserLabourWelfareCredentials}
            {appName === 'PeopleFino' &&
              orgProfileInfo.haveAddOtherReg &&
              getUserOtherRegCredentials}

            <Stack spacing={3}>
              {/* <Typography variant="h7" mt={1}>
                    PF Login Credentials
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="pfUserName"
                      value={orgProfileInfo.pfUserName}
                      onChange={handleChange}
                    />
                    <TextFie      fullWidth
                      label="Password"
                      name="pfPassword"
                      value={orgProfileInfo.pfPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack> */}

              {appName === 'StartupFino' && (
                <>
                  <Typography variant="h7" mt={1}>
                    Income tax Login Credentials
                  </Typography>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="incometaxUserName"
                      value={orgProfileInfo.incometaxUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="incomeTaxPassword"
                      value={orgProfileInfo.incomeTaxPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>

                  <Typography variant="h7" mt={1}>
                    Traces Login Credentials
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="tracesUserName"
                      value={orgProfileInfo.tracesUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="tracesPassword"
                      value={orgProfileInfo.tracesPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>

                  <Typography variant="h7" mt={1}>
                    TAN Login Credentials
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="tanUserName"
                      value={orgProfileInfo.tanUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="tanPassword"
                      value={orgProfileInfo.tanPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>
                  <Typography variant="h7" mt={1}>
                    Startup India Login Credentials
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="startupUserName"
                      value={orgProfileInfo.startupUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="startupPassword"
                      value={orgProfileInfo.startupPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>
                  <Typography variant="h7" mt={1}>
                    RBI Login Credentials
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="rbiUserName"
                      value={orgProfileInfo.rbiUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="rbiPassword"
                      value={orgProfileInfo.rbiPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>

                  <Typography variant="h7" mt={1}>
                    Professional Tax Login Credentials
                  </Typography>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="pfUserName"
                      value={orgProfileInfo.pfUserName}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Password"
                      name="pfPassword"
                      value={orgProfileInfo.pfPassword}
                      onChange={handleChange}
                      type="password"
                    />
                  </Stack>
                </>
              )}
            </Stack>
            {/* <Button>
                  Add Other Credentials
                </Button> */}
            <Stack>
              <Button onClick={handleSkip}>Skip</Button>
            </Stack>
          </Stack>
        )}
        {/*... buttons ...*/}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          {/* <LoadingButton fullWidth size="large" type="submit" variant="contained">
                Get Started
              </LoadingButton> */}
          {currentPage !== 1 && (
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="error"
              onClick={handleGoBack}
            >
              Go back
            </LoadingButton>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Next Step
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

const styles = {
  hidden: {
    display: 'none'
  },
  importLabel: {
    color: 'black'
  }
};
