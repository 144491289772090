import Constants from '../../utils/constants';
// import { base_url } from 'src/config';
// import axios from 'axios';
import { setToken, setCompanyData, setCompanyId } from '../../utils/common';
import HttpWrapper from 'src/utils/HttpWrapper';
import { trackPromise } from 'react-promise-tracker';

export const getStatus = () => (dispatch) => {
  trackPromise(
    HttpWrapper('GET', '/company/status/', false, {})
      .then((res, err) => {
        setCompanyId(res.data[0]['id']);
        dispatch({
          type: Constants.SET_COMPANY_ID,
          payload: res.data[0]['id']
        });

        if (res.data.length > 0) {
          dispatch({
            type: Constants.SET_NEW_COMPANY,
            payload: false
          });
        } else {
          dispatch({
            type: Constants.SET_NEW_COMPANY,
            payload: true
          });
        }

        setCompanyData(res.data);

        return dispatch({
          type: Constants.SET_COMPANT_DATA,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.SET_NEW_COMPANY,
          payload: true
        });
        return dispatch({
          type: Constants.SET_COMPANT_DATA,
          payload: []
        });
      })
  );
};

export const intiateLogin = (data) => (dispatch) => {
  dispatch({
    type: Constants.SET_USER_LOGIN_START
  });
  trackPromise(
    HttpWrapper('POST', '/auth/google/', false, data, true)
      .then((res, err) => {
        setToken(res.data.access_token);
        return dispatch({
          type: Constants.SET_USER_LOGIN_SUCCESS,
          payload: res.data
        });
      })
      .catch((err) => {
        return dispatch({
          type: Constants.SET_USER_LOGIN_FAILURE,
          payload: err
        });
      })
  );
};

export const intiateIdPasswordLogin = (data) => (dispatch) => {
  dispatch({
    type: Constants.SET_USER_LOGIN_START
  });

  trackPromise(
    HttpWrapper('POST', '/dj-rest-auth/login/', false, data, true)
      .then((res, err) => {
        setToken(res.data.access_token);
        return dispatch({
          type: Constants.SET_USER_LOGIN_SUCCESS,
          payload: res.data
        });
      })
      .catch((err) => {
        return dispatch({
          type: Constants.SET_USER_LOGIN_FAILURE,
          payload: err.response.data
        });
      })
  );
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: Constants.SET_USER_LOGOUT
  });
};
