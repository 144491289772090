import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import { useSelector } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, IconButton } from '@mui/material';
import {
  add_invoice,
  invoice_descriptions,
  invoice_manage,
  update_invoice
} from 'src/redux/actions/invoice_manage_action';
import moment from 'moment';
import { element } from 'prop-types';
import { ModeEdit } from '@mui/icons-material';

export default function Tax_View(props) {
  const { handleClickClose, row } = props;
  console.log('row', row);
  const dispatch = useDispatch();
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const all_services = useSelector((state) => state.invoice_reducers);
  const edit_invoice_Data = useSelector((state) => state.invoice_reducers.invoice_manage);

  console.log(all_services, 'all_services');
  //opts
  const requesters = ['Deepak Malhotra', 'Karan Malhotra'];
  const status_opts = ['Paid', 'Partially Paid', 'Unpaid'];
  const billing_opts = [
    'RM Financial Consultancy',
    'LetsGoLegal Advisory Private',
    'Accounts Matrix',
    'Suvidha Consultancy',
    'Karan Malhotra & Associates',
    'KDR Management Services'
  ];
  const bill_type_opts = ['Bill of Supply', 'Tax Invoice'];

  // useStates
  const [comp_name, setcomp_name] = useState(null);
  const [req_date, setreq_date] = useState(null);
  const [req_by, setreq_by] = useState(null);
  const [gst_no, setgst_no] = useState(null);
  const [bill_ent, setbill_ent] = useState(null);
  const [in_no, setin_no] = useState(null);
  const [in_date, setin_date] = useState(null);
  const [pay_stat, setpay_stat] = useState(null);
  const [pay_rcvd, setpay_rcvd] = useState(null);
  const [tds_ded, settds_ded] = useState(null);
  const [out_amt, setout_amt] = useState(null);
  const [bill_types, setbill_types] = useState(null);
  const [in_remarks, setin_remarks] = useState(null);
  const [list_company_id, setlist_company_id] = useState(null);
  const [services_list, setservices_list] = useState(null);
  const [api_data, setapi_data] = useState();
  console.log(api_data, 'refersh');

  const filter_by_invoice_id = edit_invoice_Data?.filter((item) => item.id === row)[0];
  console.log('edit_world', filter_by_invoice_id);
  const invoices = filter_by_invoice_id;
  const invoice_descriptions_list = invoices?.invoice_description
    ?.map((items) => items.description)
    .join(', ');

  const [formValues, setFormValues] = useState(invoices?.invoice_description);
  const [check, setcheck] = useState(null);
  const [check2, setcheck2] = useState(invoices?.invoice_amoint);
  console.log('inoices_Desc', invoices?.invoice_description);
  console.log(check2, 'check2');
  const [matched_company_id, setmatched_company_id] = useState(invoices?.company_id);

  console.log('formvalues_dsfsdfdsfdsf', invoices?.invoice_description);
  useEffect(() => {
    setcomp_name(invoices?.company_name);
    setreq_date(invoices?.requested_date);
    setreq_by(invoices?.request_by);
    setgst_no(invoices?.gst_number);
    setbill_ent(invoices?.billing_entity);
    setin_no(invoices?.invoice_number);
    setin_remarks(invoices?.remark);
    setin_date(invoices?.invoice_date);
    setbill_types(invoices?.bill_type);
    setpay_stat(invoices?.payment_status);
    setpay_rcvd(invoices?.payment_received);
    settds_ded(invoices?.tds_deducted);
    setout_amt(invoices?.outstanding);
    setcheck(invoices?.invoice_description.map((items) => items.description));
    setcheck2(invoices?.invoice_amoint);
    setFormValues(invoices?.invoice_description);
  }, [filter_by_invoice_id]);

  console.log(formValues, 'checker');

  let addFormFields = () => {
    if (
      formValues[formValues.length - 1].description !== '' &&
      formValues[formValues.length - 1].amount !== ''
    ) {
      setFormValues([...formValues, { description: '', amount: '' }]);
    }
  };

  let removeFormFields = (index) => {
    let newFormValues = [...formValues];
    const new_arr = [];
    newFormValues.forEach((ele, i) => {
      if (index !== i) {
        new_arr.push(ele);
      }
    });
    setFormValues(new_arr);
    let removed_sum = new_arr.reduce(function (acc, obj) {
      return acc + parseInt(obj.amount);
    }, 0);
    setout_standing(removed_sum);
  };

  let handleChange = (index, field_name, newValue) => {
    let newFormValues = [...formValues];
    newFormValues[index][field_name] = newValue;
    setFormValues(newFormValues);
  };

  const [formData, setFormData] = useState({
    company_name: '',
    requested_date: '',
    request_by: '',
    gst_number: '',
    billing_entity: '',
    bill_type: '',
    invoice_description: [],
    remark: '',
    invoice_amoint: 0,
    tds_deducted: 0,
    payment_received: 0
  });
  let name, valuee;
  const opts = all_companies?.all_companies?.map((option) => option.company_name);
  const handleFormDataChange = (e) => {
    console.log("formData['company_name']", formData);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(formData, 'formNOdata');
  };

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(invoice_descriptions());
    dispatch(invoice_manage());
  }, []);

  useEffect(() => {
    setservices_list(all_services.invoice_ser);
  }, [all_services]);

  useEffect(() => {
    setapi_data(edit_invoice_Data.invoice_manage);
  }, []);

  useEffect(() => {
    setlist_company_id(all_companies.all_companies);
  }, [all_companies]);
  const [matched_xyz, setmatched_xyz] = useState(formData);

   const [gst_amount,setgst_amont]=useState(((0.18)*( formValues.reduce(function (acc, obj) {
    return acc + parseInt(obj.amount);
  }, 0))))


  console.log(gst_amount,"gst_amount")



  const [out_standing, setout_standing] = useState(
    formValues.reduce(function (acc, obj) {
      return acc + parseInt(obj.amount);
    }, 0)
  );
  console.log(
    'reduce',
    formValues.reduce(function (acc, obj) {
      return acc + parseInt(obj.amount);
    }, 0),
    out_standing
  );
  const handleSumbit = () => {
    formData['company_id'] = matched_company_id;
    formData.gst_number=gst_no
    formData.tds_deducted=tds_ded
    formData.payment_received=pay_rcvd
    formData.requested_date = moment(in_date).format('YYYY-MM-DD');
    formData.invoice_description = formValues;
    formData.invoice_amoint = formValues.reduce(function (acc, obj) {
      return acc + parseInt(obj.amount);
    }, 0);
    {
      console.log('form_outstanding', out_standing);
    }
    formData.outstanding = out_standing - tds_ded - pay_rcvd;
    formData.remark = in_remarks;
    if(in_date){
      formData.invoice_date = moment(in_date).format('YYYY-MM-DD');
    }
    formData.requested_date=req_date
    dispatch(update_invoice(invoices.id, formData));
    handleClickClose();
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Autocomplete
              value={comp_name || ''}
              name="company_name"
              fullWidth
              freeSolo
              display="flex"
              onInputChange={(event, newValue) => {
                setcomp_name(newValue);
                {
                  formData.company_name = newValue;
                }
                {
                  list_company_id.filter((item) => {
                    if (item.company_name === newValue) {
                      setmatched_company_id(item.id);
                    }
                  });
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Company Name" />}
            />
          </Grid>
          <Grid item lg={6}>
            <Autocomplete
              value={req_by}
              name="request_by"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setreq_by(newValue);
                {
                  formData.request_by = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={requesters}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Request By" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <TextField
              value={gst_no}
              fullWidth
              name="gst_number"
              onChange={(e) => {
                setgst_no(e.target.value);
                {
                  formData.gst_number = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="GST Number"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <Autocomplete
              value={bill_ent}
              name="billing_entity"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setbill_ent(newValue);
                {
                  formData.billing_entity = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={billing_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Billing Entity" />}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <Autocomplete
              value={bill_types}
              fullWidth
              name="bill_type"
              display="flex"
              onInputChange={(event, newValue) => {
                setbill_types(newValue);
                {
                  formData.bill_type = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={bill_type_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Billing Type" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <Autocomplete
              value={pay_stat}
              name="payment_status"
              fullWidth
              display="flex"
              onInputChange={(event, newValue) => {
                setpay_stat(newValue);
                {
                  formData.payment_status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={status_opts}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Payment Status" />}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <TextField
              value={in_no}
              fullWidth
              name="invoice_number"
              onChange={(e) => {
                setin_no(e.target.value);
                {
                  formData.invoice_number = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Invoice Number"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Invoice Date"
                value={in_date}
                name="invoice_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setin_date(newValue);
                  {
                    formData.invoice_date = moment(newValue).format('YYYY-MM-DD');
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} md={3} xl={3}>
            <TextField
              value={tds_ded}
              fullWidth
              name="tds_deducted"
              type="number"
              onChange={(e) => {
                settds_ded(e.target.value);
                {
                  formData.tds_deducted = e.target.value;
                }

                {
                  console.log(
                    'tds_deduced',
                    formValues.reduce(function (acc, obj) {
                      return acc + parseInt(obj.amount);
                    }, 0),
                    pay_rcvd,
                    e.target.value
                  );
                }
                {
                  handleFormDataChange;
                }
              }}
              label="TDS Deducted"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} xl={3}>
            <TextField
              value={pay_rcvd}
              fullWidth
              type="number"
              name="payment_received"
              onChange={(e) => {
                setpay_rcvd(e.target.value);
                {
                  formData.payment_received = e.target.value;
                }

                {
                  console.log('payment_rcvd', check2, tds_ded, e.target.value);
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Payment Received"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} xl={3}>
            {console.log('out_standingxxxyyyy', out_standing, tds_ded, pay_rcvd)}

            <TextField
              value={gst_amount}
              fullWidth
              name="outstanding"
              onChange={(e) => {
                setout_amt(e.target.value);
                {
                  formData.outstanding = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              inputProps={{ readOnly: true }}
              label="GST"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} xl={3}>
            {console.log('out_standingxxxyyyy', out_standing, tds_ded, pay_rcvd)}

            <TextField
              value={(out_standing - tds_ded - pay_rcvd)+gst_amount}
              fullWidth
              name="outstanding"
              onChange={(e) => {
                setout_amt(e.target.value);
                {
                  formData.outstanding = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              inputProps={{ readOnly: true }}
              label="Outstanding"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              value={in_remarks}
              fullWidth
              name="remark"
              onChange={(e) => {
                setin_remarks(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {console.log(formValues, 'element_type_formValues')}
          {console.log(
            'form_values',
            Object.keys(formValues).map((key) => formValues[key])
          )}

          {formValues.map((element, index) => (
            <>
              <Grid item xs={6} md={6}>
                <Autocomplete
                  value={element.description}
                  name="description"
                  fullWidth
                  freeSolo
                  display="flex"
                  onInputChange={(_, newValue) => handleChange(index, 'description', newValue)}
                  id="controllable-states-demo"
                  options={services_list?.map((row) => row.service)}
                  sx={{ margin: 1 }}
                  renderInput={(params) => <TextField {...params} label="Invoice Description" />}
                />
              </Grid>
              <Grid item xs={2} md={4}>
                <TextField
                  value={element.amount}
                  fullWidth
                  name="amount"
                  type={'number'}
                  onChange={(e) => {
                    let newFormValues = [...formValues];
                    newFormValues[index][e.target.name] = e.target.value;
                    setFormValues(newFormValues);
                    {
                      console.log(tds_ded, pay_rcvd, 'defined_by_val');
                    }
                    setout_standing(
                      formValues.reduce(function (acc, obj) {
                        return acc + parseInt(obj.amount);
                      }, 0)
                    );
                    setgst_amont(
                      0.18*(formValues.reduce(function (acc, obj) {
                        return acc + parseInt(obj.amount);
                      }, 0))
                    )
                  }}
                  label="Invoice Amount"
                  variant="outlined"
                  sx={{ margin: 1 }}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <IconButton>
                  <Button type="button" onClick={() => addFormFields()}>
                    <i className="fa fa-plus" />
                  </Button>
                </IconButton>
                {index ? (
                  <IconButton>
                    <Button type="button" onClick={() => removeFormFields(index)}>
                      <i className="fa fa-times" area-hidden="true" />
                    </Button>
                  </IconButton>
                ) : (
                  ''
                )}
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={handleSumbit} variant="contained">
          Update Inovice
        </Button>
      </Box>
    </>
  );
}
