import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Label from 'src/components/Label';
import { dateFormat } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import Link from '@mui/material/Link';
import { base } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import { deletegstfilling, updategstfilling } from 'src/redux/actions/gstBook';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';

export const GSTR3B = (props) => {
  const { gstFilling,gstNos } = props;

  if (gstFilling != null && gstFilling.length > 0) {
    gstFilling.sort(function (a, b) {
      return new Date(a.due_data.due_date) - new Date(b.due_data.due_date);
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Frequency</TableCell>
            <TableCell align="center">Period</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Date of Filling</TableCell>
            <TableCell align="center">Delay Days</TableCell>
            <TableCell align="center">Paid Through Cash</TableCell>
            <TableCell align="center">Paid Through Credit</TableCell>
            <TableCell align="center">Interest</TableCell>
            <TableCell align="center">GSTR-3B</TableCell>
            <TableCell align="center" sx={{display: isStartupFino ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gstFilling &&
            gstFilling.map((row, i) => {
              console.log(row);
              return (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                  <TableCell align="center">{row.frequency}</TableCell>
                  <TableCell align="center">{row.month}</TableCell>
                  <TableCell align="center">
                    {row.due_data.due_date ? dateFormat(row.due_data.due_date) : 'NA'}
                  </TableCell>
                  <TableCell align="center">
                    {row.date_fill ? dateFormat(row.date_fill) : 'NA'}
                  </TableCell>
                  <TableCell align="center">
                    <Label color={row.due_data.delay > 0 ? 'error' : 'success'}>
                      {row.due_data.delay > 0 ? row.due_data.delay : 0}
                    </Label>
                  </TableCell>

                  <TableCell align="center">
                    {row.challan_copy != null && (
                      <Link href={row.challan_copy} target="_blank">
                        {row.paid_cash}
                      </Link>
                    )}
                    {row.challan_copy == null && row.paid_cash}
                  </TableCell>
                  <TableCell align="center">{row.paid_credit}</TableCell>
                  <TableCell align="center">{row.intrest}</TableCell>
                  <TableCell align="center">
                    <Link href={row.gst_copy} target="_blank">
                      GST Return
                    </Link>
                  </TableCell>
                  <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                    <Box textAlign="center" sx={{ display: 'flex' }}>
                      <Update row={row} gstNos={gstNos} />
                      <ConfirmProvider>
                        <Delete row={row} gstNos={gstNos} />
                      </ConfirmProvider>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              Total
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {gstFilling
                .map((item) => parseInt(item.paid_cash))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {gstFilling
                .map((item) => parseInt(item.paid_credit))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {gstFilling
                .map((item) => parseInt(item.intrest))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props,gstNos) => {
  const { row } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletegstfilling(row.id,companyId))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row,gstNos } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <GSTR3BUpdate row={row} gstNos={gstNos} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const GSTR3BUpdate = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row ,gstNos} = props;
  console.log("GST ABHBSJABFISBAIK", row);
  const [value, setValue] = useState(row.date_fill);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    frequency: '',
    paid_cash: '',
    paid_credit: '',
    intrest: '',
    date_fill: '',
    // challan_copy: '',
    // gst_copy: '',
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Apr-June',
    'July-Sep',
    'Oct-Dec',
    'Jan-Mar'
  ];
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  // const frequency = ['Monthly', 'Quaterly', 'Half Yearly', 'Yearly'];
  const [freq, setFreq] = useState(row.frequency);
  const [finace, setFinace] = useState(row.finance_year);
  const [mon, setMon] = useState(row.month);
  const [pcash, setPcash] = useState(row.paid_cash);
  const [pcred, setPcred] = useState(row.paid_credit);
  const [intrest, setIntrest] = useState(row.intrest);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    formData[`${name}`] = valuee
    // setFormData({ ...formData, [name]: valuee });
    // console.log(name);
    // console.log(valuee);
    // console.log('HEllo WORLD');
  };
  const dis = (e) => {
    console.log('form data', formData);
    formData['complianceID'] = row.complianceID_id;
    formData['companyId'] = companyId
    formData['gst_num'] = gstNos
    // formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
    // if(formData.gst_copy == '')
    // {
    //   formData.gst_copy = row.gst_copy;
    // }
    // if(formData.challan_copy == '')
    // {
    //   formData.challan_copy = row.challan_copy;
    // }

    var bodyFormData = new FormData();
    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }

    dispatch(updategstfilling(row.id, bodyFormData,companyId))
  };

  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData)
  }
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
          <Select sx={{ margin: 1 }}  name="frequency" onChange ={handleFormDataChange} value ={formData.frequency} >
          <MenuItem value={"Monthly"}>Monthly</MenuItem>
          <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
          <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
          <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select> */}
        <Autocomplete
          value={freq}
          name="frequency"
          onChange={(event, newValue) => {
            setFreq(newValue);
            {
              formData.frequency = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={["Monthly", "Quaterly"]}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Frequency" />}
        />
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" />}
        />
        {console.log(formData)}
        {/* <InputLabel id="demo-simple-select-label" >Finance year</InputLabel>
          <Select onChange ={handleFormDataChange} name="finance_year" value ={formData.finance_year}  sx={{ margin: 1 }}  >
            {
              fyear.map((element)=>(
                <MenuItem name="finance_year"  sx={{ overflow :"scroll" }} value={element}>{element}</MenuItem>
              ))
            }
          </Select> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date fill"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <h4>GST Copy</h4>
        <Box textAlign="right" >
          <a href={row.gst_copy} target="_blank">
            GST Copy
          </a>
        </Box>
        <TextField name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <TextField
          value={pcash}
          name="paid_cash"
          onChange={(e) => {
            setPcash(e.target.value);
            {
              formData.paid_cash = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Paid cash"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          value={pcred}
          name="paid_credit"
          onChange={(e) => {
            setPcred(e.target.value);
            {
              formData.paid_credit = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Paid credit"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          name="intrest"
          onChange={(e) => {
            setIntrest(e.target.value);
            {
              formData.intrest = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          value={intrest}
          label="Interest Paid"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <h4>Challan Copy</h4>
        <Box textAlign="right" >
          <a href={row.challan_copy} target="_blank">
            Challan Copy
          </a>
        </Box>
        <TextField name='challan_copy' onChange={handleSelectFile} type="file" sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
