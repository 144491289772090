import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  Typography
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from 'src/utils/common';
import { getAllDueDates } from 'src/redux/actions/dueDates';
import { useSearchParams } from 'react-router-dom';
import { getStatus } from '../sections/@dashboard/app/dueDatesTables/AllDueDates';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
export const DueDatesMobile = ({ dueDates, filter }) => {
  const [dueDateData, setDueDateData] = useState([]);
  useEffect(() => {
    console.log('dueDates', dueDates);
    if (filter && dueDates != undefined) {
      const filterDueDate = dueDates?.filter((row) => {
        return getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 0;
      });
      setDueDateData(filterDueDate);
    } else {
      setDueDateData(dueDates);
    }
  }, [filter, dueDates]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          '& td': {
            fontSize: '0.5rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Compliance Name</TableCell>
            <TableCell>Period</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dueDateData?.length > 0 &&
            dueDateData.map((row) => {
              return <Row row={row} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.compliance_name}</TableCell>
        <TableCell>{row.period}</TableCell>
        <TableCell>{dateFormat(row.current_due_date)}</TableCell>
        <TableCell>
          {row.due_date_applicable === false && (
            <Label variant="ghost" color="error">
              Not Applicable
            </Label>
          )}
          {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 1 &&
            row.due_date_applicable === true && (
              <Label variant="ghost" color="success">
                Ontime
              </Label>
            )}
          {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 2 &&
            row.due_date_applicable === true && (
              <Label variant="ghost" color="secondary">
                Upcoming
              </Label>
            )}
          {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 0 &&
            row.due_date_applicable === true && (
              <Label variant="ghost" color="error">
                Overdue
              </Label>
            )}
          {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 4 &&
            row.due_date_applicable === true && (
              <Label variant="ghost" color="default">
                Delayed
              </Label>
            )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Box sx={{ margin: 1 }}> */}
            <Table
              size="small"
              sx={{
                '& th': {
                  background: 'white',
                  color: 'black'
                }
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Law</TableCell>
                  <TableCell>Frequency</TableCell>
                  {/* <TableCell>Extended Date</TableCell> */}
                  <TableCell>Filling Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{row.book_name !== 'NA' ? row.book_name : row.category}</TableCell>
                  <TableCell>{row.frequency}</TableCell>
                  {/* <TableCell>
                    {row.current_due_date === null ? '' : dateFormat(row.current_due_date)}
                  </TableCell> */}
                  <TableCell>
                    {row.date_of_filling === null ? '' : dateFormat(row.date_of_filling)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* </Box> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
