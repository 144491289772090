import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getToken } from '../../utils/common';
import { istalenteye, isStartupFino } from 'src/config';
import axios from 'axios';
import {
  Grid,
  Box,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Button,
  Link
} from '@mui/material';
import { mapFinalcialYearToYear } from 'src/utils/common';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function ReportPage() {
  const orgState = useSelector((state) => state.orgProfileReducer);
  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [doc, setDoc] = useState('PF');
  const years = ['2021-22', '2022-23', '2023-24'];
  const doctType = !istalenteye? ['TDS', 'PF', 'ESI', 'GST', 'INCOME TAX']:['PF', 'ESI'];
  const link = [
    "https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/Apr%20to%20Sep'21%20Challans.rar?alt=media&token=7411ab5d-25d3-4a0b-9a11-f86301f625d6",
    "https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/EPF%20Apr'21%20to%20Feb'22.rar?alt=media&token=e627241a-edea-4edc-bc65-3b1f5d99667f"
  ];
  const [downloadLink, setDownloadLink] = useState(link[0]);

  const [detail, setDetail] = useState('TDS Challan');
  const tds = [
    'TDS Challan',
    'TDS Return Acknowledgment',
    'TDS Return Statement',
    'Oltas Confirmation Receipt'
  ];

  const roc = ['Balance Sheet', 'P&L'];
  const gst = ['GSTR-3B', 'GSTR-1', 'GSTR-9', 'GSTR-9C', 'GST Payment Challan'];
  const it = [
    'ITR Acknowledgment',
    'ITR Form',
    'Tax Audit Report',
    'Transfer Pricing Report',
    'Income Tax 26AS'
  ];

  const pf = ['PF Payment Receipt', 'ECR Statement', 'ECR Challan'];
  const esic = ['ESIC Payment Receipt', 'ESIC Challan Statement'];
  const getDetails = (doc) => {
    switch (doc) {
      case 'TDS':
        return tds;
      case 'PF':
        return pf;
      case 'ESI':
        return esic;
      case 'GST':
        return gst;
      case 'INCOME TAX':
        return it;
      case 'ROC':
        return roc;
      default:
        return tds;
    }
  };
  const handleDocChange = (event) => {
    setDoc(event.target.value);
  };

  useEffect(() => {
    setDetail(getDetails(doc)[0]);
  }, [doc]);

  const handleDetailChange = (event) => {
    setDetail(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleClick = async () => {
    const baseUrl = `
https://api.startupfino.in/compliance/downloads/?fin_year=${year}&book_name=${doc}&compliance_name=${detail}&company_id=${localStorage.getItem(
      'company_id'
    )}`;
    axios({
      method: 'GET',
      url: baseUrl,
      headers:{
        'Authorization': getToken()
      }
    })
      .then((res) => {
        window.open(baseUrl, '_blank');
      })
      .catch((err) => {
        toast.error('Data not found');
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl sx={{ mr: 1 }}>
            <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={year}
              onChange={handleYearChange}
              input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
              )}
              MenuProps={MenuProps}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="From Month"
                            value={fromDate}
                            onChange={(newValue) => {
                                setFromDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="To Month"
                            value={toDate}
                            onChange={(newValue) => {
                                setToDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider> */}
          <FormControl sx={{ ml: 1 }}>
            <InputLabel id="demo-multiple-chip-label">Book Name</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={doc}
              onChange={handleDocChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {doctType.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ ml: 1 }}>
            {/* <InputLabel id="demo-multiple-chip-label"></InputLabel> */}
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={detail}
              onChange={handleDetailChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {getDetails(doc).map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button variant="outlined" onClick={handleClick}>
              {/* <Link
                variant="a"
                target="_blank"
                href={`
https://api.startupfino.in/compliance/downloads/?fin_year=${year}&book_name=${doc}&compliance_name=${detail}&company_id=${localStorage.getItem(
                  'company_id'
                )}`}
                underline="none"
              > */}
              Download
              {/* </Link> */}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
