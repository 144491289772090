import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';

export const allnotify_get=()=>(dispatch)=>{
    HttpWrapper('GET', `/company/notifications-list/`)
    .then((res) => {
      dispatch({
        type: constants.SET_ALL_NOTIFICATIONS,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_ALL_NOTIFICATIONS,
        payload: []
      });
    });
  
  }
  export const updateNoti = (data,id,message) => (dispatch) => {
    console.log(data);
    HttpWrapper('PATCH', `/company/notifications/notification/${id}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success(`${message}`);
        dispatch(allnotify_get())
      })
      .catch((err) => {
        console.log(err);
      });
  };
