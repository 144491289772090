import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Label from 'src/components/Label';
import { dateFormat } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCompanyId } from 'src/utils/common';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import { deletegstdemand, updategstDemand, getGSTDemand } from 'src/redux/actions/gstBook';
import moment from 'moment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino } from 'src/config';


export const Demands = (props) => {
  const { gstDemand,gstNos } = props;

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Notice/Demand Order Id</TableCell>
            <TableCell align="center">Issued by</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Notice/Order Description</TableCell>
            <TableCell align="center">Notice Issuance Date</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Amount of Demand</TableCell>
            <TableCell align="center">Notice Copy</TableCell>
            <TableCell align="center" sx={{display: isStartupFino ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gstDemand &&
            gstDemand
              .sort(function (a, b) {
                return new Date(b.notice_issuance_date) - new Date(a.notice_issuance_date);
              })
              .map((row, i) => {
                return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                    <TableCell align="center">{row.orderid}</TableCell>
                    <TableCell align="center">{row.issued_by}</TableCell>
                    <TableCell align="center">{row.notice_type}</TableCell>
                    <TableCell align="center">{row.notice_descriptor}</TableCell>
                    <TableCell align="center">
                      {row.notice_issuance_date ? dateFormat(row.notice_issuance_date) : 'NA'}
                    </TableCell>
                    <TableCell align="center">
                      {row.due_dates ? dateFormat(row.due_dates) : 'NA'}
                    </TableCell>
                    <TableCell align="center">{row.demand_amount}</TableCell>
                    <TableCell align="center">
                      <Link variant="a" href={row.notice_copy} target="_blank">
                        Notice Copy
                      </Link>
                    </TableCell>
                    <TableCell sx={{display: isStartupFino ? 'none': ''}}>
                      <Box textAlign="center" sx={{ display: 'flex' }}>
                        <Update row={row}  gstNos={gstNos} />
                        <ConfirmProvider>
                          <Delete row={row}  gstNos={gstNos} />
                        </ConfirmProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          <TableRow>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              Total
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {gstDemand
                .map((item) => parseInt(item.demand_amount))
                .reduce((prev, next) => prev + next, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props) => {
  const { row,gstNos } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletegstdemand(row.id,companyId))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row,gstNos } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <DemandUpdate row={row}  gstNos={gstNos} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const DemandUpdate = (props) => {
  const { row,gstNos } = props;
  const [value, setValue] = useState(row.notice_issuance_date);
  const [dueval, setDueval] = useState(row.due_date);
  const [payval, setPayval] = useState(row.payment_date);
  const [formData, setFormData] = useState({
    finance_year: '',
    orderid: '',
    issued_by: '',
    notice_type: '',
    notice_descriptor: '',
    notice_issuance_date: '',
    demand_amount: '',
    payment_date: '',
    due_date: ''
});
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState(row.finance_year);
  const [order, setOrder] = useState(row.orderid);
  const [issue, setIssue] = useState(row.issued_by);
  const [noticetype, setNoticetype] = useState(row.notice_type);
  const [noticedes, setNoticedes] = useState(row.notice_descriptor);
  const [amount, setAmount] = useState(row.demand_amount);
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    formData[`${name}`] = valuee
  }
  const dis = (e) => {
    const bodyFormData = new FormData();

    formData['companyId'] = companyId
    formData['gst_num'] = gstNos

    for (let key in formData) {
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    dispatch(updategstDemand(row.id, bodyFormData,companyId));
  };

  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <TextField
          value={order}
          required
          name="orderid"
          onChange={(e) => {
            setOrder(e.target.value);
            {
              formData.orderid = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Order ID"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          value={issue}
          required
          name="issued_by"
          onChange={(e) => {
            setIssue(e.target.value);
            {
              formData.issued_by = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Issued By"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          value={noticetype}
          required
          name="notice_type"
          onChange={(e) => {
            setNoticetype(e.target.value);
            {
              formData.notice_type = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Notice Type"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          value={noticedes}
          required
          name="notice_descriptor"
          onChange={(e) => {
            setNoticedes(e.target.value);
            {
              formData.notice_descriptor = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Notice Description"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Notice Insurance Date"
            value={value}
            name="notice_issuance_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.notice_issuance_date = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Due Date"
            value={dueval}
            name="due_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDueval(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.due_date = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
          value={amount}
          required
          name="demand_amount"
          onChange={(e) => {
            setAmount(e.target.value);
            {
              formData.demand_amount = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Amount of Demand"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date"
            value={payval}
            name="payment_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setPayval(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                formData.payment_date = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <h4>Notice Copy</h4>
        <Box textAlign="right" >
          <a href={row.notice_copy} target="_blank">
            Notice Copy
          </a>
        </Box>
        <TextField required name='notice_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};