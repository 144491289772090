import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { trackPromise } from 'react-promise-tracker';

export const fetchDashboardData = (companyId) => (dispatch) => {
  // companyId = '6c743b53-3e95-4d15-9c09-5539c4026c53';
  trackPromise(
  HttpWrapper('GET', `/company/dashboard/${companyId}/`, false, {})
    .then((res) => {
      if (res.data['data'] == undefined || res.data['data']['esi_interest'] == undefined) {
        dispatch({
          type: Constants.SET_DASHBOARD_DATA,
          payload: null
        });
      }
      dispatch({
        type: Constants.SET_DASHBOARD_DATA,
        payload: res.data['data']
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_DASHBOARD_ERROR,
        payload: error.message
      });
    }))
};


export const fetchDashboardScore = (companyId) => (dispatch) => {
  // companyId = '6c743b53-3e95-4d15-9c09-5539c4026c53';
  trackPromise(
  HttpWrapper('GET', `/compliance/dashboard/${companyId}`, false, {})
    .then((res) => {
      console.log('score data', res.data['data'])
      dispatch({
        type: Constants.SET_DASHBOARD_SCORE,
        payload: res.data['data']
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_DASHBOARD_ERROR,
        payload: error.message
      });
    }))
};