import React from 'react';
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import { addGst } from 'src/redux/actions/gstBook';
import { PropaneSharp } from '@mui/icons-material';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import { addesi, addesidemand } from 'src/redux/actions/esiBook';
import { getAllComplaince } from 'src/redux/actions/allcomplaince';

export const Other_Book_Fields = () => {
  const [value, setValue] = useState(null);
  const [content, setContent] = useState();
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  useEffect(() => {
    dispatch(getAllComplaince());
  }, []);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  console.log(content, 'content');
  const comp_name = [...new Set(content?.map((item) => item.complianceName))];
   console.log(comp_name,'comp_name')

  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    esi_type: '',
    esi_paid_amt: '',
    challan_num: '',
    payment_dates: '',
    receipt: '',
    contribution_history: ''
  });
  const months = ['April', 'May', 'June'];
  const states = [
    'Andaman and Nicobar',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chattisgarh',
    'Dadra and Nagar Haveli & Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himanchal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshyadeep',
    'Madhya Pradesh',
    'Maharahtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Oddisaa',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];
  const fyear = ['2021-22', '2022-23', '2023-24'];
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [esiType, setEsiType] = useState();
  const [esiamount, setEsiamount] = useState();
  const [challanno, setChallanno] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const esi_no = searchParams.get('esi_no');

  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData);
  };
  const dis = (e) => {
    console.log('form data', formData);
    formData['complianceID'] = '22fb1e74-4170-44a9-a48e-c13ccf88e732';
    formData['companyId'] = companyId;
    formData['esi_num'] = esi_no;
    formData.payment_dates = moment(formData.payment_dates).format('YYYY-MM-DD');
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    console.log('form data', formData);
    dispatch(addesi(bodyFormData));
  };

  return (
    <Box
      container
      m={1}
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      sx={{ margin: 1, overflow: 'scroll' }}
    >
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={states}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="State" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Month" required />}
        />
        <Autocomplete
          value={esiType}
          name="esi_type"
          onChange={(event, newValue) => {
            setEsiType(newValue);
            {
              formData.esi_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={['Challan', 'Return']}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Type" required />}
        />

        <Autocomplete
          value={esiType}
          name="esi_type"
          onChange={(event, newValue) => {
            setEsiType(newValue);
            {
              formData.esi_type = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={comp_name}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Compliance Name" required />}
        />
        <TextField
          name="challan_num"
          required
          onChange={(e) => {
            setChallanno(e.target.value);
            {
              formData.challan_num = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          value={challanno}
          label="Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Due Date "
            value={value}
            name="payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                formData.payment_dates = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        <h4>Attachment</h4>
        <TextField
          required
          name="receipt"
          onChange={handleSelectFile}
          type="file"
          hidden
          sx={{ margin: 1 }}
        />
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
