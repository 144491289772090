import Constants from 'src/utils/constants';

const intialState = {
  challans: [],
  penalties: [],
  error: null,
  dueDates: []
};

export const esiReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ESI_CHALLANS:
      return { ...state, challans: action.payload };
    case Constants.SET_ESI_PENALTIES:
      return { ...state, penalties: action.payload };
    case Constants.SET_ESI_ERROR:
      return { ...state, error: action.payload };
    case Constants.SET_ESI_DUE_DATES:
      return { ...state, dueDates: action.payload };
    default:
      return state;
  }
};
