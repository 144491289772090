import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchAuditorsReportInfo } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
export default function AuditorsReport() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .AuditorsDetailReportAndCaro.AuditorsReport
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">Element Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((row, index) => {
            if (row === 'Currency' || row === 'SectionHeader') {
              return <></>;
            }
            if (row.YearOfReport != null) {
              return (
                <>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <b>Year of Report</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>{row.YearOfReport}</b>
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Auditors Report has been Qualified
                    </TableCell>
                    <TableCell align="center">
                      {row.WhetherAuditorsReportHasBeenQualifiedOrReservationOrAdverseRemarkOrDisclaimer ==
                      true
                        ? 'YES'
                        : 'NO'}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Details of such remark
                    </TableCell>
                    <TableCell align="center">{row.DetailsOfSuchRemark}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Directors comments on such remark
                    </TableCell>
                    <TableCell align="center">{row.DirectorsCommentsOnSuchRemark}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Section Header
                    </TableCell>
                    <TableCell align="center">{data['SectionHeader']}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Currency
                    </TableCell>
                    <TableCell align="center">{data['Currency']}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Financial Year
                    </TableCell>
                    <TableCell align="center">{row}</TableCell>
                  </TableRow>
                  <br />
                  <br />
                </>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
