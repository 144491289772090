import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadLink from 'react-download-link';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function PrivatePlacement() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_1__COMPLETERECORDOFPRIVATEPL
            </TableCell>
            <TableCell align="center">17-09-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter</TableCell>
            <TableCell align="center"> 0.40</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC23.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC23">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">17-09-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center">0.73</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC24.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC24">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">14-09-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 0.41</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC25.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC25">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">21-06-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 0.71</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC26.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC26">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">08-04-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center">0.12</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC27.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC27">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">26-03-2021 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center">0.19</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC28.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC28">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">12-06-2020</TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 1.08</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC29.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC29">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">06-03-2020 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 0.77</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC30.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC30">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">27-02-2020 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 3.25</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC31.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC31">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">24-02-2020 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 1.56</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC32.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC32">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Complete record of private placement offers and acceptances in Form
              PAS5_COMPLETERECORDOFPRIVATEPLACE
            </TableCell>
            <TableCell align="center">27-01-2020 </TableCell>
            <TableCell align="center">Private Placement Offer Letter </TableCell>
            <TableCell align="center"> 1.48</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC33.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC33">
                Download
              </a>
            </TableCell>
          </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
