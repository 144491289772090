// material
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// component
import Iconify from '../../../../components/Iconify';

import { merge } from 'lodash';
import { BaseOptionChart } from '../../../../components/charts';
import { calOutstandingSum } from '../MainPageComponents/util';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
  //color: theme.palette.primary.darker,
  //backgroundColor: theme.palette.primary.darker
}));

export default function TotalExpense({ data, year }) {
  return (
    <>
      <a href="#totalOutstanding" style={{ textDecoration: 'none', color: 'white' }}>
        <RootStyle style={{ backgroundColor: '#8ecae6', color: 'white' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <Typography>Total Outstanding Penalties</Typography>
              <Typography variant="h5">₹ {Math.trunc(calOutstandingSum(data, year))}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Iconify icon="charm:book" width={25} height={25} style={{ color: '#2c7da0' }} />
            </Grid>
          </Grid>
        </RootStyle>
      </a>
    </>
  );
}
