import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchDebtorsInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function Debtors() {
  const rocState = useSelector((state) => state.rocReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDebtorsInfo());
  }, []);
  if ((rocState.debtorsInfo = !null)) {
    return <></>;
  }
  const data = rocState.debtorsInfo[0];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">2017 - 2018</TableCell>
            <TableCell align="center">2018 - 2019</TableCell>
            <TableCell align="center"> 2019 - 2020</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Due Exceeding 6 Months
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center"></TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Net Debtors due exceeding 6 Months
            </TableCell>
            <TableCell align="center">1,679.46</TableCell>
            <TableCell align="center">978.14</TableCell>

            <TableCell align="center"> 123.59</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Due upto 6 Months
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center"></TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Net Debtors due upto 6 Months
            </TableCell>
            <TableCell align="center"> 11,987.82</TableCell>
            <TableCell align="center">13,817.82 </TableCell>

            <TableCell align="center">14,016.90</TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Total Debtors
            </TableCell>
            <TableCell align="center">13,667.28 </TableCell>
            <TableCell align="center">14,795.95</TableCell>

            <TableCell align="center">14,140.50</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
