import React, { useEffect, useState } from 'react';
import { ComplianceScore } from '../../app/ComplianceScore.js';
import { ComplianceScoreSmall } from '../../app/ComplianceScoreSmall.js';
import { Card, CardHeader, Container, Grid, Typography } from '@mui/material';
import BasicChart from '../BasicChart.js';
import BasicChartSmall from '../BasicChartSmall.js';
import { isStartupFino, istalenteye } from 'src/config';

export const AllComplianceScore = (props) => {
  const { dashBoardScore, year } = props;
  let score_data = dashBoardScore?.[year];
  console.log('dashBoardScore', dashBoardScore?.[year], year);
  const overallcount =
    score_data !== null
      ? score_data?.pf_data.count +
        score_data?.incometax_data.count +
        score_data?.gst_filling.count +
        score_data?.tds_paid.count +
        score_data?.esi_challan.count +
        score_data?.roc_book.count
      : 0;

  const tdscount = score_data !== null ? score_data?.tds_paid.count : 0;
  const tdsfilled = score_data !== null ? score_data?.tds_paid.ontime : 0;
  const itcount = score_data !== null ? score_data?.incometax_data.count : 0;
  const itfilled = score_data !== null ? score_data?.incometax_data.ontime : 0;
  const pfcount = score_data !== null ? score_data?.pf_data.count : 0;
  const pffilled = score_data !== null ? score_data?.pf_data.ontime : 0;
  const esicount = score_data !== null ? score_data?.esi_challan.count : 0;
  const esifilled = score_data !== null ? score_data?.esi_challan.ontime : 0;

  const gstcount = score_data !== null ? score_data?.gst_filling.count : 0;
  const gstfilled = score_data !== null ? score_data?.gst_filling.ontime : 0;

  const tds_paid_score =
    score_data?.tds_paid?.count == 0
      ? 100
      : parseInt((score_data?.tds_paid?.ontime / score_data?.tds_paid?.count) * 100) || 0;

  console.log('tds_paid_score', score_data);

  const incometax_score =
    score_data?.incometax_data?.count == 0
      ? 100
      : parseInt((score_data?.incometax_data?.ontime / score_data?.incometax_data?.count) * 100) ||
        0;

  const pf_data_score =
    score_data?.pf_data?.count == 0
      ? 100
      : parseInt((score_data?.pf_data?.ontime / score_data?.pf_data?.count) * 100) || 0;

  const esi_challan_score =
    score_data?.esi_challan?.count == 0
      ? 100
      : parseInt((score_data?.esi_challan?.ontime / score_data?.esi_challan?.count) * 100) || 0;

  const roc_book_score =
    score_data?.roc_book?.count == 0
      ? 100
      : parseInt((score_data?.roc_book?.ontime / score_data?.roc_book?.count) * 100) || 0;

  const gst_filling_score =
    score_data?.gst_filling?.count == 0
      ? 100
      : parseInt((score_data?.gst_filling?.ontime / score_data?.gst_filling?.count) * 100) || 0;
  // console.log(
  //   'socre is',
  //   parseInt((score_data?.tds_paid?.ontime / score_data?.tds_paid?.count) * 100)
  // );
  return (
    <Card>
      {!istalenteye && (
        <Grid container>
          <Grid
            item
            md={5}
            container
            mt={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <BasicChart
              title="Total"
              score={
                score_data?.total?.count == 0
                  ? 100
                  : parseInt((score_data?.total?.ontime / score_data?.total?.count) * 100) || 0
              }
            />
            <Typography textAlign="center" mt={-2}>
              {score_data?.total?.ontime}/{score_data?.total?.count}
              <br />
              Compliances cleared
            </Typography>
          </Grid>

          <Grid item md={7} container>
            {!istalenteye && (
              <Grid item xs={12} sm={6} md={4}>
                <BasicChartSmall title="TDS" score={tds_paid_score} />
                <Typography textAlign="center" mt={-2}>
                  {score_data?.tds_paid?.ontime} /{score_data?.tds_paid?.count}
                  <br />
                  {/* Compliances cleared */}
                </Typography>
              </Grid>
            )}
            {!istalenteye && (
              <Grid item xs={12} sm={6} md={4}>
                <BasicChartSmall title={`IT`} score={incometax_score} />
                <Typography textAlign="center" mt={-2}>
                  {score_data?.incometax_data?.ontime} / {score_data?.incometax_data?.count}
                  <br />
                  {/* Compliances cleared */}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4}>
              <BasicChartSmall title="PF" score={pf_data_score} />
              <Typography textAlign="center" mt={-2}>
                {score_data?.pf_data?.ontime}/ {score_data?.pf_data?.count}
                <br />
                {/* Compliances cleared */}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BasicChartSmall title="ESI" score={esi_challan_score} />
              <Typography textAlign="center" mt={-2}>
                {score_data?.esi_challan?.ontime} / {score_data?.esi_challan?.count}
                <br />
                {/* Compliances cleared */}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}> */}
            {/* <BasicChartSmall title="ROC" score={roc_book_score} />
            <Typography textAlign="center" mt={-2}>
              {rocfilled}/{roccount}
              <br />
            
            </Typography> */}
            {/* </Grid> */}
            {!istalenteye && (
              <Grid item xs={12} sm={6} md={4}>
                <BasicChartSmall title="GST" score={gst_filling_score} />
                <Typography textAlign="center" mt={-2}>
                  {score_data?.gst_filling?.ontime}/ {score_data?.gst_filling?.count}
                  <br />
                  {/* Compliances cleared */}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {istalenteye && (
        <Grid container>
          <Grid
            item
            md={5}
            container
            mt={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <BasicChart
              title="PF"
              score={pf_data_score}
            />
            <Typography textAlign="center" mt={-2}>
            {score_data?.pf_data?.ontime}/ {score_data?.pf_data?.count}
                          <br />
              {/* Compliances cleared */}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            container
            mt={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <BasicChart
              title="ESI"
              score={esi_challan_score}
            />
            <Typography textAlign="center" mt={-2}>
            {score_data?.esi_challan?.ontime} / {score_data?.esi_challan?.count}
              <br />
              {/* Compliances cleared */}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
