import React from 'react';
import { Card, CardHeader } from '@mui/material';
import DirectorSingle from './DIrectorSingle';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export const DirectorDetailed = () => {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      let tmp = [];
      tmp = tmp.concat(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .DirectorSignatoryMasterSummary.DirectorCurrentMasterSummary.Director
      );
      tmp = tmp.concat(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .DirectorSignatoryMasterSummary.DirectorPastMasterSummary.Director
      );
      console.log(tmp);
      setdata(tmp);
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <>
      {data.map((director, index) => {
        if (director.DirectorName != null && director.DirectorName != '') {
          return (
            <Card>
              <CardHeader
                style={{ backgroundColor: 'hsl(149,100%,33%)', color: 'white' }}
                title={`15.${index} ${director.DirectorName} - ( DIN : ${director.DirectorDin})`}
              />
              <DirectorSingle data={director} />
            </Card>
          );
        }
      })}
    </>
  );
};
