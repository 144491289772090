import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
export const setCompanyId = (id) => {
  return {
    type: Constants.SET_COMPANY_ID,
    payload: id
  };
};

export const createCompany = (data) => (dispatch) => {
  HttpWrapper('POST', '/company/create-company/', false, data)
    .then((res, err) => {
      console.log('compang_data', res.data);
      dispatch({
        type: Constants.SET_COMPANT_DATA,
        payload: [res.data]
      });
      return dispatch({
        type: Constants.SET_COMPANY_ID,
        payload: res.data.id
      });
    })
    .catch((err) => {
      toast.error('Company with this name already exists');
      return dispatch({
        type: Constants.SET_USER_LOGIN_FAILURE,
        payload: err
      });
    });
};
