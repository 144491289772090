import Constants from '../../utils/constants';
const intialState = {
  challans: [],
  demands: null,
  dueDates: []
};

export const pfReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_PF_CHALLANS:
      return { ...state, challans: action.payload };
    case Constants.SET_PF_DEMANDS:
      return { ...state, demands: action.payload };
    case Constants.SET_PF_DUE_DATES:
      return { ...state, dueDates: action.payload };
    default:
      return state;
  }
};
