export const base_url = 'https://api.startupfino.in';

export let HEADERS_DEFAULT = {
  'Content-Type': 'application/json'
};

export let HEADERS_FORM_DATA = {
  'Content-Type': 'multipart/form-data'
};

export const google_clientId = '286872649640-re8ai9sjefgehbiu7q5lfp64dunb95l9.apps.googleusercontent.com';

const startupfinoApp = ['app.startupfino.com','localhost:300'];
const talenteye = ['localhost:3005', 'dev-compliance-book.netlify.app', 'app.peoplefino.com',];

export const isStartupFino = startupfinoApp.includes(window.location.host);
export const istalenteye = talenteye.includes(window.location.host);

export let appName;

if(istalenteye){
  appName = 'PeopleFino';
}else{
  appName = 'StartupFino';
}