import Constants from 'src/utils/constants';
const intialState = {
  companies_doc: []
};
export const allCompaniesdocsreducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_COMPANY_DOCS:
      return { ...state, companies_doc: action.payload };
    default:
      return state;
  }
};