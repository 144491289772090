import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, IconButton } from '@mui/material';
import { invoice_manage } from 'src/redux/actions/invoice_manage_action';
import { useDispatch,useSelector } from 'react-redux';
import { invoice_subs_get } from 'src/redux/actions/invoice_subscription_action';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import moment from 'moment';
import Subscription_Add from './Add Subscription/subscription_add';
import Subscription_Edit from './Action/subscription_edit';
import Subscription_delete from './Action/subscription_delete';
import { Grid } from '@mui/material';
import { Typography, Chip } from '@mui/material';

export default function Subscription_table() {
  const dispatch = useDispatch();
  const invoice_Data = useSelector((state) => state.invoice_reducers);
  const subscr_Data = useSelector((state) => state.invoice_reducers.invoice_subscription);
  console.log('invoice_Data', invoice_Data);

  const [datas, setDatas] = useState([]);
  const [api_data, setapi_data] = useState();
  const [add, setAdd] = useState(false);

  const handleClickOpen = () => {
    setAdd(true);
  };
  const handleClickClose = () => {
    setAdd(false);
  };
  useEffect(() => {
    dispatch(invoice_manage());
    dispatch(invoice_subs_get());
  }, []);

  useEffect(() => {
    setapi_data(subscr_Data);
  }, [subscr_Data]);

  let active_company_count = api_data?.filter((item) => item.status == 'Active');
  console.log('active_company_count', active_company_count);

  let Inactive_company_count = api_data?.filter((item) => item.status == 'Inactive');
  console.log('active_company_count', active_company_count);
  let Hold_company_count = api_data?.filter((item) => item.status == 'Hold');
  console.log('active_company_count', active_company_count);


  let total_amount = api_data?.map((item, index) => item.amount);
  let sum = 0;
  let active_sum = 0;
  let inactive_sum = 0;
  let hold_sum=0;
  total_amount?.forEach((value) => {
    sum += Number(value);
  });
  active_company_count?.map((item)=>item.amount).forEach((value)=>active_sum+=Number(value))
  Inactive_company_count?.map((item)=>item.amount).forEach((value)=>inactive_sum+=Number(value))
  Hold_company_count?.map((item)=>item.amount).forEach((value)=>hold_sum+=Number(value))
  console.log('api value', api_data);

  const columns = [
    {
      name: 'act',
      label: `Action`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box textAlign="center" display="flex">
                <Update row={data} />
                <Delete row={data} />
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'date_onbord',
      label: `Date of Onbourding`,
      align: 'center',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'subscription_date',
      label: `Subscription Start from`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'amt',
      label: `Amount`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: `Status of Company`,
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  useEffect(() => {

    const map_data = api_data?.map((row, index) => {
      console.log('Rows', row);
      return {
        act: row.id,
        name: row.company_name,
        date_onbord:
          row.date_of_onboarding == null ? '' : moment(row.date_of_onboarding).format('DD-MM-YYYY'),
        subscription_date:
          row.start_from == null ? '' : moment(row.start_from).format('DD-MM-YYYY'),
        amt: row.amount,
        status: row.status,
        bill_type: row.bill_type
      };
    });
    setDatas(map_data);
  }, [api_data]);

  const options = {
    selectableRows: false,
    rowsPerPageOptions: [10,100,1000, 1500, 2000],
    rowsPerPage: 10
  };

  console.log('Datass', datas);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          container
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="right"
          sx={{ margin: 1 }}
        >
          <Button onClick={handleClickOpen} variant="contained">
            Add Subscription
          </Button>


          <Dialog
            open={add}
            // scroll={'paper'}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <Box
                component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                Add Data
                <CloseRoundedIcon
                  onClick={handleClickClose}
                  style={{ color: '#ff5252' }}
                  boxShadow={1}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Subscription_Add add={add} handleClickClose={handleClickClose} />
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </Box>
      </Box>
      
          <Grid container spacing={1} padding={1}>
          <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Typography>Number of Active Companies:   {active_company_count?.length}</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>Total Monthly Subscription Fee:   ₹ {sum} <b>|</b> Active : {active_sum} <b>|</b> Inactive : {inactive_sum} <b>|</b>  Hold : {hold_sum}</Typography>
            </Grid>
          </Grid>
      <MUIDataTable title={'Subscription'} data={datas} columns={columns} options={options} />
    </>
  );
}

const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete" color="primary">
        <ModeEditIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'md'}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Data
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Subscription_Edit row={row} handleClickClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Delete = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete" color="error">
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'md'}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Delete Invoice
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Subscription_delete row={row} handleClickClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

