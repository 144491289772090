import React from 'react';
import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
  Box,
  Typography
} from '@mui/material';
import BasicChartSmall from 'src/sections/@dashboard/app/BasicChartSmall';
import BasicChart from 'src/sections/@dashboard/app/BasicChart';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function gst_panel(props) {
  const { overallGSTScore, dashBoardScore, year, state } = props;
  // const { dashBoardScore, year } = props;
  let score_data = dashBoardScore?.[year];
  console.log('dashBoardScore', dashBoardScore?.[year], year);

  const gstcount = score_data !== null ? score_data?.gst_filling.count : 0;
  const gstfilled = score_data !== null ? score_data?.gst_filling.ontime : 0;
  // const gstcount = dashBoardScore !== null ? dashBoardScore?.[year]?.gst_filling.count : 0;
  // const gstfilled = dashBoardScore !== null ? dashBoardScore?.[year]?.gst_filling.ontime : 0;
  const gst_filling_score =
    score_data?.gst_by_state?.[state]?.count == 0
      ? 100
      : parseInt(
          (score_data?.gst_by_state?.[state]?.ontime / score_data?.gst_by_state?.[state]?.count) *
            100
        ) || 0;
  // const gst_filling_score =
  //   parseInt((dashBoardScore?.[year]?.gst_filling?.filled / dashBoardScore?.[year]?.gst_filling?.count) * 100) || 0;

  return (
    <div>
      <Card>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <BasicChart title="GST" score={gst_filling_score} />
            <Typography textAlign="center" mt={-2}>
              {/* {gstfilled}/{gstcount} */}
              {score_data?.gst_filling?.ontime}/ {score_data?.gst_filling?.count}
              <br />
              Compliances cleared
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
