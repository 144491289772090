import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getVaultData, updateVaultData } from 'src/redux/actions/loginVault';
import LoginVoltPopup from 'src/components/LoginVoltPopup';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';
// import TabPanel from './test';
import TabPanel from './preferencetabs';
import { Box } from '@mui/system';

export default function Preference() {
  const companyState = useSelector((state) => state.orgProfileReducer);
  const vaultState = useSelector((state) => state.vaultReducer);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = companyState.companyId || searchParams.get('companyid') ;

  useEffect(() => {
    dispatch(getVaultData(companyId));
  }, []);

  useEffect(() => {
    console.log('hello');
    setData(vaultState.vault_data);
  }, [vaultState.vault_data]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    console.log(index);
    const tmp = data;
    tmp[index][name] = value;
    setData([...tmp]);
  };

  const handleSubmit = (data) => {
    dispatch(updateVaultData(data.id, data));
  };
  return (
    <>
      {/* <TabPanel/> */}
      <TabPanel/>
    </>
  );
}
