import React, { useState } from 'react';
import { Paper, List, ListItem, Typography, Card, CardHeader, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dateFormat } from 'src/utils/common';
export const ActionsRequired = () => {
  const [data, setData] = useState(null);
  const dueDatesState = useSelector((state) => state.dueDatesReducer);
  useEffect(() => {
    console.log(dueDatesState.overDueDates.data);
    setData(
      dueDatesState?.overDueDates?.data?.sort(function (a, b) {
        return new Date(a.dueDate) - new Date(b.dueDate);
      })
    );
  }, [dueDatesState.overDueDates]);

  const getStatus = (dueDate, extendedDueDate, filling_date) => {
    const expiry = extendedDueDate === null ? dueDate : extendedDueDate;
    //2->upcoming
    // 1->ontime
    // 0->overdue
    //4->delayed
    if (filling_date === null) {
      if (new Date(expiry) >= new Date()) {
        return 2;
      } else {
        return 0;
      }
    } else {
      if (new Date(filling_date) >= new Date(expiry)) {
        return 4;
      } else {
        return 1;
      }
    }
  };
  return (
    <Card>
      <CardContent>
        {/* <Paper style={{ maxHeight: 200, overflow: 'auto' }}> */}
        <Typography sx={{ fontSize: 20 }} color="red" gutterBottom>
          Action Requires your attention
        </Typography>
        <List style={{ maxHeight: 180, overflow: 'auto' }}>
          {data?.length > 0 &&
            data.map((item, index) => {
              if (getStatus(item.dueDate, item.extended_due_date, item.filling_date) == 0) {
                return (
                  <>
                    <ListItem component={Link} to={`/dashboard/overDueDates/#${index}`}>
                      {`${item.complianceName} for period ${item.period} was due on ${dateFormat(
                        item.dueDate
                      )} but yet to be filed`}
                    </ListItem>
                    <hr />
                  </>
                );
              }
            })}
        </List>
        {/* </Paper> */}
      </CardContent>
    </Card>
  );
};
