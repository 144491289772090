import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import { ExtendedProfileSetUp, ProfileSetUp } from './pages/ExtendedProfileSetUp';
import { BasicProfileSetUp } from './pages/BasicProfileSetup';
import Tds from './layouts/dashboard/Tds';
import { Pf } from './layouts/dashboard/Pf';
import { AllDueDates } from './sections/@dashboard/app/dueDatesTables/AllDueDates';
import { UpcomingDueDates } from './sections/@dashboard/app/dueDatesTables/UpcomingDueDates';
import { OverDueDates } from './sections/@dashboard/app/dueDatesTables/OverDueDates';
import { MainDashBoard } from './sections/@dashboard/app/MainDashBoard';
import Loginvault from './sections/authentication/Loginvault';
import Dataoperater from './sections/@dashboard/dataoperater';
import OrganizationalProfile from './layouts/dashboard/organizationalProfile/OrganizationalProfile';
import RocDashboard from './sections/@dashboard/app/RocDashboard';
import ReportPage from './sections/reportPage/index';
import { DueDiligenceBook } from './sections/@dashboard/app/DueDiligenceBook';
import { GstDashboard } from './sections/gstBook/GstDashboard';
import { IncomeTaxDashboard } from './sections/@dashboard/app/IncomeTaxDashboard';
import IncomeTax from './layouts/dashboard/IncomeTax';
import { EsiDashboard } from './sections/@dashboard/app/EsiDashBoard';
import { LiveSoon } from './pages/LiveSoon';
import { useSelector } from 'react-redux';
import { CustomizedLoginValut } from './pages/CustomizedLoginValut';
import { ManualTrigger } from './pages/ManualTrigger';
import Preference from './pages/preference';
import Masterdoc from './pages/Masterdoc';
import Fillingstatus from './pages/fillingstatus';
import Companystats from './pages/CompanyStats';
import { All_due } from './sections/@dashboard/app/dueDatesTables/All_due';
import Consoleview from './pages/console_view';
import Taskcreation from './pages/taskCreation';
import Add_task from './pages/add_task';
import TaskName from './components/task_names/task_name';
import CompanyUpload from './pages/CompanyUpload';
import { DueDatesNotification } from './pages/DueDatesNotification';
import Acesscode from './components/Invoice Manage/password/acesscode';
import Add_task_redirect from './pages/add_task_redirect';
import Cmplist from './components/comp_new_list';
import { Other_Book_Dashboard } from './sections/@dashboard/app/OtherBook_Dashboard';
import { getCompanyId } from 'src/utils/common';
import MailSender from './pages/MailSender';
import { isStartupFino } from './config';
import Feedback from './pages/Feedback';


// import { CompanyList } from './pages/companyList';
// ----------------------------------------------------------------------

export default function Router() {
  const org = JSON.parse(localStorage.getItem('company_data'));
  const authState = useSelector((state) => state.authReducer);
  const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  console.log("auth",authState,"cmp",companyId)

  return useRoutes([
    {
      path: '/dashboard',
      element: org != null ? <DashboardLayout /> : <LiveSoon />,
      children: [
        { path: 'dueDiligence', element: <DueDiligenceBook /> },
        {
          path: 'main',
          element: org != null ? <MainDashBoard /> : <></>
        },
        // { path: 'app', element: <DashboardApp /> },
        { path: 'notification-center', element: org != null ? <ManualTrigger /> : <></> },
        { path: 'notification-mail', element: org != null ? <MailSender /> : <></> },
        { path: 'companylist', element: org != null ? <Dataoperater /> : <></> },
        { path: 'tds', element: org != null ? <Tds /> : <></> },
        { path: 'pf', element: org != null ? <Pf /> : <></> },
        {
          path: 'incometax',
          element: org != null ? <IncomeTax /> : <></>
        },
        {
          path: 'gst',
          element: org != null ? <GstDashboard /> : <></>
        },
        {
          path: 'esi',
          element: org != null ? <EsiDashboard /> : <></>
        },
        {
          path: 'otherbooks',
          element: org != null ? <Other_Book_Dashboard /> : <></>
        },
        {
          path: 'preference',
          element: org != null && !isStartupFino ? <Preference /> : <></>
        },
        {
          path: 'masterdoc',
          element: org != null ? <Masterdoc /> : <></>
        },
        {
          path: 'fillingstatus',
          element: org != null ? <Fillingstatus /> : <></>
        },
        {
          path: 'companystats',
          element: org != null ? <Companystats /> : <></>
        },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
        {
          path: 'allDueDates',
          element: org != null ? <AllDueDates /> : <></>
        },
        {
          path: 'taskCreation',
          element: org != null ? <Taskcreation /> : <></>
        },
        {
          path: 'comp_list',
          element: org != null ? <Cmplist /> : <></>
        },
        {
          path: 'billing-module',
          element: org != null ? <Acesscode /> : <></>
        },
        {
          path: 'tasknames',
          element: org != null ? <TaskName /> : <></>
        },
        {
          path: 'all_DueDates',
          element: org != null ? <All_due /> : <></>
        },
        {
          path: 'upcomingDueDates',
          element: org != null ? <UpcomingDueDates /> : <></>
        },
        {
          path: 'overDueDates',
          element: org != null ? <OverDueDates /> : <></>
        },
        {
          path: 'roc',
          element: org != null ? <RocDashboard /> : <></>
        },
        {
          path: 'incometax',
          element: org != null ? <IncomeTaxDashboard /> : <></>
        },
        {
          path: 'loginvault',
          element: org != null ? <Loginvault /> : <></>
        },
        {
          path: 'organizationProfile',
          element: org != null ? <OrganizationalProfile /> : <></>
        },
        {
          path: 'reports',
          element: org != null ? <ReportPage /> : <></>
        },
        {
          path: 'feedback',
          element: org != null ? <Feedback/> : <></>
        },
        {
          path: 'profile',
          element: org != null ? <OrganizationalProfile /> : <></>
        },
        {
          path: 'console_view',
          element: org != null ? <Consoleview /> : <></>
        }
      ]
    },
    {
      path: 'data-ops',
      element: <Dataoperater />
    },


    {
      path: '/',
      //element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> }
        // ,
        { path: '/', element: <Login /> },
        { path: '/basicProfile', element:<BasicProfileSetUp />},
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/dueDates', element: <DueDatesNotification /> },
        // { path: '/livesoon', element: <LiveSoon /> },
        { path: '/loginValut', element: <CustomizedLoginValut /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/extendedProfile',
      element: <ExtendedProfileSetUp />
    },
    {
      path:'/livesoon',
      element:<LiveSoon/>
    },
    {
      path: '/add-task',
      element: <Add_task />
    },
    {
      path: '/new-task',
      element: <Add_task_redirect />
    },
    {
      path: '/companydocuments',
      element:<CompanyUpload />
    },
    {
      path: '/task-list',
      element: <Taskcreation />
    },
    // {
    //   path:'/dashboard',
    //   element: <Thankyou />
    // },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
