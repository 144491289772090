import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
export const fetchEsiChallans = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/esibook/challan/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.companyId == companyId);
      dispatch({
        type: Constants.SET_ESI_CHALLANS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_ESI_ERROR,
        payload: error.message
      });
    });
};
export const addesi = (data) => (dispatch) => {
  HttpWrapper('POST', `/esibook/challan/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addesidemand = (data) => (dispatch) => {
  HttpWrapper('POST', `/esibook/penalty/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteesidemand = (data) => (dispatch) => {
  HttpWrapper('DELETE', `/esibook/penalty/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteesichallan = (data) => (dispatch) => {
  HttpWrapper('DELETE', `/esibook/challan/getChallan/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateesiDemand = (id, data) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/esibook/penalty/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateesiChallan = (id, data) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/esibook/challan/getChallan/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
    })
    .catch((err) => {
      console.log(err);
    });
};


export const fetchEsiPenalties = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/esibook/penalty/${companyId}/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.companyId == companyId);
      dispatch({
        type: Constants.SET_ESI_PENALTIES,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_ESI_ERROR,
        payload: error.message
      });
    });
};

export const fetchEsiDueDates = () => (dispatch) => {
  HttpWrapper('GET', `/compliance/due-date-book/ESI/`, false, {})
    .then((res) => {
      const data = res.data;
      dispatch({
        type: Constants.SET_ESI_DUE_DATES,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_ESI_DUE_DATES,
        payload: []
      });
    });
};
