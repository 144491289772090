import React from 'react';
import { Container, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { triggerReminder, triggerMissDeadline } from 'src/redux/actions/testing';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { allnotify_get, updateNoti } from 'src/redux/actions/allnotificationsAction';
import { useSearchParams } from 'react-router-dom';
import Table from '@mui/material/Table';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TableBody from '@mui/material/TableBody';
import { Box } from '@mui/system';
// import { Button, InputLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { AirlineSeatReclineNormalTwoTone, RowingRounded } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Tooltip from '@mui/material/Tooltip';
import { Fade } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import CircularProgress from '@mui/material/CircularProgress';
export const ManualTrigger = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading,setloading]=useState(false)
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const [filteredContent, setFilteredContent] = useState([]);
  const [content_noti, setContent_noti] = useState([]);
  const [datas, setDatas] = useState([]);
  //notification
  const company_notify = useSelector((state) => state.allNotificationreducers);
  console.log('company_notify', company_notify);

  //Send Reminder
  const sendReminder = () => {
    console.log('Sending Reminder API');
    dispatch(triggerReminder());
  };

  //Send Deadline
  const sendDeadline = () => {
    console.log('Sending Deadline API');
    dispatch(triggerMissDeadline());
  };

  useEffect(() => {
    dispatch(allnotify_get());
  }, []);
  const columns = [
    {
      name: 'companyname',
      label: 'Company',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'client',
      label: 'Client',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, tableMeta, rowIndex) => {
          const remark = tableMeta.rowData[2];
          return (
            <Tooltip
              title={remark}
              placement="right"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 300 }}
            >
              <span>{data}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'desg',
      label: 'Role',
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'contact',
      label: 'Contact Detail',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center'
                }}
              >
                <ContactPhoneIcon sx={{ mr: 1, color: '#787276' }} />
                <Typography>: {data.phone}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  mt: 1
                }}
              >
                <ContactMailIcon sx={{ mr: 1, color: '#787276' }} />
                <Typography>: {data.email}</Typography>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'alerts',
      label: 'Alerts',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          console.log('datsssa', data);
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center'
                }}
              >
                <WhatsAppIcon sx={{ mr: 1, color: 'green' }} />
                <Checkbox
                  checked={data.whatsapp_alerts}
                  onClick={(e) => {
                    handleWhatsapp(e, data);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center'
                }}
              >
                <EmailSharpIcon sx={{ mr: 1, color: 'coral' }} />
                <Checkbox 
                checked={data.email_alerts} 
                onClick={(e) => {
                  handleEmail(e, data);
                }}
                />
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return <Update row={data} />;
        }
      }
    },
    {
      name: 'is_wa',
      label: 'WhatsApp Alerts',
      options: {
        filter: true,
        sort: false,
        display:false
      }
    },
    {
      name: 'is_ma',
      label: 'Mail Alerts',
      options: {
        filter: true,
        sort: false,
        display:false
      }
    }
  ];

  useEffect(() => {
    setContent_noti(company_notify);
    setFilteredContent(company_notify?.comp_notifications);
    console.log('content', content_noti);
  }, [company_notify]);
  useEffect(() => {
    const data = company_notify?.comp_notifications?.map((row) => {
      return {
        companyname: row.companyId.company_name,
        client: row.name,
        desg: row.role,
        contact: row,
        alerts: row,
        edit: row,
        is_wa:row.whatsapp_alerts?"WhatsApp Alert Active":"WhatsApp Alert Inactive",
        is_ma:row.email_alerts?"Mail Alert Active":"Mail Alert Inactive",

      };
    });
    setDatas(data);
  }, [company_notify]);

  //HANDLE WHATSAPP
  const handleWhatsapp = (e, row) => {

    var waspp_noti = new FormData();
    let message
    if (row.whatsapp_alerts == true) {
      row.whatsapp_alerts = false;
      message=`Disabling WhatsApp Alerts for \n ${row.name} at \n ${row.companyId.company_name}`
      console.log('Update', row.whatsapp_alerts);
    } else if (row.whatsapp_alerts == false) {
      row.whatsapp_alerts = true;
      message=`Enabling WhatsApp Alerts for \n ${row.name} at \n ${row.companyId.company_name}`
      console.log('Update', row.whatsapp_alerts);
      //
    }
    waspp_noti.append('name', row.name);
    waspp_noti.append('role', row.role);
    waspp_noti.append('email', row.email);
    waspp_noti.append('phone', row.phone);
    waspp_noti.append('whatsapp_alerts', row.whatsapp_alerts);
    console.log('check', row.whatsapp_alerts);
    dispatch(updateNoti(waspp_noti, row.id,message));
  };
  console.log('Testing', company_notify?.comp_notifications);
  console.log('Testing Filter', filteredContent);
  //HANDLE Email
  const handleEmail = (e, row) => {
    var email_noti = new FormData();
    let message
    if (row.email_alerts == true) {
      row.email_alerts = false;
      message=`Disabling Email Alerts for \n${row.name} at \n${row.companyId.company_name}`
      console.log('Update', row.email_alerts);
    } else if (row.email_alerts == false) {
      row.email_alerts = true;
      message=`Enabling Email Alerts for \n${row.name} at \n${row.companyId.company_name}`
      console.log('Update', row.email_alerts);
    }
    email_noti.append('name', row.name);
    email_noti.append('role', row.role);
    email_noti.append('email', row.email);
    email_noti.append('phone', row.phone);
    email_noti.append('email_alerts', row.email_alerts);
    console.log('check', row.whatsapp_alerts);
    dispatch(updateNoti(email_noti, row.id,message));
  };

  const data = [
    { companyname: 'Joe James', company: 'Test Corp', city: 'Yonkers', state: 'NY' },
    { name: 'John Walsh', company: 'Test Corp', city: 'Hartford', state: 'CT' },
    { name: 'Bob Herm', company: 'Test Corp', city: 'Tampa', state: 'FL' },
    { name: 'James Houston', company: 'Test Corp', city: 'Dallas', state: 'TX' }
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    searchAlwaysOpen: true,
    filterType:'multiselect'
  };
  return (
    <>
      <Grid sx={{ flexGrow: 1, mt: '10px' }} container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h4">Manage Company Alrets</Typography>
        </Grid>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={sendReminder} sx={{ ml: 1 }}>
            Trigger Reminder Whatsapp
          </Button>
          <Button variant="contained" onClick={sendDeadline}>
            Trigger Missed Deadline Whatsapp
          </Button>
        </Stack>
      </Grid>

      <MUIDataTable
        title={'Client Notification List'}
        data={datas}
        columns={columns}
        options={options}
      />
    </>
  );
};
const Update = (props) => {
  const { row } = props;
  console.log('row', row);
  // console.log("sdhfaksfd=====", row);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    phone: '',
    email: ''
  });
  const [namee,setNamee ] = useState();
  const [id, setId] = useState();
  const [rolee, setRolee] = useState();
  const [phonee, setPhonee] = useState();
  const [emaile, setEmaile] = useState();
  useEffect(()=>{
    setNamee(row.name)
    setId(row.id)
    setRolee(row.role)
    setPhonee(row.phone)
    setEmaile(row.email)
    },[row])
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log("rows are", row);

  // const handleDelete = () => {
  //   confirm({ description: "This will permanently delete " })
  //     .then(() => {
  //       setNewrows(null);
  //       console.log("newrows are after delete ",newrows);
  //     })
  //     .catch(() => console.log("Deletion cancelled."));
  // };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    console.log('form data', bodyFormData);
    console.log('id sent asdkjfakjfda', id, row.companyId.id);
    const message="Updating Values"
    dispatch(updateNoti(bodyFormData, id,message));
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>
        Edit
      </Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        fullWidth
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            //sx={boxDefault}
          >
            {row.companyId.company_name}

            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <Box
              sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }}
              display="flex"
            >
              <TextField
                required
                value={namee}
                // type="number"
                name="name"
                onChange={(e) => {
                  setNamee(e.target.value);
                  {
                    formData.name = e.target.value;
                    // console.log("Name", e.target.value);
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Authority Person Name"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={rolee}
                // type="number"
                name="role"
                onChange={(e) => {
                  setRolee(e.target.value);
                  {
                    formData.role = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Role"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={phonee}
                type="number"
                name="phone"
                onChange={(e) => {
                  setPhonee(e.target.value);
                  {
                    formData.phone = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Phone Number"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={emaile}
                type="email"
                name="email"
                onChange={(e) => {
                  setEmaile(e.target.value);
                  {
                    formData.email = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Email Address"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            </Box>
            <Box
              container
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ margin: 1 }}
            >
              <Button type="submit" onClick={dis} variant="contained">
                SAVE
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
