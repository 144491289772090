import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';

export const fetchCompanyBasicInfo = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/CompanyMasterSummary/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id === company_id);
      dispatch({
        type: Constants.SET_COMPANY_BASIC_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_COMPANY_BASIC_INFO,
        payload: []
      });
    });
};

export const orderRocData = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/make-order/${company_id}/`, false, {})
    .then((res) => {
      toast.success('Order Made Successfully!!!')
    })
    .catch((error) => {
      toast.error('Error in making Order!!!')
    });
};

export const fetchDirectors = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/DirectorMasterSummary/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_DIRECTORS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_DIRECTORS,
        payload: []
      });
    });
};

export const fetchSignatories = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/SignatoryMasterSummary/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_SIGNATORIES,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_SIGNATORIES,
        payload: []
      });
    });
};

export const fetchKycInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/kycinfo/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_KYC_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_KYC_INFO,
        payload: []
      });
    });
};
export const fetchCorporateCompanyInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/corporate/info/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_CORPORATE_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CORPORATE_INFO,
        payload: []
      });
    });
};
export const fetchFinancialHighlightsInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/financial/info/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_FINANCIAL_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_FINANCIAL_INFO,
        payload: []
      });
    });
};
export const fetchScheduleBreakupInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/schedule/info/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_SCHEDULE_BREAKUP_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_SCHEDULE_BREAKUP_INFO,
        payload: []
      });
    });
};
export const fetchOwnershipInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/OwnershipDetails/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_OWNERSHIP_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_OWNERSHIP_INFO,
        payload: []
      });
    });
};
export const fetchBorrowingsInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/Borrowings/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_BORROWINGS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_BORROWINGS_INFO,
        payload: []
      });
    });
};
export const fetchChargesInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/CompanyChargesDetailedInformation/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_COMPANY_CHARGES_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_COMPANY_CHARGES_INFO,
        payload: []
      });
    });
};
export const fetchCreditRatingsInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/CreditRatingDetail/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_CREDIT_RATINGS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CREDIT_RATINGS_INFO,
        payload: []
      });
    });
};
export const fetchProfitAndLossInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/StatementOfProfitAndLoss/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_PROFITLOSS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_PROFITLOSS_INFO,
        payload: []
      });
    });
};
export const fetchBalanceSheetInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/BalanceSheet/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_BALANCESHEET_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_BALANCESHEET_INFO,
        payload: []
      });
    });
};
export const fetchCashFlowInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/CashFlowStatementDirectMethod/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_CASH_FLOW_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CASH_FLOW_INFO,
        payload: []
      });
    });
};
export const fetchRatioAnalysisInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/RatioAnalysis/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_RATIO_ANALYSIS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_RATIO_ANALYSIS_INFO,
        payload: []
      });
    });
};
export const fetchRevenueInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/RevenueFromOperationsSchedule/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_REVENUE_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_REVENUE_INFO,
        payload: []
      });
    });
};
export const fetchFinanceCostInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/FinanceCost/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_FINANCE_COST_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_FINANCE_COST_INFO,
        payload: []
      });
    });
};
export const fetchExpenseInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/OtherExpenseSchedule/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_EXPENSE_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_EXPENSE_INFO,
        payload: []
      });
    });
};
export const fetchContingementInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/RelatedParty/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_CONTINGEMENT_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CONTINGEMENT_INFO,
        payload: []
      });
    });
};
export const fetchScheduleBorrowingsInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/revenue/info/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_SCHEDULES_BORROWINGS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_SCHEDULES_BORROWINGS_INFO,
        payload: []
      });
    });
};
export const fetchLoansAdvancesInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/LoansAndAdvances/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_LOANS_ADVANCES_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_LOANS_ADVANCES_INFO,
        payload: []
      });
    });
};
export const fetchDebtorsInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/DebtorsAnalysis/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_DEBTORS_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_DEBTORS_INFO,
        payload: []
      });
    });
};
export const fetchMiscDisclosureInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/MiscDisclosuresRelatedToBalanceSheet/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_MISC_DISCLOSURE_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_MISC_DISCLOSURE_INFO,
        payload: []
      });
    });
};
export const fetchRelatedPartyInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/RelatedParty/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_RELATED_PARTY_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_RELATED_PARTY_INFO,
        payload: []
      });
    });
};
export const fetchAuditorsDetailInfo = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/AuditorsDetail/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id == company_id);
      dispatch({
        type: Constants.SET_AUDITORS_DETAIL_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_AUDITORS_DETAIL_INFO,
        payload: []
      });
    });
};
export const fetchAuditorsReportInfo = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/AuditorsReport/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id == company_id);
      dispatch({
        type: Constants.SET_AUDITORS_REPORT_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_AUDITORS_REPORT_INFO,
        payload: []
      });
    });
};
export const fetchCaroInfo = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/AuditorCaro/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id == company_id);
      dispatch({
        type: Constants.SET_CARO_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CARO_INFO,
        payload: []
      });
    });
};
export const fetchEstablishmentInfo = (company_id) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/Establishmentaster/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id == company_id);
      dispatch({
        type: Constants.SET_ESTABLISHMENT_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_ESTABLISHMENT_INFO,
        payload: []
      });
    });
};
export const fetchChargeSearchInfo = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/OpenChargesBankWise/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id === companyId);
      dispatch({
        type: Constants.SET_CHARGE_SEARCH_INFO,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CHARGE_SEARCH_INFO,
        payload: []
      });
    });
};

export const fetchEmployeeTrend = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/roc/api/EmployeeTrend/`, false, {})
    .then((res) => {
      const data = res.data.filter((row) => row.company_id === companyId);
      dispatch({
        type: Constants.SET_EMPLOYEE_TREND,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_EMPLOYEE_TREND,
        payload: []
      });
    });
};
export const fetchChargeDetailInfo = () => (dispatch) => {
  HttpWrapper('GET', `/roc/api/revenue/info/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_CHARGE_DETAIL_INFO,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_CHARGE_DETAIL_INFO,
        payload: []
      });
    });
};

export const fetchRocData = (companyId) => (dispatch) => {
  HttpWrapper(
    'GET',
    `/roc/api/company-roc/?company_id=${companyId}`,
    false,
    {}
  )
    .then((res) => {
      dispatch({
        type: Constants.SET_ROC_DATA,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_ROC_DATA,
        payload: []
      });
    });
};
