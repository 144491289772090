import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { Link, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
export const DueDiligenceBook = () => {
  const [data, setData] = useState([
    {
      Title: 'Copy of PAN Card of Company',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/pan.pdf?alt=media&token=e07beb14-a88c-4072-b1d8-1ae25976bd88'
    },
    {
      Title: 'Copy of TAN Certificate of Company',
      link: 'abc'
    },
    {
      Title: 'Copy of GSTN Certificate',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/gstcer.pdf?alt=media&token=4d55ff26-5beb-4333-81cf-25b9be2fe8a7'
    },
    {
      Title: 'Copy of EPF Ceritificate',
      link: 'abc'
    },
    {
      Title: 'Copy of ESI Ceritificate',
      link: 'abc'
    },
    {
      Title: 'Certificate of Incorporation, MOA and AOA',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/regcer.pdf?alt=media&token=e19b5c0e-b1fb-485b-bd93-3a8141379ec3'
    },
    {
      Title: 'List of Shareholders of the Company',
      link: '?page=3'
    },
    {
      Title: 'List of Directors of Company',
      link: '?page=11'
    },
    {
      Title: 'All the TDS Challan Paid by the Company',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/challans.zip?alt=media&token=6646b658-2010-4632-b59e-40c125a2292f'
    },
    {
      Title: 'All the TDS Returns filed by the Company along with its Acknowledgement',
      link: 'abc'
    },
    {
      Title: 'All the EPF Returns filed by the Company',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/returns.zip?alt=media&token=ae5ac6da-94df-4944-a309-9263347c87ed'
    },
    {
      Title: 'All the ESI Returns filed by the Company',
      link: 'abc'
    },
    {
      Title: 'All the GSTR-3B returns filed by the Company',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/GSTR-3B.zip?alt=media&token=bec1d536-5bac-4240-8b39-669b326122ea'
    },
    {
      Title: 'All the GSTR-1 returns filed by the Company',
      link: 'https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/GSTR-1.zip?alt=media&token=c0d83344-691a-4e28-baf7-346d44979e8b'
    },
    {
      Title: 'All the GSTR-9 returns filed by the Company',
      link: 'abc'
    },
    {
      Title: 'All the Forms filed with ROC by the Company',
      link: '?page=16'
    },
    {
      Title: 'All the Income Tax Return filed by the Company',
      link: 'abc'
    },
    {
      Title: 'Details of Auditors of the Company',
      link: 'abc'
    },
    {
      Title: 'Details about Borrowings of the Company',
      link: '?page=4'
    },
    {
      Title: 'Please provide the Financial highlights about the Company',
      link: '?page=1'
    },
    {
      Title: 'Ratio Analysis on the Company',
      link: '?page=7'
    },
    {
      Title: 'Related Party Transactions of the Company',
      link: '?page=9'
    },
    {
      Title: 'Details about the Eshtablishment & Employees',
      link: '?page=12'
    },
    {
      Title: 'Details about the Charges of the Company',
      link: '?page=13'
    }
  ]);

  return (
    <Table>
      <TableBody>
        {data.map((row, i) => {
          return (
            <TableRow>
              <TableCell>{row.Title}</TableCell>
              <TableCell>
                {!(i >= 18 || i === 6 || i === 7 || i == 15) && (
                  <Button variant="outlined">
                    <Link variant="a" href={row.link} target="_blank" underline="none">
                      Download
                    </Link>
                  </Button>
                )}
                {(i >= 18 || i === 6 || i === 7 || i == 15) && (
                  <Button variant="outlined">
                    <NavLink
                      to={`/dashboard/roc${row.link}`}
                      style={{ color: 'primary', textDecoration: 'none' }}
                    >
                      Link
                    </NavLink>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
