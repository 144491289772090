import { combineReducers } from 'redux';
import { orgProfileReducer } from './orgProfileReducer';
import { authReducer } from './authReducer';
import { tdsReducer } from './tdsReducer';
import { vaultReducer } from './vaultReducer';
import { dueDatesReducer } from './dueDatesReducer';
import { pfReducer } from './pfReducer';
import { rocReducer } from './rocReducer';
import { incometaxReducer } from './incometaxReducer';
import { gstReducer } from './gstReducer';
import { esiReducer } from './esiReducer';
import { dashBoardReducer } from './dashBoardReducer';
import { allComplaincereducers } from './allcomplaincereducer';
import { allPreferencereducers } from './allpreerencereducer';
import { allCompaniesreducers } from './dataoperatorReducers';
import { allCompaniesdocsreducers } from './masterdocReducer';
import { allNotificationreducers } from './allnotificationreducer';
import { allFillStatreducers } from './fillingstatReducers';
import { allCompDemDatareducers } from './allcompDemand_Reducer';
import { profileReducer } from './profileReducer';
import { consoleViewreducer } from './console_view_reducer';
import { taskreducers } from './task_creation_Reducer';
import { team_mates_reducers } from './team_mates_Reducers';
import { team_name_reducers } from './task_name_reducers';
import { invoice_reducers } from './invoice_module_reducer';
import { allcmpmonthreducers } from './allcompmonth';
export const rootReducer = combineReducers({
  orgProfileReducer,
  authReducer,
  tdsReducer,
  vaultReducer,
  dueDatesReducer,
  pfReducer,
  rocReducer,
  incometaxReducer,
  gstReducer,
  esiReducer,
  dashBoardReducer,
  allCompaniesreducers,
  allComplaincereducers,
  allPreferencereducers,
  allCompaniesdocsreducers,
  allNotificationreducers,
  allFillStatreducers,
  allCompDemDatareducers,
  profileReducer,
  consoleViewreducer,
  taskreducers,
  team_mates_reducers,
  team_name_reducers,
  invoice_reducers,
  allcmpmonthreducers
});

