import React from 'react';
import Loginvault from 'src/sections/authentication/Loginvault';
import { Grid } from '@mui/material';
export const CustomizedLoginValut = () => {
  return (
    <Grid m={2}>
      <Loginvault />
    </Grid>
  );
};
