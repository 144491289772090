import Constants from '../../utils/constants';
const intialState = {
  tax: null,
  tds: null,
  demand: null,
  forms: null,
  dueDates: []
};

export const incometaxReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_TDS_DEDUCTED:
      return { ...state, tds: action.payload };

    case Constants.SET_TAX:
      return { ...state, tax: action.payload };

    case Constants.SET_INCOME_TAX_DEMAND:
      return { ...state, demand: action.payload };

    case Constants.SET_FORMS:
      return { ...state, forms: action.payload };
    case Constants.SET_INCOME_TAX_DUE_DATES:
      return { ...state, dueDates: action.payload };
    default:
      return state;
  }
};
