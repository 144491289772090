import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { setToken, setCompanyData, setCompanyId } from '../../utils/common';

export const getAllComplaince = () => (dispatch) => {
    // companyId = '6c743b53-3e95-4d15-9c09-5539c4026c53';
    HttpWrapper('GET', `/compliance/compliace-list/`, false, {})
      .then((res) => {
        console.log(res)
        dispatch({
          type: Constants.SET_ALL_COMPLAINCE,
          payload: res.data
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.SET_ALL_COMPLAINCE,
          payload: error.message
        });
      });
  };