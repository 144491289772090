import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useSearchParams } from 'react-router-dom';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
//import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import Button from '@mui/material/Button';

import { updateSingleDemand } from 'src/redux/actions/tds';
//import { useState } from 'react';
//import { useSelector } from 'react-redux';
import { tdsReducer } from 'src/redux/reducers/tdsReducer';
import { fetchTdsDemands } from 'src/redux/actions/tds';
import { useEffect } from 'react';
import { Box } from '@mui/system';
//import { ConfirmProvider } from "material-ui-confirm";
import { Button, InputLabel } from '@mui/material';
import {deletetdsdemand} from 'src/redux/actions/tds';
import  moment  from 'moment'; 
//import Label from 'src/components/Label';
import { toast } from 'react-toastify';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { isStartupFino,istalenteye } from 'src/config';

export default function TdsTracesDemand({ companyId }) {
  const orgState = useSelector((state) => state.orgProfileReducer);
  const tdsState = useSelector((state) => state.tdsReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTdsDemands(companyId));
  }, []);

  useEffect(() => {
    setData(tdsState.tdsDemands);
  }, [tdsState.tdsDemands]);
  const [data, setData] = useState(tdsState.tdsDemands);
  if (data == null) {
    return <div></div>;
  } else {
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>FY</TableCell>
              <TableCell align="center">Total Demand</TableCell>
              <TableCell align="center" sx={{display: isStartupFino||istalenteye  ? 'none': ''}}>Edit / Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell>{row.fin_year}</TableCell>
                <TableCell align="right">{row.total_demand}</TableCell>
                <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>
        <Box textAlign="center">
                    <Update row={row} />
                    <ConfirmProvider>
                    <Delete row={row} />
                    </ConfirmProvider>
                  </Box>
        </TableCell>

              </TableRow>
            ))}
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },

                '& th': {
                  color: 'red'
                }
              }}
            >
              <TableCell>Total</TableCell>
              <TableCell align="right">
                <Label variant="ghost" color="error">
                  {data
                    .map((item) => parseInt(item.total_demand))
                    .reduce((prev, next) => prev + next, 0)}
                </Label>
              </TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
const Delete = (props) => {
  const { row } = props;
//const { row } = props;
  const dispatch = useDispatch();
  //console.log("rows filling are", row);
  //const [newrows, setNewrows] = useState(row);
  //console.log("newrows are", newrows);
  // console.log("Challandata are",challansData);
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletetdsdemand (row.id,companyId))
        //console.log("newrows are after delete ", newrows);
      })
      .catch(() => console.log("Deletion cancelled."));
  }      
  return(
          <>
            <Button align='right' variant="contained" color ="error" onClick={() => handleDelete()}>Delete</Button>
          </>
      );
}
const Update =(props) =>
 {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rows are",row);
 
      return(
          <>
            <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={handleClose} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
    <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
            //   ref={descriptionElementRef}
              tabIndex={-1}
            >
                {/* <DropContentEsi/> */}
                <TdsDemandUpdate row={row}/>
            </DialogContentText>
          </DialogContent>
        </Dialog>
          </>
      );
     
}
const TdsDemandUpdate = (props) => {
  const { row } = props;
  const [tdsdnData, setTdsdnData] = useState({})
  //const [tdsdnData, setTdsdnData] = useState({})


    const tdsdnfyear = ["2010-11", "2011-12", "2012-13", "2013-14", "2014-15", "2015-16", "2016-17", "2017-18", "2018-19", "2019-20", "2020-21", "2021-22", "2022-23", "2023-24", "2024-25", "2025-26", "2026-27"];
  
    const [tdsdnvalue, setTdsdnvalue] = useState(row.payment_dates);
    const [tdsdnfy, setTdsdnfy] = useState(row.fin_year);
    const [tdsdntot, setTdsdntot] = useState(row.total_demand);
    const dispatch = useDispatch();
    let tdsdnname, tdsdnvaluee;
    const [searchParams, setSearchParams] = useSearchParams();
    const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
    useEffect(() =>{
      setTdsdnData(row)
    }, [row])
    
    const dis = (e) => {
      // formData['complianceID'] = complianceId
  
  
      if (tdsdnData.payment_date !== 'null') 
      {
        
        // tdsdnData['companyId'] = companyId
        //toast.success('Add Successfully');
        // tdsdnData.complianceID = "2b8fb873-2686-40c6-9707-796cef90f648"
        const bodyFormData = new FormData();
        console.log("Date enterd")
        tdsdnData.payment_date = moment(tdsdnData.payment_date).format('YYYY-MM-DD')
        for (let key in tdsdnData) {
          console.log("indian", key);
  
          if (key, tdsdnData[key] !== '0' || key, tdsdnData[key] !== '') {
            bodyFormData.append(key, tdsdnData[key])
          }
        }
        console.log('form data', tdsdnData);

        //dispatch(fetchTdsDemands(companyId));
        dispatch(updateSingleDemand(row.id,bodyFormData,companyId));
  
      }
      if (tdsdnData.payment_date == 'null'||tdsdnData.payment_date == 'Invalid date' ) 
      {
        console.log("date removed");
        // tdsdnData['companyId'] = companyId
        //toast.success('Add Successfully');
        // tdsdnData.complianceID = "2b8fb873-2686-40c6-9707-796cef90f648"
        const bodyFormData = new FormData();
        for (let key in tdsdnData) {
          console.log("main key", key);
  
          if (key, tdsdnData[key] !== '0') 
          {
            if(key=="payment_date"){
              tdsdnData.payment_date="null"
              console.log("ok",tdsdnData.payment_date)
              
            } 
            else{
              bodyFormData.append(key, tdsdnData[key])
            }
          }
          
        }
        dispatch(updateSingleDemand(row.id,bodyFormData,companyId));

       
        
        if((tdsdnData.fin_year == null)||(tdsdnData.fin_year == ''))
        {
          toast.error("Financial Year Is Required")
        }
        if((tdsdnData.total_demand == null)|| (tdsdnData.total_demand == ''))
        {
          toast.error("Total Demand Is Required")
        }
      
          console.log('form data', tdsdnData);
          //dispatch(updateSingleDemand(row.id));

          //dispatch(fetchTdsDemands(companyId));
          
         
      }
      
    };
  
    const handleTdsdnFormDataChange = (e) => {
      console.log(e);
      tdsdnname = e.target.name;
      tdsdnvaluee = e.target.value;
      setTdsdnData({ ...tdsdnData, [tdsdnname]: tdsdnvaluee });
      console.log("HEllo WORLD");
    }
    return (
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          
        {/*tds Demand and notice : finance year */}
   
        <Autocomplete
          value={tdsdnfy} name="fin_year"
          onChange={(event, newValue) => {
            setTdsdnfy(newValue);
            { 
              tdsdnData.fin_year = newValue; 
            }
            { 
              handleTdsdnFormDataChange; 
            }
          }}
          id="controllable-states-demo"
          options={tdsdnfyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" />}
        />
        {/*tds Demand and notice : Payment Date */}
  
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date"
            value={tdsdnvalue} name="payment_date"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setTdsdnvalue(newValue);
              { tdsdnData.payment_date = newValue };
              { handleTdsdnFormDataChange };
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>

        {/*tds Demand and notice : total_demand */}  

        <TextField
          value={tdsdntot} name="total_demand"
          onChange={(e) => {
            setTdsdntot(e.target.value);
            { tdsdnData.total_demand = e.target.value };
            { handleTdsdnFormDataChange };
          }}
          label="Total Demand" variant="outlined" sx={{ margin: 1 }} />
        <Button onClick={dis} variant="contained">Update</Button>
      </Box>
    );
  
}  
