import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Container, Grid, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TdsTableOne from './TdsTables/TdsTableOne';
import TdsChallanDetails from './TdsTables/TdsChallanDetails';
import ReturnFilling from './TdsTables/ReturnFilling';
import TdsTracesDemand from './TdsTables/TdsTracesDemand';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { ComplianceScore } from './ComplianceScore';
import BasicChart from './BasicChart';
import { useSelector } from 'react-redux';
import { ConfirmProvider } from 'material-ui-confirm';
import { useSearchParams } from 'react-router-dom';
import SelectAutoWidth from 'src/sections/gstBook/dropmodal'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tdsform from 'src/sections/@dashboard/app/tdsbasic';
import { isStartupFino } from 'src/config';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const years = ['2021-22', '2022-23', '2023-24'];

export default function TdsDashboard() {
  // console.log('dashBoardScore', dashBoardScore);
  const theme = useTheme();
  const tdsState = useSelector((state) => state.tdsReducer);
  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );

  const currentFinYear = mapFinalcialYearToYear(
    String(new Date().getFullYear()),
    new Date().getMonth()
  );
  let score_data = dashBoardScore?.[year];
  console.log('dashBoardScore', dashBoardScore?.[year], year);

  const tdscount = score_data !== null ? score_data?.tds_paid.count : 0;
  const tdsfilled = score_data !== null ? score_data?.tds_paid.ontime : 0;
  // const tdscount = dashBoardScore !== null ? dashBoardScore?.[year]?.tds_paid.count : 0;
  // const tdsfilled = dashBoardScore !== null ? dashBoardScore?.[year]?.tds_paid.ontime : 0;

  // const tds_paid_score =
  //   parseInt(
  //     ((dashBoardScore?.tds_paid?.filled - dashBoardScore?.tds_paid?.delay) /
  //       dashBoardScore?.tds_paid?.count) *
  //       100
  //   ) || 0;

  const tds_paid_score =
    score_data?.tds_paid?.count == 0
      ? 100
      : parseInt((score_data?.tds_paid?.ontime / score_data?.tds_paid?.count) * 100) || 0;

  const [zeroTDS, setZeroTDS] = useState([]);
  const nextYearMon = ['January', 'February', 'March'];
  const finMon = [
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March'
  ];

  const handleChange = (event) => {
    setYear(event.target.value);
    mapData(event.target.value);
  };

  useEffect(() => {
    mapData(year);
  }, [tdsState.tdsChallans]);

  const mapData = (year) => {
    const filterData = [];
    finMon.forEach((mon) => {
      const data = {
        '26Q': {
          is_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '26Q' &&
                item.month === mon &&
                item.paymentDate === null && item.complianceID === null
            ).length > 0
              ? true
              : false,
          is_non_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '26Q' &&
                item.month === mon &&
                item.paymentDate !== null && item.complianceID !== null
            ).length > 0
              ? true
              : false,
          filled:
            tdsState?.tdsChallans?.filter(
              (item) => item.finance_year === year && item.tds_type === '26Q' && item.month === mon
            ).length > 0
              ? true
              : false
        },
        '24Q': {
          is_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '24Q' &&
                item.month === mon &&
                item.paymentDate === null && item.complianceID === null
            ).length > 0
              ? true
              : false,
          is_non_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '24Q' &&
                item.month === mon &&
                item.paymentDate !== null && item.complianceID !== null
            ).length > 0
              ? true
              : false,
          filled:
            tdsState?.tdsChallans?.filter(
              (item) => item.finance_year === year && item.tds_type === '24Q' && item.month === mon
            ).length > 0
              ? true
              : false
        },
        '27Q': {
          is_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '27Q' &&
                item.month === mon &&
                parseInt(item.base_amount) > 0
            ).length > 0
              ? true
              : false,
          is_non_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '27Q' &&
                item.month === mon &&
                parseInt(item.base_amount) > 0
            ).length > 0
              ? true
              : false,
          filled:
            tdsState?.tdsChallans?.filter(
              (item) => item.finance_year === year && item.tds_type === '27Q' && item.month === mon
            ).length > 0
              ? true
              : false
        },
        '27EQ': {
          is_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '27EQ' &&
                item.month === mon &&
                parseInt(item.base_amount) > 0
            ).length > 0
              ? true
              : false,
          is_non_zero:
            tdsState?.tdsChallans?.filter(
              (item) =>
                item.finance_year === year &&
                item.tds_type === '27EQ' &&
                item.month === mon &&
                parseInt(item.base_amount) > 0
            ).length > 0
              ? true
              : false,
          filled:
            tdsState?.tdsChallans?.filter(
              (item) => item.finance_year === year && item.tds_type === '27EQ' && item.month === mon
            ).length > 0
              ? true
              : false
        }
      };
      const monthlyData = new Object();
      monthlyData[mon] = data;
      let currentDate = new Date();

      const selectedYear = year.split('-')[0];
      if (year !== currentFinYear && currentDate.getFullYear() >= parseInt(selectedYear)) {
        filterData.push(monthlyData);
      }
      // current date
      let complianceDate;
      let nextYear = 2000 + parseInt(year.split('-')[1]);

      if (nextYearMon.includes(mon) && nextYear != currentDate.getFullYear()) {
        complianceDate = new Date(`${mon} 1 ${currentDate.getFullYear() + 1}`);
      } else {
        complianceDate = new Date(`${mon} 1 ${currentDate.getFullYear()}`);
      }

      let visibleDate = new Date(complianceDate.setMonth(complianceDate.getMonth() + 1));
      if (year === currentFinYear && currentDate > visibleDate) {
        filterData.push(monthlyData);
      }
    });
    console.log('monthlyData', filterData);
    setZeroTDS(filterData);
  };

  return (
    <>
        {!isStartupFino && <Tdsform/>}
        <Card>
        

          <CardHeader title="TDS Dashboard" />
          {/* dropdown */}
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                // multiple
                value={year}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
                )}
                MenuProps={MenuProps}
              >
                {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <BasicChart title="TDS" score={tds_paid_score} />
              <Typography textAlign="center" mt={-2}>
                {/* {tdsfilled}/{tdscount} */}
                {score_data?.tds_paid?.ontime} /{score_data?.tds_paid?.count}
                <br />
                Compliances cleared
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Challan Details" />
          <TdsChallanDetails year={year} companyId={companyId} />
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Return Filling" />
          <ReturnFilling year={year} companyId={companyId} />
        </Card>
        <br></br>
        <Card>
          <CardHeader title=" Traces Demand Outstanding" />
          <TdsTracesDemand companyId={companyId} />
        </Card>
        <br></br>
        {/* <Card>
          <CardHeader title="a) Zero TDS Confirmation (If there is no TDS in any month, please select Yes)" />
          <TdsTableOne year={year} tdsChallans={zeroTDS} companyId={companyId} />
        </Card> */}
      </>
      );
}
