import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadLink from 'react-download-link';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function Charge() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form CHG-9-29092021- signed_FORMCHG929092021SIGNED.p df
            </TableCell>
            <TableCell align="center">29-09-2021</TableCell>
            <TableCell align="center">
              Form CHG - 9 - Application for registration of creation or modification of charge for
              debentures or rectification of particulars filed
            </TableCell>
            <TableCell align="center">3.66</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC44.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC44">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form CHG-9-24062021- signed_FORMCHG924062021SIGNED.p df
            </TableCell>
            <TableCell align="center">24-06-2021</TableCell>
            <TableCell align="center">
              Form CHG - 9 - Application for registration of creation or modification of charge for
              debentures or rectification of particulars filed
            </TableCell>
            <TableCell align="center">2.49</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC45.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC45">
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form CHG-1- 10012019_signed_FORMCHG110012019 SIGNED.pdf
            </TableCell>
            <TableCell align="center">10-01-2019</TableCell>
            <TableCell align="center">
              Form CHG - 1 - Application for registration of creation, modification of charge (other
              than those related to debentures)
            </TableCell>
            <TableCell align="center">4.63</TableCell>
            <TableCell align="center">
              <a target="_blank" href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROC46.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC46">
                Download
              </a>
            </TableCell>
          </TableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
