import React, { useReducer } from 'react';
import { GSTR1B } from './GstTables/GSTR1';
import { GSTR3B } from './GstTables/GSTR3B';
import { Card, CardHeader } from '@mui/material';
import { Demands } from './GstTables/Demands';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Grid } from '@mui/material';
import BasicChart from './BasicChart';
import { Box } from '@mui/material';
import BasicChartSmall from './BasicChartSmall';
import { EsiChallans } from './EsiTables/EsiChallans';
import { PaidPenalties } from './EsiTables/PaidPenalties';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { fetchEsiChallans, fetchEsiDueDates, fetchEsiPenalties } from 'src/redux/actions/esiBook';
import { useSelector } from 'react-redux';
import { NoStrollerSharp, NoStrollerTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import yearsToMonths from 'date-fns/fp/yearsToMonths';
import { OutstandingPenalties } from './EsiTables/OutstandingPenalties';
import { isStartupFino,istalenteye } from 'src/config';
import EsibasicD from './EsiDialog';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const years = ['2021-22', '2022-23', '2023-24'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}
export const EsiDashboard = () => {
  const dispatch = useDispatch();
  const orgState = useSelector((state) => state.orgProfileReducer);
  const challans = useSelector((state) => state.esiReducer.challans);
  const penalties = useSelector((state) => state.esiReducer.penalties);
  const [nos, setNos] = useState([]);
  const [challansData, setchallansData] = useState([]);
  const [penaltiesData, setpenaltiesData] = useState([]);
  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');

  const [year, setYear] = React.useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  let score_data = dashBoardScore?.[year];
  const esicount = score_data !== null ? score_data?.esi_challan.count : 0;
  const esifilled = score_data !== null ? score_data?.esi_challan.ontime : 0;

  const handleChange = (event) => {
    setYear(event.target.value);
    const dataChallan = challans.filter(
      (item) => item.esi_num === nos[value] && item.finance_year === event.target.value
    );
    const dataPenalties = penalties.filter(
      (item) =>
        item.esi_num === nos[value] &&
        parseInt(item.finance_year.split('-')[0]) <= parseInt(event.target.value.split('-')[0])
    );
    console.log('dataChallan', dataChallan);
    setchallansData(dataChallan);
    setpenaltiesData(dataPenalties);
  };

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (searchParams.get('page') != null) {
      setValue(parseInt(searchParams.get('page')));
    }
  }, [searchParams]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    handleDataChange(nos[newValue]);
    setSearchParams({companyid: companyId, esi_no: nos[newValue]});
  };

  useEffect(() => {
    dispatch(fetchEsiChallans(companyId));
    dispatch(fetchEsiPenalties(companyId));
    dispatch(fetchEsiDueDates());
  }, []);

  useEffect(() => {
    if (challans !== undefined && penalties !== undefined) {
      let nos = [
        ...new Set([
          ...challans.map((item) => item.esi_num)
          // ...penalties.map((item) => item.esi_num)
        ])
      ];
      nos = nos.filter((item) => item != null);
      setNos(nos);
      handleDataChange(nos[0]);
      setSearchParams({companyid: companyId, esi_no: nos[0]});
    }
  }, [challans, penalties]);

  const handleDataChange = (no) => {
    const dataChallan = challans.filter(
      (item) => item.esi_num === no && item.finance_year === year
    );
    const dataPenalties = penalties.filter(
      (item) =>
        item.esi_num === no &&
        parseInt(item.finance_year.split('-')[0]) <= parseInt(year.split('-')[0])
    );
    setchallansData(dataChallan);
    setpenaltiesData(dataPenalties);
  };

  return (
    <>
      {!(isStartupFino||istalenteye) && <EsibasicD challans = {challansData}/>}
      <Card>
        <CardHeader title="ESI Dashboard" />
        {/* dropdown */}
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              // multiple
              value={year}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
              )}
              MenuProps={MenuProps}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Card>
      <br></br>

      <Tabs
        value={value}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {nos.map((row, i) => {
          return <Tab label={row} {...a11yProps(i)} />;
        })}
      </Tabs>
      {nos.map((row, i) => {
        const esi_challan_score =
          (score_data?.esic_by_num?.[row]?.count || 0) == 0
            ? 100
            : parseInt(
                (score_data?.esic_by_num?.[row]?.ontime / score_data?.esic_by_num?.[row]?.count) *
                  100
              ) || 0;
        return (
          <>
          {/* <EsibasicD challans = {challansData}/> */}
          <TabPanel value={value} index={i}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <BasicChart title="ESI" score={esi_challan_score} />
                <Typography textAlign="center" mt={-2}>
                  {/* {esifilled}/{esicount} */}
                  {score_data?.esic_by_num?.[row]?.ontime || 0} /
                  {score_data?.esic_by_num?.[row]?.count || 0}
                  <br />
                  Compliances cleared
                </Typography>
              </Grid>
            </Grid>
            <Card>
              <CardHeader title="Monthly Challan Data" />
              <EsiChallans challans={challansData} />
            </Card>
            <br />
            <Grid item md={6}>
              <Card>
                <CardHeader title="Paid Penalties" />
                <PaidPenalties data={penaltiesData} />
              </Card>
            </Grid>
            <br />
            <Grid item md={6}>
              <Card>
                <CardHeader title="Outstanding Penalties" />
                <OutstandingPenalties data={penaltiesData} />
              </Card>
            </Grid>
          </TabPanel>
          </>
        );
      })}
    </>
  );
};
