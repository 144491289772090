// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

//redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LoadingIndicator from "src/theme/loader"
// ----------------------------------------------------------------------

// Sentry.init({
//   dsn: "https://3acf017c83404b729b3e21fa803af32f@o1200707.ingest.sentry.io/6346044",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Provider store={store.store}>
    {/* <PersistGate loading={null} persistor={store.persistor}> */}
      <HelmetProvider>
        <BrowserRouter>
          <LoadingIndicator/>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
