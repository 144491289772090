import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Autocomplete from '@mui/material/Autocomplete';

// import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import {useNavigate} from "react-router-dom";
import { getVaultData, updateVaultData } from 'src/redux/actions/loginVault';
import LoginVoltPopup from 'src/components/LoginVoltPopup';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useHref, useNavigationType, useSearchParams } from 'react-router-dom';
import { getAllCompanies, updateStatus } from 'src/redux/actions/dataoperator';
import Moment from 'react-moment';
import constants from 'src/utils/constants';
import { getCompanyData, setCompanyId } from 'src/utils/common';
import { getCompanyId } from 'src/utils/common';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import Switch from '@mui/material/Switch';
import { orderRocData } from 'src/redux/actions/roc';
import { order_tax_deduction_data } from 'src/redux/actions/incometax';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Dataoperater() {
  const authState = useSelector((state) => state.authReducer);
  const [open_active, setOpen_active] = useState(false);
  const [name_box, setname_box] = useState();
  const [dialog_row, setdialog_row] = useState();
  const [dialog_index, setdialog_index] = useState();

  const handleConfirmDialog = (e, row, index) => {
    e.preventDefault();
    setname_box(content[index].company_name);
    setdialog_row(row);
    setdialog_index(index);
    setOpen_active(true);
  };
  const handleClose_Active = () => {
    setOpen_active(false);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const companyIds = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();

  const navigate = useNavigate();
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const options = all_companies?.all_companies?.map((item) => item.company_name);
  console.log('options', options);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [update_Comp, setupdate_comp] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [company_name_auto, setcompany_name_auto] = useState();
  const [company_name_id, setcompany_name_id] = useState();
  const [company_type, setcompany_type] = useState();
  const handleClickOpen = (e, companyId) => {
    console.log('id', companyId);
    setCompanyId(companyId);
    setOpen(true);
  };
  const handleClickOpenUpdate = (e, index) => {
    setupdate_comp(true);
    setname_box(content[index].company_name);
    setcompany_name_id(content[index].id);
    console.log('settedid', content[index].id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseUpdate = () => {
    setupdate_comp(false);
  };

  const handleAgree = () => {
    setOpen(false);
    order_roc_data();
  };

  const selectCompany = (e, companyId) => {
    e.preventDefault();
    //const companyId = all_companies.all_companies[]['id'];
    //console.log('companyId', all_companies.all_companies[index])
    dispatch({
      type: constants.SET_COMPANY_ID,
      payload: companyId
    });
    setCompanyId(companyId);
    localStorage.setItem('company_id', companyId);
    navigate(`/dashboard/main?companyid=${companyId}`, { replace: true });
  };

  const order_roc_data = () => {
    dispatch(orderRocData(companyId));
  };

  const order_tax_deduction_data_api = (e, company_id) => {
    e.preventDefault();
    dispatch(order_tax_deduction_data(company_id));
  };

  const [content, setContent] = useState([]);
  let se;
  const [searched, setSearched] = useState();
  const requestSearch = (searchedVal) => {
    console.log("searchedVal",searchedVal);
    if (value == 0) {
      const secondary_filter = all_companies.all_companies.filter(
        (item) => item.source === 'StartupFino' && item.category === 'Default'
      );
      const filteredRows = secondary_filter.filter((ele) => {
        return ele.company_name.toLowerCase().startsWith(searchedVal.toLowerCase());
      });
      setContent(filteredRows);
    }
    if (value == 1) {
      const secondary_filter = all_companies.all_companies.filter(
        (item) => item.source === 'One Time'
      );
      const filteredRows = secondary_filter.filter((ele) => {
        return ele.company_name.toLowerCase().startsWith(searchedVal.toLowerCase());
      });
      setContent(filteredRows);
    }
    if (value == 2) {
      const secondary_filter = all_companies.all_companies.filter(
        (item) => item.source === 'StartupFino' && item.category === 'Churn Client'
      );
      const filteredRows = secondary_filter.filter((ele) => {
        return ele.company_name.toLowerCase().startsWith(searchedVal.toLowerCase());
      });
      setContent(filteredRows);
    }
    if (value == 3) {
      const secondary_filter = all_companies.all_companies.filter(
        (item) => item.source === 'StartupFino' && item.category === 'Low Priority'
      );
      const filteredRows = secondary_filter.filter((ele) => {
        return ele.company_name.toLowerCase().startsWith(searchedVal.toLowerCase());
      });
      setContent(filteredRows);
    }
  };
  {
    console.log('content changed is', content);
  }
  console.log(
    'One_Time',
    all_companies.all_companies.filter((item) => item.source === 'One Time')
  );
  console.log(
    'Already Exisits',
    all_companies.all_companies.filter((item) => item.source === 'StartupFino')
  );

  // useEffect(
  //   () => {
  //     setContent(all_companies.all_companies.filter((item) => item.source === 'StartupFino'));
  //   },
  //   [all_companies],
  //   ['id']
  // );
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const [toggle, setToggle] = useState();
  const handleSwitch = (e, row, index) => {
    console.log('change', toggle);
    var switchdata = new FormData();
    console.log('i am ', content[index].is_company_active);
    if (row.is_company_active == true) {
      content[index].is_company_active = false;
      console.log('update', content[index].is_company_active);
    } else if (row.is_company_active == false) {
      content[index].is_company_active = true;
      console.log('update', content[index].is_company_active);
      console.log('Row', row);
    }
    switchdata.append('companyId', companyIds);
    switchdata.append('company_type', row.company_type);
    switchdata.append('date_of_inc', row.date_of_inc);
    switchdata.append('is_company_active', content[index].is_company_active);
    console.log('switch', switchdata);
    dispatch(updateStatus(switchdata, row.id));
    setContent([]);
    if (value == 0) {
      console.log('true');
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Default'
        )
      );
    }
    if (value == 1) {
      setContent(all_companies.all_companies.filter((item) => item.source === 'One Time'));
    }
    if (value == 2) {
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Churn Client'
        )
      );
    }
    if (value == 3) {
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Low Priority'
        )
      );
    }
    handleClose_Active(false);

    console('COMPANY SWITCH SUCCESSS -- -  - - - - - ');
  };
  //onst label = { inputProps: { 'aria-label': 'Switch demo' } };

  let tr = 0,
    fa = 0,
    i = 0;
  content.map((row) => {
    row.is_company_active ? tr++ : fa++;
    i++;
  });
  const handlePostData = () => {
    // Prepare the data to be sent in the request
    const requestData = {
      category: company_type
    };

    // Set up the request configuration
    const config = {
      method: 'patch',
      url: `https://api.startupfino.in/company/company/${company_name_id}/`,
      headers: {
        Authorization: localStorage.getItem('token') // Replace with your actual access token
      },
      data: requestData
    };

    // Send the PATCH request
    axios
      .request(config)
      .then((response) => {
        console.log('Company category updated successfully:', response.data);
        toast.success('Updated Successfully');
        // Handlse any other logic, e.g., close the dialog, update state, etc.
        dispatch(getAllCompanies());
        setContent([]);
        handleCloseUpdate();
        if (company_type == 'Churn Client') {
          setValue(2);
          const secondary_filter = all_companies.all_companies.filter(
            (item) => item.source === 'StartupFino' && item.category === 'Churn Client'
          );
          setContent(secondary_filter);
        }
        if (company_type == 'Lower Priority') {
          setValue(3);
          const secondary_filter = all_companies.all_companies.filter(
            (item) => item.source === 'StartupFino' && item.category === 'Low Priority'
          );
          setContent(secondary_filter);
        }
        setcompany_name_auto();
        setcompany_type();
      })
      .catch((error) => {
        console.error('Error updating company category:', error);

        // Handle error, show error message, etc.
      });
  };

  console.log('true', tr, 'false', fa, 'all ', i);
  const handleadd = (row) => {
    // const {row} = props;
    let url = `https://task-manager-startupfino.netlify.app/?companyid=${row}`;
    // s = s+ window.origin;
    // s = s+"/new-task?companyid="+row;
    window.open(url, '_blank', 'noreferrer');
  };
  console.log('WINDOWS.>>>>>>', window.origin);
  // const cancelSearch = () => {
  //   setSearched("");
  //   requestSearch(searched);
  // };

  // {
  //   console.log("Hello World",content)
  //   let a= [] , i=0;
  //   let b = all_companies;
  //    all_companies.map((ele) => (
  //     console.log("Hello World"),
  //     a[i] = ele.company_name,
  //     i++
  //   ))
  //   console.log("Data stored in a",a)
  //   console.log("Data stored in b",b)
  // }

  useEffect(() => {
    if (value == 0) {
      console.log('true');
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Default'
        )
      );
    }
    if (value == 1) {
      setContent(all_companies.all_companies.filter((item) => item.source === 'One Time'));
    }
    if (value == 2) {
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Churn Client'
        )
      );
    }
    if (value == 3) {
      setContent(
        all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Low Priority'
        )
      );
    }

    console.log('Rushabh', value, authState);
  }, [value, all_companies]);
  console.log('HiRushabh', content);
  const active_fino = all_companies?.all_companies?.filter(
    (item) => item.is_company_active == true
  );
  const Inactive_fino = all_companies?.all_companies?.filter(
    (item) => item.is_company_active == false
  );
  const determineCategory = (option) => {
    const item = all_companies?.all_companies.find((item) => item.company_name === option);
    console.log('item', item);
    if (item.source === 'One Time') {
      return 'One Time';
    } else if (item.source === 'StartupFino' && item.category === 'Churn Client') {
      return 'Churn';
    } else if (item.source === 'StartupFino' && item.category === 'Low Priority') {
      return 'Lower Priority';
    } else if (item.category === 'Default' && item.source === 'StartupFino') {
      return 'Recurring';
    }
  };

  const determineContent = (newValue) => {
    // Determine content based on the selected value
    switch (newValue) {
      case 'Churn':
        return all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Churn Client'
        );
      case 'One Time':
        return all_companies.all_companies.filter((item) => item.source === 'One Time');
      case 'Lower Priority':
        return all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Low Priority'
        );
      case 'Recurring':
        return all_companies.all_companies.filter(
          (item) => item.source === 'StartupFino' && item.category === 'Default'
        );
      default:
        // Handle default case or return an empty array
        return [];
    }
  };

  return (
    <>
      {/* {vaultState.isUpdated && <Alert severity="success">Successfully Updated!</Alert>} */}
      {/* <SearchBar/> */}
      <Dialog fullWidth={true} maxWidth={'sm'} open={open_active} onClose={handleClose_Active}>
        <DialogTitle>Do you want to change Company status</DialogTitle>
        <DialogContent>{name_box}</DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={(e) => {
              handleSwitch(e, dialog_row, dialog_index);
            }}
          >
            Yes
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={(e) => {
              handleClose_Active();
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" sx={{ mb: 2 }} onClick={handleClickOpenUpdate}>
        Update Company Type
      </Button>
      <Grid container>
        <h5>
          Total StartupFino Companies : {options?.length} | Active Companies : {active_fino?.length}{' '}
          | Inactive Companies : {Inactive_fino?.length}{' '}
        </h5>
      </Grid>
      {/* <Autocomplete
        value={company_name_auto}
        name="company_name"
        fullWidth
        onChange={(event, newValue) => {
          requestSearch(newValue)
          setcompany_name_auto(newValue);
          let ids = all_companies?.all_companies.find((item) => item.company_name === newValue);
          console.log("ids",ids)
          console.log("idss==soruce",ids?.source)
          setContent([ids])
          if(ids?.source==="StartupFino" && ids?.category==="Default"){
            setValue(0)
          }
          if(ids?.source==="One Time"){
            setValue(1)
          }
          if(ids?.source==="StartupFino" && ids?.category==="Chrun Client"){
            setValue(2)
          }
          if(ids?.source==="StartupFino" && ids?.category==="Low Priority"){
            setValue(3)
          }

        }}
        display="flex"
        id="controllable-states-demo"
        options={options}
        groupBy={(option) => determineCategory(option)}
        sx={{ margin: 1 }}
        renderInput={(params) => <TextField {...params} label="Search in All Categories" />}
      /> */}

      <Box sx={{ display: 'flex', width: 100 }}></Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Reccuring" {...a11yProps(0)} />
            <Tab label="One Time" {...a11yProps(1)} />
            <Tab label="Churn" {...a11yProps(2)} />
            <Tab label="P2 Client" {...a11yProps(3)} />
          </Tabs>
        </Box>
      </Box>
      <TextField
        sx={{ paddingBottom: 2, mt: 2 }}
        fullWidth
        label={
          value == 0
            ? 'Search in Recurring'
            : value == 1
            ? 'Search in One Time'
            : value == 2
            ? 'Search in Churn'
            : 'Search in Lower Priority'
        }
        id="fullWidth"
        value={se}
        onChange={(e) => requestSearch(e.target.value)}
      />
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          }
        }}
        aria-label="simple table"
      >
        <TableBody>
          <TableCell align="center">
            {value == 0 && <h4>Reccuring Companies : {i}</h4>}
            {value == 1 && <h4>One Time Companies : {i}</h4>}
            {value == 2 && <h4>Churn Companies : {i}</h4>}
            {value == 3 && <h4>Low Priority Companies : {i}</h4>}
          </TableCell>
          <TableCell align="center">
            <h4>Total Active Companies : {tr}</h4>
          </TableCell>
          <TableCell align="center">
            <h4>Total Inactive Companies : {fa}</h4>
          </TableCell>
        </TableBody>
      </Table>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Company Name</TableCell>
              <TableCell align="center">Onboarding Date</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Add Task</TableCell>
              <TableCell align="center">Update</TableCell>
              <TableCell align="center">ROC Data</TableCell>
              <TableCell align="center">26AS Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {content
              .sort(function (a, b) {
                const date1 = new Date(a.created_at);
                const date2 = new Date(b.created_at);
                return date2 - date1;
              })
              .map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">{row.company_name}</TableCell>
                  <TableCell align="center">
                    {<Moment format="MMMM Do YYYY, h:mm:ss a">{row.created_at}</Moment>},{' '}
                    <Moment fromNow>{row.created_at}</Moment>
                  </TableCell>
                  <TableCell align="center">
                    {row.source === 'StartupFino' && row.category === 'Default' && (
                      <>
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            handleClickOpenUpdate(e, index);
                          }}
                        >
                          Reccuring
                        </Button>
                      </>
                    )}
                    {row.source === 'StartupFino' && row.category === 'Churn Client' && (
                      <>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={(e) => {
                            handleClickOpenUpdate(e, index);
                          }}
                        >
                          Churn
                        </Button>
                      </>
                    )}
                    {row.source === 'StartupFino' && row.category === 'Low Priority' && (
                      <>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            handleClickOpenUpdate(e, index);
                          }}
                        >
                          Lower Priority
                        </Button>
                      </>
                    )}
                    {row.source === 'One Time' && (
                      <>
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={(e) => {
                            handleClickOpenUpdate(e, index);
                          }}
                        >
                          One Time
                        </Button>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {/* // {row.is_company_active ? <h4>Active</h4> : <h4>Inactive</h4>} */}
                    <Box
                      onClick={(e) => {
                        handleConfirmDialog(e, row, index);
                      }}
                    >
                      {/* {row.is_company_active ? (
                      <>
                        <h4>Active</h4>
                        <Switch
                          onClick={(e) => {
                            // handleConfirmDialog();
                            // handleSwitch(e, row, index);
                          }}
                          defaultChecked
                        />
                      </>
                    ) : (
                      <>
                        <h4>Inactive</h4>
                        <Switch
                          onClick={(e) => {
                            // handleConfirmDialog();
                            // handleSwitch(e, row, index);
                          }}
                        />
                      </>
                    )} */}
                      {row?.is_company_active ? 'Active' : 'Inactive'}
                      <Switch checked={row?.is_company_active} />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={(e) => {
                        console.log('row   ->>>', row.id);
                        handleadd(row.id);
                        // href=(http://localhost:3000/add-task?companyid=510abbef-aeab-4131-b386-cff29a231bd4);
                      }}
                      // aria-label="delete"
                      color="primary"
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(e) => selectCompany(e, row.id)}
                    >
                      Update item
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(e) => handleClickOpen(e, row.id)}
                    >
                      Order ROC Data
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(e) => order_tax_deduction_data_api(e, row.id)}
                    >
                      Order 26AS
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Making Order for ROC Data'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to proceed to make order ROC Data ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleAgree} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={update_Comp}
          onClose={handleCloseUpdate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth="true"
        >
          
          {value===0&&
          <DialogTitle id="alert-dialog-title">
            Update Recurring Company
          </DialogTitle>
          }
          {value===1&&
          <DialogTitle id="alert-dialog-title">
            Update One Time
          </DialogTitle>
          }
          {value===2&&
          <DialogTitle id="alert-dialog-title">
            Update Churn Client
          </DialogTitle>
          }
          {value===3&&
          <DialogTitle id="alert-dialog-title">
            Update P2 Client
          </DialogTitle>
          }
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={name_box}
                  name="company_name"
                  fullWidth
                  // onChange={(event, newValue) => {
                  //   setcompany_name_auto(newValue);
                  //   let ids = all_companies?.all_companies.filter(
                  //     (item) => item.company_name === newValue
                  //   );
                  //   console.log(ids[0]?.id, 'idSelctedauto');
                  //   setcompany_name_id(ids[0]?.id);
                  // }}
                  display="flex"
                  id="controllable-states-demo"
                  // options={options}
                  sx={{ margin: 1 }}
                  renderInput={(params) => <TextField {...params} label="Company Name" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={company_type}
                  name="company_name"
                  fullWidth
                  onChange={(event, newValue) => {
                    setcompany_type(newValue);
                  }}
                  display="flex"
                  id="controllable-states-demo"
                  options={['Low Priority', 'Churn Client']}
                  sx={{ margin: 1 }}
                  renderInput={(params) => <TextField {...params} label="Company Type" />}
                />{' '}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdate} variant="contained" color="error">
              Close
            </Button>
            <Button onClick={handlePostData} variant="contained" color="success" autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </>
  );
}
