import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { setToken, setCompanyData, setCompanyId } from '../../utils/common';
import { toast } from 'react-toastify';

  export const cmp_list_data = (client_type,companyId,period,year) =>(dispatch)=> {
    HttpWrapper('GET', `/compliance/send-report/${client_type}/${companyId}/${period}/${year}/`)
      .then((res, err) => {
        console.log('res', res.data);
        dispatch({
            type: Constants.SET_MONTH_COMP,
            payload: res.data
          });
        toast.success('Changed Company Status');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  export const send_email = (data) => () => {
    HttpWrapper('POST', `/compliance/send-email-report/`, false, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Add Successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };