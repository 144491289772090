import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
import { useDispatch,useSelector } from 'react-redux';
import { element } from 'prop-types';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import {addincometax} from 'src/redux/actions/incometax';
import { addincometaxpay } from 'src/redux/actions/incometax';
import { addoutsandmd } from 'src/redux/actions/incometax';

//

function SelectAutoWidth() {
    const [complianceId, setComplianceId] = useState('');
    
    const handleChange = (event) => {
        setComplianceId(event.target.value);
        

    };

    return (
        <div>
            <Box
                container
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                sx={{ margin: 1, overflow: "scroll" }}
            >
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Compliance</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={complianceId}
                        onChange={handleChange}
                        autoWidth
                        label="ComplianceId"
                    >
                        <MenuItem value={10}>Income Tax Return (Section)</MenuItem>
                        <MenuItem value={21}>Income Tax Payment</MenuItem>
                        <MenuItem value={22}>Outstanding Demand </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box>
                {/* <h1>Hello world</h1> */}
                {
                    {
                        10: <Income_Tax_Return/>,
                        21: <Income_Tax_Payment/>,
                        22: <Outstanding_Demand/>
                    }[complianceId]
                }
            </Box>
        </div>
    );
}
const Income_Tax_Return = () => {
    const [value, setValue] = useState(null);
    const [formData, setFormData] = useState({
        finance_year: "",
        period: "",
        form_name: "",
        date_fill: "",
        interest_paid: '',
        status:"",
        acknowledgement:"",
        form:""
    });
    const itstatus = ["Original", "Revised"];
    const form_names = ["ITR", "Tax Audit Report", "TP Audit Report"];
    const fyear = ["2021-22", "2022-23", "2023-24"];
    const period = ["Apr-March"];
    const [itstat, setItstat] = useState();
    const [perd, setPerd] = useState();
    const [intrest, setInterest] = useState();
    const [finace, setFinace] = useState();
    const [formtyp, setFormtyp] = useState();
    const dispatch = useDispatch();
    let name, valuee;
    const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

    
    const handleFormDataChange = (e) => {
        console.log(e);
        name = e.target.name;
        valuee = e.target.value;
        setFormData({ ...formData, [name]: valuee });
        console.log(name);
        console.log(valuee);
        console.log("HEllo WORLD");
    }
    const dis = (e) => {
        
        // formData['complianceId'] = complianceId
        formData.complianceID="06602b02-2987-47ed-b560-1153fd3fca94"
        formData['companyId'] = companyId
        formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')        //formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
        var bodyFormData = new FormData();
        for(let key in formData){
          if(key,formData[key] !== ''){
            bodyFormData.append(key,formData[key])
          }
        }
        console.log('form filled income tax return:', formData);
        //toast.success('Add Successfully');
        dispatch(addincometax(bodyFormData,companyId));
      };

      const handleSelectFile = (e) => {
        e.preventDefault();
    
        name = e.target.name;
        valuee = e.target.files[0];
        setFormData({ ...formData, [name]: valuee });
        console.log('File name', formData)
      }
        return (
        <>
            <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
      
      
                {/*income tax return : Financial  Year */}
                
                <Autocomplete
                    value={finace} name="finance_year"
                    onChange={(event, newValue) => {
                        setFinace(newValue);
                        { 
                            formData.finance_year = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={fyear}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Finance year" />}
                />
                {/*income tax return : Period */}
                <Autocomplete
                    value={perd} name="period"
                    onChange={(event, newValue) => {
                        setPerd(newValue);
                        { 
                            formData.period = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={period}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Period" />}
                />

                {/*income tax return : Form Name */}

                <Autocomplete
                    value={formtyp} name="form type"
                    onChange={(event, newValue) => {
                        setFormtyp(newValue);
                        { 
                            formData.form_name = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={form_names}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Form Type" />}
                />
                {console.log(formData)}

                {/*income tax return : filling date\\\\*/}
                
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date Of Filling"
                        value={value} name="date_fill"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setValue(newValue);
                            { 
                                formData.date_fill = newValue; 
                            }
                            { 
                                handleFormDataChange; 
                            }
                        }}
                        renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
                    />
                </LocalizationProvider>
                
                {/*income tax return : It status*/}
                <TextField
                    value={intrest}
                    required
                    name="interest_paid"
                    type="number"
                    onChange={(e) => {
                        setInterest(e.target.value);
                        {
                        formData.interest_paid = e.target.value;
                        }
                        {
                        handleFormDataChange;
                        }
                    }}
                    label="Interest Paid"
                    variant="outlined"
                    sx={{ margin: 1 }}
                    />

                <Autocomplete
                    value={itstat} name="it stat"
                    onChange={(event, newValue) => {
                        setItstat(newValue);
                        { 
                            formData.status = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={itstatus}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Status" />}
                />

                {/*income tax return : It status*/}

                <h5>Acknowledgement </h5>
                <TextField required name='acknowledgement' onChange={handleSelectFile} type="file"  hidden sx={{ margin: 1 }} />

                {/*income tax return : It status*/}

                <h5>Form </h5>
                <TextField required name='form' onChange={handleSelectFile} type="file"  sx={{ margin: 1 }} />
            </Box>

                {/*income tax return : Save Button */}

            <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
                <Button onClick={dis} variant="contained">SAVE</Button></Box>
        </>
    );
};
const Income_Tax_Payment = () => {
    const [value, setValue] = useState(null);
    const [formData, setFormData] = useState({
        finance_year: "Finance year",
        nature_tax: "Tax Nature",
        period: "Period",
        payment_dates: "",
        amount:"",
        challan_num: "",
        bsr_code: "",
        upload_challan: ""
        
    });
    
    const nature_tax = ["Advance Tax", "Self Assessment Tax"];
    const fyear = ["2021-22", "2022-23", "2023-24"];
    const period = ["Apr-March"];
    const [perd, setPerd] = useState();
    const [finace, setFinace] = useState();
    const [taxntr, setTaxntr] = useState();
    const [amt,setAmt]=useState();
    const [chall_no, setChall_no] = useState();
    const [bsrcd, setBsrcd] = useState();
    const dispatch = useDispatch();  
    let name, valuee;
    const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

    const handleFormDataChange = (e) => {
        console.log(e);
        name = e.target.name;
        valuee = e.target.value;
        setFormData({ ...formData, [name]: valuee });
        console.log(name);
        console.log(valuee);
        console.log("HEllo WORLD");
    }

    const handleSelectFile = (e) => {
        e.preventDefault();

        name = e.target.name;
        valuee = e.target.files[0];
        setFormData({ ...formData, [name]: valuee });
        console.log('File name', formData)
      }
      const dis = (e) => {
        // formData['complianceId'] = complianceId
        formData['companyId'] = companyId
        formData.complianceID="06602b02-2987-47ed-b560-1153fd3fca94"
        formData.payment_dates = moment(formData.payment_dates).format('YYYY-MM-DD')
        var bodyFormData = new FormData();
        for(let key in formData){
          if(key,formData[key] !== ''){
            bodyFormData.append(key,formData[key])
          }
        }
        console.log('form data', formData);
        //toast.success('Add Successfully');
        dispatch(addincometaxpay(bodyFormData));
      };

    
    return (
        <>
            <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

                {/*income tax payment : finance_year */}
                
                <Autocomplete
                    value={finace} name="finance_year"
                    onChange={(event, newValue) => {
                        setFinace(newValue);
                        { 
                            formData.finance_year = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={fyear}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Finance year" />}
                />
                {/*income tax payment : Period */}

                <Autocomplete
                    value={perd} name="Period"
                    onChange={(event,newValue) => {
                        setPerd(newValue);
                        { 
                            formData.period = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={period}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Period" />}
                />
                {/*income tax payment : Tax Nature */}

                <Autocomplete
                    value={taxntr} name="Tax nature"
                    onChange={(event, newValue) => {
                        setTaxntr(newValue);
                        { 
                            formData.nature_tax = newValue; 
                        }
                        { 
                            handleFormDataChange; 
                        }
                    }}
                    id="controllable-states-demo"
                    options={nature_tax}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Tax Nature" />}
                />
                {console.log(formData)}

                {/*income tax payment : Date Of Payment */}

                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date Of Payment"
                        value={value} name="payment_dates"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setValue(newValue);
                            { 
                                formData.payment_dates = newValue; 
                            }
                            { 
                                handleFormDataChange; 
                            }
                        }}
                        renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
                    />

                </LocalizationProvider>

                {/*income tax payment : Amount */}

                <TextField
                    value={amt} name="amount"
                    onChange={(e) => {
                        setAmt(e.target.value);
                        { 
                            formData.amount = e.target.value;
                        }
                        { 
                            handleFormDataChange;
                        }
                    }}
                    label="Amount" variant="outlined" required sx={{ margin: 1 }} />

                {/*income tax payment : Challan Number */}

                <TextField
                    value={chall_no} name="challan_num"
                    onChange={(e) => {
                        setChall_no(e.target.value);
                        { formData.challan_num = e.target.value };
                        { handleFormDataChange };
                    }}
                    label="Challan Number" variant="outlined" required sx={{ margin: 1 }} />

                {/*income tax payment : BSR code */}

                <TextField value={bsrcd} name="bsr code" onChange={(e) => {
                    setBsrcd(e.target.value);
                    { formData.bsr_code= e.target.value };
                    { handleFormDataChange };
                }} label="BSR code" variant="outlined" sx={{ margin: 1 }} />

                {/*income tax payment : Upload Challan*/}
                
                <h5>Upload Challan</h5>
                <TextField required name='upload_challan' onChange={handleSelectFile} type="file"  sx={{ margin: 1 }} />
            </Box>
            <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
                <Button onClick={dis} variant="contained">SAVE</Button></Box>
        </>
    );
};
const Outstanding_Demand = () => {
    const [value, setValue] = useState(null);
    const [valuez, setValuez] = useState(null);
    const [formData, setFormData] = useState({
        finance_year: "Finance year",
        period: "Period",
        demand_month: "",
        demand_date:"",
        section: "",
        demand_amount: "",
        intrest: "",
        payment_date: "",
        notice:""

    });
    const fyear = ["2021-22", "2022-23", "2023-24"];
    const period = ["Apr-March"];
    const [perd, setPerd] = useState();
    const [finace, setFinace] = useState();
    const [sect, setSect] =useState();
    const [amt, setAmt] =useState();
    const dispatch = useDispatch();
    let name, valuee;
    const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
    const handleFormDataChange = (e) => {
        console.log(e);
        name = e.target.name;
        valuee = e.target.value;
        setFormData({ ...formData, [name]: valuee });
        console.log(name);
        console.log(valuee);
        console.log("HEllo WORLD");
    }
    const handleSelectFile = (e) => {
        e.preventDefault();
    
        name = e.target.name;
        valuee = e.target.files[0];
        setFormData({ ...formData, [name]: valuee });
        console.log('File name', formData)
      }
    const dis = (e) => {
        // formData['complianceId'] = complianceId
        formData['companyId'] = companyId
        formData.complianceID="06602b02-2987-47ed-b560-1153fd3fca94"
        //formData['complianceId']="06602b02-2987-47ed-b560-1153fd3fca94"
        formData.demand_month = moment(formData.demand_date).format('MMMM')
        formData.demand_date = moment(formData.demand_date).format('YYYY-MM-DD')
        formData.payment_date = moment(formData.payment_date).format('YYYY-MM-DD')
        console.log("month",formData.demand_month);
        var bodyFormData = new FormData();
        if (formData.payment_date !== 'null') 
        {
          formData['companyId'] = companyId
          //toast.success('Add Successfully');
          formData.complianceID="06602b02-2987-47ed-b560-1153fd3fca94"
          var bodyFormData = new FormData();
          console.log("Date enterd")
          formData.payment_date = moment(formData.payment_date).format('YYYY-MM-DD')
          for (let key in formData) {
            console.log("indian", key);
    
            if (key, formData[key] !== '0' || key, formData[key] !== '') {
              bodyFormData.append(key, formData[key])
            }
      
          }
          console.log('form data', formData);
          
          dispatch(addoutsandmd(bodyFormData));
    
        }
        if (formData.payment_date == 'null'||formData.payment_date == 'Invalid date' ) 
        {
          console.log("date removed");
          formData['companyId'] = companyId
          //toast.success('Add Successfully');
          formData.complianceID="06602b02-2987-47ed-b560-1153fd3fca94"
          var bodyFormData = new FormData();
          for (let key in formData) {
            console.log("main key", key);
    
            if (key, formData[key] !== '0') 
            {
              if(key=="payment_date"){
                formData.payment_date="null"
                console.log("ok",formData.payment_date)
                
              } 
              else{
                bodyFormData.append(key, formData[key])
              }
            }
            
          }
         
          console.log('form data', formData);
            dispatch(addoutsandmd(bodyFormData));
    
         
        }
    }

    return (
        <>
            <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
         
                <Autocomplete
                    value={finace} name="finance_year"
                    onChange={(event, newValue) => {
                        setFinace(newValue);
                        { formData.finance_year = newValue };
                        { handleFormDataChange };
                    }}
                    id="controllable-states-demo"
                    options={fyear}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Finance year" />}
                />
                <Autocomplete
                    value={perd} name=" period "
                    onChange={(event, newValue) => {
                        setPerd(newValue);
                        { formData.period = newValue };
                        { handleFormDataChange };
                    }}
                    id="controllable-states-demo"
                    options={period}
                    sx={{ margin: 1 }}
                    renderInput={(params) => <TextField {...params} required label="Period" />}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date Of Demand"
                        value={value} name="demand_date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setValue(newValue);
                            { formData.demand_date = newValue };
                            { handleFormDataChange };
                        }}
                        renderInput={(params) => <TextField {...params} required sx={{ margin: 1 }} />}
                    />
                </LocalizationProvider>
                <TextField
                    value={sect} name="section"
                    onChange={(e) => {
                        setSect(e.target.value);
                        { formData.section = e.target.value };
                        { handleFormDataChange };
                    }}
                    label="Section" variant="outlined" required sx={{ margin: 1 }} />
                <TextField value={amt} name="demand_amount" onChange={(e) => {
                    setAmt(e.target.value);
                    { formData.demand_amount = e.target.value };
                    { handleFormDataChange };
                }} label="Amount" variant="outlined" required sx={{ margin: 1 }} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label=" Demand Payment Date"
                        value={valuez} name="payment_date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setValuez(newValue);
                            { formData.payment_date = newValue };
                            { handleFormDataChange };
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
                    />
                </LocalizationProvider>
                
                <h5>Notice Copy</h5>
                <TextField required name='notice' onChange={handleSelectFile} type="file"  sx={{ margin: 1 }} />
            </Box>
            <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
                <Button onClick={dis}  variant="contained">SAVE</Button></Box>
        </>
  );

};

export default SelectAutoWidth;
