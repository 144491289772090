import axios from 'axios';
import { base_url, HEADERS_DEFAULT, HEADERS_FORM_DATA } from '../config';
import { getToken } from '../utils/common';

const HttpWrapper = (method, apiUrl, withFormDataHeaders, data, isAuth = false) => {
  if (!isAuth) {
    HEADERS_DEFAULT['Authorization'] = getToken();
    HEADERS_FORM_DATA['Authorization'] = getToken();
  }
  return axios({
    method,
    url: base_url + apiUrl,
    headers: withFormDataHeaders ? HEADERS_FORM_DATA : HEADERS_DEFAULT,
    data
  });
};

export default HttpWrapper;
