import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function OtherDownloadable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Approval letter of extension of financial year or AGM 10022022_APPROVALLETTEROFEXTENS
            </TableCell>
            <TableCell align="center">10-02-2022 </TableCell>
            <TableCell align="center">Approval letter for extension of AGM </TableCell>
            <TableCell align="center"> 0.12</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REeJneMvHU/HSBrdVchPrefeAAg3YNDlCqrdDQFtsgin0rE/8wpoW2x//I7cxrLwAdcg3rx16Fn+dp4EMSDEZcs/ndSv8SXmZI9f6rwTFpAzz1ebKI6YVxb6mrLs0CcazE="
                ownload="ROC47"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Directors report as per section 134(3)- 10022022_DIRECTORSREPORTASPERSE
              CTION134310022022.pdf
            </TableCell>
            <TableCell align="center">10-02-2022 </TableCell>
            <TableCell align="center">Description not available</TableCell>
            <TableCell align="center">1.47</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGxwzVcckckYyOu4iAlttzC2BT3WaxM3ohvT3Ewp8GytBd0scO3eGdOOYNTjMif8AXMyDbD8UhZNX3gWRdbGAXvAxM7sG5exoy5cq+KHyzTuQ=="
                download="ROC48"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Financial Staements duly authenticated as per section 134 (Including Boards
              report, auditors
            </TableCell>
            <TableCell align="center">10-02-2022 </TableCell>
            <TableCell align="center">Auditors report</TableCell>
            <TableCell align="center"> 2.44</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+GyMhBwBh5yK3rmk8DRG1Tz5+uZM0n3B4HQfonK4UthSg9iD505RU5eVvbjUi5BjIDPKVuiNDI4mHNPbGwMt1pylwBEzz0oA85EYeVfJnRJnNIw42yFKaigF8wp4/HY6z6PpXLrwcIBJ1qZB1YKFS64Wu5y4nX8uEpE8Qozs02bcnh0ADGxRrydnRmhwAVl/m1enAEYVSJMoC3i0qfTjnxc="
                download="ROC49"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">17-09-2021 </TableCell>
            <TableCell align="center">List of allottees</TableCell>
            <TableCell align="center"> 1.39</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZYPKDoCSdR4NMUrbEORT4w2mlipHmhwr5Hre+VvpyfiCDhH0lDRYG5o="
                download="ROC50"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">17-09-2021 </TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center">0.43</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZTYoWJjU3t0NxR+SNVZQtleE7YmaNzjgpPep4AyiKGaeZZmESDzaTME="
                download="ROC51"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">14-09-2021 </TableCell>
            <TableCell align="center">List of allottees</TableCell>
            <TableCell align="center">0.44</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZRrG6sGftB5NfArRCTNpKT57slpAd6mzYaayIOV9FRJagAkkivR0HyM="
                download="ROC52"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the resolution authorising the issue of the debenture
              series_COPYOFTHERESOLUTIONAUTHO
            </TableCell>
            <TableCell align="center">13-09-2021</TableCell>
            <TableCell align="center">Resolution authorising issue of debenture series</TableCell>
            <TableCell align="center"> 0.36</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+HubVpGCPHZfGcvGnJpSUtyZX98lzgGnzzXP+klYOXDRZD1AxUzyaCD56Kx2mEgp/ImVNcXW23HYXy6qpMgrtg+TOHEfXquOBbZvJDfBx4m8nGt8NUR339KqW5d3IlPn+A=="
                download="ROC53"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">30-07-2021 </TableCell>
            <TableCell align="center">Description not available</TableCell>
            <TableCell align="center"> 1.22</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2+JiA3NTqz9EXAbVOSaPAmCMM5bFSXbps7xxaIYHu+7srRSjS+UM58NA6msLnLKaNQ=="
                download="ROC54"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">21-06-2021</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.62</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZfvXzdZfliHR3MQiGCIyFfa6k3C4x2UBsC0Z/yzJKIIdBWXhItTqUO8="
                download="ROC55"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the resolution authorising the issue of the debenture
              series_COPYOFTHERESOLUTIONAUTHO
            </TableCell>
            <TableCell align="center">03-06-2021 </TableCell>
            <TableCell align="center">Resolution authorising issue of debenture series </TableCell>
            <TableCell align="center"> 0.33</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+HubVpGCPHZfGcvGnJpSUtyZX98lzgGnzzXP+klYOXDRZD1AxUzyaCD56Kx2mEgp/ImVNcXW23HYS97Pte5NZV+z20woiCVkEex1qBBHI1ZQtjCxRp+gRXv8/UInGmBfGA=="
                download="ROC56"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association 20052021_ALTEREDARTICLESOFASSOCI ATION20052021.pdf
            </TableCell>
            <TableCell align="center">20-05-2021 </TableCell>
            <TableCell align="center">AoA - Articles of Association </TableCell>
            <TableCell align="center"> 2.23</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE49K8rvBCXKiuUBaRCxT4+eJc50m3C0kc4B+jEQ0Cv56ZQJZeS2W/ml9LKBnt+4TBBvT3pDweNdsqX3pLUXQLG9s="
                download="ROC57"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of association 20052021_ALTEREDMEMORANDUMOFA SSOCIATION20052021.pdf
            </TableCell>
            <TableCell align="center">20-05-2021 </TableCell>
            <TableCell align="center">MOA - Memorandum of Association </TableCell>
            <TableCell align="center"> 0.38</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE4yxV3YtPVRI3lZijT7+3qV+DslDqTmxMjf1GV6dSuQQLPyCKvyzh6qGiI+W05QjvknZ/0ffjqF9RNip/AXLZpNo="
                download="ROC58"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">20-05-2021 </TableCell>
            <TableCell align="center">Description not available</TableCell>
            <TableCell align="center"> 0.69</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz24PFu/fwQh3i8gDkyO+oN4r1C9bZ5iJhHO32+X5+Em4L21sDLipptBhjTUCzEouBdQ=="
                download="ROC59"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">07-05-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 3.33</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2/ZTJqHl7ZBJWBTtj8ofdAM6XlxlsRyTmwA60ObLMdWLsQydmovUDxCgScGDU0SI9w=="
                download="ROC60"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 13042021_ALTEREDMEMORANDUMOFA SSCIATION13042021.pdf{' '}
            </TableCell>
            <TableCell align="center">13-04-2021</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.39</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE4yxV3YtPVRI3lZijT7+3qV8hrSl4Eld1e2dvZAJKdOlbCE+uJXhOWv2C4fOewjCA6feCNpJqrQjQTMdxHMZ/IqQ="
                download="ROC61"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the resolution for alteration of capital;- 13042021_COPYOFTHERESOLUTIONFOR{' '}
            </TableCell>
            <TableCell align="center">13-04-2021</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.64</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+HubVpGCPHZf1GHS/MPDs8qtHlndLdbZUP0HyYBVS7x7YahpxmMv9/pGusrjYssmKbdIeIKDRbRGZ5PuvXrsRx8+fLW53yp7Q4JkD914jRa7"
                download="ROC62"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">08-04-2021 </TableCell>
            <TableCell align="center">List of allottees</TableCell>
            <TableCell align="center"> 1.23</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZRoS0v2hlDjK+1nnVma1n5P4fg+HUVQ6pBf7jO6gMTZXVsOHG0fH10w="
                download="ROC63"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">08-04-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 2.76</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz24owDhOnQg6ep/yX87RnFjSL16bRWcy94otLMmo8oAZCuAbjT6wbOVZBaR6Kp3BdmA=="
                download="ROC64"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.71</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2zeJ8hTTj/FuRHkkg8xRovvSJzAz+satKiVwhASEJhUfG0vAse717Kl2hP2jzZODvg=="
                download="ROC65"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of association 31032021_ALTEREDMEMORANDUMOFA SSOCIATION31032021.pdf{' '}
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">MOA - Memorandum of Association</TableCell>
            <TableCell align="center">0.39</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE4yxV3YtPVRI3lZijT7+3qV+DslDqTmxMjX5pPrgijNv+mWPqwaLAFlKJD8QRcwwMURABCiuwM4i4nv4eepA7uug="
                download="ROC66"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 3.04</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2zeJ8hTTj/FuQQPudD/ilXBJDbycQHdS9JOJHpiOhw2o/Xl7BFFy/8d5/J/BFzpQ8g=="
                download="ROC67"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association 31032021_ALTEREDARTICLESOFASSOCI ATION31032021.pdf{' '}
            </TableCell>
            <TableCell align="center">01-04-2021 </TableCell>
            <TableCell align="center">AoA - Articles of Association </TableCell>
            <TableCell align="center"> 2.23</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE49K8rvBCXKiuUBaRCxT4+eJc50m3C0kc4KKZmRfchzia3eFJsCENlkzfyowckrKt7HThSoPeekXc1VSuUN4H+7U="
                download="ROC68"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">26-03-2021 </TableCell>
            <TableCell align="center">List of allottees</TableCell>
            <TableCell align="center"> 0.31</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZaZs0rT42Z+Rb4quByCoshVvoWH4XGnSAt21w9+1oktbTNqGxamMzO8="
                download="ROC69"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">11-03-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.14</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz24toP2SdTLIQ7bNtjlqw9MxoaOsIHMMLmoHC5el0Ujf/LaQTQShMmUG03Uc0Vj7aTA=="
                download="ROC70"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">09-03-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.89</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2+ML2ScGxW3oaluf9uZEt0QFtCg4si8xU0V6xSlH88SLax6XG7HR8q/pGaiJqsNiHg=="
                download="ROC71"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Approval letter for extension of AGM;- 28022021_APPROVALLETTERFOREXTEN
              SIONOFAGM28022021.pdf
            </TableCell>
            <TableCell align="center">28-02-2021 </TableCell>
            <TableCell align="center">Approval letter for extension of AGM</TableCell>
            <TableCell align="center"> 0.33</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REeJneMvHU/HSBrdVchPref5iGtJwu1kJKVfFByuq2d/i3p3k5LBOl4+kdCothpQ4uVni6QRcDbzi/zDuZT4FikNIfbROj2hi/7/WiayFRByQ=="
                download="ROC72"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Approval letter of extension of financial year or AGM 11022021_APPROVALLETTEROFEXTENSI
            </TableCell>
            <TableCell align="center">11-02-2021 </TableCell>
            <TableCell align="center">Approval letter for extension of AGM </TableCell>
            <TableCell align="center"> 0.33</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REeJneMvHU/HSBrdVchPrefeAAg3YNDlCqrdDQFtsgin0rE/8wpoW2x//I7cxrLwAdG0dcwKpcZnPhWEhQSx6hHZ93c8LnGNB0WDRtpz8orfhcudjXLYubvFtM9gEB59+w="
                download="ROC73"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Directors report as per section 134(3)- 11022021_DIRECTORSREPORTASPERSE
              CTION134311022021.pdf{' '}
            </TableCell>
            <TableCell align="center">11-02-2021 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.77</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGxwzVcckckYyOu4iAlttzC2BT3WaxM3ohvT3Ewp8GytBd0scO3eGdOZ0ABa1eXfFVjzxbWXQf2aYitN4gs3LyyPO6cHHU63nkLccgqqEbNGQ=="
                download="ROC74"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Financial Staements duly authenticated as per section 134 (Including Boards
              report, auditors{' '}
            </TableCell>
            <TableCell align="center">11-02-2021 </TableCell>
            <TableCell align="center">Auditors report </TableCell>
            <TableCell align="center"> 1.60</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+GyMhBwBh5yK3rmk8DRG1Tz5+uZM0n3B4HQfonK4UthSg9iD505RU5eVvbjUi5BjIDPKVuiNDI4mHNPbGwMt1pylwBEzz0oA85EYeVfJnRJnNIw42yFKaigF8wp4/HY6z6PpXLrwcIBJ1qZB1YKFS64Wu5y4nX8uEuzKYUZS2BhuOtaK44S7O3hKalyzExuHScFTlThksc5rq6LEMArsAMQ="
                download="ROC75"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">12-06-2020 </TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.63</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZStgLzV37OF6jqxptVNSGIR9vWR6Ud7vCPJLtUmIPTWIMYV+Duh4YwI="
                download="ROC76"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">20-05-2020 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.99</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz24PFu/fwQh3iWivRnkyLEg5B+POWXeWByKwhJJ4Fc8aSKWhihAGoe7vbqpSy2Y+TYQ=="
                download="ROC77"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered articles of association 13032020_ALTEREDARTICLESOFASSOCI ATION13032020.pdf{' '}
            </TableCell>
            <TableCell align="center">13-03-2020 </TableCell>
            <TableCell align="center">AoA - Articles of Association </TableCell>
            <TableCell align="center"> 4.22</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE49K8rvBCXKiuUBaRCxT4+eJc50m3C0kc4B9RaB/oU2zAoExskJrPM1GfmOOTa7quVzSvsPn7mVeKTKYdtuSxL4o="
                download="ROC78"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">13-03-2020 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.34</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2/HBdvtsnKSar1SI9cIVHjfrjJWFNu+rlZSafqZWDCx+A/6nJDgCGWdbHn3ADKcAuA=="
                download="ROC79"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">06-03-2020</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.17</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZQ/pZPkAOdDjBE8pkauXw2ZDCycco/2cxw9y5SXqFXXgF0RHRHjkSUo="
                download="ROC80"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">27-02-2020</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.15</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZUcetpOtQ1hLjfWquBwbKtcG2Y+EVxXjOBqpCP7Qi6shtezmwWhleAw="
                download="ROC81"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">24-02-2020</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.41</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZTbWBYGFAt7cXRFZz9ccSl7UBOU7AjMScNrHOaa3awgTTYL8j8dCg5E="
                download="ROC81"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall{' '}
            </TableCell>
            <TableCell align="center">27-01-2020</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.41</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZUcetpOtQ1hLWPJ/DvQKSh79UzpdPBzIFnsSs/H6XkmhkIhKonumUwo="
                download="ROC82"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 07012020_ALTEREDMEMORANDUMOFA SSCIATION07012020.pdf{' '}
            </TableCell>
            <TableCell align="center">07-01-2020 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.51</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE4yxV3YtPVRI3lZijT7+3qV8hrSl4Eld1e8D8+tv5equsy/7dJanY5dQPs89bMnUmtsNnVGhKYjZJDQJ+TbkipBQ="
                download="ROC83"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the resolution for alteration of capital;- 07012020_COPYOFTHERESOLUTIONFOR
            </TableCell>
            <TableCell align="center">07-01-2020</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.94</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+HubVpGCPHZf1GHS/MPDs8qtHlndLdbZUP0HyYBVS7x7YahpxmMv9/pk0U58Y5u2iHOwhwXyP0kCKe7DrTaoeYpB8K7vBINxqBhB3Sg/Tk7r"
                download="ROC84"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">20-12-2019</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center">0.10</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZc0uIgz0eTjH1Qca9DmXugbI6NVjrs5shFinp6ww2YvP2o1F1xusDag="
                download="ROC85"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Directors report as per section 134(3)- 28112019_DIRECTORSREPORTASPERSE
              CTION134328112019.pdf
            </TableCell>
            <TableCell align="center">29-11-2019 </TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.51</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGxwzVcckckYyOu4iAlttzC2BT3WaxM3ohvT3Ewp8GytLxUpt+oa2HdKlVJVLr8xYqPkLP4c/RvAjn34ZJzwf7f4EY2J03H0M2pHWTnmIm1Rg=="
                download="ROC86"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Financial Staements duly authenticated as per section 134 (Including Boards
              report, auditors
            </TableCell>
            <TableCell align="center">29-11-2019 </TableCell>
            <TableCell align="center">Auditors report </TableCell>
            <TableCell align="center"> 1.32</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+GyMhBwBh5yK3rmk8DRG1Tz5+uZM0n3B4HQfonK4UthSg9iD505RU5eVvbjUi5BjIDPKVuiNDI4mHNPbGwMt1pylwBEzz0oA85EYeVfJnRJnNIw42yFKaigF8wp4/HY6z6PpXLrwcIBJ1qZB1YKFS64Wu5y4nX8uElpS4FUduVRx/rngvhbKcAsscPBAwHVPpia9bgtu8LCKvylvZXEbyg8="
                download="ROC87"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">16-09-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.45</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz2+67KwegZ7fu5FdA6eUzUxECejlK6Em1BLB27yY4nA9y965okfJAlzllTsS2w9A5Eg=="
                download="ROC88"
              >
                Download
              </a>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">30-08-2019</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.18</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZbd7ygq4bPJ5gB7rg1Vq9bl+ICkz3DP5KwrRIxa2g+F4V80EcdyHqcg="
                download="ROC89"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              List of allottees. Attach separate list for each allotment (refer instruction kit for
              format).If not attached, then it shall
            </TableCell>
            <TableCell align="center">17-08-2019</TableCell>
            <TableCell align="center">List of allottees </TableCell>
            <TableCell align="center"> 0.20</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REgNPUSh1rmkjFw0dJS7MAhiU4nmllr77BGxbZvfcFIuKAfwfYbQKz7PDcm+XCw0qtR7LcCjPF2dKBsD+GO9TzKzqBus8/aj8jwEQHQ+Qhv+Mnc3bw2O1b4a2/HIPVM7teuftkk/CjPUtcBuoUxRbdthFbZh3BNw4REJSLvyz/hoJXzvbCY9FXHkfOcncu3ShA3jX9qW1lvZTYoWJjU3t0NaEZJBRluu7kbFdywwiE8n6JiriNM6/4imPd7dkumhrc="
                download="ROC90"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">16-08-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.47</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz21OAUfAR0Zubd1scIzjWaqs4OmP00ZErwttjvVW4BVy8Se3nq3KbY9imhG+uZSPQ6w=="
                download="ROC91"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">01-08-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.47</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz29WGIq3ClA5Bb4p+YotUPy+UCLpGMb4gukrZcw59enmMn+MoHokW/emE/5KXNk5xJw=="
                download="ROC92"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy(s) of resolution(s) along with copy of explanatory statement under section 173-
            </TableCell>
            <TableCell align="center">22-07-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.37</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGcF71L/C71j5BU2R/IBXUls7l829/Xu2hv8tDa8rAScukOFSJXFI65K2io6eYWQmxsPbMmgcpYqAGiAG/QY796OOqIp7lYrtWU7AVj0tgz24gK8nuBL9ENxC2wlQ5raYKDYVHlxlJsJCntTWcNwwPNt3lu69wFIk4WWmOzME5Oaw=="
                download="ROC93"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Altered memorandum of assciation;- 02072019_ALTEREDMEMORANDUMOFA SSCIATION02072019.pdf
            </TableCell>
            <TableCell align="center">02-07-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 1.04</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RG36yDyJxHE4yxV3YtPVRI3lZijT7+3qV8hrSl4Eld1e0xP42Lrcn9bwbh+mG+GO56ktMePKApJa8EEcrDv0fTOkFsAzy+uGyA="
                download="ROC94"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the resolution for alteration of capital;- 02072019_COPYOFTHERESOLUTIONFOR
            </TableCell>
            <TableCell align="center">02-07-2019</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.85</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+HubVpGCPHZf1GHS/MPDs8qtHlndLdbZUP0HyYBVS7x7YahpxmMv9/rw3eBTSM3ujY2Me3MBadfa+czVluxeq7Kn+aZCGvCqluny2DSYJJA9"
                download="ROC95"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Directors report as per section 134(3)- 22122018_DIRECTORSREPORTASPERSE
              CTION134322122018.pdf
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 2.15</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGxwzVcckckYyOu4iAlttzC2BT3WaxM3ohvT3Ewp8GytLxUpt+oa2HdTcguI63krnW0AhkDQ/0PmyFi29G4vGNrAxXFvYqFNi0kOb3mj4V3jw=="
                download="ROC96"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of resolution passed by the company 22122018_COPYOFRESOLUTIONPASSED
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Resolution passed by the company </TableCell>
            <TableCell align="center"> 0.24</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+KaS6tosZ2Ko4IcLKmhCh75Ip83+Ro3OUGf59vKTqiXZaBYyf+a+lKcuouhM5sKcfp69i6IaUMgyJEyKjbQU+R/mHBA75GZkZg=="
                download="ROC97"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of the intimation sent by company 22122018_COPYOFTHEINTIMATIONSENT
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Intimation received from the company </TableCell>
            <TableCell align="center"> 0.16</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+MgAkngWSZYSUl6I6mHQQcOdltLxKzIwvfgkxVcYKH5mqSux3nJklGPjhob+iJfzwI82tQ7T5Kii1muDombjLJe6VGa08Bg7LQ=="
                download="ROC98"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of Financial Staements duly authenticated as per section 134 (Including Boards
              report, auditors
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Auditors report </TableCell>
            <TableCell align="center"> 1.60</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+GyMhBwBh5yK3rmk8DRG1Tz5+uZM0n3B4HQfonK4UthSg9iD505RU5eVvbjUi5BjIDPKVuiNDI4mHNPbGwMt1pylwBEzz0oA85EYeVfJnRJnNIw42yFKaigF8wp4/HY6z6PpXLrwcIBJ1qZB1YKFS64Wu5y4nX8uEkyEXREZPi3bE7sspDqQQoshidtPlSnFdHyqiD2dITl4VVJRfRgJ5/4="
                download="ROC99"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Copy of written consent given by auditor 22122018_COPYOFWRITTENCONSENTGI
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Copy of written Consent given by Auditor</TableCell>
            <TableCell align="center"> 0.21</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RHt9EOcjqsB+F3VJMfuPjZYj6CAB9bsLl9q4xL+3HnFzzkLmJ+W5j03cCvziUcznVS2t2lpq3DuUrYfI5X4abUH7N4FAWbJ1gkqfqQFZi04VA=="
                download="ROC100"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              CERTIFICATE OF REGISTRATION OF THE SPECIAL RESOLUTION CONFIRMING ALTERATION OF OBJECT
              CLAUSE(S)-
            </TableCell>
            <TableCell align="center">09-10-2018</TableCell>
            <TableCell align="center">Alteration of Object Clause</TableCell>
            <TableCell align="center"> 0.50</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGkGHzrmGayBr3NnkwO5gia3OGvsxI9uzZOfknGbNC7WyGn3pc5rU/+47H84LgEaQ26O939yorr8QEb9awVSzb0K1OlFQCCGHf/MbLTZNpDHLxLD+cK+9++Xk4smSFy17lj4i7/ABiAQ/UQfybHvUy0IiOSF/M4Mkh3nb1jT0O8SQmOuh/Wh9qM"
                download="ROC101"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Notice of resignation;- 22022018_NOTICEOFRESIGNATION220 22018.pdf
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">Registation of Director</TableCell>
            <TableCell align="center"> 0.05</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REIgyDOf+UT3tIZteSheyucMiwr6lsU+ap+hTJuSBSYHHypW9Ve00YgoJHtrF0hFj0YNDo2FVr6W5WeCGoBECkZ"
                download="ROC102"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form DIR-12- 22022018_signed_FORMDIR12220220 18SIGNED.pdf
            </TableCell>
            <TableCell align="center">22-12-2018</TableCell>
            <TableCell align="center">
              Form DIR - 12 - Particulars of appointment of Directors and the key managerial
              personnel and the changes among them{' '}
            </TableCell>
            <TableCell align="center"> 1.20</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REhFbheftVUxfGvEd6SdQVXElqW83Wve4fo+9I9jsiRTTbS6S5bbt79szCSBwmCfQBkEeCUHmBj5sOTX9j0jmxZ"
                download="ROC103"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form DIR-12- 29082017_signed_FORMDIR122908201 7SIGNED.pdf
            </TableCell>
            <TableCell align="center">29-08-2017</TableCell>
            <TableCell align="center">
              Form DIR - 12 - Particulars of appointment of Directors and the key managerial
              personnel and the changes among them
            </TableCell>
            <TableCell align="center"> 3.75</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REhFbheftVUxW9tx574PekTb95iFbg7F42yaz1gXiJ1NAP9YtHiF/z2PcmxPUc58/BT/q4gdx0pwADyW75qjlyL"
                download="ROC104"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Declaration of the appointee director, Managing director, in Form
              No_DECLARATIONOFTHEAPPOINTEEDIR
            </TableCell>
            <TableCell align="center">23-08-2017</TableCell>
            <TableCell align="center">Description not available </TableCell>
            <TableCell align="center"> 0.29</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9REpL37uBxuImZeSkPaL3p+g3pL7Vr4H+kwYk1qFUtJ6fMRNKTYPfhfum5qt9Ch+5rjV2vcQAJSIIwu6O5Z7/rn/4e6wT6fryHbWisoJTzdNrWlALesm8E6Ll3ASNsTg3FxxaqDMVnyzSg=="
                download="ROC105"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Letter of appointment;- 23082017_LETTEROFAPPOINTMENT230 82017.pdf
            </TableCell>
            <TableCell align="center">23-08-2017</TableCell>
            <TableCell align="center">Letter of Appointment </TableCell>
            <TableCell align="center"> 0.29</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RE8PaKX85oRlpcRtxxKAX+bRxyQoZQuGLioYwqm7voUKrR8/jViUVjWYHKmA7Mt3GDEItkxPEqL7CNoP4BFaokM"
                download="ROC106"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form SPICe MOA (INC-33)- 17012017_FORMSPICEMOAINC331701201 7.pdf
            </TableCell>
            <TableCell align="center">17-01-2017</TableCell>
            <TableCell align="center">
              Form SPICe - Simplified Proforma for Incorporating Company Electronically (SPICe)
            </TableCell>
            <TableCell align="center"> 0.80</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGZvlfYmNNqTAJRdV40F6P9Y3i8MUXNZwgIlNHEj1vUNnTR4fqcDbA4RXUXt6IRDACNhKBzmjA2qMZ/rFx6zXvm"
                download="ROC107"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Form SPICe AOA (INC-34)- 17012017_FORMSPICEAOAINC341701201 7.pdf
            </TableCell>
            <TableCell align="center">17-01-2017</TableCell>
            <TableCell align="center">
              Form SPICe - Simplified Proforma for Incorporating Company Electronically (SPICe){' '}
            </TableCell>
            <TableCell align="center"> 1.34</TableCell>
            <TableCell align="center">
              <a
                href="https://www.instafinancials.com/downloader.aspx?ID=3xMhoVog9RGZvlfYmNNqTOQq9R2qO5XQQX0HS+pAoDH8mDvZVZU4/lSSqo8eI/EaFVzh32GfCiZustEu2U78IXJQI3XJkD3T"
                download="ROC108"
              >
                Download
              </a>
            </TableCell>
          </TableRow>

          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
