import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useSearchParams } from 'react-router-dom';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Label from 'src/components/Label';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { getCompanyId } from 'src/utils/common';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchDueDates,
  intialTdsChallanFetching,
  updateSingleChallan,
  deletetdschallan
} from 'src/redux/actions/tds';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { calDelay } from './ReturnFilling';
import { InputLabel, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { monthToDigit, digitToMonth, dateFormat, mapFinalcialYearToYear } from 'src/utils/common';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import DatePicker from '@mui/lab/DatePicker';
//import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino, istalenteye } from 'src/config';




const Input = styled('input')({
  display: 'none'
});

export default function TdsChallanDetails({ year, companyId }) {
  const state = useSelector((state) => state.orgProfileReducer);
  const tdsState = useSelector((state) => state.tdsReducer);
  const dispatch = useDispatch();
  const [data, setData] = useState(tdsState.tdsChallans);

  useEffect(() => {
    dispatch(fetchDueDates());
    dispatch(intialTdsChallanFetching(companyId));
  }, []);

  useEffect(() => {
    if (tdsState.tdsChallans != null) {
      const filter_data = tdsState.tdsChallans.filter(
        (item) => item.paymentDate !== null && item.complianceID !== null
      );
      setData(filter_data);
    }
  }, [tdsState.tdsChallans]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...data];
    list[index][name] = value;
    setData(list);
    console.log('data', data);
  };
  const handleChallanCopyChange = (index, event) => {
    const list = [...data];
    list[index].user_challan = event.target.files[0];
    setData(list);
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const handleUpdate = (index) => {
    var bodyFormData = new FormData();
    console.log('challan', data[index].user_challan);
    if (!isValidHttpUrl(data[index].user_challan) && data[index].user_challan !== null) {
      bodyFormData.append('user_challan', data[index].user_challan);
    }
    bodyFormData.append('month', data[index].month);
    bodyFormData.append(
      'base_amount',
      !isNaN(data[index].base_amount) ? data[index].base_amount : '0.0'
    );
    bodyFormData.append(
      'interest_amount',
      !isNaN(data[index].interest_amount) ? data[index].interest_amount : '0.0'
    );
    bodyFormData.append('bsr_code', data[index].bsr_code);
    dispatch(updateSingleChallan(data[index].id, bodyFormData));
  };


  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (tdsState.dueDates != null) {
      const dueDateObj = tdsState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };

  if (tdsState.isLoading || data == null || tdsState.dueDates == null) {
    return <div></div>;
  } else {
    console.log('year', year);
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& th': {
              fontSize: '1rem',
              background: 'hsl(149,100%,33%)',
              color: 'white'
            },
            '& td': {
              fontSize: '0.7rem'
            }
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Month</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Section</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Delay Days</TableCell>
              <TableCell>TDS Payment</TableCell>
              <TableCell>Interest</TableCell>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}} >Edit/delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length &&
              data
                .sort(function (a, b) {
                  return (
                    new Date(getExpiryDate(a.complianceID, a.month, a.finance_year)) -
                    new Date(getExpiryDate(b.complianceID, b.month, b.finance_year))
                  );
                })
                .map((row, i) => {
                  if (year == row.finance_year) {
                    return (
                      <Row
                        row={row}
                        i={i}
                        handleChange={handleChange}
                        handleChallanCopyChange={handleChallanCopyChange}
                        setData={setData}
                        data={data}
                        handleUpdate={handleUpdate}
                      />
                    );
                  }

                })}
            <TableRow>

              <TableCell></TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: 'larger' }}>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '120%' }}>
                {data
                  .map((item) =>
                    item.base_amount != null && item.finance_year == year
                      ? parseFloat(item.base_amount)
                      : 0
                  )
                  .reduce((prev, next) => prev + next, 0)}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '120%' }}>
                {data
                  .map((item) =>
                    item.interest_amount != null && item.finance_year == year
                      ? parseFloat(item.interest_amount)
                      : 0
                  )
                  .reduce((prev, next) => prev + next, 0)}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '120%' }}>
                {data
                  .map((item) =>
                    item.base_amount != null && item.finance_year == year
                      ? parseFloat(item.base_amount)
                      : 0
                  )
                  .reduce((prev, next) => prev + next, 0) +
                  data
                    .map((item) =>
                      item.interest_amount != null && item.finance_year == year
                        ? parseFloat(item.interest_amount)
                        : 0
                    )
                    .reduce((prev, next) => prev + next, 0)}
              </TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </TableContainer>
    );
  }
}

const mapType = {
  '24Q': 'Salary TDS',
  '26Q': 'Non salary TDS',
  '27Q': 'Non residents TDS',
  '27EQ': 'TCS'
};

const styles = {
  hidden: {
    display: 'none'
  },
  importLabel: {
    color: 'white'
  },
  table: {
    font: 10
  }
};

const Row = (props) => {
  const { row, i, handleChange, handleChallanCopyChange, setData, data } = props;
  const [open, setOpen] = React.useState(false);
  const tdsState = useSelector((state) => state.tdsReducer);
  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (tdsState.dueDates != null) {
      const dueDateObj = tdsState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };
  const delay =
    tdsState.dueDates.length > 0
      ? calDelay(getExpiryDate(row.complianceID, row.month, row.finance_year), row.paymentDate)
      : 0;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.month} - {row.finance_year}</TableCell>
        <TableCell>{mapType[row.tds_type]}</TableCell>
        <TableCell>{row.section}</TableCell>
        <TableCell>
          {tdsState?.dueDates?.length > 0
            ? dateFormat(getExpiryDate(row.complianceID, row.month, row.finance_year))
            : '-'}
        </TableCell>
        <TableCell>{dateFormat(row.paymentDate)}</TableCell>
        <TableCell>
          <Label
            variant="ghost"
            color={(delay > 0 && 'error') || (row.status == 'Upcoming' && 'info') || 'success'}
          >
            {delay}
          </Label>
        </TableCell>
        <TableCell>{row.base_amount}</TableCell>
        <TableCell>{row.interest_amount}</TableCell>
        <TableCell colSpan={2}>
          {parseFloat(row.base_amount == null || row.base_amount === '' ? 0 : row.base_amount) +
            parseFloat(
              row.interest_amount == null || row.interest_amount === '' ? 0 : row.interest_amount
            )}
        </TableCell>
        <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>
          <Box textAlign="center" sx={{ display: 'flex' }}>
            <Update row={row} />
            <ConfirmProvider>
              <Delete row={row} />
            </ConfirmProvider>
          </Box>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                More Challan Details
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                sx={{
                  '& th': {
                    background: 'white',
                    color: 'black'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Challan No.</TableCell>
                    <TableCell width={2000}>BSR Code</TableCell>
                    <TableCell>
                      Oltas Confirmation <br />
                      Receipt
                    </TableCell>
                    <TableCell>Challan Copy</TableCell>
                    <TableCell>Update Challan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.challan_num}</TableCell>
                    <TableCell>{row.bsr_code}</TableCell>
                    <TableCell>
                      {tdsState.tdsChallans && tdsState.tdsChallans[i].receipt === null && (
                        <h5>Not Uploaded</h5>
                      )}
                      {tdsState.tdsChallans && tdsState.tdsChallans[i].receipt && (
                        <Link
                          variant="a"
                          href={row.receipt}
                          target="_blank"
                          underline="none"
                        >
                          User Challan
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      {tdsState.tdsChallans && tdsState.tdsChallans[i].user_challan === null && (
                        <h5>Not Uploaded</h5>
                      )}
                      {tdsState.tdsChallans && tdsState.tdsChallans[i].user_challan && (
                        <Link
                          variant="a"
                          href={row.user_challan}
                          target="_blank"
                          underline="none"
                        >
                          User Challan
                        </Link>
                      )}
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Delete = (props) => {
  const { row } = props;
  //const { row } = props;
  const dispatch = useDispatch();
  //console.log("rows filling are", row);
  //const [newrows, setNewrows] = useState(row);
  //console.log("newrows are", newrows);
  // console.log("Challandata are",challansData);
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletetdschallan(row.id))
        //console.log("newrows are after delete ", newrows);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}  >Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState();
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rows are", row);

  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={handleClose} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>

        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <TdsChallanUpdate row={row} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const TdsChallanUpdate = (props) => {
  const { row } = props;
  const [tdsvalue, setTdsvalue] = useState(row.paymentDate);
  const [tdsData, setTdsData] = useState({
    finance_year: '',
    month: '',
    tds_type: '',
    section: '',
    base_amount: "",
    interest_amount: "",
    paymentDate: '',
    challan_num: '',
    bsr_code: ''
});
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const tdsmonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const tdstypes = [{ id: "1", name: "24Q" }, { id: "2", name: "26Q" }, { id: "3", name: "27Q" }, { id: "4", name: "27EQ" }];

  const tdsection = [{ id: "1", tdstypesId: "24Q", name: "92A - Payment to Government Employees other than Union Government employees" },
  { id: "2", tdstypesId: "24Q", name: "92B - Payment to Employees other than Government employees" },
  { id: "3", tdstypesId: "24Q", name: "94P - Payment to a specified Senior Citizen" },
  { id: "4", tdstypesId: "24Q", name: "92C - Payment to Union Government Employees" },
  { id: "5", tdstypesId: "26Q", name: "193 - Interest on Securities" },
  { id: "6", tdstypesId: "26Q", name: "194 - Dividend" },
  { id: "7", tdstypesId: "26Q", name: "4BA - Certain Income from the units of Business Trust" },
  { id: "8", tdstypesId: "26Q", name: "4BB - Winning from Horse Race" },
  { id: "9", tdstypesId: "26Q", name: "4DA - Payment in respect of Life Insurance Policy" },
  { id: "10", tdstypesId: "26Q", name: "4EE - Payment in respect of Deposits under National Saving Scheme" },
  { id: "11", tdstypesId: "26Q", name: "4LA - Payment of Compensation on Acquisition of certain Immovable Property" },
  { id: "12", tdstypesId: "26Q", name: "94A - Interest Other than Interest on Securities" },
  { id: "13", tdstypesId: "26Q", name: "94B - Winning from Lotteries and Crossword Puzzles" },
  { id: "14", tdstypesId: "26Q", name: "94C - Payment of Contractors or Sub Contractors" },
  { id: "15", tdstypesId: "26Q", name: "94D - Insurance Commission" },
  { id: "16", tdstypesId: "26Q", name: "94F - Payment on account of Repurchase of Units by Mutual Fund" },
  { id: "17", tdstypesId: "26Q", name: "94G - Commission, prize etc. on sale of lottery tickets" },
  { id: "18", tdstypesId: "26Q", name: "94H - Commission or Brokerage" },
  { id: "19", tdstypesId: "26Q", name: "94I - Rent" },
  { id: "20", tdstypesId: "26Q", name: "94J - Fees for Professional or Technical Services" },
  { id: "21", tdstypesId: "26Q", name: "94K - Income payable to resident assessee in respect of Units of a specified Mutual Fund or of the Units of the UTI" },
  { id: "22", tdstypesId: "26Q", name: "94N - Payment of Certain amounts in Cash" },
  { id: "23", tdstypesId: "26Q", name: "94Q - Payment of tax at source on payment of certain sum for purchase of goods" },
  { id: "23", tdstypesId: "26Q", name: "4IC - Payment under specified agreement" },
  { id: "25", tdstypesId: "26Q", name: "94O - TDS on ecommerce transactions" },
  { id: "26", tdstypesId: "27Q", name: "4LB - Income by way of Interest from Infrastructure, Debt Fund Payable to a Non Resident" },
  { id: "27", tdstypesId: "27Q", name: "4LC - Income by way of Interest from a specified company payable to a Non Resident" },
  { id: "28", tdstypesId: "27Q", name: "94E - Payment to Non Resident Sportsmen/Sports Association" },
  { id: "29", tdstypesId: "27Q", name: "96A - Income in respects of Units of non residents" },
  { id: "30", tdstypesId: "27Q", name: "96B - Payment in respect of Units to an Offshore fund" },
  { id: "31", tdstypesId: "27Q", name: "96C - Payment from foreign currency Bonds or Shares of Indian Company payable to a non resident" },
  { id: "32", tdstypesId: "27Q", name: "96D - Income from Foreign Institutional investors from securities" },
  { id: "33", tdstypesId: "27Q", name: "195 - Other sums payable to a Non Resident" },
  { id: "34", tdstypesId: "27Q", name: "2AA - Payment of accumulated balance due to an employee" },
  { id: "36", tdstypesId: "27Q", name: "4LD - Interest on Rupee Dinominated Bonds of Companies or Government Securities" },
  { id: "37", tdstypesId: "27Q", name: "LBC - Income in respect of investment in securitization trust" },
  { id: "38", tdstypesId: "27Q", name: "LBB - Income in respect of Units of a investment Fund" },
  { id: "40", tdstypesId: "27EQ", name: "6CA - Alcoholic liquor for human consumption" },
  { id: "41", tdstypesId: "27EQ", name: "6CB - Timber obtained under a forest lease" },
  { id: "42", tdstypesId: "27EQ", name: "6CC - Timber obtained under any mode other than forest lease" },
  { id: "43", tdstypesId: "27EQ", name: "6CD - Timber obtained under any mode other than forest lease" },
  { id: "44", tdstypesId: "27EQ", name: "6CE - Scrap" },
  { id: "45", tdstypesId: "27EQ", name: "6CF - Parking Lot" },
  { id: "46", tdstypesId: "27EQ", name: "6CG - Toll Plaza" },
  { id: "47", tdstypesId: "27EQ", name: "6CH - Mining and quarrying" },
  { id: "48", tdstypesId: "27EQ", name: "6CI - Tendu Leaves" },
  { id: "49", tdstypesId: "27EQ", name: "6CJ - Minerals" },
  { id: "50", tdstypesId: "27EQ", name: "6CK - Bullion and Jewellery" },
  { id: "51", tdstypesId: "27EQ", name: "6DA - Income from Specified funds from Securities referred to in section 115AD" },
  { id: "52", tdstypesId: "27EQ", name: "6CL - TCS on Sale of Motor Vehicle" },
  { id: "53", tdstypesId: "27EQ", name: "6CM - TCS on Sale in cash of any goods (Other than bullion/Jewellery)" },
  { id: "54", tdstypesId: "27EQ", name: "6CN - TCS on providing of any services (Other than Ch -XVIIB)" },
  { id: "55", tdstypesId: "27EQ", name: "6CO - TCS on foreign remmitance" },
  { id: "56", tdstypesId: "27EQ", name: "6CR - TCS on Sales of Goods" },
  { id: "57", tdstypesId: "27EQ", name: "6CP - Remmitance under LRS for educational loan" },
  { id: "58", tdstypesId: "27EQ", name: "6CQ - Remittance under LRS for the purposes other than purchase of overseas tour or education loan" },
  { id: "59", tdstypesId: "27EQ", name: "6C(IH) TCS on Sales of exports of goods" }




  ];
  const [tdsfy, setTdsfy] = useState(row.finance_year);
  const [tdsmon, setTdsmon] = useState(row.month);
  const [tdsamt, setTdsamt] = useState(row.base_amount);
  const [tdsintrest, setTdsintrest] = useState(row.interest_amount);
  const [tdsty, setTdsty] = useState(row.tds_type);
  const [tdsec, setTdsec] = useState(row.section);
  const [tdschallno, setTdschallno] = useState(row.challan_num);
  const [tdsbsrcode, setTdsbsrcode] = useState(row.bsr_code);
  const [tdsid, setTdsid] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const handleTds = (id) => {
    setTdsid(id);

    console.log("i am id", id);
  }
  const handleTdsFormDataChange = (e) => {
    console.log(e);
    tdsname = e.target.name;
    valu = e.target.value;

    setTdsData({ ...tdsData, [tdsname]: valu });
    console.log(tdsname);
    console.log(valu);
  }

  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    //const bodyFormData = new FormData();
    tdsData[`${name}`] = valuee

  }
  const dis = (e) => {

    const bodyFormData = new FormData();
    // formData['complianceID'] = complianceId
    // tdsData['companyId'] = companyId
    // tdsData['complianceID'] = row.complianceID;

    //var bodyFormData = new FormData();
    for (let key in tdsData) {
      if (key, tdsData[key] !== '') {
        bodyFormData.append(key, tdsData[key])
      }
    }
    console.log('form data', tdsData);
    dispatch(updateSingleChallan(row.id, bodyFormData,companyId));
  };

  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/*tdschallan : Finance yeare*/}
        <Autocomplete
          value={tdsfy} name="finance_year"
          onChange={(event, newValue) => {
            setTdsfy(newValue);
            {
              tdsData.finance_year = newValue;
            }
            {
              handleTdsFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        {/*tdschallan : Month*/}
        <Autocomplete
          value={tdsmon} name="month"
          onChange={(event, newValue) => {
            setTdsmon(newValue);
            {
              tdsData.month = newValue;
            }
            {
              handleTdsFormDataChange;
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          id="controllable-states-demo"
          options={tdsmonths}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Month" required />}
        />
        {/*tdschallan : Types*/}
        <Autocomplete
          value={tdsty} name="tds_type"
          onChange={(event, newValue) => {
            setTdsty(newValue);
            {
              tdsData.tds_type = newValue;
            }
            {
              handleTds(newValue);
              handleTdsFormDataChange;
            };

          }}
          id="controllable-states-demo"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={tdstypes.map((el) => el.name)}
          getOptionLabel={(option) => typeof option === 'string'
            || option instanceof String ? option : ""}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="TDS Type" required />}
        />
        {/*tdschallan : Tds section Types*/}
        <Autocomplete
          value={tdsec} name="section"
          onChange={(e, newValue) => {
            setTdsec(newValue);
            {
              formData.section = newValue;
            }
            {
              handleTdsFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={tdsection.filter(x => x.tdstypesId === tdsid).map((el) => el.name)}
          getOptionLabel={(option) => typeof option === 'string'
            || option instanceof String ? option : ""}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="TDS Section" required />}
        />
        {/*tdschallan : Tds Payment Date Filling*/}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date"
            value={tdsvalue} name="paymentDate"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setTdsvalue(newValue);
              {
                newValue = moment(newValue).format('YYYY-MM-DD');
                tdsData.paymentDate = newValue;
              }
              {
                handleTdsFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>
        {/*tdschallan : Tds Challan Number*/}
        <TextField
          value={tdschallno} name="challan_num"
          onChange={(e) => {
            setTdschallno(e.target.value);
            {
              tdsData.challan_num = e.target.value;
            }
            {
              handleTdsFormDataChange
            };
          }}
          label="Challan Number" variant="outlined" sx={{ margin: 1 }} required />
                
          <TextField
          value={tdsamt} name="base_amount"
          onChange={(e) => {
            setTdsamt(e.target.value);
            { 
              tdsData.base_amount = e.target.value; 
            }
            { 
              handleTdsFormDataChange
            };
          }}
          label="TDS Payment" variant="outlined" sx={{ margin: 1 }} />
                
        <TextField
          value={tdsintrest} name="interest_amount"
          onChange={(e) => {
            setTdsintrest(e.target.value);
            { 
              tdsData.interest_amount = e.target.value; 
            }
            { 
              handleTdsFormDataChange
            };
          }}
          label="Interest" variant="outlined" sx={{ margin: 1 }} />
         
        {/*tdschallan : BSR Code*/}
        <TextField
          value={tdsbsrcode} name="bsr_code"
          onChange={(e) => {
            setTdsbsrcode(e.target.value);
            {
              tdsData.bsr_code = e.target.value;
            }
            {
              handleTdsFormDataChange;
            }
          }}
          label="BSR Code" variant="outlined" sx={{ margin: 1 }} />

        {/*tdschallan : Otlas file*/}
        <h5>Oltas Confirmation</h5>
        <Box textAlign="right" >
          <a href={row.receipt} target="_blank">
            Oltas Confirmation
          </a>
        </Box>

        <TextField name='receipt' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        {/*tdschallan : Tds challan file*/}
        <h5>TDS Challan </h5>
        <Box textAlign="right" >
          <a href={row.user_challan} target="_blank">
            Tds Challan
          </a>
        </Box>

        <TextField name='user_challan' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        {/*tdschallan : Tds Save Button*/}
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>

      </Box>
    </>
  );
};

const createDate = (month, year) => {
  const years = year.split('-');
  const intialYear = years[0];
  console.log(years);
  const finalYear = years[0].substring(0, 2) + years[1];
  console.log(finalYear);
  if (monthToDigit[month] >= 3) {
    const date = new Date(intialYear + '-' + month + '-' + '01');
    console.log(date);
    return date;
  } else {
    const date = new Date(finalYear + '-' + month + '-' + '01');
    console.log(date);
    return date;
  }
};
