export default {
  SET_ORG_PROFILE: 'SET_ORG_PROFILE',
  SET_ORG_PROFILE_SUCCESS: 'SET_ORG_PROFILE_SUCCESS',
  SET_ORG_PROFILE_FAILURE: 'SET_ORG_PROFILE_FAILURE',
  SET_USER_LOGIN_START: 'SET_USER_LOGIN_START',
  SET_USER_LOGIN_SUCCESS: 'SET_USER_LOGIN_SUCCESS',
  SET_USER_LOGIN_FAILURE: 'SET_USER_LOGIN_FAILURE',
  SET_USER_LOGOUT: 'SET_USER_LOGOUT',
  SET_COMPANY_ID: 'SET_COMPANY_ID',
  SET_COMPANT_DATA: 'SET_COMPANT_DATA',
  SET_NEW_COMPANY: 'SET_NEW_COMPANY',
  SET_IS_UPDATED: 'SET_IS_UPDATED',

  //TDS RELATED
  // ->challan

  SET_COMPANY_TDS_CHALLAN_START: 'SET_COMPANY_TDS_CHALLAN_START',
  SET_COMPANY_TDS_CHALLAN_SUCCESS: 'SET_COMPANY_TDS_CHALLAN_SUCCESS',
  SET_COMPANY_TDS_CHALLAN_FAILURE: 'SET_COMPANT_TDS_CHALLAN_FAILURE',
  SET_DUE_DATES_START: ' SET_DUE_DATES_START',

  SET_DUE_DATES_SUCCESS: ' SET_DUE_DATES_SUCCESS',
  SET_DUE_DATES_FAILURE: ' SET_DUE_DATES_FAILURE',

  // ->Receipt

  SET_COMPANY_TDS_RECEIPT_START: 'SET_COMPANY_TDS_CHALLAN_START',
  SET_COMPANY_TDS_RECEIPT_SUCCESS: 'SET_COMPANY_TDS_RECEIPT_SUCCESS',
  SET_COMPANY_TDS_RECEIPT_FAILURE: 'SET_COMPANY_TDS_RECEIPT_FAILURE',

  // ->Total Demand
  SET_TDS_DEMAND_START: 'SET_TDS_DEMAND_START',
  SET_TDS_DEMAND_SUCCESS: 'SET_TDS_DEMAND_SUCCESS',
  SET_TDS_DEMAND_FAILURE: 'SET_TDS_DEMAND_FAILURE',
  SET_LOGIN_VAULT_DATA: 'SET_LOGIN_VAULT_DATA',

  //duedates
  SET_ALL_DUE_DATES: 'SET_ALL_DUE_DATES',
  SET_UPCOMING_DUE_DATES: 'SET_UPCOMING_DUE_DATES',
  SET_OVER_DUE_DATES: 'SET_OVER_DUE_DATES',
  SET_YEAR: 'SET_YEAR',

  //pfbook
  SET_PF_CHALLANS: 'SET_PF_CHALLANS',
  SET_PF_DEMANDS: 'SET_PF_DEMANDS',
  SET_PF_DUE_DATES: 'SET_PF_DUE_DATES',

  //incometax
  SET_TDS_DEDUCTED: 'SET_TDS_DEDUCTED',
  SET_TAX: 'SET_TAX',
  SET_FORMS: 'SET_FORMS',
  SET_INCOME_TAX_DEMAND: 'SET_INCOME_TAX_DEMAND',
  SET_INCOME_TAX_DUE_DATES: 'SET_INCOME_TAX_DUE_DATES',

  //ROC book
  SET_COMPANY_BASIC_INFO: 'SET_COMPANY_BASIC_INFO',
  SET_KYC_INFO: 'SET_KYC_INFO',
  SET_CORPORATE_INFO: 'SET_CORPORATE_INFO',
  SET_FINANCIAL_INFO: 'SET_FINANCIAL_INFO',
  SET_SCHEDULE_BREAKUP_INFO: 'SET_SCHEDULE_BREAKUP_INFO',
  SET_OWNERSHIP_INFO: 'SET_OWNERSHIP_INFO',
  SET_BORROWINGS_INFO: 'SET_BORROWINGS_INFO',
  SET_COMPANY_CHARGES_INFO: 'SET_COMPANY_CHARGES_INFO',
  SET_CREDIT_RATINGS_INFO: 'SET_CREDIT_RATINGS_INFO',
  SET_PROFITLOSS_INFO: 'SET_PROFITLOSS_INFO',
  SET_BALANCESHEET_INFO: 'SET_BALANCESHEET_INFO',
  SET_CASH_FLOW_INFO: 'SET_CASH_FLOW_INFO',
  SET_RATIO_ANALYSIS_INFO: 'SET_RATIO_ANALYSIS_INFO',
  SET_REVENUE_INFO: 'SET_REVENUE_INFO',
  SET_FINANCE_COST_INFO: 'SET_FINANCE_COST_INFO',
  SET_EXPENSE_INFO: 'SET_EXPENSE_INFO',
  SET_CONTINGEMENT_INFO: 'SET_CONTINGEMENT_INFO',
  SET_SCHEDULES_BORROWINGS_INFO: 'SET_SCHEDULES_BORROWINGS_INFO',
  SET_LOANS_ADVANCES_INFO: 'SET_LOANS_ADVANCES_INFO',
  SET_DEBTORS_INFO: 'SET_DEBTORS_INFO',
  SET_MISC_DISCLOSURE_INFO: 'SET_MISC_DISCLOSURE_INFO',
  SET_RELATED_PARTY_INFO: 'SET_RELATED_PARTY_INFO',
  SET_AUDITORS_DETAIL_INFO: 'SET_AUDITORS_DETAIL_INFO',
  SET_AUDITORS_REPORT_INFO: 'SET_AUDITORS_REPORT_INFO',
  SET_CARO_INFO: 'SET_CARO_INFO',
  SET_ESTABLISHMENT_INFO: 'SET_ESTABLISHMENT_INFO',
  SET_EMPLOYEE_TREND: 'SET_EMPLOYEE_TREND',
  SET_CHARGE_SEARCH_INFO: 'SET_CHARGE_SEARCH_INFO',
  SET_CHARGE_DETAIL_INFO: 'SET_CHARGE_DETAIL_INFO',
  SET_DIRECTORS: 'SET_DIRECTORS',
  SET_SIGNATORIES: 'SET_SIGNATORIES',
  SET_ROC_DATA: 'SET_ROC_DATA',
  // GST BOok
  SET_GST_FILLING_3B: 'SET_GST_FILLING_3B',
  SET_GST_FILLING_1R: 'SET_GST_FILLING_1R',
  SET_GST_ERROR: 'SET_GST_ERROR',
  SET_GST_DUE_DATES: 'SET_GST_DUE_DATES',
  SET_GST_DEMAND: 'SET_GST_DEMAND',
  SET_GST_OVERVIEW: 'SET_GST_OVERVIEW',
  SET_GST_9: 'SET_GST_9',
  SET_GST_9C: 'SET_GST_9C',
  SET_GST_FREQ: 'SET_GST_FREQ',

  //ESI BOOK
  SET_ESI_CHALLANS: 'SET_ESI_CHALLANS',
  SET_ESI_PENALTIES: 'SET_ESI_PENALTIES',
  SET_ESI_ERROR: 'SET_ESI_ERROR',
  SET_ESI_DUE_DATES: 'SET_ESI_DUE_DATES',

  //MAIN DASHBOARD

  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
  SET_DASHBOARD_ERROR: 'SET_DASHBOARD_ERROR',
  SET_DASHBOARD_SCORE: 'SET_DASHBOARD_SCORE',

  // reports
  SET_REPORT_DATA: 'SET_REPORT_DATA',
  //profiles
  SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
  //all companies
  SET_ALL_COMPANIES: 'SET_ALL_COMPANIES',
   //all complaince
   SET_ALL_COMPLAINCE: 'SET_ALL_COMPLAINCE',
   //all preference
   SET_ALL_PREFERENCE: 'SET_ALL_PREFERENCE',
   //set_All docs
  SET_COMPANY_DOCS: 'SET_COMPANY_DOCS',
  //Set ALL Notifications
  SET_ALL_NOTIFICATIONS: 'SET_ALL_NOTIFICATIONS',
  //Set ALL Filling Stat
  SET_ALL_FILLSTAT:'SET_ALL_FILLSTAT',
  //Set All Demand Data
  SET_ALL_COMPDEMDATA:'SET_ALL_COMPDEMDATA',
  //for Console view
  SET_CONSOLE_VIEW:'SET_CONSOLE_VIEW',
  //for task manage / create
  SET_TASK_MANAGE:'SET_TASK_MANAGE',
  //for team mates
  SET_TEAM:'SET_TEAM',
  //for task names
  SET_TASK_NAME:'SET_TASK_NAME',
  SET_CLIENT_MAIL:'SET_CLIENT_MAIL',

  //for Invoice
  SET_INVOICE_MANAGE:'SET_INVOICE_MANAGE',
  //descriptions
  SET_INVOICE_SERVICES:'SET_INVOICE_SERVICES',
  //invoice sub
  SET_INVOICE_SUBS:'SET_INVOICE_SUBS',

  SET_MONTH_COMP:'SET_MONTH_COMP'


};
