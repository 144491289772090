import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import { getAllComplaince } from 'src/redux/actions/allcomplaince';
import { deletepreference, getAllPreference, updatepreference } from 'src/redux/actions/preference';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import { addGst, addGstdemand } from 'src/redux/actions/gstBook';
import { PropaneSharp } from '@mui/icons-material';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';

function SelectAutoWidth(props) {
  const {gstNos, state} = props;
  const [complianceId, setComplianceId] = useState('');
  const handleChange = (event) => {
    setComplianceId(event.target.value);
  };

  return (
    <div>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1, overflow: 'scroll' }}
      >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Compliance</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={complianceId}
            onChange={handleChange}
            autoWidth
            label="Compliance"
          >
            <MenuItem value={7}>GSTR-3B</MenuItem>
            <MenuItem value={8}>GSTR-1</MenuItem>
            <MenuItem value={9}>GSTR-9</MenuItem>
            <MenuItem value={10}>GSTR-9C</MenuItem>
            <MenuItem value={11}>Demand and Notices Details</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {/* <h1>Hello world</h1> */}
        {
          {
            7: <Fields complianceId={complianceId} gstNos={gstNos} state={state}/>,
            8: <Gstr1 complianceId={complianceId} gstNos={gstNos} state={state}/>,
            9: <Gstr9 gstNos={gstNos} state={state}/>,
            10: <Gstr9c gstNos={gstNos} state={state}/>,
            11: <Demand gstNos={gstNos} state={state}/>
          }[complianceId]
        }
        {/* <Fields complianceId={complianceId} /> */}
      </Box>
    </div>
  );
}
const Fields = (props) => {
  const { complianceId, gstNos, state} = props
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
    // dispatch(getVaultData(companyId));
  }, []);
  let compname = "GSTR_3B_"+state;
  console.log("Complaince Name dfaifikadghagf", compname , all_complaince);
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(compname))
  console.log("OPT)))))))))))",opts);
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    frequency: '',
    paid_cash: '',
    paid_credit: '',
    intrest: '',
    date_fill: '',
    challan_copy: '',
    gst_copy: '',
    gst_num: ''
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Apr-June',
    'July-Sep',
    'Oct-Dec',
    'Jan-Mar'
  ];
  const fyear = [
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  // const frequency = ['Monthly', 'Quaterly', 'Half Yearly', 'Yearly'];
  const [freq, setFreq] = useState();
  const [finace, setFinace] = useState();
  const [mon, setMon] = useState();
  const [pcash, setPcash] = useState();
  const [pcred, setPcred] = useState();
  const [intrest, setIntrest] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();


  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log('HEllo WORLD');
  };
  const dis = (e) => {
    console.log('form data', formData);
    // formData['complianceID'] = complianceId
    formData['companyId'] = companyId
    formData.gst_num=gstNos
    console.log("gst fill num",gstNos);
    formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
    
    const compid = opts
    .filter((x) => x.frequency.includes(formData.frequency))
    formData['complianceID'] = compid[0].id;
    console.log("ID))()()()()()()()",compid,formData);
    var bodyFormData = new FormData();
    for(let key in formData){
      if(key,formData[key] !== ''){
        bodyFormData.append(key,formData[key])
      }
    }

    dispatch(addGst(bodyFormData,companyId))
  };

  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData)
  }
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
        <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
        <MenuItem value={"Monthly"}>Monthly</MenuItem>
        <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
        <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
        <MenuItem value={"Yearly"}>Yearly</MenuItem>
        </Select> */}
        <Autocomplete
          value={freq}
          name="frequency"
          onChange={(event, newValue) => {
            setFreq(newValue);
            {
              formData.frequency = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={["Monthly","Quaterly"]}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Frequency" />}
        />
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" />}
        />
        {console.log(formData)}
        {/* <InputLabel id="demo-simple-select-label" >Finance year</InputLabel>
        <Select onChange ={handleFormDataChange} name="finance_year" value ={formData.finance_year}  sx={{ margin: 1 }}  >
          {
            fyear.map((element)=>(
              <MenuItem name="finance_year"  sx={{ overflow :"scroll" }} value={element}>{element}</MenuItem>
            ))
          }
        </Select> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date fill"
            value={value}
            name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                formData.date_fill = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <h4>GST Copy</h4>
        <TextField name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        <TextField
          value={pcash}
          name="paid_cash"
          onChange={(e) => {
            setPcash(e.target.value);
            {
              formData.paid_cash = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Paid cash"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          value={pcred}
          name="paid_credit"
          onChange={(e) => {
            setPcred(e.target.value);
            {
              formData.paid_credit = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Paid credit"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <TextField
          name="intrest"
          onChange={(e) => {
            setIntrest(e.target.value);
            {
              formData.intrest = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          value={intrest}
          label="Interest Paid"
          variant="outlined"
          sx={{ margin: 1 }}
        />
        <h4>Challan Copy</h4>
        <TextField name='challan_copy' onChange={handleSelectFile} type="file" sx={{ margin: 1 }} />
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          SAVE
        </Button>
      </Box>
    </>
  );
};
const Gstr1 = (props) => {
    const { complianceId,gstNos, state} = props;
    const all_complaince = useSelector((state) => state.allComplaincereducers);
    const all_preference = useSelector((state) => state.allPreferencereducers);
    const [content, setContent] = useState([]);
    useEffect(() => {
      setContent(all_complaince.all_complaince);
    }, [all_complaince]);
    useEffect(() => {
      dispatch(getAllComplaince());
      dispatch(getAllPreference(companyId));
      // dispatch(getVaultData(companyId));
    }, []);
    let compname = "GSTR_1_"+state;
    console.log("Complaince Name dfaifikadghagf", compname , all_complaince);
    const opts = all_complaince.all_complaince
      .filter((x) => x.complianceName.includes(compname))
    console.log("OPT)))))))))))",opts);
    const [value, setValue] = useState(null);
    const [formData, setFormData] = useState({
      frequency: '',
      finance_year: '',
      month: '',
      date_fill: '',
      gst_copy: '',
    });
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      'Apr-June',
      'July-Sep',
      'Oct-Dec',
      'Jan-Mar'
    ];
    const fyear = [
      '2021-22',
      '2022-23',
      '2023-24'
    ];
    const [finace, setFinace] = useState();
    const [freq, setFreq] = useState();
    const [mon, setMon] = useState();
    const dispatch = useDispatch();
    let name, valuee;
    const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  
  
    const handleFormDataChange = (e) => {
      console.log(e);
      name = e.target.name;
      valuee = e.target.value;
      setFormData({ ...formData, [name]: valuee });
      console.log(name);
      console.log(valuee);
      console.log('HEllo WORLD');
    };
    const handleSelectFile = (e) => {
      e.preventDefault();
  
      name = e.target.name;
      valuee = e.target.files[0];
      setFormData({ ...formData, [name]: valuee });
      console.log('File name', formData)
    }
    const dis = (e) => {
      console.log('form data', formData);
      // formData['complianceID'] =  "231b1774-4426-4887-b814-714a8ff362a0"
      formData['companyId'] = companyId
      formData.gst_num=gstNos
      console.log("gst fill num",gstNos);
        formData['intrest'] = "0"
      formData['paid_cash'] = 0
      formData['paid_credit'] = 0
  
      formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
      const compid = opts
      .filter((x) => x.frequency.includes(formData.frequency))
      formData['complianceID'] = compid[0].id;
      console.log("ID))()()()()()()()",compid,formData);
      var bodyFormData = new FormData();
      for(let key in formData){
        if(key,formData[key] !== ''){
          bodyFormData.append(key,formData[key])
        }
      }
      console.log('form data', formData);
      dispatch(addGst(bodyFormData,companyId));
    };
    return (
      <>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
          <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
          <MenuItem value={"Monthly"}>Monthly</MenuItem>
          <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
          <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
          <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select> */}
          <Autocomplete
            value={freq}
            name="frequency"
            onChange={(event, newValue) => {
              setFreq(newValue);
              {
                formData.frequency = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            id="controllable-states-demo"
            options={["Monthly","Quaterly"]}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Frequency" required />}
          />
          <Autocomplete
            value={finace}
            name="finance_year"
            onChange={(event, newValue) => {
              setFinace(newValue);
              {
                formData.finance_year = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            id="controllable-states-demo"
            options={fyear}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Finance year" required />}
          />
          <Autocomplete
            value={mon}
            name="month"
            onChange={(event, newValue) => {
              setMon(newValue);
              {
                formData.month = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            id="controllable-states-demo"
            options={months}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Months/Period" required/>}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Filing"
              value={value}
              name="date_fill"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.date_fill = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
            />
          </LocalizationProvider>
          <h4>GST Return Upload</h4>
          <TextField required name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        </Box>
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>
      </>
    );
  };
const Gstr9 = (props) => {

    const { gstNos,state } = props;
    const all_complaince = useSelector((state) => state.allComplaincereducers);
    const all_preference = useSelector((state) => state.allPreferencereducers);
    const [content, setContent] = useState([]);
    useEffect(() => {
      setContent(all_complaince.all_complaince);
    }, [all_complaince]);
    useEffect(() => {
      dispatch(getAllComplaince());
      dispatch(getAllPreference(companyId));
      // dispatch(getVaultData(companyId));
    }, []);
    let compname = "GSTR_9_"+state;
    // console.log("Complaince Name dfaifikadghagf", compname , all_complaince);
    const opts = all_complaince.all_complaince
      .filter((x) => x.complianceName.includes(compname))
    // console.log("OPT)))))))))))",opts);
    const [value, setValue] = useState(null);
    const [formData, setFormData] = useState({
      finance_year: '',
      date_fill: '',
      gst_copy: ''
    });
    const fyear = [
      '2021-22',
      '2022-23',
      '2023-24'
    ];
    const [finace, setFinace] = useState();
    const dispatch = useDispatch();
    let name, valuee;
    const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  
  
    const handleFormDataChange = (e) => {
      console.log(e);
      name = e.target.name;
      valuee = e.target.value;
      setFormData({ ...formData, [name]: valuee });
      console.log(name);
      console.log(valuee);
      console.log('HEllo WORLD');
    };
    const handleSelectFile = (e) => {
      e.preventDefault();
  
      name = e.target.name;
      valuee = e.target.files[0];
      setFormData({ ...formData, [name]: valuee });
      console.log('File name', formData)
    }
    const dis = (e) => {
      console.log('form data', formData);
      // formData['complianceID'] = "7c6d46dc-e0ff-46f0-901a-7112e92ae016";
      formData['companyId'] = companyId
      formData['intrest'] = "0"
      formData['paid_cash'] = 0
      formData['paid_credit'] = 0
      formData.gst_num=gstNos
      console.log("gst fill num",gstNos);  
      formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
      const compid = opts
      formData['complianceID'] = compid[0].id;
      console.log("ID))()()()()()()()",compid,formData);
      var bodyFormData = new FormData();
      for(let key in formData){
        if(key,formData[key] !== ''){
          bodyFormData.append(key,formData[key])
        }
      }
      console.log('form data', formData);
      dispatch(addGst(bodyFormData,companyId));
    };
    return (
      <>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          <Autocomplete
            value={finace}
            name="finance_year"
            onChange={(event, newValue) => {
              setFinace(newValue);
              {
                formData.finance_year = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            id="controllable-states-demo"
            options={fyear}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Finance year" required />}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Filing"
              value={value}
              name="date_fill"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.date_fill = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
            />
          </LocalizationProvider>
          <h4>GST Return Upload</h4>
          <TextField required name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }}  />
        </Box>
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>
      </>
    );
  };
const Gstr9c = (props) => {
  const { gstNos,state } = props;
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const all_preference = useSelector((state) => state.allPreferencereducers);
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    dispatch(getAllPreference(companyId));
    // dispatch(getVaultData(companyId));
  }, []);
  let compname = "GSTR_9C_"+state;
  console.log("Complaince Name dfaifikadghagf", compname , all_complaince);
  const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(compname))
  console.log("OPT)))))))))))",opts);
    const [value, setValue] = useState(null);
    const [formData, setFormData] = useState({
      finance_year: '',
      date_fill: '',
      gst_copy: ''
    });
    const fyear = [
      '2021-22',
      '2022-23',
      '2023-24'
    ];
    const [finace, setFinace] = useState();
    const dispatch = useDispatch();
    let name, valuee;
    const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  
  
    const handleFormDataChange = (e) => {
      console.log(e);
      name = e.target.name;
      valuee = e.target.value;
      setFormData({ ...formData, [name]: valuee });
      console.log(name);
      console.log(valuee);
      console.log('HEllo WORLD');
    };
    const handleSelectFile = (e) => {
      e.preventDefault();
  
      name = e.target.name;
      valuee = e.target.files[0];
      setFormData({ ...formData, [name]: valuee });
      console.log('File name', formData)
    }
    const dis = (e) => {
      console.log('form data', formData);
      // formData['complianceID'] = "7c6d46dc-e0ff-46f0-901a-7112e92ae016"
      formData['companyId'] = companyId
      formData['intrest'] = "0"
      formData['paid_cash'] = 0
      formData['paid_credit'] = 0
      formData.gst_num=gstNos
      console.log("gst fill num",gstNos);
  
  
      formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
      const compid = opts
      formData['complianceID'] = compid[0].id;
      console.log("ID))()()()()()()()",compid,formData);
      var bodyFormData = new FormData();
      for(let key in formData){
        if(key,formData[key] !== ''){
          bodyFormData.append(key,formData[key])
        }
      }
      console.log('form data', formData);
      dispatch(addGst(bodyFormData,companyId));
    };
    return (
      <>
        <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
          {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
          <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
          <MenuItem value={"Monthly"}>Monthly</MenuItem>
          <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
          <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
          <MenuItem value={"Yearly"}>Yearly</MenuItem>
          </Select> */}
          <Autocomplete
            value={finace}
            name="finance_year"
            onChange={(event, newValue) => {
              setFinace(newValue);
              {
                formData.finance_year = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            id="controllable-states-demo"
            options={fyear}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Finance year" required />}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Filing"
              value={value}
              name="date_fill"
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setValue(newValue);
                {
                  formData.date_fill = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
            />
          </LocalizationProvider>
          <h4>GST Return Upload</h4>
          <TextField required name='gst_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }}  />
        </Box>
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>
      </>
    );
  };
  const Demand = (props) => {
      const { gstNos } = props;
      const [value, setValue] = useState(null);
      const [dueval, setDueval] = useState(null);
      const [payval, setPayval] = useState(null);
      const [formData, setFormData] = useState({
        finance_year: '',
        orderid: '',
        issued_by: '',
        notice_type: '',
        notice_descriptor: '',
        notice_issuance_date: '',
        due_date: '',
        demand_amount: '',
        payment_date: '',
        notice_copy: '',
      });
      const fyear = [
        '2021-22',
        '2022-23',
        '2023-24'
      ];
      const [finace, setFinace] = useState();
      const [order, setOrder] = useState();
      const [issue, setIssue] = useState();
      const [noticetype, setNoticetype] = useState();
      const [noticedes, setNoticedes] = useState();
      const [amount, setAmount] = useState();
      const dispatch = useDispatch();
      let name, valuee;
      const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
    
    
      const handleFormDataChange = (e) => {
        console.log(e);
        name = e.target.name;
        valuee = e.target.value;
        setFormData({ ...formData, [name]: valuee });
        console.log(name);
        console.log(valuee);
        console.log('HEllo WORLD');
      };
      const handleSelectFile = (e) => {
        e.preventDefault();
    
        name = e.target.name;
        valuee = e.target.files[0];
        setFormData({ ...formData, [name]: valuee });
        console.log('File name', formData)
      }
      const dis = (e) => {
        console.log('form data', formData);
        // formData['complianceID'] = complianceId
        formData['companyId'] = companyId
        formData.gst_num=gstNos
        console.log("gst fill num",gstNos);
        //formData.due_date = moment(formData.due_date).format('YYYY-MM-DD')
        formData['notice_issuance_date'] = moment(formData.notice_issuance_date).format('YYYY-MM-DD')
        //formData.payment_date = moment(formData.payment_date).format('YYYY-MM-DD')

    
        const bodyFormData = new FormData();
        for(let key in formData){
          if(key,formData[key] !== ''){
            if((key=="due_date")){
              if((formData.due_date==null)||(formData.due_date == 'Invalid date'))
              {
                formData.due_date=null
                bodyFormData.append(key,formData[key])
              }
              else{
                  formData.due_date = moment(formData.due_date).format('YYYY-MM-DD')
                  bodyFormData.append(key,formData[key])
              }

            }
            if((key=="payment_date")){
              if((formData.payment_date==null)||(formData.payment_date == 'Invalid date'))
              {
                formData.payment_date=null
                bodyFormData.append(key,formData[key])
              }
              else{
                formData.payment_date = moment(formData.payment_date).format('YYYY-MM-DD')
                bodyFormData.append(key,formData[key])
              }
            }
            else{
              bodyFormData.append(key,formData[key])
            }
            
          
          }
        }
        console.log('form data', formData);
        dispatch(addGstdemand(bodyFormData,companyId));
      };
      return (
        <>
          <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
            {/* <InputLabel id="demo-simple-select-label" >Frequency</InputLabel>
            <Select sx={{ margin: 1 }}  name="filling_type" onChange ={handleFormDataChange} value ={formData.filling_type} >
            <MenuItem value={"Monthly"}>Monthly</MenuItem>
            <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
            <MenuItem value={"Half Yearly"}>Half Yearly</MenuItem>
            <MenuItem value={"Yearly"}>Yearly</MenuItem>
            </Select> */}
            <Autocomplete
              value={finace}
              name="finance_year"
              onChange={(event, newValue) => {
                setFinace(newValue);
                {
                  formData.finance_year = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={fyear}
              sx={{ margin: 1 }}
              renderInput={(params) => <TextField {...params} label="Finance year" required />}
            />
            <TextField
                value={order}
                required
                name="orderid"
                onChange={(e) => {
                    setOrder(e.target.value);
                    {
                    formData.orderid = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
              }}
                label="Order ID"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            <TextField
                value={issue}
                required
                name="issued_by"
                onChange={(e) => {
                    setIssue(e.target.value);
                    {
                    formData.issued_by = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
              }}
                label="Issued By"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            <TextField
                value={noticetype}
                required
                name="notice_type"
                onChange={(e) => {
                    setNoticetype(e.target.value);
                    {
                    formData.notice_type = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
              }}
                label="Notice Type"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            <TextField
                value={noticedes}
                required
                name="notice_descriptor"
                onChange={(e) => {
                    setNoticedes(e.target.value);
                    {
                    formData.notice_descriptor = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
              }}
                label="Notice Description"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Notice Insurance Date"
                value={value}
                name="notice_issuance_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setValue(newValue);
                  {
                    formData.notice_issuance_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required/>}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                value={dueval}
                name="due_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setDueval(newValue);
                  {
                    formData.due_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
              />
            </LocalizationProvider>
            <TextField
                value={amount}
                required
                name="demand_amount"
                onChange={(e) => {
                    setAmount(e.target.value);
                    {
                    formData.demand_amount = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
              }}
                label="Amount of Demand"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Payment Date"
                value={payval}
                name="payment_date"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setPayval(newValue);
                  {
                    formData.payment_date = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
              />
            </LocalizationProvider>
            <h4>Notice Copy</h4>
            <TextField required name='notice_copy' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
          </Box>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained">
              SAVE
            </Button>
          </Box>
        </>
      );
    };
export default SelectAutoWidth;
// export {setAge};
