import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import MUIDataTable from 'mui-datatables';
import { allcompdemData_get } from 'src/redux/actions/allcompDemData_Action';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { calSumTillYear } from 'src/sections/@dashboard/app/MainPageComponents/util';
import { Button } from '@mui/material';
import { consoleView_get ,console_del ,consol_update} from 'src/redux/actions/console_view_action';
import { useConfirm } from 'material-ui-confirm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, TextField } from '@mui/material';

export default function Consoleview() {
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [cons_data, setCons_data] = useState([]);
  const consolidate = useSelector((state) => state.consoleViewreducer);
  useEffect(() => {
    dispatch(consoleView_get());
  }, []);
  useEffect(() => {
    setCons_data(consolidate.console_views);
  }, [consolidate]);
  const confirm = useConfirm();
  const handleDelete = (e,rowid) => {
    confirm({ description: 'This will permanently delete ' })
      .then(() => {
        console.log("row.id",rowid)
         dispatch(console_del(rowid));
        console.log('delete Success');
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const [open, setOpen] = useState(false);
  const [comp_name, setComp_name] = useState('');
  const [scroll, setScroll] = useState('paper');
  const [namee, setNamee] = useState();
  const [pass_up, setPass_up] = useState();

  const [formData, setFormData] = useState({
    username: '', 
    password: ''
  });
  const [rows_id, setrows_id] = useState();

  const handleUpdate = (e, row) => {
    console.log('update', row);
    setOpen(true);
    setComp_name(row.companyID__company_name);
    setNamee(row.username);
    setPass_up(row.password);
    setrows_id(row.id);
  };

  const handleFormDataChange = (e) => {
    console.log("called")
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log("formData",formData)
  };

  const handleSave = (e) =>{
    dispatch(consol_update(rows_id,formData))
  }

  const columns = [
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'port_name',
      label: 'Portal Name ',
      align: 'center',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'reg_no',
      label: 'Registration Number ',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'user_id',
      label: 'User Name ',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'pass',
      label: 'Password ',
      options: {
        filter: true,
        sort: true
      }
    },
    // { INTEREST P A I D }
    {
      name: 'up',
      label: 'Update',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'del',
      label: 'Delete',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const options = {
    searchAlwaysOpen: true,
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [10, 500, 1000,10000]

    //count, // Use total number of items
  };
  const data = consolidate?.console_views?.map((row) => {
    // console.log("row",row.companyID__company_name)
    return {
      name: row.companyID__company_name,
      port_name: row.bookName,
      reg_no: row.creadNum,
      user_id: row.username,
      pass: row.password,

      up: (
        <>
          <Button
            type="submit"
            variant="contained"
            color="success"
            onClick={(e) => handleUpdate(e, row)}
          >
            Update
          </Button>
        </>
      ),
      del: (
        <>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={(e) => handleDelete(e, row.id)}
          >
            Delete
          </Button>
        </>
      )
    };
  });
  return (
    <>
      <MUIDataTable title={'Consolidate View'} data={data} columns={columns} options={options} />
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            //sx={boxDefault}
          >
            {comp_name}
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box
              sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }}
              display="flex"
            >
              <TextField
                required
                value={namee}
                // type="number"
                name="username"
                onChange={(e) => {
                  setNamee(e.target.value);
                  {
                    formData.username = e.target.value;
                     console.log("Name", e.target.value);
                  }
                  {
                    handleFormDataChange(e);
                  }
                }}
                label="User Name"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={pass_up}
                // type="number"
                name="password"
                onChange={(e) => {
                  setPass_up(e.target.value);
                  {
                    formData.password = e.target.value;
                     console.log("Name", e.target.value);
                  }
                  {
                    handleFormDataChange(e);
                  }
                }}
                label="Password"
                variant="outlined"
                sx={{ margin: 1 }}
              />

              <Box
                container
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                sx={{ margin: 1 }}
              >
                <Button 
                variant="contained"
                onClick={handleSave}>
                  SAVE</Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
