import constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const invoice_manage = () => (dispatch)=>{
    HttpWrapper('GET', `/invoices-manager/api/invoices/`)
    .then((res) => {
      dispatch({
        type: constants.SET_INVOICE_MANAGE,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_INVOICE_MANAGE,
        payload: []
      });
    });
  }
  export const invoice_descriptions = () => (dispatch)=>{
    HttpWrapper('GET', `/invoices-manager/api/service/`)
    .then((res) => {
      dispatch({
        type: constants.SET_INVOICE_SERVICES,
        payload: res.data
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.SET_INVOICE_SERVICES,
        payload: []
      });
    });
  }

  export const add_invoice = (data) => (dispatch) => {
    HttpWrapper('POST', `/invoices-manager/api/invoices/`, false, data)
      .then((res, err) => {
        dispatch(invoice_manage());
        toast.success('Add Successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };  

  export const update_invoice = (id, data) => (dispatch) => {
    HttpWrapper('PATCH', `/invoices-manager/api/invoices/${id}/`, false, data)
      .then((res, err) => {
        toast.success('Update Successfully');
        dispatch(invoice_manage());
        console.log("res",res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const delete_invoice = (data) => (dispatch) => {
    HttpWrapper('DELETE', `/invoices-manager/api/invoices/${data}/`, true, data)
      .then((res, err) => {
        console.log('res', res.data);
        toast.success('Delete Successfully');
        dispatch(invoice_manage());
      })
      .catch((err) => {
        console.log(err);
      });
  };

