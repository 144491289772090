import { Card, CardHeader, Grid, CardContent, Typography } from '@mui/material';
import React from 'react';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TodayIcon from '@mui/icons-material/Today';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { getStatus } from '../dueDatesTables/AllDueDates';
export const TotalComplianceDue = (props) => {
  const { dueDatesState, year, companyId } = props;
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();
  const current_date = yyyy + '-' + mm + '-' + dd;
  var current_month = moment().subtract(1, 'month').startOf('month').format('MMMM');


  let today_compliance =
    dueDatesState?.allDueDates?.length > 0
      ? dueDatesState.allDueDates.filter((item) => {
        if (
          new Date(item.current_due_date).getDate() === new Date().getDate() &&
          new Date(item.current_due_date).getMonth() === new Date().getMonth() &&
          new Date(item.current_due_date).getFullYear() === new Date().getFullYear() &&
          item.date_of_filling === null
        ) {
          return true;
        } else {
          return false;
        }
        })
      : [];

  let upcoming_compliance = dueDatesState?.upcomingDueDates?.length > 0
    ? dueDatesState.upcomingDueDates.filter((item) => {
        if (
          new Date(item.current_due_date).getFullYear() === new Date().getFullYear() &&
          new Date(item.current_due_date).getMonth() === new Date().getMonth() && 
          item.date_of_filling === null
        ) {
          return true;
        }
        return false;
      })
    : [];

  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <TodayIcon style={{ fontSize: 50 }} htmlColor="blue" />
            <Link to={`/dashboard/upcomingDueDates?companyid=${companyId}`}>
              <Typography gutterBottom variant="h5" component="div">
                Compilance Due Today
              </Typography>
            </Link>
            <Typography variant="h3" color="green">
              {today_compliance.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <WatchLaterIcon style={{ fontSize: 50 }} htmlColor="yellow" />
            <Link to={`/dashboard/upcomingDueDates?companyid=${companyId}`}>
              <Typography gutterBottom variant="h5" component="div">
                Upcoming Compliance
              </Typography>
            </Link>
            <Typography variant="h3" color="blue">
              {upcoming_compliance.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card>
          <CardContent>
            <ReportProblemIcon style={{ fontSize: 50 }} htmlColor="red" />
            <Link to={`/dashboard/overDueDates?companyid=${companyId}`}>
              <Typography gutterBottom variant="h5" component="div">
                Overdue Compliance
              </Typography>
            </Link>
            <Typography variant="h3" color="red">
            {dueDatesState?.overDueDates?.length > 0
                  ? dueDatesState?.overDueDates?.filter(
                      (row) =>
                        getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) == 0
                    ).length
                  : 0}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
