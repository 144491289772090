import Constants from 'src/utils/constants';
const intialState = {
  task_management: []
};
export const taskreducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_TASK_MANAGE:
      return { ...state, task_management: action.payload };
    default:
      return state;
  }
};