import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { sentenceCase } from 'change-case';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from 'src/utils/common';
import { getAllDueDates } from 'src/redux/actions/dueDates';
import { useSearchParams } from 'react-router-dom';
import { getStatus } from '../sections/@dashboard/app/dueDatesTables/AllDueDates';
export const DueDatesLaptopView = ({ dueDates, filter }) => {
  const [dueDateData, setDueDateData] = useState([]);
  useEffect(() => {
    if (filter && dueDates != undefined) {
      const filterDueDate = dueDates?.filter((row) => {
        return getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) === 0;
      });
      setDueDateData(filterDueDate);
    } else {
      setDueDateData(dueDates);
    }
  }, [filter, dueDates]);
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Law</TableCell>
            <TableCell align="center">Frequency</TableCell>
            <TableCell align="center">Period</TableCell>
            <TableCell align="center">Compliance Name</TableCell>
            <TableCell align="center">Due Date</TableCell>
            {/* <TableCell align="center">Extended Date</TableCell> */}
            <TableCell align="center">Filling Date</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dueDateData?.length > 0 &&
            dueDateData.map((row) => {
              return (
                <TableRow hover>
                  <TableCell align="center">{row.book_name !== 'NA' ? row.book_name : row.category}</TableCell>
                  <TableCell align="center">{row.frequency}</TableCell>
                  <TableCell align="center">{row.period}</TableCell>
                  <TableCell align="center">{row.compliance_name}</TableCell>
                  <TableCell align="center">{dateFormat(row.current_due_date)}</TableCell>
                  {/* <TableCell align="center">
                    {row.extended_due_date === null ? '' : dateFormat(row.extended_due_date)}
                  </TableCell> */}
                  <TableCell align="center">
                    {row.date_of_filling === null ? '' : dateFormat(row.date_of_filling)}
                  </TableCell>
                  <TableCell align="center">
                    {row.due_date_applicable === false && (
                      <Label variant="ghost" color="error">
                        Not Applicable
                      </Label>
                    )}
                    {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) ===
                      1 &&
                      row.due_date_applicable === true && (
                        <Label variant="ghost" color="success">
                          Ontime
                        </Label>
                      )}
                    {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) ===
                      2 &&
                      row.due_date_applicable === true && (
                        <Label variant="ghost" color="secondary">
                          Upcoming
                        </Label>
                      )}
                    {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) ===
                      0 &&
                      row.due_date_applicable === true && (
                        <Label variant="ghost" color="error">
                          Overdue
                        </Label>
                      )}
                    {getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) ===
                      4 &&
                      row.due_date_applicable === true && (
                        <Label variant="ghost" color="default">
                          Delayed
                        </Label>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
