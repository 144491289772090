import Constants from 'src/utils/constants';
const intialState = {
  all_companies: []
};
export const allCompaniesreducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_COMPANIES:
      return { ...state, all_companies: action.payload };
    default:
      return state;
  }
};