import Constants from 'src/utils/constants';
const intialState = {
  team_mate: [],
  client_emails: []
};
export const team_mates_reducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_TEAM:
      return { ...state, team_mate: action.payload };
      case Constants.SET_CLIENT_MAIL:
        return { ...state, client_emails: action.payload };
    default:
      return state;
  }
};