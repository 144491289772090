import Constants from 'src/utils/constants';
const intialState = {
  console_views: []
};
export const consoleViewreducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_CONSOLE_VIEW:
      return { ...state, console_views: action.payload };
    default:
      return state;
  }
};