import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { sentenceCase } from 'change-case';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { dateFormat } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDueDates, updateDueDate } from '../../../../redux/actions/dueDates';
import { useSearchParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
const moment = require('moment');
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Link from '@mui/material/Link';

export const AllDueDates = () => {
  const [dueDates, setdueDates] = useState([]);
  const dueDatesState = useSelector((state) => state.dueDatesReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const [open, setOpen] = useState(false);
  const [selectedID, setID] = useState('');
  const [reason, setReason] = useState('');
  const [edit, setEdit] = useState(false);
  const [dof, setDof] = useState(new Date().toDateString());

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDueDates(companyId));
  }, []);
  useEffect(() => {
    setdueDates(
      dueDatesState?.allDueDates?.sort(function (a, b) {
        return new Date(a.current_due_date) - new Date(b.current_due_date);
      })
    );
  }, [dueDatesState.allDueDates]);

  const submitReason = (e) => {
    e.preventDefault();
    if (reason.trim().length === 0) {
      toast.error('Please enter valid reason');
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('due_date_applicable', false);
      bodyFormData.append('reason_for_non_applicable', reason);
      dispatch(updateDueDate(selectedID, bodyFormData, 1));
      setOpen(false);
      setReason('');
    }
  };

  const handleClickOpen = (id) => {
    setID(id);
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    setReason('');
  };

  const handleChange = (event) => {
    event.preventDefault();
    setReason(event.target.value);
  };


  const toggleEdit = (id) => {
    setEdit(!edit);
    setID(id);
  };

  const handleDateUpdate = () =>{
    const date_of_filling = moment(dof).format('YYYY-MM-DD');
    const bodyFormData = new FormData();
      bodyFormData.append('date_of_filling', date_of_filling);
      dispatch(updateDueDate(selectedID, bodyFormData, 1));
      setEdit(false)
  } 

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Law</TableCell>
            <TableCell align="center">Frequency</TableCell>
            <TableCell align="center">Period</TableCell>
            <TableCell align="center">Compliance Name</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dueDates?.length > 0 &&
            dueDates.map((row) => {
              return (
                <Tooltip title={row.description}>
                  <TableRow hover id={row.id}>
                    <TableCell align="center">{row.book_name}</TableCell>
                    <TableCell align="center">{row.frequency}</TableCell>
                    <TableCell align="center">
                      {row.period} ({row.fin_year})
                    </TableCell>
                    <TableCell align="center">
                      {row.ref_id === 'NA' && row.compliance_name}
                      {row.ref_id !== 'NA' &&
                        row.compliance_name + ' (' + row.sub_section.slice(0, 4) + ')'}
                    </TableCell>
                    <TableCell align="center">{dateFormat(row.current_due_date)}</TableCell>
                    {/* <TableCell align="center">
                    {row.extended_due_date === null ? '' : dateFormat(row.extended_due_date)}
                  </TableCell> */}
                    <TableCell align="center">
                      {row.date_of_filling === null && row.id === selectedID && edit === true && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date of Filling"
                            name="date_of_filling"
                            value={dof}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                            onChange={(newValue) => {
                              setDof(newValue);
                            }}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      )}
                      {row.date_of_filling !== null && dateFormat(row.date_of_filling)}
                    </TableCell>
                    <TableCell align="center">
                      {row.due_date_applicable === false && (
                        <Label variant="ghost" color="error">
                          Not Applicable
                        </Label>
                      )}
                      {getStatus(
                        row.current_due_date,
                        row.current_due_date,
                        row.date_of_filling
                      ) === 1 &&
                        row.due_date_applicable === true && (
                          <Label variant="ghost" color="success">
                            Ontime
                          </Label>
                        )}
                      {getStatus(
                        row.current_due_date,
                        row.current_due_date,
                        row.date_of_filling
                      ) === 2 &&
                        row.due_date_applicable === true && (
                          <Label variant="ghost" color="secondary">
                            Upcoming
                          </Label>
                        )}
                      {getStatus(
                        row.current_due_date,
                        row.current_due_date,
                        row.date_of_filling
                      ) === 0 &&
                        row.due_date_applicable === true && (
                          <Label variant="ghost" color="error">
                            Overdue
                          </Label>
                        )}
                      {getStatus(
                        row.current_due_date,
                        row.current_due_date,
                        row.date_of_filling
                      ) === 4 &&
                        row.due_date_applicable === true && (
                          <Label variant="ghost" color="default">
                            Delayed
                          </Label>
                        )}
                    </TableCell>

                    <TableCell align="center">
                      <Box sx={{ display: 'flex' }}>
                        {row.book_name.includes('book') == true && edit === false && (
                          <Tooltip title="Update">
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              onClick={(e) => toggleEdit(row.id)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {row.date_of_filling == null && edit === false && (
                          <Tooltip title="Not Applicable">
                            <IconButton
                              aria-label="not-app"
                              // color="error"
                              onClick={(e) => {
                                handleClickOpen(row.id);
                              }}
                            >
                              <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {edit === true && (
                          <Stack direction="row" spacing={2}>
                            <Button variant="contained" color="success" onClick={handleDateUpdate}>
                              Update
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={(e) => {
                                setEdit(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        )}
                        <FileS row={row} />
                        {row.attachment === null ? "": 
                        <Tooltip title="View">                          
                          <Link
                            target="_blank"
                            href={row.attachment}>
                            <IconButton color='success'>
                            <InsertDriveFileOutlinedIcon/>
                            </IconButton>
                          </Link>
                          </Tooltip>  
                        }
                        
                      </Box>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
        </TableBody>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reason for Not Applicable</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please give reason for not consider this compliance
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="Reason"
              multiline
              maxRows={4}
              type="text"
              fullWidth
              variant="standard"
              value={reason}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitReason}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Table>
    </TableContainer>
  );
};

export const getStatus = (dueDate, extendedDueDate, filling_date) => {
  const expiry = extendedDueDate === null ? dueDate : extendedDueDate;
  //2->upcoming
  // 1->ontime
  // 0->overdue
  //4 ->delayed
  if (filling_date === null) {
    if (
      new Date(expiry).getDate() === new Date().getDate() &&
      new Date(expiry).getMonth() === new Date().getMonth() &&
      new Date(expiry).getFullYear() === new Date().getFullYear()
    ) {
      return 2;
    } else if (new Date(expiry).getTime() < new Date().getTime()) {
      return 0;
    } else {
      return 2;
    }
  } else {
    if (new Date(filling_date) > new Date(expiry)) {
      return 4;
    } else {
      return 1;
    }
  }
};

const FileS = (props) => {
  const { row } = props;
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    else{
      const bodyFormData = new FormData();
      bodyFormData.append('attachment', fileObj);
      dispatch(updateDueDate(row.id, bodyFormData, 1));
    }
    console.log("fileObj",fileObj);

  };
  return (
    <>
      <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={handleFileChange} />
      <Tooltip title="Upload">
        <IconButton>
      <UploadIcon onClick={handleClick} />
      </IconButton>
      </Tooltip>
    </>
  );
};
