import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import Link from '@mui/material/Link';
import FileSaver from 'file-saver'

export default function TdsDeducted(props) {
  const { year } = props;
  const incomeState = useSelector((state) => state.incometaxReducer);
  if (incomeState.tds == null) {
    return <></>;
  }
  const data = incomeState.tds.filter((item) => item.finance_year === year);

  const downloadCSV = (e, data) => {
    e.preventDefault();
    console.log('data', data)
    const dataString = Papa.unparse(data,{});
    const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'tds_dedution.csv');
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Quarter</TableCell>
            <TableCell>TDS Amount</TableCell>
            <TableCell>Download Files(csv)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.quarter}</TableCell>
                <TableCell>{row.tds_amount}</TableCell>
                <TableCell>
                  <Link
                    variant="a"
                    // href={tdsState.tdsChallans[i].user_challan}
                    target="_blank"
                    underline="none"
                    onClick={(e) => downloadCSV(e, row.tds_data)}
                  >
                    Download
                  </Link>
                  {/* <a type="a"  target="_blank">
                    Link
                  </a> */}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            style={{
              fontWeight: 'bold'
            }}
          >
            <TableCell>Total TDS Deducted</TableCell>
            <TableCell>
              {data.map((item) => parseInt(item.tds_amount)).reduce((prev, next) => prev + next, 0)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {/* <TableRow
            
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              
              Download Current 26AS
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
