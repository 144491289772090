import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { addpf, deletepfdemand, updatepfDemand } from 'src/redux/actions/pf';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Label from 'src/components/Label';
import { dateFormatNew } from 'src/utils/common';
import { calDelay } from '../TdsTables/ReturnFilling';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { dateFormat } from 'src/utils/common';
import { getCompanyId } from 'src/utils/common';
import { ConfirmProvider } from "material-ui-confirm";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Button, InputLabel } from '@mui/material';
import { Collapse } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isStartupFino,istalenteye } from 'src/config';
import Alert from '@mui/material/Alert';

export const PaidEpfDemand = ({ year }) => {
  const pfState = useSelector((state) => state.pfReducer);
  const getExpiryDate = (compliance_id, month, fin_year) => {
    if (pfState.dueDates != null) {
      const dueDateObj = pfState.dueDates.filter((item) => {
        return (
          item.complianceID.id === compliance_id &&
          item.fin_year === fin_year &&
          item.period === month
        );
      });

      if (dueDateObj.length > 0) {
        const data = dueDateObj[0];
        if (data.extended_due_date === null) {
          return data.dueDate;
        } else {
          return data.extended_due_date;
        }
      } else {
        return false;
      }
    }
  };

  const [demandData, setdemandData] = useState();
  useEffect(() => {
    const penality = pfState?.demands?.filter((item) => item.penality_payment_dates !== null 
    // && item.finance_year === year
    );
    console.log('pf_penality', pfState?.demands)
    console.log('pf_penality data', penality)
    setdemandData(penality);
  }, [pfState?.demands]);
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& th': {
            fontSize: '1rem',
            background: 'hsl(149,100%,33%)',
            color: 'white'
          },
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>TRRN</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Delay Days</TableCell>
            <TableCell>Total Penality</TableCell>
            <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>Edit/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log(demandData)}
          {demandData &&
            demandData.map((row, i) => {
              if (row.finance_year.split('-')[0] <= year.split('-')[0]) {
                const delay =
                  pfState.dueDates.length > 0
                    ? calDelay(
                      getExpiryDate(
                        '60af05a5-0a24-449e-87d1-c49b5a90862f',
                        row.month,
                        row.finance_year
                      ),
                      row.payment_dates
                    )
                    : 0;
                return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={i}>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.trrn}</TableCell>
                    <TableCell>
                      {pfState?.dueDates?.length > 0
                        ? dateFormat(
                          getExpiryDate(
                            '60af05a5-0a24-449e-87d1-c49b5a90862f',
                            row.month,
                            row.finance_year
                          )
                        )
                        : '-'}
                    </TableCell>
                    <TableCell>{row.penality_payment_dates ? dateFormat(row.penality_payment_dates) : '-'}</TableCell>
                    <TableCell>
                      <Label color={delay > 0 ? 'error' : 'success'}>{delay}</Label>
                    </TableCell>
                    <TableCell>
                      <Label color={row.total_penalty > 0 ? 'error' : 'success'}>
                        {row.total_penalty}
                      </Label>
                    </TableCell>
                    <TableCell sx={{display: isStartupFino||istalenteye ? 'none': ''}}>
                      <Box textAlign="center">
                        <Update row={row} />
                        <ConfirmProvider>
                          <Delete row={row} />
                        </ConfirmProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const Delete = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  
  const confirm = useConfirm();
  const handleDelete = () => {
    confirm({ description: "This will permanently delete " })
      .then(() => {
        dispatch(deletepfdemand(row.id,companyId))
        console.log("newrows are after delete ", row.id);
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete</Button>
    </>
  );
}
const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("rows are", row);
  return (
    <>
      <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>Edit</Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">

          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          //sx={boxDefault}
          >
            Edit Data

            <CloseRoundedIcon onClick={handleClose} style={{ color: "#ff5252" }} boxShadow={1} />

          </Box>

        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <PfdemandUpadte row={row} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
const PfdemandUpadte = (props) => {
  //   const { complianceId } = PropaneSharp
  const { row,handleClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const [formData, setFormData] = useState({
    finance_year: '',
    month: '',
    total_penalty: '',
    penality_payment_dates: '',
  });
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const fyear = [
    '2011-12',
    '2012-13',
    '2013-14',
    '2014-15',
    '2015-16',
    '2016-17',
    '2017-18',
    '2018-19',
    '2019-20',
    '2020-21',
    '2021-22',
    '2022-23',
    '2023-24'
  ];
  const [finace, setFinace] = useState(row.finance_year);
  const [mon, setMon] = useState(row.month);
  const [penalty, setPenalty] = useState(row.total_penalty);
  const [value, setValue] = useState(row.penality_payment_dates);
  const [trr_num,setTrr_num]=useState(row.trrn)
  const dispatch = useDispatch();
  let name, valuee;



  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log("nameak",name);
    console.log("valuess",valuee);
    console.log('HEllo WORLD');
  };
  const dis = (e) => {

    formData['trrn'] = row.trrn;
    // formData.finance_year=finace
    // formData.month=mon
    // formData.total_penalty=penalty
    var bodyFormData = new FormData();
    for (let key in formData) {
        bodyFormData.append(key, formData[key])
    }
    console.log('form data', bodyFormData);
    dispatch(updatepfDemand(row.id, bodyFormData,companyId));
    handleClose()
  };
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Autocomplete
          value={finace}
          name="finance_year"
          onChange={(event, newValue) => {
            setFinace(newValue);
            {
              formData.finance_year = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required />}
        />
        <Autocomplete
          value={mon}
          name="month"
          onChange={(event, newValue) => {
            setMon(newValue);
            {
              formData.month = newValue;
            }
            {
              handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Months" required />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Penalty Payment Date"
            value={value}
            name="penality_payment_dates"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              {
                if(newValue != null){
                  newValue = moment(newValue).format('YYYY-MM-DD');
                  formData["penality_payment_dates"] = newValue;
                }else{
                  formData["penality_payment_dates"] = '';
                  console.log("Eslse called")
                }
              }
              {
                handleFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
          />
        </LocalizationProvider>
        <TextField
          required
          value={penalty}
          type="number"
          name="total_penalty"
          onChange={(e) => {
            setPenalty(e.target.value);
            {
              formData.total_penalty = e.target.value;
            }
            {
              handleFormDataChange;
            }
          }}
          label="Penalty Amount"
          variant="outlined"
          sx={{ margin: 1 }}
        />
      </Box>
      {
      finace === null ||
      finace === undefined ||
      mon === undefined ||
      mon === null ||
      penalty === undefined ||
      penalty === '' 
      ? (
        <>
          <Alert severity="error" alignItems="center">
            {' '}
            Fill all fileds with (*)
          </Alert>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained" disabled>
              UPDATE
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button onClick={dis} variant="contained">
              UPDATE
            </Button>
          </Box>
        </>
      )}

    </>
  );
};
