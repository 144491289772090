import React from 'react';
import { AppCurrentVisits } from 'src/sections/@dashboard/app';
import TdsDashboard from 'src/sections/@dashboard/app/TdsDashboard';
const Tds = () => {
  return (
    <div className="tds">
      <TdsDashboard />
    </div>
  );
};

export default Tds;
