import React, { useEffect, useState } from 'react';
import { GSTR1B } from '../@dashboard/app/GstTables/GSTR1';
import { GSTR3B } from '../@dashboard/app/GstTables/GSTR3B';
import { Button, Card, CardHeader } from '@mui/material';
import { Demands } from '../@dashboard/app/GstTables/Demands';
import { getAllComplaince } from 'src/redux/actions/allcomplaince';
import { mapFinalcialYearToYear, getGSTState } from 'src/utils/common';
import { getCompanyId } from 'src/utils/common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { FormControl, FormLabel, FormControlLabel } from '@mui/material';
import { RadioGroup, Radio, Stack } from '@mui/material';
import BasicD from './BasicDailog';
import GSTPanel from 'src/components/gst_book/gst_panel';
import { deletepreference, getAllPreference, updatepreference } from 'src/redux/actions/preference';
import { getVaultData, updateVaultData } from 'src/redux/actions/loginVault';

import {
  getGSTFilling1R,
  getGSTFilling3B,
  fetchDueDates,
  getGSTDemand,
  getGST9,
  getGST9C,
  postGstFreq,
  fetchGstFreq,
  updateGstFreq
} from 'src/redux/actions/gstBook';
import { useSelector, useDispatch } from 'react-redux';
import { GSTR9 } from '../@dashboard/app/GstTables/GSTR9';
import { GSTR9_C } from '../@dashboard/app/GstTables/GSTR9_C';

import yearsToMonths from 'date-fns/yearsToMonths/index.js';
import { Grid } from '@mui/material';
import YearDropDown from 'src/components/gst_book/YearDropDown';
import { isStartupFino , istalenteye} from 'src/config';
// import BasicModal from './BasicModal';

const years = ['2021-22', '2022-23', '2023-24'];

const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export const GstDashboard = () => {
  const all_preference = useSelector((state) => state.allPreferencereducers);
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const vaultState = useSelector((state) => state.vaultReducer);

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const gst3b = useSelector((state) => state.gstReducer.gstFillings_3b);
  const gst1R = useSelector((state) => state.gstReducer.gstFillings_1r);
  const gst9 = useSelector((state) => state.gstReducer.gst_9);
  const gst9c = useSelector((state) => state.gstReducer.gst_9c);
  const gstDemand = useSelector((state) => state.gstReducer.gst_demand);
  const dueDates = useSelector((state) => state.gstReducer.dueDates);
  const companyData = useSelector((state) => state.orgProfileReducer);
  const gstFreq = useSelector((state) => state.gstReducer.gstFreq);
  const tdsState = useSelector((state) => state.tdsReducer);
  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);

  const [states, setStates] = useState([]);
  const [gst3bData, set3bData] = useState([]);
  const [gst9Data, set9Data] = useState([]);
  const [gst9cData, set9cData] = useState([]);
  const [gst1rData, set1rData] = useState([]);
  const [demandData, setDemandData] = useState([]);
  const [data, setData] = useState(null);
  const [gstNos, setGstNos] = useState({});
  const [overallGSTScore, setOverallGstScore] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');


  useEffect(() => {
    setData(vaultState.vault_data);
  }, [vaultState]);

  const gst_nos_vault = data?.filter((item) =>  item.creadName.includes('GST') === true);
  console.log('gst_nos_vault', gst_nos_vault);

  useEffect(() => {
    dispatch(getVaultData(companyId));
    dispatch(getAllPreference(companyId));
    dispatch(getAllComplaince());
  }, []);
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);

  console.log('content', content);

  const gst_pref = all_preference.all_preference.filter((item) =>
    item.compliance_name.includes('GST')
  );
  let states_list = gst_pref.map((item) => {
    const state_name = item.compliance_name.split('_');
    return state_name.at(-1);
  });

  states_list = states_list.filter((item, i, ar) => ar.indexOf(item) === i);

  states_list = states_list.filter((item) => item.includes('GST') === false);
  console.log('states_list', states_list);

  const [year, setYear] = React.useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );
  const [freq, setFreq] = useState({
    Q1: '',
    Q2: '',
    Q3: '',
    Q4: ''
  });

  const handleYearChange = (event) => {
    setYear(event.target.value);
    console.log(event.target.value);
    const data3b = gst3b.filter(
      (item) =>
        getGSTState(item.gst_num) === states[value] && item.finance_year === event.target.value
    );
    const data1r = gst1R.filter(
      (item) =>
        getGSTState(item.gst_num) === states[value] && item.finance_year === event.target.value
    );
    const data9 = gst9.filter(
      (item) =>
        getGSTState(item.gst_num) === states[value] && item.finance_year === event.target.value
    );
    const data9c = gst9c.filter(
      (item) =>
        getGSTState(item.gst_num) === states[value] && item.finance_year === event.target.value
    );
    set3bData(data3b);
    set1rData(data1r);
    set9Data(data9);
    set9cData(data9c);
    calculateScore(data3b.concat(data1r));
    const freq = {
      Q1: getFreq(event.target.value, gstNos[states[value]], 'Q1'),
      Q2: getFreq(event.target.value, gstNos[states[value]], 'Q2'),
      Q3: getFreq(event.target.value, gstNos[states[value]], 'Q3'),
      Q4: getFreq(event.target.value, gstNos[states[value]], 'Q4')
    };
    setFreq(freq);
  };

  useEffect(() => {
    dispatch(getGST9(companyId));
    dispatch(getGST9C(companyId));
    dispatch(getGSTFilling1R(companyId));
    dispatch(getGSTFilling3B(companyId));
    dispatch(getGSTDemand(companyId));
    dispatch(fetchDueDates());
    dispatch(fetchGstFreq(companyId));
  }, []);

  useEffect(() => {
    if (dueDates !== undefined && gst1R !== undefined && gst3b !== undefined && gst_nos_vault !== undefined) {
      const statesName = [...new Set(gst_nos_vault?.map((item) => getGSTState(item.creadNum)))];
      const nos = Object.assign(
        {},
        ...gst_nos_vault?.map((item) => ({ [getGSTState(item.creadNum)]: item.creadNum }))
      );
      setGstNos(nos);
      setStates(statesName);
      const freq = {
        Q1: getFreq(year, nos[statesName[0]], 'Q1'),
        Q2: getFreq(year, nos[statesName[0]], 'Q2'),
        Q3: getFreq(year, nos[statesName[0]], 'Q3'),
        Q4: getFreq(year, nos[statesName[0]], 'Q4')
      };
      setFreq(freq);
      handleDataChange(statesName[0]);
    }
  }, [dueDates, gst1R, gst3b, gst9, gst9c, gstFreq]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleDataChange(states[newValue]);
    let compname = 'GSTR_3B_' + states[newValue];
    const opts = all_complaince.all_complaince.filter((x) => x.complianceName.includes(compname));
  };

  const handleDataChange = (state_name) => {
    const data3b = gst3b.filter(
      (item) => getGSTState(item.gst_num) === state_name && item.finance_year === year
    );

    const data1r = gst1R.filter(
      (item) => getGSTState(item.gst_num) === state_name && item.finance_year === year
    );

    const data9 = gst9.filter(
      (item) => getGSTState(item.gst_num) === state_name && item.finance_year === year
    );
    const data9c = gst9c.filter(
      (item) => getGSTState(item.gst_num) === state_name && item.finance_year === year
    );
    const dataDemand = gstDemand.filter((item) => getGSTState(item.gst_num) === state_name);
    set3bData(data3b);
    set1rData(data1r);
    set9Data(data9);
    set9cData(data9c);
    setDemandData(dataDemand);

    calculateScore(data3b.concat(data1r));

    const freq = {
      Q1: getFreq(year, gstNos[state_name], 'Q1'),
      Q2: getFreq(year, gstNos[state_name], 'Q2'),
      Q3: getFreq(year, gstNos[state_name], 'Q3'),
      Q4: getFreq(year, gstNos[state_name], 'Q4')
    };
    setFreq(freq);
  };

  const getFreq = (year, num, quarter) => {
    const item = gstFreq.filter(
      (row) => row.finance_year === year && row.gst_num === num && row.month === getQuarter(quarter)
    );
    if (item != null && item.length > 0) {
      console.log('HELLO GST FREQ+++++', item[0].filling_type);
      return item[0].filling_type;
    } else {
      return 'Monthly';
    }
  };

  const calculateScore = (data) => {
    const delayData = data.filter((item) => item.due_data.delay > 0);
    const score = ((data.length - delayData.length) / data.length) * 100;
    console.log('score', score, data.length - delayData.length);
    setOverallGstScore(parseInt(score));
  };

  const handleFreqChange = (quarter, event) => {
    console.log(event.target.value);
    setFreq({ ...freq, [quarter]: event.target.value });
  };
  // const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();

  const handleSubmit = (num, quarter) => {
    var bodyFormData = new FormData();
    bodyFormData.append('companyId', companyId);
    bodyFormData.append('finance_year', year);
    bodyFormData.append('month', getQuarter(quarter));
    bodyFormData.append('filling_type', freq[quarter]);
    bodyFormData.append('gst_num', num);
    const item = gstFreq.filter(
      (row) => row.finance_year === year && row.gst_num === num && row.month === getQuarter(quarter)
    );
    if (item != null && item.length > 0) {
      console.log(item);
      dispatch(updateGstFreq(bodyFormData, item[0].id));
    } else {
      dispatch(postGstFreq(bodyFormData));
    }
  };

  const getQuarter = (quarter) => {
    switch (quarter) {
      case 'Q1':
        return 'Apr-June';
      case 'Q2':
        return 'July-Sep';
      case 'Q3':
        return 'Oct-Dec';
      default:
        return 'Jan-Mar';
    }
  };
  // console.log("STATE", state);
  return (
    <>
      <YearDropDown handleYearChange={handleYearChange} year={year} years={years} />
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {states.map((row, i) => {
          return <Tab label={row} {...a11yProps(i)} />;
        })}
      </Tabs>
      {states.map((state, i) => {
        return (
          <TabPanel value={value} index={i}>
            {(!isStartupFino && !istalenteye) && <BasicD gstNos={gstNos[state]} state={state} />}
            <GSTPanel
              handleYearChange={handleYearChange}
              year={year}
              years={years}
              overallGSTScore={overallGSTScore}
              dashBoardScore={dashBoardScore}
              state={state}
            />
            <br />
            <Card>
              <CardHeader title="GSTR 3B Filing Status" />
              <GSTR3B gstFilling={gst3bData} gstNos={gstNos[state]} />
            </Card>
            <br />
            <Card>
              <CardHeader title="GSTR 1 Filing Status" />
              <GSTR1B gstFilling={gst1rData} gstNos={gstNos[state]} />
            </Card>
            <br></br>
            <Card>
              <CardHeader title="GSTR 9 Filing Status" />
              <GSTR9 gst9={gst9Data} gstNos={gstNos[state]} />
            </Card>
            <br></br>
            <Card>
              <CardHeader title="GSTR 9C Filing Status" />
              <GSTR9_C gst9c={gst9cData} gstNos={gstNos[state]} />
            </Card>
            <br></br>
            <Card>
              <CardHeader title="Demand and Notices Details" />
              <Demands gstDemand={demandData} gstNos={gstNos[state]} />
            </Card>
            <br></br>
            <Card>
              <Stack direction="column">
                {quarters.map((row, index) => {
                  return (
                    <FormControl>
                      <Stack direction="row">
                        <Grid container>
                          <Grid item md={1} m={1}>
                            <FormLabel id="demo-controlled-radio-buttons-group">{row}</FormLabel>
                          </Grid>
                          <Grid item md={3}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={freq[row]}
                              onChange={(e) => handleFreqChange(row, e)}
                            >
                              <Stack direction="row">
                                <FormControlLabel
                                  value="Quaterly"
                                  control={<Radio />}
                                  label="Quaterly"
                                />
                                <FormControlLabel
                                  value="Monthly"
                                  control={<Radio />}
                                  label="Monthly"
                                />
                              </Stack>
                            </RadioGroup>
                          </Grid>
                          <Grid item m={1} md={2}>
                            <Button
                              variant="contained"
                              onClick={() => handleSubmit(gstNos[state], row)}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                      </Stack>
                    </FormControl>
                  );
                })}
              </Stack>
            </Card>
          </TabPanel>
        );
      })}
    </>
  );
};
