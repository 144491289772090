import { useState, useEffect } from 'react';
//import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useSearchParams } from 'react-router-dom';
import { getAllComplaince } from 'src/redux/actions/allcomplaince';
import { deletepreference, getAllPreference, updatepreference } from 'src/redux/actions/preference';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Select from '@mui/material/Select';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'date-fns';
//import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import moment from 'moment';
import { getCompanyId } from 'src/utils/common';
import { addtdschallan } from 'src/redux/actions/tds';
import { addtdsreturn } from 'src/redux/actions/tds';
import { adddemandnotice,intialTdsChallanFetching } from 'src/redux/actions/tds';

//import {toast} from  


function TdsSelectAutoWidth() {
  const [complianceId, setComplianceId] = useState('');
  const handleChange = (event) => {
    setComplianceId(event.target.value);
  };

  return (
    <div>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1, overflow: "scroll" }}
      >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Compliance</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={complianceId}
            onChange={handleChange}
            autoWidth
            label="Compliance"
          >
            <MenuItem sx={{ m: 1 }} value={10}>TDS Challan</MenuItem>
            <MenuItem sx={{ m: 1 }} value={21}>TDS Return</MenuItem>
            <MenuItem sx={{ m: 1 }} value={22}>Demand & Notice</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {/* <h1>Hello world</h1> */}
        {
          {
            10: <Tdschallan />,
            21: <Tdsreturn />,
            22: <Demandnotice />
          }[complianceId]
        }
      </Box>
    </div>
  );
}
const Tdschallan = () => {
  const [tdsvalue, setTdsvalue] = useState(null);
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    // dispatch(getAllPreference(companyId));
    // dispatch(getVaultData(companyId));
  }, []);
  console.log("Complaince Name dfaifikadghagf", all_complaince);
  const [tdsData, setTdsData] = useState({
    finance_year: "Finance Year",
    month: "Month",
    tds_type: "Tds Type",
    section: "Tdsec",
    paymentDate: "",
    filling_type: "",
    challan_num: "",
    base_amount: "",
    interest_amount: "",
    bsr_code: "",
    receipt: "",
    user_challan: ""
  });
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const tdsmonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const tdstypes = [{ id: "1", name: "24Q" }, { id: "2", name: "26Q" }, { id: "3", name: "27Q" }, { id: "4", name: "27EQ" }];

  const tdsection = [{ id: "1", tdstypesId: "24Q", name: "92A - Payment to Government Employees other than Union Government employees" },
  { id: "2", tdstypesId: "24Q", name: "92B - Payment to Employees other than Government employees" },
  { id: "3", tdstypesId: "24Q", name: "94P - Payment to a specified Senior Citizen" },
  { id: "4", tdstypesId: "24Q", name: "92C - Payment to Union Government Employees" },
  { id: "5", tdstypesId: "26Q", name: "193 - Interest on Securities" },
  { id: "6", tdstypesId: "26Q", name: "194 - Dividend" },
  { id: "7", tdstypesId: "26Q", name: "4BA - Certain Income from the units of Business Trust" },
  { id: "8", tdstypesId: "26Q", name: "4BB - Winning from Horse Race" },
  { id: "9", tdstypesId: "26Q", name: "4DA - Payment in respect of Life Insurance Policy" },
  { id: "10", tdstypesId: "26Q", name: "4EE - Payment in respect of Deposits under National Saving Scheme" },
  { id: "11", tdstypesId: "26Q", name: "4LA - Payment of Compensation on Acquisition of certain Immovable Property" },
  { id: "12", tdstypesId: "26Q", name: "94A - Interest Other than Interest on Securities" },
  { id: "13", tdstypesId: "26Q", name: "94B - Winning from Lotteries and Crossword Puzzles" },
  { id: "14", tdstypesId: "26Q", name: "94C - Payment of Contractors or Sub Contractors" },
  { id: "15", tdstypesId: "26Q", name: "94D - Insurance Commission" },
  { id: "16", tdstypesId: "26Q", name: "94F - Payment on account of Repurchase of Units by Mutual Fund" },
  { id: "17", tdstypesId: "26Q", name: "94G - Commission, prize etc. on sale of lottery tickets" },
  { id: "18", tdstypesId: "26Q", name: "94H - Commission or Brokerage" },
  { id: "19", tdstypesId: "26Q", name: "94I - Rent" },
  { id: "20", tdstypesId: "26Q", name: "94J - Fees for Professional or Technical Services" },
  { id: "21", tdstypesId: "26Q", name: "94K - Income payable to resident assessee in respect of Units of a specified Mutual Fund or of the Units of the UTI" },
  { id: "22", tdstypesId: "26Q", name: "94N - Payment of Certain amounts in Cash" },
  { id: "23", tdstypesId: "26Q", name: "94Q - Payment of tax at source on payment of certain sum for purchase of goods" },
  { id: "23", tdstypesId: "26Q", name: "4IC - Payment under specified agreement" },
  { id: "25", tdstypesId: "26Q", name: "94O - TDS on ecommerce transactions" },
  { id: "26", tdstypesId: "27Q", name: "4LB - Income by way of Interest from Infrastructure, Debt Fund Payable to a Non Resident" },
  { id: "27", tdstypesId: "27Q", name: "4LC - Income by way of Interest from a specified company payable to a Non Resident" },
  { id: "28", tdstypesId: "27Q", name: "94E - Payment to Non Resident Sportsmen/Sports Association" },
  { id: "29", tdstypesId: "27Q", name: "96A - Income in respects of Units of non residents" },
  { id: "30", tdstypesId: "27Q", name: "96B - Payment in respect of Units to an Offshore fund" },
  { id: "31", tdstypesId: "27Q", name: "96C - Payment from foreign currency Bonds or Shares of Indian Company payable to a non resident" },
  { id: "32", tdstypesId: "27Q", name: "96D - Income from Foreign Institutional investors from securities" },
  { id: "33", tdstypesId: "27Q", name: "195 - Other sums payable to a Non Resident" },
  { id: "34", tdstypesId: "27Q", name: "2AA - Payment of accumulated balance due to an employee" },
  { id: "36", tdstypesId: "27Q", name: "4LD - Interest on Rupee Dinominated Bonds of Companies or Government Securities" },
  { id: "37", tdstypesId: "27Q", name: "LBC - Income in respect of investment in securitization trust" },
  { id: "38", tdstypesId: "27Q", name: "LBB - Income in respect of Units of a investment Fund" },
  { id: "40", tdstypesId: "27EQ", name: "6CA - Alcoholic liquor for human consumption" },
  { id: "41", tdstypesId: "27EQ", name: "6CB - Timber obtained under a forest lease" },
  { id: "42", tdstypesId: "27EQ", name: "6CC - Timber obtained under any mode other than forest lease" },
  { id: "43", tdstypesId: "27EQ", name: "6CD - Timber obtained under any mode other than forest lease" },
  { id: "44", tdstypesId: "27EQ", name: "6CE - Scrap" },
  { id: "45", tdstypesId: "27EQ", name: "6CF - Parking Lot" },
  { id: "46", tdstypesId: "27EQ", name: "6CG - Toll Plaza" },
  { id: "47", tdstypesId: "27EQ", name: "6CH - Mining and quarrying" },
  { id: "48", tdstypesId: "27EQ", name: "6CI - Tendu Leaves" },
  { id: "49", tdstypesId: "27EQ", name: "6CJ - Minerals" },
  { id: "50", tdstypesId: "27EQ", name: "6CK - Bullion and Jewellery" },
  { id: "51", tdstypesId: "27EQ", name: "6DA - Income from Specified funds from Securities referred to in section 115AD" },
  { id: "52", tdstypesId: "27EQ", name: "6CL - TCS on Sale of Motor Vehicle" },
  { id: "53", tdstypesId: "27EQ", name: "6CM - TCS on Sale in cash of any goods (Other than bullion/Jewellery)" },
  { id: "54", tdstypesId: "27EQ", name: "6CN - TCS on providing of any services (Other than Ch -XVIIB)" },
  { id: "55", tdstypesId: "27EQ", name: "6CO - TCS on foreign remmitance" },
  { id: "56", tdstypesId: "27EQ", name: "6CR - TCS on Sales of Goods" },
  { id: "57", tdstypesId: "27EQ", name: "6CP - Remmitance under LRS for educational loan" },
  { id: "58", tdstypesId: "27EQ", name: "6CQ - Remittance under LRS for the purposes other than purchase of overseas tour or education loan" },
  { id: "59", tdstypesId: "27EQ", name: "6C(IH) TCS on Sales of exports of goods" }




  ];
  let finalid = "";
  const [tdsfy, setTdsfy] = useState();
  const [tdsmon, setTdsmon] = useState();
  const [tdsamt, setTdsamt] = useState();
  const [tdsintrest, setTdsintrest] = useState();
  const [tdsty, setTdsty] = useState([]);
  const [tdsec, setTdsec] = useState([]);
  const [tdschallno, setTdschallno] = useState();
  const [tdsbsrcode, setTdsbsrcode] = useState();
  const [tdsid, setTdsid] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  useEffect(() => {
    setTdsty(tdstypes);
  }, [])


  const handleTds = (id) => {
    setTdsid(id);
    finalid = id;
    console.log("i am id", id);
  }
  const handleTdsFormDataChange = (e, id) => {
    console.log(e);
    tdsname = e.target.name;
    tdsvalue = e.target.value;

    setTdsData({ ...tdsData, [tdsname]: tdsvalue });
    console.log(tdsname);
    console.log(tdsvalue);
    console.log("HEllo WORLD");
  }

  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setTdsData({ ...tdsData, [name]: valuee });
    console.log('File name', tdsData)
  }
  const dis = (e) => {
    tdsData['companyId'] = companyId
    let compname = "TDS Challan_"+tdsData.tds_type;
    const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(compname))
    const compid = opts
    .filter((x) => x.frequency.includes(tdsData.filling_type))
    tdsData['complianceID'] = compid[0].id;
    tdsData.paymentDate = moment(tdsData.paymentDate).format('YYYY-MM-DD')
    var bodyFormData = new FormData();
    for (let key in tdsData) {
      if (key, tdsData[key] !== '') {
        bodyFormData.append(key, tdsData[key])
      }
    }
    console.log('form data', tdsData);
    dispatch(addtdschallan(bodyFormData,companyId));
    

  };

  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        {/*tdschallan : Finance yeare*/}
        <Autocomplete
          value={tdsfy} name="finance_year"
          onChange={(event,newValue) => {
            setTdsfy(newValue);
            {
              tdsData.finance_year = newValue;
            }
            {
              handleTdsFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required /> }
        />
        {/*tdschallan : Month*/}
        <Autocomplete
          value={tdsmon} name="month"
          onChange={(event,newValue) => {
            setTdsmon(newValue);
            { 
              tdsData.month = newValue; 
            }
            { 
              handleTdsFormDataChange; 
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          id="controllable-states-demo"
          options={tdsmonths}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Month" required />  }
        />
        {/*tdschallan : Types*/}
        <Autocomplete
          value={tdsty} name="Tds Types"
          onChange={(event, newValue) => {
            setTdsty(newValue);
            { 
              tdsData.tds_type = newValue; 
            }
            { 
              handleTds(newValue);
            };

          }}
          id="controllable-states-demo"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={tdstypes.map((el) => el.name)}
          getOptionLabel={(option) => typeof option === 'string'
            || option instanceof String ? option : ""}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="TDS Type" required />}
        />
        {/*tdschallan : Tds section Types*/}
        <Autocomplete
          value={tdsec} name="tdsec"
          onChange={(e, newValue) => {
            setTdsec(newValue);
            {
              tdsData.section = newValue;
            }
          }}
          id="controllable-states-demo"
          options={tdsection.filter(x => x.tdstypesId === tdsid).map((el) => el.name)}
          getOptionLabel={(option) => typeof option === 'string'
            || option instanceof String ? option : ""}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="TDS Section" required />  }
        />
        {/*tdschallan : Tds Payment Date Filling*/}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Payment Date"
            value={tdsvalue} name="paymentDate"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setTdsvalue(newValue);
              { 
                tdsData.paymentDate = newValue;
              }
              { 
                handleTdsFormDataChange;
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />  }
          />
        </LocalizationProvider>
        {/*tdschallan : Tds Challan Number*/}
        <TextField
          value={tdschallno} name="Challan Number"
          onChange={(e) => {
            setTdschallno(e.target.value);
            { 
              tdsData.challan_num = e.target.value; 
            }
            { 
              handleTdsFormDataChange
            };
          }}
          label="Challan Number" variant="outlined" sx={{ margin: 1 }} required/>
                
        <TextField
          value={tdsamt} name="base_amount"
          onChange={(e) => {
            setTdsamt(e.target.value);
            { 
              tdsData.base_amount = e.target.value; 
            }
            { 
              handleTdsFormDataChange
            };
          }}
          label="TDS Payment" variant="outlined" sx={{ margin: 1 }} />
                
        <TextField
          value={tdsintrest} name="interest_amount"
          onChange={(e) => {
            setTdsintrest(e.target.value);
            { 
              tdsData.interest_amount = e.target.value; 
            }
            { 
              handleTdsFormDataChange
            };
          }}
          label="Interest" variant="outlined" sx={{ margin: 1 }} />
                
        {/*tdschallan : BSR Code*/}
        <TextField
          value={tdsbsrcode} name="BSR Code"
          onChange={(e) => {
            setTdsbsrcode(e.target.value);
            { 
              tdsData.bsr_code = e.target.value; 
            }
            { 
              handleTdsFormDataChange; 
            }
          }}
          label="BSR Code" variant="outlined" sx={{ margin: 1 }}/>
                
        {/*tdschallan : Otlas file*/}  
        <h5>Oltas Confirmation</h5>
        <TextField name='receipt' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        {/*tdschallan : Tds challan file*/} 
        <h5>TDS Challan </h5>
        <TextField name='user_challan' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
        {/*tdschallan : Tds Save Button*/}
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button onClick={dis} variant="contained">
            SAVE
          </Button>
        </Box>

      </Box>
    </>
  );
};
const Tdsreturn = () => {
  const [value, setValue] = useState(null);
  const all_complaince = useSelector((state) => state.allComplaincereducers);
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(all_complaince.all_complaince);
  }, [all_complaince]);
  useEffect(() => {
    dispatch(getAllComplaince());
    // dispatch(getAllPreference(companyId));
    // dispatch(getVaultData(companyId));
  }, []);
  console.log("Complaince Name dfaifikadghagf", all_complaince);
  const [formData, setFormData] = useState({
    finance_year: "",
    quarter: "",
    form_type:"",
    form_name: "",
    token_num: "",
    date_fill: "",
    return_status:"",
    return_file:"",
    acknowledgement:""

  });
  const formtype = ["Orignal", "Revised"];
  const formname = ["26Q", "24Q", "27Q", "27EQ"];
  const returnstatus = ["Processed with defaults","Processed without defaults"];
  const fyear = ["2021-22", "2022-23", "2023-24"];
  const quartermonth = ["Apr-June", "July-Sep", "Oct-Dec", "Jan-Mar"];

  const [quat, setQuat] = useState();
  const [finace, setFinace] = useState();
  const [retstat,setRetstat]= useState();
  const [fromna, setFormna] = useState();
  const [formty, setFormty] = useState();
  const [tokno, setTokno] = useState();
  const dispatch = useDispatch();
  let name, valuee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');

  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log("HEllo WORLD");
  }
  const handleSelectFile = (e) => {
    e.preventDefault();

    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log('File name', formData)
  }
  const dis = (e) => {
    // formData['complianceID'] = complianceId
    formData['companyId'] = companyId
    let compname = "TDS Return_"+formData.form_name;
    const opts = all_complaince.all_complaince
    .filter((x) => x.complianceName.includes(compname))
    // const compid = opts
    // .filter((x) => x.frequency.includes("Monthly"))
    formData['complianceID'] = opts[0].id;
    //toast.success('Add Successfully');
    // formData['complianceID']="7017f28d-e06e-41ff-a0bb-c4b3a13938fa"
    formData.date_fill = moment(formData.date_fill).format('YYYY-MM-DD')
    var bodyFormData = new FormData();
    var count = 1
    for (let key in formData) {
      if ((key, formData[key] == '0') || (key, formData[key] == '')){
         count=count+1 
      }
      if (key, formData[key] !== '') {
        bodyFormData.append(key, formData[key])
      }
    }
    // console.log('form data', formData);
    if(formData.fin_year !== '' && formData.form_name !== '' ){
    dispatch(addtdsreturn(bodyFormData,companyId));
    }
     
    // console.log("pakad",count)
    if(count>5)
    {
        toast.error("Please fill All required fields")
    }
    else{
      if(formData.finance_year=='')
      {
        toast.error("Financial Year Is Required")
      }
    }
  };


  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

        {/*tds return : Financial Year*/}  
        <Autocomplete
          value={finace} name="finance_year"
          onChange={(event,newValue) => {
            setFinace(newValue);
            { 
              formData.finance_year = newValue; 
            }
            {
               handleFormDataChange;
            }
          }}
          id="controllable-states-demo"
          options={fyear}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Finance year" required /> }
        />
        {/*tds return : Quarter */}
        <Autocomplete
          value={quat} name="quater"
          onChange={(event,newValue) => {
            setQuat(newValue);
            { 
              formData.quarter = newValue;
            }
            { 
              handleFormDataChange; 
            }
          }}
          id="controllable-states-demo"
          options={quartermonth}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Quarter" required />}
        />
        {/*tds return : Form Type */}        
        <Autocomplete
          value={formty} name="form_type"
          onChange={(event,newValue) => {
            setFormty(newValue);
            { 
              formData.form_type = newValue;
            }
            {
               handleFormDataChange; 
            }
          }}
          id="controllable-states-demo"
          options={formtype}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="From Type" required />}
        />

        {/*tds return : Form Name */}        
        <Autocomplete
          value={fromna} name="form_name"
          onChange={(event, newValue) => {
            setFormna(newValue);
            { 
              formData.form_name = newValue; 
            }
            { 
              handleFormDataChange; 
            }
          }}
          id="controllable-states-demo"
          options={formname}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="From Name" required />}
        />
        {/*tds return : token number */}
        <TextField
          value={tokno} name="token_num"
          onChange={(e) => {
            setTokno(e.target.value);
            { 
              formData.token_num = e.target.value; 
            }
            {
               handleFormDataChange; 
            }
          }}
          label="Token Number" variant="outlined" sx={{ margin: 1 }} required />

        {/*tds return : Date Of filling */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Filling"
            value={value} name="date_fill"
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setValue(newValue);
              { 
                formData.date_fill = newValue; 
              }
              { 
                handleFormDataChange; 
              }
            }}
            renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
          />
        </LocalizationProvider>

        {/*tds return : Tds return status */}
        <Autocomplete
          value={retstat} name="return_status"
          onChange={(event, newValue) => {
            setRetstat(newValue);
            { 
              formData.return_status = newValue; 
            }
            { 
              handleFormDataChange; 
            }
          }}
          id="controllable-states-demo"
          options={returnstatus}
          sx={{ margin: 1 }}
          renderInput={(params) => <TextField {...params} label="Return Status" required />}
        />

        {/*tds return : Tds return file */}
        
        <h5>TDS Return</h5>
        <TextField required name='return_file' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />

        {/*tds return : Tds Ack. file */}
        
        <h5>TDS Return Acknowledgment</h5>
        <TextField required name='acknowledgement' onChange={handleSelectFile} type="file" hidden sx={{ margin: 1 }} />
      </Box>

        {/*tds return : Tds Save */}

      <Box container m={1} display="flex" justifyContent="center" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={dis} variant="contained">SAVE</Button></Box>
    </>
  );

};
const Demandnotice = () => {
  const [tdsdnData, setTdsdnData] = useState({
    fin_year: "",
    payment_date: "",
    total_demand: ""
  });
  const tdsdnfyear = ["2010-11", "2011-12", "2012-13", "2013-14", "2014-15", "2015-16", "2016-17", "2017-18", "2018-19", "2019-20", "2020-21", "2021-22", "2022-23", "2023-24", "2024-25", "2025-26", "2026-27"];

  const [tdsdnvalue, setTdsdnvalue] = useState(null);
  const [tdsdnfy, setTdsdnfy] = useState();
  const [tdsdntot, setTdsdntot] = useState();
  const dispatch = useDispatch();
  let tdsdnname, tdsdnvaluee;
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const dis = (e) => {
    // formData['complianceID'] = complianceId


    if (tdsdnData.payment_date !== 'null') 
    {
      tdsdnData['companyId'] = companyId
      //toast.success('Add Successfully');
      tdsdnData.complianceID = "2b8fb873-2686-40c6-9707-796cef90f648"
      var bodyFormData = new FormData();
      console.log("Date enterd")
      tdsdnData.payment_date = moment(tdsdnData.payment_date).format('YYYY-MM-DD')
      for (let key in tdsdnData) {
        console.log("indian", key);

        if (key, tdsdnData[key] !== '0' || key, tdsdnData[key] !== '') {
          bodyFormData.append(key, tdsdnData[key])
        }
  
      }
      console.log('form data', tdsdnData);
      
      dispatch(adddemandnotice(bodyFormData,companyId));

    }
    if (tdsdnData.payment_date == 'null'||tdsdnData.payment_date == 'Invalid date' ) 
    {
      console.log("date removed");
      tdsdnData['companyId'] = companyId
      //toast.success('Add Successfully');
      tdsdnData.complianceID = "2b8fb873-2686-40c6-9707-796cef90f648"
      var bodyFormData = new FormData();
      for (let key in tdsdnData) {
        console.log("main key", key);

        if (key, tdsdnData[key] !== '0') 
        {
          if(key=="payment_date"){
            tdsdnData.payment_date="null"
            console.log("ok",tdsdnData.payment_date)
            
          } 
          else{
            bodyFormData.append(key, tdsdnData[key])
          }
        }
        
      }
     
      
      if((tdsdnData.fin_year == null)||(tdsdnData.fin_year == ''))
      {
        toast.error("Financial Year Is Required")
      }
      if((tdsdnData.total_demand == null)|| (tdsdnData.total_demand == ''))
      {
        toast.error("Total Demand Is Required")
      }
    
        console.log('form data', tdsdnData);
        dispatch(adddemandnotice(bodyFormData,companyId));
    }
    
  };

  const handleTdsdnFormDataChange = (e) => {
    console.log(e);
    tdsdnname = e.target.name;
    tdsdnvaluee = e.target.value;
    setTdsdnData({ ...tdsdnData, [tdsdnname]: tdsdnvaluee });
    console.log("HEllo WORLD");
  }
  return (
    <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">

      {/*tds Demand and notice : finance year */}

      <Autocomplete
        value={tdsdnfy} name="fin_year"
        onChange={(event, newValue) => {
          setTdsdnfy(newValue);
          {
            tdsdnData.fin_year = newValue;
          }
          {
            handleTdsdnFormDataChange;
          }
        }}
        id="controllable-states-demo"
        options={tdsdnfyear}
        sx={{ margin: 1 }}
        renderInput={(params) => <TextField {...params} label="Finance years" required />}
      />
      {/*tds Demand and notice : Payment Date */}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Payment Date"
          value={tdsdnvalue}
          name="payment_date"
          inputFormat="dd/MM/yyyy"
          onChange={(newValue) => {
            setTdsdnvalue(newValue);
            { tdsdnData.payment_date = newValue };
          }}
          renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} />}
        />
      </LocalizationProvider>

      {/*tds Demand and notice : total_demand */}

      <TextField  




       
        value={tdsdntot} name="total_demand"
        onChange={(e) => {
          setTdsdntot(e.target.value);
          { tdsdnData.total_demand = e.target.value };
          { handleTdsdnFormDataChange };
        }}
        label="Total Demand" variant="outlined" sx={{ margin: 1 }} required />
      <Button onClick={dis} variant="contained">SAVE</Button>
    </Box>
  );

};

export default TdsSelectAutoWidth;
// export {setComplianceId};
