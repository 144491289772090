import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@mui/material/Modal';
import { flexbox } from '@mui/system';
// import SelectAutoWidth from './dropmodal';
import { TextField } from '@mui/material';
import { useState } from 'react';
// import { setAge } from './dropmodal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DropContentEsi from './Esidropmodal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 

// import DropContent from './Pfdropmodal';


function EsibasicD(props) {
  const {challans} = props;
  const [open, setOpenadd] = React.useState(false);
  const handleOpenadd = () => setOpenadd(true);
  const handleCloseadd = () => setOpenadd(false);
  const [open1, setOpenupdate] = React.useState(false);
  const handleOpenupdate = () => setOpenupdate(true);
  const handleCloseupdate = () => setOpenupdate(false);

  // const [showText, setShowText] = useState(false);
  // const onClick = () => setShowText(true);

  return (
    <>
      <Dialof challans = {challans}/>
    </>

  );
}

const Dialof = (props) => {
  const {challans} = props;
  
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box container m={1} display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ margin: 1 }} >
        <Button onClick={handleClickOpen('paper')} variant="contained" sx={{ mr: 2 }}>ADD</Button>
        <Button onClick={handleClickOpen('body')} variant="contained" >Update</Button>
        <Dialog
          open={open}
          //onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">

            <Box
              component="span"
              m={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            //sx={boxDefault}
            >
              Add Data

              <CloseRoundedIcon onClick={() => setOpen(false)} style={{ color: "#ff5252" }} boxShadow={1} />

            </Box>

          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              //   ref={descriptionElementRef}
              tabIndex={-1}
            >
              <DropContentEsi challans={challans} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} variant="contained">Cancel</Button> */}
            {/* <Button onClick={handleClose} variant="contained">SAVE</Button> */}
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default EsibasicD;
