import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import { rootReducer } from './reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import { composeWithDevTools } from 'redux-devtools-extension';


const persistConfig = {
  key: 'root',
  storage,
}
const initialState = {};
const enhancers = [];
const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer)


const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

// export default createStore(persistedReducer, initialState, composedEnhancers);

// let store = createStore(persistedReducer, initialState, composedEnhancers);
// let persistor = persistStore(store)

let store = createStore(rootReducer, initialState, composedEnhancers);

export default {
  store
}

// export default  {
//   store, persistor
// }
