import Constants from 'src/utils/constants';
const intialState = {
  comp_demandData: []
};
export const allCompDemDatareducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_COMPDEMDATA:
      return { ...state, comp_demandData: action.payload };
    default:
      return state;
  }
};
