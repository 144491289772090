import Constants from '../../utils/constants';
const intialState = {
  notifications: []
};

export const profileReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_NOTIFICATION_DATA:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};
