import Constants from 'src/utils/constants';
const intialState = {
    all_preference: []
};
export const allPreferencereducers = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_PREFERENCE:
      return { ...state, all_preference: action.payload };
    default:
      return state;
  }
};