import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { invoice_manage,delete_invoice } from 'src/redux/actions/invoice_manage_action';
import Label from 'src/components/Label';
import { Button } from '@mui/material';
const Tax_Delete = (props) => {
  const { row,handleClickClose } = props;

  const [comp_name, setcomp_name] = useState(null);
  const [check2, setcheck2] = useState(null);
  const [req_date, setreq_date] = useState(null);
  const [req_by, setreq_by] = useState(null);
  const [gst_no, setgst_no] = useState(null);
  const [bill_ent, setbill_ent] = useState(null);
  const [in_no, setin_no] = useState(null);
  const [in_date, setin_date] = useState(null);
  const [pay_stat, setpay_stat] = useState(null);
  const [pay_rcvd, setpay_rcvd] = useState(null);
  const [tds_ded, settds_ded] = useState(null);
  const [out_amt, setout_amt] = useState(null);
  const [bill_types, setbill_types] = useState(null);
  const [in_remarks, setin_remarks] = useState(null);
  const [list_company_id, setlist_company_id] = useState(null);
  const [services_list, setservices_list] = useState(null);
  const [password, setpassword] = useState('password');
  const [user_acess, setUser_Acess] = useState();

  const edit_invoice_Data = useSelector((state) => state.invoice_reducers);
  const [api_data, setapi_data] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(invoice_manage());
  }, []);
  useEffect(() => {
    setapi_data(edit_invoice_Data.invoice_manage);
  }, [edit_invoice_Data]);
  console.log('delete', api_data, row, edit_invoice_Data);
  const filter_by_invoice_id = api_data?.filter((item) => item.id === row)[0];
  console.log(filter_by_invoice_id, 'i m in delete');
  const invoices = filter_by_invoice_id;

  useEffect(() => {
    setcomp_name(invoices?.company_name);
    setreq_date(invoices?.requested_date);
    setreq_by('Deepak Malhotra');
    setgst_no(invoices?.gst_number);
    setbill_ent(invoices?.billing_entity);
    setin_no(invoices?.invoice_number);
    setin_remarks(invoices?.remark);
    setin_date(invoices?.invoice_date);
    setbill_types(invoices?.bill_type);
    setpay_stat(invoices?.payment_status);
    setpay_rcvd(invoices?.payment_received);
    settds_ded(invoices?.tds_deducted);
    setout_amt(invoices?.outstanding);
    setcheck2(invoices?.invoice_amoint);
  }, [filter_by_invoice_id]);

  const handleDelete = () =>{
    dispatch(delete_invoice(row))
    handleClickClose();
  }

  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Typography variant="overline" display="block" gutterBottom color={user_acess===password?"red":"black"}>
            Alert : Following data will be deleted
        </Typography>
          <Typography align="left" color="black">
            Company Name : {comp_name}
          </Typography>
        <Typography align="left" color="black">
          Invoice Amount : {check2}
        </Typography>
        <Box justifyContent="center" display="flex" sx={{margin:2}}>
          <TextField
            value={user_acess}
            type="password"
            onChange={(e) => {
              setUser_Acess(e.target.value);
            }}
            label="Enter Password To Delete Invoice"
            variant="outlined"
            sx={{ margin: 1, width: 300 }}
          />
        <Button variant="contained" color="error" sx={{ m: 2 }}
        disabled={user_acess===password?false:true}
        onClick={handleDelete}
        >
        Delete Invoice
      </Button>
        </Box>
      </Box>

    </>
  );
};

export default Tax_Delete;
