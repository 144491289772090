import Constants from '../../utils/constants';
import { mapFinalcialYearToYear } from 'src/utils/common';
const intialState = {
  allDueDates: [],
  upcomingDueDates: [],
  overDueDates: [],
  year: '2021-22'
};

export const dueDatesReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_ALL_DUE_DATES:
      return { ...state, allDueDates: action.payload };
    case Constants.SET_UPCOMING_DUE_DATES:
      return { ...state, upcomingDueDates: action.payload };
    case Constants.SET_OVER_DUE_DATES:
      return { ...state, overDueDates: action.payload };
    case Constants.SET_YEAR:
      return { ...state, year: action.payload };

    default:
      return state;
  }
};
