import { Stack, Divider, Typography } from '@mui/material';

import Loginbtn from './Loginbtn';

export default function AuthSocial() {
  return (
    <>
      <Loginbtn />
      <Stack direction="row" spacing={2}>
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:google-fill" color="#DF3E30" height={24} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" height={24} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" height={24} />
        </Button> */}
      </Stack>

      {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider> */}
    </>
  );
}

// import React, { useState } from 'react';
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
// import { Stack, Button, Divider, Typography } from '@mui/material';

// const clientId = '106440327417-orgng26dvoqt8mbmp45jnn2rk8qo2jj9.apps.googleusercontent.com';

// function AuthSocial() {
//   const [showloginButton, setShowloginButton] = useState(true);
//   const [showlogoutButton, setShowlogoutButton] = useState(false);
//   const onLoginSuccess = (res) => {
//     console.log('Login Success:', res.profileObj);
//     setShowloginButton(false);
//     setShowlogoutButton(true);
//   };

//   const onLoginFailure = (res) => {
//     console.log('Login Failed:', res);
//   };

//   const onSignoutSuccess = () => {
//     alert('You have been logged out successfully');
//     console.clear();
//     setShowloginButton(true);
//     setShowlogoutButton(false);
//   };

//   return (
//     <div>
//       google login part
//       <GoogleLogin clientId='' onSuccess={responseGoogle} onFailure={responseGoogle}/>

//       google login part

//       <Button fullWidth size="large" color="inherit" variant="outlined">
//           <Iconify icon="eva:facebook-fill" color="#1877F2" height={24} />
//         </Button>

//       <Button fullWidth size="large" color="inherit" variant="outlined">
//       <div>
//         {showloginButton ? (
//           <GoogleLogin
//             clientId={clientId}
//             buttonText="Sign In"
//             onSuccess={onLoginSuccess}
//             onFailure={onLoginFailure}
//             cookiePolicy={'single_host_origin'}
//             isSignedIn={true}
//           />
//         ) : null}

//         {showlogoutButton ? (
//           <GoogleLogout
//             clientId={clientId}
//             buttonText="Sign Out"
//             onLogoutSuccess={onSignoutSuccess}
//           ></GoogleLogout>
//         ) : null}
//       </div>
//       </Button>

//       <Button fullWidth size="large" color="inherit" variant="outlined">
//           <Iconify icon="eva:twitter-fill" color="#1C9CEA" height={24} />
//         </Button>

//       <Divider sx={{ my: 3 }}>
//         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//           OR
//         </Typography>
//       </Divider>
//     </div>
//   );
// }
// export default AuthSocial;
