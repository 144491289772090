import React from 'react';
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useState } from 'react';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { fCurrency } from 'src/utils/formatNumber';
import { calOutstandingSum } from './util';
import { calSumTillYear } from './util';
import { isStartupFino, istalenteye } from 'src/config';

export const OutstandinTaxTable = ({ outstandingData, year }) => {
  console.log('out', outstandingData);
  return (
    <>
      {!istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                // background: 'rgb(3, 252, 211)',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Outstanding Tax</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Notice</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>GST</TableCell>
                <TableCell>
                  {calSumTillYear(
                    JSON.parse(outstandingData['gst_outstanding'])['demand_amount'],
                    year
                  )}
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>TDS</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'][`${year}`] ===
              undefined
                ? 0
                : JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['tds_demand_outstanding'])['total_demand'],
                    year
                  )}
                </TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>PF</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`] ===
              undefined
                ? 0
                : JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['pf_penalties_outstanding'])['total_penalty'],
                    year
                  )}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>ESI</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`] ===
              undefined
                ? 0
                : JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['esi_outstanding'])['total_penalty'],
                    year
                  )}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>IncomeTax</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['tax_demand_interest'])['demand_amount'][`${year}`] ===
              undefined
                ? 0
                : JSON.parse(outstandingData['tax_demand_interest'])['demand_amount'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['tax_demand_outstanding'])['demand_amount'],
                    year
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>
                  <Label color="success">
                    {fCurrency(calOutstandingSum(outstandingData, year))}
                  </Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {istalenteye && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& th': {
                fontSize: '1rem',
                // background: 'rgb(3, 252, 211)',
                background: 'hsl(149,100%,33%)',
                color: 'white'
              }
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Outstanding Tax</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Notice</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>PF</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(outstandingData['pf_penalties_interest'])['total_penalty'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['pf_penalties_outstanding'])['total_penalty'],
                    year
                  )}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>ESI</TableCell>
                <TableCell>
                  {/* {JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`] ===
                  undefined
                    ? 0
                    : JSON.parse(outstandingData['esi_outstanding'])['total_penalty'][`${year}`]} */}
                  {calSumTillYear(
                    JSON.parse(outstandingData['esi_outstanding'])['total_penalty'],
                    year
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>
                  <Label color="success">
                    {calSumTillYear(
                      JSON.parse(outstandingData['pf_penalties_outstanding'])['total_penalty'],
                      year
                    ) +
                      calSumTillYear(
                        JSON.parse(outstandingData['esi_outstanding'])['total_penalty'],
                        year
                      )}
                  </Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
