import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
export default function ChargeSearch() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      const tmp =
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .ChargeSearchReport.OpenChargesBankWise.ChargeHolder;
      setdata(tmp);
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Charge Holder</TableCell>
            <TableCell align="center">Amount (₹ in Lakhs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
              <TableCell component="th" scope="row">
                {row.ChargeHolderName}
              </TableCell>
              <TableCell align="center">{row.OpenChargeTotalInINRBankWise}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
