import React from 'react';
import { useState,useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// components
import Page from '../components/Page';
import { ExtendedProfileForm } from 'src/sections/authentication/register/ExtendedProfileForm';
import { appName } from 'src/config';
import logoGIF from 'src/gif/basicprof.gif';
import creds from 'src/gif/creds.gif';
import noti from 'src/gif/noti.gif';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 20,
  paddingRight: 15,
  paddingLeft: 15,
  paddingBottom:20,
  width: '520px',
  boxShadow: '1px 0px 15px 4px rgb(43 48 53 / 24%)',
  overflowY: 'auto', // Add this to show a scrollbar
  maxHeight: '500px', // Set a fixed height or adjust as needed
  '::-webkit-scrollbar': {
    width: '12px', // You can adjust the width
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)', // You can set the color you prefer  
    borderRadius: '6px', // You can adjust the border radius
  },
  '::-webkit-scrollbar-thumb:hover': {  
    background: 'rgba(0, 0, 0, 0.4)', // Hover color
  },

}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 680,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 800 : 500]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#00AB55' : '#808080'
  }
}));

export const ExtendedProfileSetUp = () => {
  const [onpage, setonpage] = useState(1);
  const handleProgress = (val) => {
    setonpage(val);
  };
  const handleboola = (val) => {
    setboola(val);
  };
  useEffect(() => {
    // Scroll to the top of the section when onpage changes to 2.
    if(onpage==2||onpage==1){
      console.log('page2true')
      const section = document.getElementById('scrollToSection');
      if(section){
        console.log('page2truescroll',section)
        section.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [onpage]);
  console.log('onpage', onpage);
  return (
    <Box
      style={{
        display: 'flex',
        overflow: 'hidden',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'white',
        justifyItems:'center'
      }}
    >
    <Grid container  sx={{backgroundColor:"white",display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <Grid
            item
            xs={7}
            md={7}
            lg={7}
            sx={{
              mb: 8,
              position: 'sticky',
              top: 0,
              height: '100vh',
              justifyContent:'center', // Set a fixed height for the image section
              display:'flex',
              flexDirection:'column'
            }}
          >
            {onpage == 1 && 
            <img src={logoGIF} alt="Your Logo" />
            
            }
            {onpage == 2 && <img src={creds} alt="Your Logo" style={{marginTop:20}} />}
            {onpage == 3 && <img src={noti} alt="Your Logo"style={{marginTop:20}}/>}

            <Box sx={{ ml: 10, mt: 2 }}>
              <Typography variant="h5">Simple 5 Steps Onboarding</Typography>
              <Box alignItems="center" justifyContent="center" width={'80%'} sx={{ mt: 2 }}>
                {/* Your progress bar and step info */}
                <BorderLinearProgress variant="determinate" value={(onpage + 1) * 20} />
                <Typography variant="p" sx={{ mt: 2 }}>
                  Step {onpage + 1} of 5
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} md={5} lg={5} display='flex' justifyContent='center'>
              <ContentStyle >
                <SectionStyle id="scrollToSection">
                  <ExtendedProfileForm handleProgress={handleProgress} handleboola={handleboola}/>
                </SectionStyle>
              </ContentStyle>
          </Grid>
        </Grid>
    </Box>
  );
};
