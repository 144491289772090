import Constants from '../../utils/constants';

const intialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isLoading: true,
  error: null
};
export const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_USER_LOGIN_START:
      return { ...state, isLoading: true };
    case Constants.SET_USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        isLoading: false
      };
    case Constants.SET_ORG_PROFILE_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case Constants.SET_USER_LOGOUT:
      return {
        ...state,
        user: null,
        accessToken: null,
        refreshToken: null,
        isLoading: true,
      };
    default:
      return state;
  }
};
