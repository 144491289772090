import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
export const getGSTFilling3B = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gstfilling_3b/${companyId}/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_FILLING_3B,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const getGSTFilling1R = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gstfilling_1r/${companyId}/`, false, {})
    .then((res) => {
      console.log("gst r1 data",res.data) 
        dispatch({
        type: Constants.SET_GST_FILLING_1R,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const getGST9 = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gstfilling_9/${companyId}/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_9,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const getGST9C = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gstfilling_9c/${companyId}/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_9C,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const getGSTDemand = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gstdemand_details/?companyId=${companyId}`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_DEMAND,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const deletegstdemand = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/gstbook/gstdemand_details/all/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
      dispatch(getGSTDemand(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deletegstfilling = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/gstbook/gstfilling/get/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
      dispatch(getGSTFilling1R(companyId))
      dispatch(getGSTFilling3B(companyId))
      dispatch(getGST9(companyId))
      dispatch(getGST9C(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updategstDemand = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/gstbook/gstdemand_details/all/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(getGSTDemand(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updategstfilling = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/gstbook/gstfilling/get/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      console.log("reached patch in Get filling")

      toast.success('Updated Challan Data');
      dispatch(getGSTFilling1R(companyId))
      dispatch(getGSTFilling3B(companyId))
      dispatch(getGST9(companyId))
      dispatch(getGST9C)



    })
    .catch((err) => {
      console.log(err);
    });
};



export const fetchDueDates = () => (dispatch) => {
  HttpWrapper('GET', `/compliance/due-date-book/GST/`, false, {})
    .then((res) => {
      const data = res.data;
      dispatch({
        type: Constants.SET_GST_DUE_DATES,
        payload: data
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGSTOverview = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gst-overview/${companyId}/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_OVERVIEW,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};

export const postGstFreq = (data) => (dispatch) => {
  HttpWrapper('POST', `/gstbook/gst-frequency/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated  Freq');
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addGst = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/gstbook/gstfilling/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(getGSTFilling1R(companyId))
      dispatch(getGSTFilling3B(companyId))
      dispatch(getGST9(companyId))
      dispatch(getGST9C(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addGstdemand = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/gstbook/gstdemand_details/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(getGSTDemand(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateGstFreq = (data, id) => (dispatch) => {
  HttpWrapper('PUT', `/gstbook/gst-frequency/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated  Freq');
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchGstFreq = (companyId) => (dispatch) => {
  HttpWrapper('GET', `/gstbook/gst-frequency/${companyId}/`, false, {})
    .then((res) => {
      dispatch({
        type: Constants.SET_GST_FREQ,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.SET_GST_ERROR,
        payload: error.message
      });
    });
};
