import Constants from 'src/utils/constants';

const intialState = {
  data: null,
  score: null,
  error: null
};

export const dashBoardReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_DASHBOARD_DATA:
      return { ...state, data: action.payload };
    case Constants.SET_DASHBOARD_SCORE:
      return { ...state, score: action.payload };
    case Constants.SET_DASHBOARD_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
