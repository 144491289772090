import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadLink from 'react-download-link';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function Incorporation() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell align="center">Filing Date</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Size MB</TableCell>
            <TableCell align="center">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              CERTIFICATE OF INCORPORATION20170118_CERTIFICATEOFINCORPORATI ON20170118.pdf
            </TableCell>
            <TableCell align="center">18-01-2017 </TableCell>
            <TableCell align="center">Certificate of Incorporation</TableCell>
            <TableCell align="center">0.50</TableCell>
            <TableCell align="center">
              <a target={"_blank"} href={"https://firebasestorage.googleapis.com/v0/b/compliancebook.appspot.com/o/ROC%2FROCROC22.pdf?alt=media&token=5a993899-a66c-4ffd-b204-96767d64006e"} download="ROC22">
                Download
              </a>
            </TableCell>
          </TableRow>

          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
