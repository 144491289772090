import Constants from '../../utils/constants';

const intialState = {
  vault_data: null,
  isUpdated: false
};

export const vaultReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_LOGIN_VAULT_DATA:
      return { ...state, vault_data: action.payload };
    case Constants.SET_IS_UPDATED:
      return { ...state, isUpdated: action.payload };
    default:
      return state;
  }
};
