import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function BasicInfo() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  const [otherData, setOtherData] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .CompanyMasterSummary
      );
      setOtherData(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .CompanyKeyHighlights.CompanyNonFinKeyHighlights
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => ( */}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Age (Incorp. Date)
            </TableCell>

            <TableCell align="center">
              {data.CompanyDateOfInc != null ? data.CompanyDateOfInc : 'NA'}
            </TableCell>
            <TableCell align="center">BalanceSheet Date</TableCell>
            <TableCell align="center">
              {data.CompanyLastBsDate != null ? data.CompanyLastBsDate : 'NA'}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Company Status
            </TableCell>

            <TableCell align="center">
              {data.CompanyMcaStatus != null ? data.CompanyMcaStatus : 'NA'}
            </TableCell>
            <TableCell align="center">Paid up Capital</TableCell>
            <TableCell align="center">
              {data.CompanyPaidUpCapital != null ? data.CompanyPaidUpCapital : 'NA'}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Company Type
            </TableCell>
            <TableCell align="center">
              {data.CompanyCategory != null ? data.CompanyCategory : 'NA'}
            </TableCell>
            <TableCell align="center">Profit After Tax </TableCell>
            <TableCell align="center">NA</TableCell>
            {/* <TableCell align="center">₹10.54 Lakhs</TableCell> */}
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Company SubCategory
            </TableCell>
            <TableCell align="center">
              {data.CompanySubCategory != null ? data.CompanySubCategory : 'NA'}
            </TableCell>
            <TableCell align="center">Open Charges </TableCell>
            <TableCell align="center">
              {otherData.OpenChargeTotalInINR != null ? otherData.OpenChargeTotalInINR : 'NA'}
            </TableCell>
            {/* <TableCell align="center">₹10.54 Lakhs</TableCell> */}
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Email ID
            </TableCell>
            <TableCell align="center">
              {data.CompanyEmail != null ? data.CompanyEmail : 'NA'}
            </TableCell>
            <TableCell align="center">Directors </TableCell>
            <TableCell align="center">
              Current :
              {otherData.DirectorCurrentCount != null ? otherData.DirectorCurrentCount : 'NA'}
              Directors ( Past :
              {otherData.DirectorPastCount != null ? otherData.DirectorPastCount : 'NA'} Directors )
            </TableCell>
            {/* <TableCell align="center">₹10.54 Lakhs</TableCell> */}
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Company Website
            </TableCell>
            <TableCell align="center">
              {data.CompanyWebSite != null ? data.CompanyWebSite : 'NA'}
            </TableCell>
            <TableCell align="center">Number of Signatories </TableCell>
            <TableCell align="center">
              {otherData.SignatoriesCurrentCount == null ? 'NA' : otherData.SignatoriesCurrentCount}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Industry
            </TableCell>
            <TableCell align="center">
              {data.CompanyMcaIndustry != null ? data.CompanyMcaIndustry : 'NA'}
            </TableCell>
            <TableCell align="center">Company Address </TableCell>
            <TableCell align="center">
              {data.CompanyFullAddress != null ? data.CompanyFullAddress : 'NA'}
            </TableCell>
          </TableRow>

          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
