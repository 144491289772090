import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchDirectors } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function DirectorsDetail() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      let tmp = [];
      tmp = tmp.concat(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .DirectorSignatoryMasterSummary.DirectorCurrentMasterSummary.Director
      );
      setdata(tmp);
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Company Directors</TableCell>
            <TableCell align="center">Director DIN</TableCell>
            <TableCell align="center">Designation</TableCell>
            <TableCell align="center">Appointment Date</TableCell>
            <TableCell align="center">Shares Holding</TableCell>
            <TableCell align="center">Disqualified u/s 164(2)</TableCell>
            <TableCell align="center">DIN Deactivated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.DirectorName}
                </TableCell>
                <TableCell align="center">{row.DirectorDin}</TableCell>
                <TableCell align="center">{row.DirectorDesignation}</TableCell>
                <TableCell align="center">{row.DirectorDateOfAppnt}</TableCell>
                <TableCell align="center">{row.DirectorCapitalization}</TableCell>
                <TableCell align="center">
                  {row.DisqualifiedUs1642 != null && row.DisqualifiedUs1642 == true ? 'Yes' : 'NO'}
                </TableCell>
                <TableCell align="center">
                  {row.DinDeactivated && row.DinDeactivated == true ? 'YES' : 'NO'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
