import React from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Button, Grid } from '@mui/material';

import { useNavigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import { BasicProfileForm } from 'src/sections/authentication/register/BasicProfileForm';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loginbtn from 'src/sections/authentication/Loginbtn';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';
import { appName } from 'src/config';
import logoGIF from 'src/gif/livesoon.gif';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: '1px 0px 15px 4px rgb(43 48 53 / 24%)'

}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 580,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',

}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 800 : 500]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.mode === 'light' ? '#00AB55' : '#808080'
  }
}));

export const LiveSoon = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container backgroundColor='white'>
        <Grid
          item
          xs={7}
          md={7}
          lg={7}
          sx={{
            mb: 6,
            mt: 2
          }}
        >
          <img src={logoGIF} />
          <Box sx={{ ml: 10, mt: 2 }}>
            <Typography variant="p" style={{ fontSize: '18px',fontWeight:500 }}>
              Your company profile is currently undergoing verification and<br></br> will be
              <span style={{ color: 'green' }}> Live</span> within 48 hours.
            </Typography>
            <Box alignItems="center" justifyContent="center" width={'80%'} sx={{ mt: 2 }}>
              <BorderLinearProgress variant="determinate" value={100} />
              <Typography variant="p" sx={{ mt: 2 }}>
                All steps are completed !
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5} lg={5} md={5}>
          <RootStyle title={'Register | ' + appName}>
            <Container>
                <ContentStyle>
                  <LogoOnlyLayout />
                  <Typography
                    variant="h2"
                    sx={{ px: 5, mt: 2, mb: 5 }}
                    style={{ textAlign: 'center', fontSize: '24px' }}
                  >
                    Welcome to {appName}.
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{ px: 5, mb: 5 }}
                    style={{ textAlign: 'center', paddingTop: '10px' }}
                  >
                    LIVE<span style={{ color: 'green' }}> SOON</span>
                  </Typography>
                  <Typography variant="h5" sx={{ px: 5, mt: 2 }} style={{ textAlign: 'center',fontSize: '18px'}}>
                  Congratulations,your sign up process is completed.              
                    </Typography>
                  <br></br>
                  <Typography variant="p" style={{ textAlign: 'center', fontSize: '16px' }}>
                  Your company profile is undergoing verification, please take a moment to View or Update your credentials at Login Vault.
                  </Typography>
                  {/* <Typography sx={{ px: 5, mt: 2, mb: 5 }}> */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/loginValut')}
                      style={{ textAlign: 'center', borderRadius: '20px', width: '160px' }}
                    >
                      Login Vault
                    </Button>
                  </Box>
                </ContentStyle>
            </Container>
          </RootStyle>
        </Grid>
      </Grid>
    </>
  );
};
