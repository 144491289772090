import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Paper } from '@mui/material';
import Label from 'src/components/Label';
import { dateFormat } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { getUpcomingDueDates } from 'src/redux/actions/dueDates';
import { getStatus } from './AllDueDates';
import { useSearchParams } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
const moment = require('moment');

export const UpcomingDueDates = () => {
  const [dueDates, setdueDates] = useState([]);
  const dueDatesState = useSelector((state) => state.dueDatesReducer);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const [open, setOpen] = useState(false);
  const [selectedID, setID] = useState('');
  const [reason, setReason] = useState('');
  const [edit, setEdit] = useState(false);
  const [dof, setDof] = useState(new Date().toDateString());

  useEffect(() => {
    dispatch(getUpcomingDueDates(companyId));
  }, [companyId]);

  useEffect(() => {
    setdueDates(
      dueDatesState?.upcomingDueDates?.sort(function (a, b) {
        return new Date(a.current_due_date) - new Date(b.current_due_date);
      })
    );
  }, [dueDatesState.upcomingDueDates]);

  const submitReason = (e) => {
    e.preventDefault();
    if (reason.trim().length === 0) {
      toast.error('Please enter valid reason');
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('due_date_applicable', false);
      bodyFormData.append('reason_for_non_applicable', reason);
      dispatch(updateDueDate(selectedID, bodyFormData, 3));
      setOpen(false);
      setReason('');
    }
  };

  const handleClickOpen = (id) => {
    setID(id);
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    setReason('');
  };

  const handleChange = (event) => {
    event.preventDefault();
    setReason(event.target.value);
  };

  const toggleEdit = (id) => {
    setEdit(!edit);
    setID(id);
  };

  const handleDateUpdate = () => {
    const date_of_filling = moment(dof).format('YYYY-MM-DD');
    const bodyFormData = new FormData();
    bodyFormData.append('date_of_filling', date_of_filling);
    dispatch(updateDueDate(selectedID, bodyFormData, 3));
    setEdit(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& td': {
            fontSize: '0.7rem'
          }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Law</TableCell>
            <TableCell align="center">Frequency</TableCell>
            <TableCell align="center">Period</TableCell>
            <TableCell align="center">Comliance Name</TableCell>
            <TableCell align="center">Due Date</TableCell>
            <TableCell align="center">Filling Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dueDates?.map((row) => {
            if (getStatus(row.current_due_date, row.current_due_date, row.date_of_filling) == 2) {
              return (
                <TableRow hover>
                  <TableCell align="center">{row.book_name}</TableCell>
                  <TableCell align="center">{row.frequency}</TableCell>
                  <TableCell align="center">{row.period}</TableCell>
                  <TableCell align="center">{row.compliance_name}</TableCell>
                  <TableCell align="center">{dateFormat(row.current_due_date)}</TableCell>
                  <TableCell align="center">
                    {row.date_of_filling === null && row.id === selectedID && edit === true && (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // openTo="year"
                          // views={['year', 'month', 'day']}
                          label="Date of Filling"
                          name="date_of_filling"
                          value={dof}
                          renderInput={(params) => <TextField {...params} helperText={null} />}
                          onChange={(newValue) => {
                            setDof(newValue);
                          }}
                          inputFormat="dd/MM/yyyy"
                        />
                      </LocalizationProvider>
                    )}
                    {row.date_of_filling !== null && dateFormat(row.date_of_filling)}
                  </TableCell>
                  <TableCell align="center">
                    <Label variant="ghost" color="secondary">
                      Upcoming
                    </Label>
                  </TableCell>
                  <TableCell align="center">
                    {row.book_name.includes('book') == true && edit === false && (
                      <Tooltip title="Edit">
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={(e) => toggleEdit(row.id)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      </Tooltip>
                    )}
                    {row.date_of_filling == null && edit === false && (
                      <Tooltip title="Not applicable">
                      <IconButton
                        aria-label="not-app"
                        color="error"
                        onClick={(e) => {
                          handleClickOpen(row.id);
                        }}
                      >
                        <RemoveCircleOutlineOutlinedIcon />
                      </IconButton>
                      </Tooltip>
                    )}
                    {edit === true && (
                      <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="success" onClick={handleDateUpdate}>
                          Update
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            setEdit(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reason for Not Applicable</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please give reason for not consider this compliance
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="Reason"
              multiline
              maxRows={4}
              type="text"
              fullWidth
              variant="standard"
              value={reason}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitReason}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Table>
    </TableContainer>
  );
};
