import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

export const getNotificationsData = (companyId) => (dispatch) => {
  trackPromise(
    HttpWrapper('GET', `/company/notifications/${companyId}/`, false, {})
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: Constants.SET_NOTIFICATION_DATA,
          payload: res.data
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.SET_NOTIFICATION_DATA,
          payload: []
        });
      })
  );
};

export const updateSingleRole = (id, data) => (dispatch) => {
  trackPromise(
    HttpWrapper('PATCH', `/company/notifications/notification/${id}/`, true, data)
      .then((res, err) => {
        console.log(res);
        toast.success('Updated Challan Data');
      })
      .catch((err) => {
        console.log(err);
      })
  );
};

export const getCompanyData = (companyId) => (dispatch) => {
  trackPromise(
    HttpWrapper('GET', `/company/status/`, false, {})
      .then((res) => {
        const data = res.data.filter((company) => company.id === companyId)[0];
        dispatch({
          type: Constants.SET_COMPANY_DATA,
          payload: data
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.SET_COMPANY_DATA,
          payload: []
        });
      })
  );
};
