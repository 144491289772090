import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchRatioAnalysisInfo } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function RatioAnalysis() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .RatioAnalysis
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  const fields = {
    // CurrentRatio: 'Growth Ratio',
    OperatingRevenueGrowth: ' Operative Revenue growth (%)',
    EBDITAGrowth: 'EBITDA growth (%)',
    EPSGrowth: 'EPS growth (%)',
    UnsecuredBorrowings: ' Profitability Ratios',
    EBDITAMargin: ' EBITDA margin (%)',
    PATMargin: 'PAT margin (%)',
    ReturnOnCapitalEmployed: '  Return on capital employed (RoCE) (%)',
    ReturnOnEquity: 'Return on equity (RoE) (%)',
    // WorkingCapitalTurnover: 'Working Capital Ratios',
    // UnsecuredBorrowings: 'Avg. Debtors Outstanding days',
    // TotalBorrowings: 'Liquidity and Solvency Ratios',
    InterestCoverage: 'Interest coverage',
    CurrentRatio: 'Current ratio',
    LeverageTOLTNW: ' Leverage (TOL/TNW)',
    AssetTurnoverRatio: 'Asset Turnover',
    WorkingCapitalTurnover: '  Working Capital Turnover',
    // TotalBorrowings: 'Cash Flow Indicator Ratios',
    OperatingCashMargin: 'Operating Cash Margin (OCF/Sales) (%)',
    FreeCashFlowPercentage: ' Free Cash Flow/OCF (%)',
    CashGeneratingPower: 'Cash Generating Power',
    ShortTermDebtCoverageRatio: 'Short-Term Debt Coverage Ratio',
    CapitalExpenditureCoverageRatio: '  Capital Expenditure Coverage Ratio'
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(fields).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index) => {
                return yearToDataMap[year][key] != null ? (
                  <TableCell key={index}>{yearToDataMap[year][key]}</TableCell>
                ) : (
                  <TableCell key={index}>NA</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
