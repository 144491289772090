import React from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Grid, CircularProgress } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// components
import Page from '../components/Page';
import { BasicProfileForm } from 'src/sections/authentication/register/BasicProfileForm';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { appName } from 'src/config';
import logoGIF from 'src/gif/basicprof.gif';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 20,
  padding: 5,
  paddingRight: 15,
  paddingLeft: 15,
  paddingTop: 30,
  paddingBottom: 30,
  width:470,
  boxShadow: '1px 0px 15px 4px rgb(43 48 53 / 24%)',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',

}));
const customStyles = {
  root: {
    color: 'red' // Change this to the color you want for the rest of the progress bar
  }
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 800 : 500]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#00AB55' : '#808080'
  }
}));

export const BasicProfileSetUp = () => {
  return (
    <Grid container  sx={{backgroundColor:"white",display:'flex',flexDirection:'row',justifyContent:'center',minHeight:'100vh'}}>
      <Grid
        item
        xs={7}
        md={7}
        lg={7}
        sx={{backgroundColor:"white",display:'flex',flexDirection:'column',justifyContent:'center'}}
      >
        <img src={logoGIF} style={{width:'100%'}}/>
        <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
          <Box alignItems="center" justifyContent="center" width={'80%'} sx={{ mt: 2 }}>
          <Typography variant="h5" marginLeft='20'>
            {''}
            {''}Simple 5 Steps Onboarding
          </Typography>
            <BorderLinearProgress variant="determinate" value={20} />
            <Typography variant="p" sx={{ mt: 2 }}>
              Step 1 of 5
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* <Grid item xs={7} md={7} lg={7}>
            <Container>
            </Container>
        </Grid> */}
      <Grid item xs={5} md={5} lg={5}
              sx={{backgroundColor:"white",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
      >
        <RootStyle title={'Register | ' + appName}>
          <ContentStyle>
            <SectionStyle>
              <LogoOnlyLayout />
              <Typography
                variant="h5"
                style={{
                  fontFamily: 'Public Sans',
                  fontWeight: 600,
                  textAlign: 'center',
                  fontSize: '20px'
                }}
                sx={{ mt: 2, mb: 5 }}
              >
                Setup Your Organization Profile
              </Typography>
              <BasicProfileForm />
            </SectionStyle>
          </ContentStyle>
        </RootStyle>
      </Grid>
    </Grid>
  );
};
