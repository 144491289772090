
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import { useSelector } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, IconButton } from '@mui/material';
import moment from 'moment';
import { delete_subscription } from 'src/redux/actions/invoice_subscription_action';
export default function Subscription_delete(props) {
  const { handleClickClose, row } = props;
  const dispatch = useDispatch();
  const edit_subscr_Data = useSelector((state)=>state.invoice_reducers.invoice_subscription);
  const all_companies = useSelector((state) => state.allCompaniesreducers);

  const filter_by_sub_id = edit_subscr_Data?.filter((item) => item.id === row)[0];
  const subs = filter_by_sub_id;
  console.log("f",subs)

  //opts
  const status_opts = ['Active', 'Inactive','Hold'];


  // useStates
  const [comp_name, setcomp_name] = useState(null);
  const [onboard_date, setonboard_date] = useState(null);
  const [start_date, setstart_date] = useState(null);
  const [stat, setstat] = useState(null);
  const [amt, setamt] = useState(null);
  const [list_company_id, setlist_company_id] = useState();
  const [password, setpassword] = useState('password');
  const [user_acess, setUser_Acess] = useState();

  const [matched_company_id, setmatched_company_id] = useState();


  const [formData, setFormData] = useState({
    company_name: '',
    date_of_onboarding: '',
    start_from: '',
    amount: '',
    status: ''
  });
  let name, valuee;
  const opts = all_companies?.all_companies?.map((option) => option.company_name);
  const handleFormDataChange = (e) => {
    console.log("formData['company_name']", formData);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(formData, 'formNOdata');
  };
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  useEffect(() => {
    setlist_company_id(all_companies.all_companies);
  }, [all_companies]);

  const handleDelete = () =>{
    dispatch(delete_subscription(row))
    handleClickClose();
  }
  console.log('formsforms', formData);

  useEffect(() => {
    setcomp_name(subs?.company_name);
    setonboard_date(subs?.date_of_onboarding)
    setstart_date(subs?.start_from)
    setstat(subs?.status)
    setamt(subs?.amount)

  }, [filter_by_sub_id]);
  // console.log('outside', matched_company_id);
  return (
    <>
      <Box sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }} display="flex">
        <Typography variant="overline" display="block" gutterBottom color={user_acess===password?"red":"black"}>
            Alert : Following data will be deleted
        </Typography>
          <Typography align="left" color="black">
            Company Name : {comp_name}
          </Typography>
        <Typography align="left" color="black">
           Amount : {amt}
        </Typography>
        <Box justifyContent="center" display="flex" sx={{margin:2}}>
          <TextField
            value={user_acess}
            type="password"
            onChange={(e) => {
              setUser_Acess(e.target.value);
            }}
            label="Enter Password To Delete Subscription"
            variant="outlined"
            sx={{ margin: 1, width: 300 }}
          />
        <Button variant="contained" color="error" sx={{ m: 2 }}
        disabled={user_acess===password?false:true}
        onClick={handleDelete}
        >
        Delete Subscription
      </Button>
        </Box>
      </Box>
    </>
  );
}
