import React from 'react';
import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'src/utils/common';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { masterdoc_post, trigger_update_get } from 'src/redux/actions/masterdocAction';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import { appName } from 'src/config';
import logoGIF from 'src/gif/documents.gif';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import axios from 'axios';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 800 : 500]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.mode === 'light' ? '#00AB55' : '#808080'
  }
}));
export default function CompanyUpload() {
  const companyId = useSelector((state) => state.orgProfileReducer.companyId) || getCompanyId();
  const authState = useSelector((state) => state.authReducer);
  console.log('documents mai,', authState.accessToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let name, valuee;
  //docdata

  const [filedetails, setFiledetails] = useState();

  const [docfiled, setDocfiled] = useState([
    { filename: 'Certificate of Incorporation', filevalue: '', uploadfilename: '' },
    { filename: 'MOA', filevalue: '', uploadfilename: '' },
    { filename: 'AOA', filevalue: '', uploadfilename: '' },
    { filename: 'PAN Card of Company ', filevalue: '' },
    { filename: 'TAN Allotment Letter ', filevalue: '' },
    { filename: 'GST Certificate ', filevalue: '' },
    { filename: 'Startup India Certificate ', filevalue: '' },
    { filename: 'MSME Certificate ', filevalue: '' },
    { filename: 'EPF Certificate ', filevalue: '' },
    { filename: 'ESIC Certificate ', filevalue: '' },
    { filename: 'Professional Tax Certificate ', filevalue: '' },
    { filename: 'Trade Mark Certificate ', filevalue: '' },
    { filename: 'Previous Accounting Backup ', filevalue: '' },
    { filename: 'Bank Statement', filevalue: '' },
    { filename: 'TDS Challan ', filevalue: '' },
    { filename: 'TDS Return Acknowledgement ', filevalue: '' },
    { filename: 'TDS Return Working ', filevalue: '' },
    { filename: 'GST Working ', filevalue: '' },
    { filename: 'ROC Form and Challan ', filevalue: '' },
    { filename: 'Customer Aggrement ', filevalue: '' },
    { filename: 'Vendor Agreement ', filevalue: '' },
    { filename: 'Employee Agreement ', filevalue: '' }
  ]);
  const [docfiled2, setDocfiled2] = useState([
    { filename: 'Certificate of Incorporation', filevalue: '', uploadfilename: '' },
    { filename: 'MOA', filevalue: '', uploadfilename: '' },
    { filename: 'AOA', filevalue: '', uploadfilename: '' },
    { filename: 'PAN Card of Company ', filevalue: '' },
    { filename: 'GST Certificate ', filevalue: '' },
    { filename: 'EPF Certificate ', filevalue: '' },
    { filename: 'ESIC Certificate ', filevalue: '' },
    { filename: 'Professional Tax Certificate ', filevalue: '' },
    { filename: 'Shop and Establishment Certificate ', filevalue: '' },
    { filename: 'LWF Certificate or Receipt ', filevalue: '' },
    { filename: 'Employee Offer Letter ', filevalue: '' },
    { filename: 'Employee Form-11 ', filevalue: '' },
    { filename: 'Employee Form-2 ', filevalue: '' },
    { filename: 'Employee Form- F ', filevalue: '' },
    { filename: 'PF Returns and Challans ', filevalue: '' },
    { filename: 'ESIC Returns and Challans ', filevalue: '' },
    { filename: 'Labour Law Registers ', filevalue: '' },
    { filename: 'POSH Return and Policy  ', filevalue: '' },
    { filename: 'PT Returns and Challans ', filevalue: '' },
    { filename: 'LWF Returns and Challans  ', filevalue: '' },
    { filename: 'Shop and Establishment Returns ', filevalue: '' }
  ]);

  const handleSelectFile = (e, index) => {
    // Upload data

    const list = [...docfiled];
    list[index].filevalue = e.target.files[0];
    setDocfiled(list);
  };
  const handleSelectFile_People = (e, index) => {
    // Upload data
    const list = [...docfiled2];
    list[index].filevalue = e.target.files[0];
    setDocfiled2(list);
  };

  const handleDelete = (e, index) => {
    e.preventDefault();
    const list = [...docfiled];
    list[index].filevalue = '';
    setDocfiled(list);
  };
  const handleDelete_People = (e, index) => {
    e.preventDefault();
    const list = [...docfiled2];
    list[index].filevalue = '';
    setDocfiled2(list);
  };

  const handlestart = () => {
    if (appName === 'StartupFino') {
      for (let i = 0; i < docfiled.length; i++) {
        if (docfiled[i].filevalue !== '') {
          console.log('docfiled', docfiled);
          var data = new FormData();
          data.append('companyId', companyId);
          data.append('document', docfiled[i].filevalue);
          data.append('document_name', docfiled[i].filename);
          dispatch(masterdoc_post(data));
        }
      }
      navigate(`/livesoon?companyid=${getCompanyId()}`, { replace: true });
    }
    if (appName === 'PeopleFino') {
      for (let i = 0; i < docfiled2.length; i++) {
        if (docfiled2[i].filevalue !== '') {
          console.log('docfiled', docfiled2);
          var data = new FormData();
          data.append('companyId', companyId);
          data.append('document', docfiled2[i].filevalue);
          data.append('document_name', docfiled2[i].filename);
          dispatch(masterdoc_post(data));
        }
      }
      navigate(`/livesoon?companyid=${getCompanyId()}`, { replace: true });
    }
    // dispatch(trigger_update_get(getCompanyId(), authState.accessToken));
    let headersList = {
      "Accept": "*/*",
      "Authorization": `Bearer ${authState.accessToken}` 
     }
     
     let reqOptions = {
       url: `https://api.startupfino.in/compliance/send-onboarding-email/${getCompanyId()}/`,
       method: "GET",
       headers: headersList,
     }
     
     let response = axios.request(reqOptions);
     console.log('success',response.data);
     

  };
  const handleAddfiled = () => {
    setDocfiled([...docfiled, { filename: '', filevalue: '' }]);
    setflag(false);
  };
  const handleAddfiled_People = () => {
    setDocfiled2([...docfiled2, { filename: '', filevalue: '' }]);
    setflag(false);
  };
  const handleremovefiled_People = (index) => {
    const values = [...docfiled2];
    values.splice(index, 1);
    setDocfiled2(values);
  };
  const handleremovefiled = (index) => {
    const values = [...docfiled];
    values.splice(index, 1);
    setDocfiled(values);
  };
  const handleChangeInput = (index, event) => {
    const vals = [...docfiled];
    vals[index][event.target.name] = event.target.value;
    console.log(index, event.target.name);
    console.log('vals', vals);
    setDocfiled(vals);
  };
  const handleChangeInput_People = (index, event) => {
    const vals = [...docfiled2];
    vals[index][event.target.name] = event.target.value;
    console.log(index, event.target.name);
    console.log('vals', vals);
    setDocfiled2(vals);
  };
  const [flag, setflag] = useState(true);
  const [flag2, setflag2] = useState(false);
  const data = [
    { name: 'Alice', age: 25 },
    { name: 'Bob', age: 30 },
    { name: 'Carol', age: 35 }
  ];
  return (
    <Grid
      sx={{
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        justifyItems: 'center',
        minHeight: '100vh'
      }}
      container
      spacing={2}
    >
      <Grid
        item
        xs={6}
        md={6}
        lg={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center'
        }}
      >
        <img src={logoGIF} />
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box alignItems="center" justifyContent="center" width={'80%'} sx={{ mt: 2 }}>
            <Typography variant="h5" marginLeft="20">
              {''}
              {''}Simple 5 Steps Onboarding
            </Typography>
            <BorderLinearProgress variant="determinate" value={90} />
            <Typography variant="p" sx={{ mt: 2 }}>
              Step 5 of 5, Great you are almost there !
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        lg={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6" align="center" sx={{ m: 2 }}>
          Upload Company's Documents
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid key={1} item>
            <div
              id="mybar"
              style={{
                maxHeight: 450,
                boxShadow: '1px 0px 15px 4px rgb(43 48 53 / 24%)'
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 450,
                  '&::-webkit-scrollbar': {
                    width: '12px'
                    // You can adjust the width
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.3)', // You can set the color you prefer
                    borderRadius: '6px' // You can adjust the border radius
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: 'rgba(0, 0, 0, 0.6)' // Hover color
                  }
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 480,
                    '& th': {
                      fontSize: '1rem',
                      background: 'hsl(149,100%,33%)',
                      color: 'white'
                    },
                    '& td': {
                      fontSize: '0.7rem'
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Company Documents</TableCell>
                      <TableCell> Upload</TableCell>
                      <TableCell>Filename</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appName === 'StartupFino' && (
                      <>
                        {docfiled.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {index > 21 && flag == false && (
                                <TableCell>
                                  <TextField
                                    id="outlined-basic"
                                    name="filename"
                                    label="Other Documents"
                                    variant="outlined"
                                    value={row.filename}
                                    onChange={(event) => handleChangeInput(index, event)}
                                  />
                                </TableCell>
                              )}
                              {row.filename != '' && index < 22 && (
                                <TableCell>
                                  <h3>{row.filename}</h3>
                                </TableCell>
                              )}
                              <TableCell>
                                <Button
                                  variant="contained"
                                  component="label"
                                  //sx={{ m: 2 }}
                                  size="small"
                                  disabled={row.filevalue === '' ? false : true}
                                >
                                  {console.log('new row', row.filevalue)}
                                  Upload
                                  <input
                                    name={row.filevalue}
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      handleSelectFile(e, index);
                                    }}
                                  />
                                </Button>
                              </TableCell>
                              {
                                <TableCell>
                                  <h4>
                                    {row.filevalue.name}{' '}
                                    <span>
                                      {row.filevalue === '' ? (
                                        ''
                                      ) : (
                                        <IconButton
                                          aria-label="delete"
                                          color="error"
                                          onClick={(e) => handleDelete(e, index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </span>
                                  </h4>
                                </TableCell>
                              }
                              {index == docfiled.length - 1 && (
                                <TableRow>
                                  <TableCell>
                                    <Button type="button" onClick={handleAddfiled}>
                                      Add Other Documents
                                    </Button>
                                    {flag == false && index > 21 && (
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleremovefiled(index);
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableRow>
                          );
                        })}
                      </>
                    )}

                    {appName === 'PeopleFino' && (
                      <>
                        {docfiled2.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {index > 20 && flag == false && (
                                <TableCell>
                                  <TextField
                                    id="outlined-basic"
                                    name="filename"
                                    label="Other Documents"
                                    variant="outlined"
                                    value={row.filename}
                                    onChange={(event) => handleChangeInput_People(index, event)}
                                  />
                                </TableCell>
                              )}
                              {row.filename != '' && index < 21 && (
                                <TableCell>
                                  <h3>{row.filename}</h3>
                                </TableCell>
                              )}
                              <TableCell>
                                <Button
                                  variant="contained"
                                  component="label"
                                  //sx={{ m: 2 }}
                                  size="small"
                                  disabled={row.filevalue === '' ? false : true}
                                >
                                  {console.log('new row', row.filevalue)}
                                  Upload
                                  <input
                                    name={row.filevalue}
                                    multiple
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                      handleSelectFile_People(e, index);
                                    }}
                                  />
                                </Button>
                              </TableCell>
                              {
                                <TableCell>
                                  <h4>
                                    {row.filevalue.name}{' '}
                                    <span>
                                      {row.filevalue === '' ? (
                                        ''
                                      ) : (
                                        <IconButton
                                          aria-label="delete"
                                          color="error"
                                          onClick={(e) => handleDelete_People(e, index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </span>
                                  </h4>
                                </TableCell>
                              }
                              {index == docfiled2.length - 1 && (
                                <TableRow>
                                  <TableCell>
                                    <Button type="button" onClick={handleAddfiled_People}>
                                      Add Other Documents
                                    </Button>
                                    {flag == false && index > 20 && (
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleremovefiled_People(index);
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                              {/* {
                          console.log("lenght", docfiled.length, "index", index, "flag", flag)
                        } */}
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Grid container justifyContent="center">
              <Button variant="contained" component="label" onClick={handlestart} sx={{ mt: 3 }}>
                Get Started
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
