import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { intiateLogin, getStatus } from 'src/redux/actions/login';
import { useNavigate } from 'react-router-dom';
import { google_clientId } from 'src/config';
import { getCompanyId, getCompanyData } from 'src/utils/common';
import { isStartupFino, istalenteye } from 'src/config';
import { Button, IconButton, Box, Grid } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import SendIcon from '@mui/icons-material/Send';

import Iconify from 'src/components/Iconify';
// const clientId = '106440327417-orgng26dvoqt8mbmp45jnn2rk8qo2jj9.apps.googleusercontent.com';

function Loginbtn() {
  const authState = useSelector((state) => state.authReducer);
  const companyState = useSelector((state) => state.orgProfileReducer);
  const navigate = useNavigate();
  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const dispatch = useDispatch();
  const onLoginSuccess = (res) => {
    console.log(res);
    const credDetails = {
      access_token: res.accessToken,
      id_token: res.tokenId
    };
    dispatch(intiateLogin(credDetails));
    setShowloginButton(false);
    setShowlogoutButton(true);
  };

  const onLoginFailure = (res) => {
    console.log('Login Failed:', res);
  };

  const onSignoutSuccess = () => {
    alert('You have been logged out successfully');
    console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
  };
  useEffect(() => {
    if (!authState.isLoading) {
      dispatch(getStatus());
    }
  }, [authState.user, authState.isLoading]);

  useEffect(() => {
    let selectedCompany;
    let companyData = getCompanyData() || companyState?.companyData;
    if (companyData.length > 0) {
      selectedCompany = companyData?.filter((item) => item.id === companyState.companyId)[0];
    }

    if (companyState.isNew === true && authState.isLoading === false) {
      navigate('/basicProfile', { replace: true });
    } else if ((isStartupFino === true || istalenteye === true) && authState.isLoading == false) {
      if (selectedCompany?.is_company_active === false) {
        navigate(`/livesoon?companyid=${getCompanyId()}`, { replace: true });
      } else {
        navigate(`/dashboard/main?companyid=${getCompanyId()}`, { replace: true });
      }
    } else if (companyState.isNew === false && authState.isLoading == false) {
      navigate(`/dashboard/main?companyid=${getCompanyId()}`, { replace: true });
    }
  }, [companyState.isNew, companyState.companyData]);

  return (
    <div>
      {showloginButton ? (
        <>
          <GoogleLogin
            clientId={google_clientId}
            render={(renderProps) => (
              <>
                <Grid container display="flex" flex-flexDirection={'row'} width="300px">
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      variant="outlined"
                      sx={{ color: 'black', boxShadow: 3, fontSize: '14px', fontWeight: '100' }}
                      size="large"
                      startIcon={<Iconify icon="flat-color-icons:google" />}
                    >
                      Log In
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      variant="outlined"
                      sx={{ color: 'black', boxShadow: 3, fontSize: '14px', fontWeight: '100' }}
                      size="large"
                      startIcon={<Iconify icon="flat-color-icons:google" />}
                    >
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        </>
      ) : null}

      {showlogoutButton ? (
        <GoogleLogout
          clientId={google_clientId}
          buttonText="Sign Out"
          onLogoutSuccess={onSignoutSuccess}
        ></GoogleLogout>
      ) : null}
    </div>
  );
}
export default Loginbtn;
