import Constants from '../../utils/constants';
import HttpWrapper from 'src/utils/HttpWrapper';
import { toast } from 'react-toastify';

//for challlan
export const intialTdsChallanFetching = (companyId) => (dispatch) => {
  dispatch({
    type: Constants.SET_COMPANY_TDS_CHALLAN_START
  });
  HttpWrapper('GET', `/tds/challan/all/?companyId=${companyId}`, false, {})
    .then((res) => {
      console.log(res);
      dispatch({
        type: Constants.SET_COMPANY_TDS_CHALLAN_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: Constants.SET_COMPANY_TDS_CHALLAN_FAILURE,
        payload: error
      });
    });
};

export const intialTdsRecieptFetching = (companyId) => (dispatch) => {
  dispatch({
    type: Constants.SET_COMPANY_TDS_RECEIPT_START
  });
  HttpWrapper('GET', `/tds/receipt/all/?companyId=${companyId}`, false, {})
    .then((res) => {
      console.log(res);
      dispatch({
        type: Constants.SET_COMPANY_TDS_RECEIPT_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: Constants.SET_COMPANY_TDS_RECEIPT_FAILURE,
        payload: error
      });
    });
};


export const updateSingleChallan = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/tds/challan/getChallan/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
      dispatch(intialTdsChallanFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deletetdschallan = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/tds/challan/getChallan/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      console.log('india')
      toast.success('Delete Successfully');
      dispatch(intialTdsChallanFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateSingleReciept = (id, data,companyId) => (dispatch) => {
  console.log(data);
  HttpWrapper('PATCH', `/tds/receipt/getReceipt/${id}/`, true, data)
    .then((res, err) => {
      console.log(res);
      toast.success('Updated Challan Data');
      dispatch(intialTdsRecieptFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deletetdsreturn = (data,companyId) => (dispatch) => {
  HttpWrapper('DELETE', `/tds/receipt/getReceipt/${data}`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Delete Successfully');
      dispatch(intialTdsRecieptFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateSingleDemand = (id,data,companyId) => (dispatch) => {
  HttpWrapper('PATCH', `/tds/demand/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Successfully');
      dispatch(fetchTdsDemands(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deletetdsdemand = (data,companyId) => (dispatch) => {
  //console.log(da);
  HttpWrapper('DELETE', `/tds/demand/${data}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Deleted Successfully');
      dispatch(fetchTdsDemands(companyId))
    })
    .catch((err) => {
     
      console.log(err);
    });
};


// export const updateSingleDemand = (id, data) => (dispatch) => {
//   console.log(data);
//   HttpWrapper('PATCH', `/tds/demand/bb3df4f3-1b6f-462d-9535-0178dcf21af7/`, true, data)
//     .then((res, err) => {
//       console.log('res', res.data);
//       toast.success('Updated Challan Data');
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
export const deletedemand = () => (dispatch) => {
  console.log(data);
  HttpWrapper('DELETE', `/incometaxbook/demand/${id}/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Updated Challan Data');
    })
    .catch((err) => {
      console.log(err);
    });
};


export const createChallan = (data) => (dispatch) => {
  console.log(data);
  HttpWrapper('POST', `/tds/challan/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Zero TDS Data Updated');
      dispatch(intialTdsChallanFetching(res.data['companyId']));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchDueDates = () => (dispatch) => {
  dispatch({
    type: Constants.SET_DUE_DATES_START
  });
  HttpWrapper('GET', `/compliance/due-date-book/TDS/`, false, {})
    .then((res) => {
      const data = res.data;
      dispatch({
        type: Constants.SET_DUE_DATES_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: Constants.SET_COMPANY_TDS_CHALLAN_FAILURE,
        payload: error
      });
    });
};

export const fetchTdsDemands = (companyId) => (dispatch) => {
  dispatch({
    type: Constants.SET_TDS_DEMAND_START
  });
  HttpWrapper('GET', `/tds/demand/`, false, {})
    .then((res) => {
      console.log("fectch",res)
      console.log("HI")
      const data = res.data.filter((res) => res.companyId === companyId);
      dispatch({
        type: Constants.SET_TDS_DEMAND_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: Constants.SET_TDS_DEMAND_FAILURE,
        payload: error
      });
    });
};
export const addtdschallan = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/tds/challan/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(intialTdsChallanFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addtdsreturn = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/tds/receipt/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(intialTdsRecieptFetching(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};
export const adddemandnotice = (data,companyId) => (dispatch) => {
  HttpWrapper('POST', `/tds/demand/`, true, data)
    .then((res, err) => {
      console.log('res', res.data);
      toast.success('Add Successfully');
      dispatch(fetchTdsDemands(companyId))
    })
    .catch((err) => {
      console.log(err);
    });
};

