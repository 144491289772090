export const getToken = () => localStorage.getItem('token') || false;

export const setToken = (token) => {
  localStorage.setItem('token', `Bearer ${token}`);
};

export const setCompanyId = (company_id) => {
  localStorage.setItem('company_id', company_id);
};

export const getCompanyId = () => localStorage.getItem('company_id') || false;

export const setCompanyData = (company_data) => {
  localStorage.setItem('company_data', JSON.stringify(company_data));
};

export const getCompanyData = () => JSON.parse(localStorage.getItem('company_data')) || false;



export const digitToMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const monthToDigit = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
};

export const dateFormat = (d) => {
  if (d != false) {
    var date = d;
    var datearray = date.split('/');

    var newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
    var monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    var t = new Date(d);
    return t.getDate() + '-' + monthNames[t.getMonth()] + '-' + t.getFullYear();
  }
};

export const dateFormatNew = (d) => {
  var date = d;
  var datearray = date.split('/');

  var newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
  var monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  var t = new Date(newdate);
  return t.getDate() + '-' + monthNames[t.getMonth()] + '-' + t.getFullYear();
};

export const mapFinalcialYearToYear = (year, month) => {
  const digit = month;
  console.log(year);
  if (digit >= 3) {
    const finalYear = parseInt(year.substring(2, 4)) + 1;
    const financeYear = year + '-' + finalYear;
    return financeYear;
  } else {
    const finalYear = year.substring(2, 4);
    const intialYear = parseInt(year) - 1;
    const financeYear = intialYear + '-' + finalYear;
    return financeYear;
  }
};

export const removeToken = () => {
  localStorage.clear();
};

export const getGSTState = (gst_num) => {
  const gst_code = {
    1: 'Jammu and Kashmir',
    2: 'Himanchal Pradesh',
    3: 'Punjab',
    4: 'Chandigarh',
    5: 'Uttarakhand',
    6: 'Haryana',
    7: 'Delhi',
    8: 'Rajasthan',
    9: 'Uttar Pradesh',
    10: 'Bihar',
    11: 'Sikkim',
    12: 'Arunachal Pradesh',
    13: 'Nagaland',
    14: 'Manipur',
    15: 'Mizoram',
    16: 'Tripura',
    17: 'Meghalaya',
    18: 'Assam',
    19: 'West Bengal',
    20: 'Jharkhand',
    21: 'Oddisaa',
    22: 'Chattisgarg',
    23: 'Madhya Pradesh',
    24: 'Gujarat',
    26: 'Dadra and Nagar Haveli & Daman and Diu',
    27: 'Maharahtra',
    29: 'Karnataka',
    30: 'Goa',
    31: 'Lakshyadeep',
    32: 'Kerala',
    33: 'Tamil Nadu',
    34: 'Puducherry',
    35: 'Andaman and Nicobar',
    36: 'Telangana',
    37: 'Andhra Pradesh',
    38: 'Ladakh'
  };
  let initial_num = gst_num.slice(0, 2);
  return gst_code[parseInt(initial_num)];
};

export const getAge = (birthDate) => {
  var from = birthDate.split('-');
  var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
  var cur = new Date();
  var diff = cur - birthdateTimeStamp;
  // This is the difference in milliseconds
  var currentAge = Math.floor(diff / 31557600000);
  return currentAge;
};

export const base = 'http://api.startupfino.in';
