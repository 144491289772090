import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import React,{useEffect,useState} from 'react';
import { Card, Stack, Link, Container, Typography, Grid,Box } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
import AuthSocial from '../sections/authentication/AuthSocial';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { appName } from 'src/config';
import logoGIF from '../gif/Welcome.gif';
// import GoogleLoginButton from '../sections/authentication/GoogleLoginButton';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));


// ----------------------------------------------------------------------

export default function Login() {
  useEffect(() => {
    // Get the referral ID from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get('refer');
  
    // Store the referral ID in local storage
    if (referralId) {
      localStorage.setItem('referralId', referralId);
    }
  }, []); 
  console.log("stoarge",localStorage.getItem('referralId'))
  return (
    <>
      <Grid container sx={{ backgroundColor: 'white' }}>
        <Grid
          item
          xs={8}
          md={8}
          lg={8}
          sx={{
            backgroundImage: `url(${logoGIF})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        ></Grid>

        <Grid item xs={4} md={4} lg={4}>
            <RootStyle title={'Login | ' + appName}>
              <Container maxWidth="md">
                <ContentStyle>
                  <Stack>
                  <LogoOnlyLayout />
                  <br></br>
                  <Box sx={{display:"flex", alignItems:"center",flexDirection:"column", mb:10}}>
                    <Typography variant="h4" gutterBottom sx={{mb:2,
                    fontSize: {
                      xs: '18px',  // Font size for extra small screens
                      sm: '22px',  // Font size for small screens
                      md: '24px',  // Font size for medium screens
                      lg: '28px',  // Font size for large screens
                      xl: '32px'   // Font size for extra large screens
                    }
                    }}>
                      Welcome To {appName}
                    </Typography>
                    <Box sx={{ml:2}}>
                    <AuthSocial/>
                    </Box>
                  </Box>
                  </Stack>
                  <LoginForm />
                  {/* <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      mt: 1
                    }}
                  >
                   Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register" underline="hover">
                Get started
              </Link>
                  </Typography> */}
                </ContentStyle>
              </Container>
            </RootStyle>
          </Grid> 
      </Grid>
    </>
  );
}
