import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import axios from 'axios';
const Feedback = () => {
  const [feedbackCall, setfeedbackCall] = useState([]);
  const [TableData, setTableData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://api.startupfino.in/email-service/feedback_email_rating/',
        {
          headers: {
            Authorization: localStorage.getItem('token')
          }
        }
      );
      setfeedbackCall(response.data);
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const data = feedbackCall?.data?.map((row) => {
      return {
        company: row.company_name,
        direct: row.name,
        Month1: row.rating?.[moment().format('MMM').toLowerCase()],
        remarksMonth1: row.remark?.[moment().format('MMM').toLowerCase()],
        Month2: row.rating?.[moment().subtract(1, 'month').format('MMM').toLowerCase()],
        remarksMonth2: row.remark?.[moment().subtract(1, 'month').format('MMM').toLowerCase()],
        Month3: row.rating?.[moment().subtract(2, 'month').format('MMM').toLowerCase()],
        remarksMonth3: row.remark?.[moment().subtract(2, 'month').format('MMM').toLowerCase()],
        Month4: row.rating?.[moment().subtract(3, 'month').format('MMM').toLowerCase()],
        remarksMonth4: row.remark?.[moment().subtract(3, 'month').format('MMM').toLowerCase()],
        Month5: row.rating?.[moment().subtract(4, 'month').format('MMM').toLowerCase()],
        remarksMonth5: row.remark?.[moment().subtract(4, 'month').format('MMM').toLowerCase()],
        Month6: row.rating?.[moment().subtract(5, 'month').format('MMM').toLowerCase()],
        remarksMonth6: row.remark?.[moment().subtract(5, 'month').format('MMM').toLowerCase()]
      };
    });

    setTableData(data);
  }, [feedbackCall]);

  const columns = [
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'direct',
      label: 'Director',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'remarksMonth1',
      label: `Remarks (${moment().format('MMMM')})`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'Month1',
      label: moment().format('MMM'),
      options: {
        filter: true,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth1');
          const remark = tableMeta.rowData[2];
          console.log('remark', tableMeta.rowData);
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'remarksMonth2',
      label: `Remarks (${moment().subtract(1, 'month').format('MMMM')})`,
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'Month2',
      label: moment().subtract(1, 'month').format('MMM'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth2');
          console.log('remarkColumn', remarkColumn);
          const remark = tableMeta.rowData[4];
          console.log('remark', tableMeta.rowData);
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'remarksMonth3',
      label: `Remarks (${moment().subtract(2, 'month').format('MMMM')})`,
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'Month3',
      label: moment().subtract(2, 'month').format('MMM'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth3');
          console.log('remarkColumn', remarkColumn);
          const remark = tableMeta.rowData[6];
          console.log('remark', tableMeta.rowData);
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'remarksMonth4',
      label: `Remarks (${moment().subtract(3, 'month').format('MMMM')})`,
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'Month4',
      label: moment().subtract(3, 'month').format('MMM'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth4');
          console.log('remarkColumn', remarkColumn);
          const remark = tableMeta.rowData[8];
          console.log('remark', tableMeta.rowData);
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'remarksMonth5',
      label: `Remarks (${moment().subtract(4, 'month').format('MMMM')})`,
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'Month5',
      label: moment().subtract(4, 'month').format('MMM'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth5');
          console.log('remarkColumn', remarkColumn);
          const remark = tableMeta.rowData[10];
          console.log('remark', tableMeta.rowData);
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'remarksMonth6',
      label: `Remarks (${moment().subtract(5, 'month').format('MMMM')})`,
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'Month6',
      label: moment().subtract(5, 'month').format('MMM'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const remarkColumn = columns.find((column) => column.name === 'remarksMonth6');
          const remark = tableMeta.rowData[12];
          return (
            <Tooltip
              title={<span style={{ fontSize: '15px', fontWeight: '100' }}>{remark}</span>}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <span>{value}</span>
            </Tooltip>
          );
        }
      }
    }
  ];

  const data = [
    {
      direct: 'Joe James',
      company: 'Test Corp',
      remarks: 'Good',
      Month1: 3,
      Month2: 4,
      Month3: 5,
      Month4: 4,
      Month5: 7,
      Month6: 9,
      remarksMonth1:
        'Well done, exceeding expectations. Your commitment is commendable, making clients happy.',
      remarksMonth2:
        'Impressive work and commitment. Keep pushing for excellence, contributing to overall success.',
      remarksMonth3:
        "Could be better, let's focus on improvement. Identify areas for growth and continuous enhancement.",
      remarksMonth5:
        'Excellent services provided. Client satisfaction is evident, and your efforts have a positive impact.',
      remarksMonth6:
        'Satisfactory performance overall. Maintain the positive momentum and continue striving for improvement.'
    },
    {
      direct: 'John Walsh',
      company: 'Test Corp',
      remarks: 'Good Services',
      Month1: 3,
      Month2: 4,
      Month3: 5,
      Month5: 7,
      Month6: 9,
      remarksMonth1: 'Well done',
      remarksMonth2: 'Impressive',
      remarksMonth3: 'Could be better',
      remarksMonth5: 'Excellent',
      remarksMonth6: 'Satisfactory'
    },
    {
      direct: 'Bob Herm',
      company: 'Company Abc',
      remarks: 'Better financial support by team',
      Month1: 3,
      Month2: 4,
      Month3: 5,
      Month5: 7,
      Month6: 9,
      remarksMonth1: 'Great progress',
      remarksMonth2: 'Solid performance',
      remarksMonth3: 'Room for improvement',
      remarksMonth5: 'Fantastic',
      remarksMonth6: 'Top-notch'
    },
    {
      direct: 'James Houston',
      company: 'Company Abc',
      remarks: 'On time compliance fillings',
      Month1: 9,
      Month2: 6,
      Month3: 5,
      Month4: 4,
      Month5: 2,
      Month6: 1,
      remarksMonth1: 'Exceptional',
      remarksMonth2: 'Good effort',
      remarksMonth3: 'Needs attention',
      remarksMonth4: 'Well done',
      remarksMonth5: 'Outstanding',
      remarksMonth6: 'Excellent'
    }
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    sortOrder: {
      name: 'Month1',
      direction: 'desc'
    },
    expandableRows: true,
    renderExpandableRow: (rowData, tableMeta) => {
      console.log('tableMeta', tableMeta);
      const colSpan = rowData.length + 1;
      return (
        <>
          <TableCell colSpan={colSpan} sx={{ borderRadius: '30px' }}>
            <Table
              sx={{
                m: 1,
                width: 550,
                '& th': {
                  fontSize: '1rem',
                  border: '1px solid #000000'
                },
                '& td': {
                  fontSize: '1rem',
                  border: '1px solid #000000'
                }
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log('feedbackCallfeedbackCall0', typeof feedbackCall.data[0])}
                <>
                  <TableRow>
                    <TableCell>{moment().format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {
                        feedbackCall.data[tableMeta.dataIndex].remark?.[
                          moment().format('MMM').toLowerCase()
                        ]?
                        feedbackCall.data[tableMeta.dataIndex].remark?.[
                          moment().format('MMM').toLowerCase()
                        ]:"N/A"

                      }
                    </TableCell>
                    <TableCell>
                      {
                        feedbackCall.data[tableMeta.dataIndex].rating?.[
                          moment().format('MMM').toLowerCase()
                        ]?
                        feedbackCall.data[tableMeta.dataIndex].rating?.[
                          moment().format('MMM').toLowerCase()
                        ]:"N/A"
                      }
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{moment().subtract(1, 'month').format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].remark?.[
                        moment().subtract(1, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].remark?.[
                            moment().subtract(1, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].rating?.[
                        moment().subtract(1, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].rating?.[
                            moment().subtract(1, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{moment().subtract(2, 'month').format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].remark?.[
                        moment().subtract(2, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].remark?.[
                            moment().subtract(2, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].rating?.[
                        moment().subtract(2, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].rating?.[
                            moment().subtract(2, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{moment().subtract(3, 'month').format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].remark?.[
                        moment().subtract(3, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].remark?.[
                            moment().subtract(3, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].rating?.[
                        moment().subtract(3, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].rating?.[
                            moment().subtract(3, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{moment().subtract(4, 'month').format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].remark?.[
                        moment().subtract(4, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].remark?.[
                            moment().subtract(4, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].rating?.[
                        moment().subtract(4, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].rating?.[
                            moment().subtract(4, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{moment().subtract(5, 'month').format('MMMM-YY')}</TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].remark?.[
                        moment().subtract(5, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].remark?.[
                            moment().subtract(5, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {feedbackCall.data[tableMeta.dataIndex].rating?.[
                        moment().subtract(5, 'month').format('MMM').toLowerCase()
                      ]
                        ? feedbackCall.data[tableMeta.dataIndex].rating?.[
                            moment().subtract(5, 'month').format('MMM').toLowerCase()
                          ]
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableCell>
        </>
      );
    }
  };

  return (
    <>
      <MUIDataTable title={'Feedback'} data={TableData} columns={columns} options={options} />
    </>
  );
};

export default Feedback;
