import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Typography, Chip } from '@mui/material';
import { Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSearchParams } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import Label from 'src/components/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MUIDataTable from 'mui-datatables';
import {
  allfillstat_patch,
  get_due_dates_by_period,
  get_due_dates_by_period_2,
  get_all_due_dates_company_id
} from 'src/redux/actions/fillstatAction';
import { CurrencyYenTwoTone, RawOff } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import moment, { months } from 'moment';
import { chunk } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, Box } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import Link from '@mui/material/Link';
import { getAllCompanies } from 'src/redux/actions/dataoperator';
import {
  add_Task,
  task_all_get,
  team_Mates_get,
  task_Names_get,
  deletetask,
  updatetask,
  client_mail_list
} from 'src/redux/actions/task_creation_Action';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import { red } from '@mui/material/colors';
import { Duedate } from 'src/components/task_creations/DueDate';
import { cmp_list_data, send_email } from 'src/redux/actions/cmp_list_new';

export default function Cmplist() {
  const task_Data = useSelector((state) => state.taskreducers);
  const teams = useSelector((state) => state.team_mates_reducers);
  const filling_data = useSelector((state) => state.allFillStatreducers);
  const all_companies = useSelector((state) => state.allCompaniesreducers);
  const task_name = useSelector((state) => state.team_name_reducers);

  const [cmpname, setcmpname] = useState(null);
  const [flag, setflag] = useState(null);
  const [flag2, setflag2] = useState(null);
  const [period, setperiod] = useState(null);
  const [tasks_data, settasks_data] = useState([]);
  const [teamates_data, setteamates_data] = useState([]);
  const [all_cmpmonth, setall_cmpmonth] = useState([]);
  const [task_names, setTask_names] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(task_all_get());
    dispatch(team_Mates_get());
    dispatch(task_Names_get());
    dispatch(client_mail_list());
  }, []);

  const all_cmp_month = useSelector((state) => state.allcmpmonthreducers.all_comp);
  useEffect(() => {
    settasks_data(task_Data.task_management);
  }, [task_Data]);
  useEffect(() => {
    setteamates_data(teams.team_mate);
  }, [teams]);
  useEffect(() => {
    setTask_names(task_name.task_name);
  }, [task_name]);
  useEffect(() => {
    setall_cmpmonth(all_cmp_month);
  }, [all_cmp_month]);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const [extra, setExtra] = useState(null);
  const [tabdata, setTabdata] = useState([]);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [selectedID, setID] = useState('');
  const [value, setValue] = useState(new Date());
  const [opt_value, setOpt_value] = useState(' ');
  const [open, setOpen] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [counter, setCounter] = useState(0);
  var companies_id;
  let data_total = filling_data.comp_fillstat.length;
  
    const client_emails_list = all_cmp_month?.email_list?.map((item) => item.email);
    const [clientMail2, setClientMail2] = useState(client_emails_list);
    const [clientMail, setClientMail] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    //let a = 0;
    const now = new Date();
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('YYYY-MM-DD');
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format('YYYY-MM-DD');
    dispatch(get_due_dates_by_period(lastDay, firstDay));
    dispatch(getAllCompanies());
    setTabdata(filling_data);
  }, []);

  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);

  const handleCheckbox = (e, id) => {
    setOpen(true);
    setID(id);
  };
  const columns = [
    {
      name: 'name',
      label: 'Compliance Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bill_info',
      label: 'Periods',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'due_date',
      label: 'Due Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Typography>{moment(data).format('DD-MM-YYYY')}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'date_task',
      label: 'Filing Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Typography>{moment(data).format('DD-MM-YYYY')}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'task_own',
      label: 'No of Delay Days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'attachment_file',
      label: 'Attachment',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          if (data !== '') {
            return (
              <>
                <Link href={data} target="_blank">Open File</Link>
              </>
            );   
          }else{
            return (
              <Typography>No File</Typography>
            )
          }
        }
      }
    },
  ];
  useEffect(() => {
    let i = 0;
    const data = all_cmpmonth?.due_dates_list?.map((row) => {
      return {
        name: row.compliance_name,
        task_own: row.delay_days>0?row.delay_days:0,
        date_task: row.date_of_filling,
        due_date: row.current_due_date,
        bill_info: row.period,
        attachment_file: row.attachment_file
      };
    });
    setDatas(data);
  }, [all_cmpmonth]);
  const [scroll, setScroll] = React.useState('paper');
  const changePage = (page) => {
    isLoading(true);
    xhrRequest(page).then((res) => {
      isLoading(false), page(page), datas(res.data), count(res.total);
    });
  };
  const options = {
    filterType: 'multiselect',
    selectableRows: false,
    rowsPerPageOptions: [1000, 1500, 2000],
    rowsPerPage: 1000,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: window.screen.availHeight
  };
  const opts = all_companies.all_companies.map((option) => option.company_name);
  var mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const [formData, setFormData] = useState({
    company_id: '',
    period: '',
    year: ''
  });
  const onSubmit = (e) => {
    e.preventDefault();
    let compID = all_companies.all_companies.filter((x) => x.company_name.includes(cmpname))[0][
      'id'
    ];
    console.log('compID', compID);
    console.log('period', period);
    let month = mL[moment(period).months()];
    console.log('month', month);
    console.log('year', moment(period).years());
    let client_type = 'test';
    let year = moment(period).years().toString();
    setflag(true);
    formData.company_id = compID;
    formData.year = year;
    formData.period = month;

    dispatch(cmp_list_data(client_type, compID, month, year));
    // console.log('all_cmpmonth', a);
  };
  let month = mL[moment(period).months()];
  console.log('all_cmpmonth', all_cmpmonth);
 
  const dis = (e) => {
    formData["email-list"] = clientMail[0];
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
          bodyFormData.append(key, formData[key]);
        }
      }
      console.log("bodyFormData",bodyFormData);
      console.log("formData",formData);
      dispatch(send_email(formData));
      setOpen(false);
    // dispatch(updatetask(selected_task.sr_id, bodyFormData));
    // handleClose();
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {/* <h4>Total Number of task = {dummy_task_data.length}</h4> */}
          <Autocomplete
            value={cmpname}
            onChange={(event, newValue) => {
              setcmpname(newValue);
            }}
            id="controllable-states-demo"
            options={opts}
            sx={{ margin: 1 }}
            renderInput={(params) => <TextField {...params} label="Company List" required />}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              //   openTo='month'
              views={['year', 'month']}
              label="Compliance Period"
              value={period}
              inputFormat="MM/yyyy"
              onChange={(newValue) => {
                setperiod(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ margin: 1 }} required />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sx={{ my: 2 }}>
          <Button
            onClick={onSubmit}
            variant="contained"
            // sx={{ mr: 2 }}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={2} sx={{ my: 2 }}>
          {flag && (
            <Button
              onClick={handleClickOpen('paper')}
              variant="contained"
              // sx={{ mr: 2 }}
            >
              Send Mail
            </Button>
          )}
        </Grid>
      </Grid>
      {flag && (
        <MUIDataTable
          title={'Monthly Complaince List'}
          data={datas}
          columns={columns}
          options={options}
        />
      )}
      <Dialog
        open={open}
        // maxWidth='md'
        fullWidth
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Send Mail
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: '#ff5252' }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Box
              sx={{ margin: 1, justifyContent: 'center', flexDirection: 'column' }}
              display="flex"
            >
              <Grid container spacing={2}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  {month && <Typography variant='h5' >Compliance Period : {month}</Typography>}
                </Grid>
                <Grid item xs={3} />
              </Grid>
              <br /><br />
              <Grid container spacing={2}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    name="client_mails"
                    fullWidth
                    freeSolo
                    // value={clientMail}
                    options={client_emails_list}
                    onChange={(event, newValue) => {
                      setClientMail( [newValue]);
                      // setClientMail2([...clientMail2, newValue]);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Client Mail" placeholder="Enter mail id" />
                    )}
                  />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Box>
            <Box
              container
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ margin: 1 }}
            >
              <Button
                // onClick={onSubmit}
                onClick={dis} 
                variant="contained"
                // sx={{ mr: 2 }}
              >
                Submit
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
