import moment, { months } from 'moment';
import { Typography, Chip } from '@mui/material';
export const Duedate = (props) => {
  const { data } = props;
  // console.log('DATA DATE', data);
  let datemy = moment().format('YYYY');
  let ordatey = moment().format('YYYY');
  let datemm = moment().format('MM');
  let ordatem = moment(data).format('MM');
  let datem = moment().format('DD');
  let ordate = moment(data).format('DD');
  let maindiff = new Date().getTime() - new Date(data).getTime() - 86400000
  // console.log('Difference in date====>>', maindiff);
  return (
    <>
      {maindiff > 0 && (
        <Typography sx={{ color: 'red' }}>{moment(data).format('DD-MM-YYYY')}</Typography>
      )}
      {maindiff <= 0 && <Typography>{moment(data).format('DD-MM-YYYY')}</Typography>}
    </>
  );
};
