// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmProvider } from 'material-ui-confirm';

// ----------------------------------------------------------------------
import './App.css';

export default function App() {
  return (
    <ThemeConfig>
      <ConfirmProvider>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <ToastContainer />
        <Router />
      </ConfirmProvider>
    </ThemeConfig>
  );
}
