import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Invoice_Tabs from '../Tabs/Invoice_Tabs';

export default function Accesscode() {
  const [password, setpassword] = useState('password');
  const [user_acess, setUser_Acess] = useState();

  return (
    <>
      {password === user_acess && <Invoice_Tabs />}
      <Dialog
        open={password === user_acess ? false : true}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Access Code Needed</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField
              value={user_acess}
              type="password"
              name="task_description"
              onChange={(e) => {
                setUser_Acess(e.target.value);
              }}
              label="Enter Password"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
