import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchMiscDisclosureInfo } from '../../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData(), createData(), createData(), createData(), createData()];

export default function Misc() {
  const rocState = useSelector((state) => state.rocReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMiscDisclosureInfo());
  }, []);
  if ((rocState.miscDisclosureInfo = !null)) {
    return <></>;
  }
  const data = rocState.miscDisclosureInfo[0];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            <TableCell align="center">2017 - 2018</TableCell>
            <TableCell align="center">2018 - 2019</TableCell>
            <TableCell align="center"> 2019 - 2020</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Earning in Foreign Exchange
            </TableCell>
            <TableCell align="center">25,508.13</TableCell>
            <TableCell align="center"> 34,073.22 </TableCell>
            <TableCell align="center">39,036.56</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
