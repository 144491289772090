import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, IconButton } from '@mui/material';
import { invoice_manage } from 'src/redux/actions/invoice_manage_action';
import { useDispatch,useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import moment from 'moment';
import Invoice_Update from './Actions/invoice_update';
import Invoice_Delete from './Actions/invoice_delete';
import Invoice_View from './Actions/invoice_view';
import Bill_Add from './Add Bill/Bill_Add';
import { Grid,Typography } from '@mui/material';

export default function Bill_table() {
  const dispatch = useDispatch();
  const invoice_Data = useSelector((state) => state.invoice_reducers);
console.log("invoice_Data",invoice_Data)

  const [datas, setDatas] = useState([]);
  const[api_data,setapi_data]=useState()
  const [add, setAdd] = useState(false);
  const handleClickOpen=()=>{
    setAdd(true)
  }
  const handleClickClose=()=>{
    setAdd(false)
  }
  useEffect(() => {
    dispatch(invoice_manage());
  }, []);
  useEffect(() => {
    setapi_data(invoice_Data.invoice_manage.filter((item)=>item.bill_type==='Bill of Supply'));
  }, [invoice_Data]);

  console.log("api value",api_data)

//Summ Part
let invoice_amount_sum = 0;
let invoice_payment_sum = 0;
let invoice_outstanding_sum = 0;
let total_amount = api_data?.map((item, index) => item.invoice_amoint);
total_amount?.forEach((value) => {
  invoice_amount_sum += Number(value);
});
let total_payment = api_data?.map((item, index) => item.payment_received);
total_payment?.forEach((value) => {
  invoice_payment_sum += Number(value);
});
let total_outstanding = api_data?.map((item, index) => item.outstanding);
total_outstanding?.forEach((value) => {
  invoice_outstanding_sum += Number(value);
});

  const columns = [
    {
      name: 'act',
      label: `Action`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box textAlign="center" display='flex'>
                <Update row={data} />
                <Delete row={data} />
                <View row={data} />
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: {
        //     whiteSpace: 'nowrap',
        //     position: 'sticky',
        //     left: '0',
        //     background: 'white',
        //     zIndex: 100
        //   }
        // }),
        // setCellHeaderProps: () => ({
        //   style: {
        //     whiteSpace: 'nowrap',
        //     position: 'sticky',
        //     left: 0,
        //     background: 'white',
        //     zIndex: 101
        //   }
        // })
      }
    },
    {
      name: 'req_Date',
      label: `Request Date`,
      align: 'center',
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'req_by',
      label: `Request By`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'gst_num',
      label: `GST Number `,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'bill_entity',
      label: `Biling Entity`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'bill_type',
      label: `Biling Type`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'desc',
      label: `Invoice Description`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    // { INTEREST P A I D }
    {
      name: 'in_amt',
      label: `Invoice Amount`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'in_num',
      label: `Invoice Number`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'in_Date',
      label: `Invoice Date`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'pay_stat',
      label: `Payment Status`,
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'tds_deduct',
      label: `TDS Deducted	`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: 'rcvd',
      label: `Payment Received`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'out',
      label: `Outstanding`,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'rem',
      label: `Remark`,
      options: {
        filter: true,
        sort: true,
        display: false
      }
    }
  ];
  useEffect(()=>{
    console.log("om",api_data)
  let  len_description=[]

    const desc_map=api_data?.map((row,index)=>row.invoice_description)
    console.log("desc_map",desc_map)
    const hey_rush =desc_map?.map((row,index)=>row)
    console.log("hey_rush",hey_rush)
    
  const map_data= api_data?.map((row,index)=>{

    console.log("Rows",row)
    return{
      act:row.id,
      name:row.company_name,
      req_Date:row.requested_date==null?'':moment(row.requested_date).format('DD-MM-YYYY'),
      req_by:row.request_by,
      gst_num:row.gst_number,
      bill_entity:row.billing_entity,
      bill_type:row.bill_type,
      desc:row.invoice_description.map((item) => item.description).join(', '),
      in_amt:row.invoice_amoint,
      in_num:row.invoice_number,
      in_Date:row.invoice_date==null?'':moment(row.invoice_date).format('DD-MM-YYYY'),
      pay_stat:row.payment_status,
      tds_deduct:row.tds_deducted,
      rcvd:row.payment_received,
      out:row.outstanding,
      rem:row.remark
    }
  }) 
  setDatas(map_data)
  },[api_data])

  const data = [
    ['Joe James', 'Test Corp', 'Yonkers', 'NY'],
    ['John Walsh', 'Test Corp', 'Hartford', 'CT'],
    ['Bob Herm', 'Test Corp', 'Tampa', 'FL'],
    ['James Houston', 'Test Corp', 'Dallas', 'TX']
  ];

  const options = {
    selectableRows: false,
    rowsPerPageOptions: [10,100,1000, 1500, 2000],
    rowsPerPage: 10,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: { background: row[9] !== null ? '#80808052' : ''},
      };
    }
  };

  console.log("Datass",datas)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          container
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="right"
          sx={{ margin: 1 }}
        >
          <Button onClick={handleClickOpen} variant="contained" 
          >
            Add Invoice
          </Button>
          <Dialog
            open={add}
            // scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <Box
                component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width='100%'
              >
                Add Data
                <CloseRoundedIcon
                  onClick={handleClickClose}
                  style={{ color: '#ff5252' }}
                  boxShadow={1}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Bill_Add add={add} handleClickClose={handleClickClose}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Grid container spacing={1} padding={2}>
          <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Typography> Bill of Supply | Total Invoice Amount : ₹ {invoice_amount_sum} | Payment Received : ₹ {invoice_payment_sum} | Outstanding : ₹ {invoice_outstanding_sum} </Typography>
            </Grid>
          </Grid>

 
      <MUIDataTable title={'Billing'} data={datas} columns={columns} options={options} />
    </>
  );
}

const Update = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete" color="primary">
        <ModeEditIcon />
      </IconButton>
      <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={'md'}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          component="span"
          m={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Edit Data
          <CloseRoundedIcon
            onClick={() => setOpen(false)}
            style={{ color: '#ff5252' }}
            boxShadow={1}
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Invoice_Update row={row} handleClickClose={handleClose} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
    </>
  );
};
const Delete = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <IconButton onClick={handleClickOpen('paper')} aria-label="delete"  color="error">
        <DeleteIcon />
      </IconButton>
      <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={'md'}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          component="span"
          m={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Delete Invoice
          <CloseRoundedIcon
            onClick={() => setOpen(false)}
            style={{ color: '#ff5252' }}
            boxShadow={1}
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          < Invoice_Delete row={row} handleClickClose={handleClose} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
    </>
  );
};
const View = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen('paper')}>
      <MarkEmailUnreadOutlinedIcon />
      </IconButton>
      <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={'md'}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          component="span"
          m={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Invoice Data
          <CloseRoundedIcon
            onClick={() => setOpen(false)}
            style={{ color: '#ff5252' }}
            boxShadow={1}
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Invoice_View row={row} handleClickClose={handleClose} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
    </>
  );
};