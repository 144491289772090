import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDueDates, getFilterDueDate, getOverDueDates } from 'src/redux/actions/dueDates';
import { useSearchParams } from 'react-router-dom';
import { DueDatesMobile } from './DueDatesMobileView';
import { DueDatesLaptopView } from './DueDatesLaptopView';
import { MosqueSharp } from '@mui/icons-material';
import { getStatus } from '../sections/@dashboard/app/dueDatesTables/AllDueDates';


export const DueDatesNotification = () => {
  const [dueDates, setdueDates] = useState([]);
  const dueDatesState = useSelector((state) => state.dueDatesReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid') || localStorage.getItem('company_id');
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const [doFilter, setFilter] = useState(false)

  const dispatch = useDispatch();
  useEffect(() => {
    if(startDate !==null && endDate !== null){
      setFilter(false)
      dispatch(getFilterDueDate(companyId, startDate, endDate));
    }else{
      setFilter(true)
      dispatch(getOverDueDates(companyId))
    }
  }, [startDate, endDate, companyId]);

  useEffect(() => {
    if(startDate !==null && endDate !== null){
      setdueDates(
        dueDatesState?.allDueDates?.sort(function (a, b) {
          return new Date(a.current_due_date) - new Date(b.current_due_date);
        })
      );
    }else{
      setdueDates(
        dueDatesState?.overDueDates?.sort(function (a, b) {
          return new Date(a.current_due_date) - new Date(b.current_due_date);
        })
      );
    }
    
  }, [dueDatesState.allDueDates, dueDatesState.overDueDates]);


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const mql = window.matchMedia('(max-width: 768px)');

  // For subsequent renders if screen size changes
  mql.addEventListener('change', (e) => {
    let mobileView = e.matches;
    if (mobileView) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  if (isMobile) {
    return (
      <>
        <DueDatesMobile dueDates={dueDates} filter={doFilter} />
      </>
    );
  } else {
    return (
      <>
        <DueDatesLaptopView dueDates={dueDates} filter={doFilter} />
      </>
    );
  }
};
