import React, { useState, useEffect } from 'react';
import { Card, CardHeader } from '@mui/material';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
  Box,
  Typography
} from '@mui/material';
import Tableone from './Incometax/Tableone';
import TdsDeducted from './Incometax/TdsDeducted';
import TaxPayment from './Incometax/TaxPayment';
import FormsFilled from './Incometax/FormsFilled';
import BasicChart from './BasicChart';
import Ib4 from './Incometax/Ib4';
import {
  fetchDemand,
  fetchFormsFilled,
  fetchTdsDeducted,
  fetchTaxPayment,
  fetchIncomeDueDates
} from 'src/redux/actions/incometax';
import { mapFinalcialYearToYear } from 'src/utils/common';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import Incometaxbook from './incometaxbasic';
import { isStartupFino } from 'src/config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const years = ['2020-21', '2021-22', '2022-23', '2023-24'];
export const IncomeTaxDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyid');
  const [year, setYear] = useState(
    mapFinalcialYearToYear(String(new Date().getFullYear()), new Date().getMonth())
  );

  const dashBoardScore = useSelector((state) => state.dashBoardReducer.score);
  let score_data = dashBoardScore?.[year];
  console.log('dashBoardScore', dashBoardScore?.[year], year);
  const itcount = score_data !== null ? score_data?.incometax_data.count : 0;
  const itfilled = score_data !== null ? score_data?.incometax_data.ontime : 0;
  // const itcount = dashBoardScore !== null ? dashBoardScore?.[year]?.incometax_data.count : 0;
  // const itfilled = dashBoardScore !== null ? dashBoardScore?.[year]?.incometax_data.ontime : 0;
  // const incometax_score =
  //   parseInt(
  //     (dashBoardScore?.incometax_data?.count - dashBoardScore?.incometax_data?.delay) /
  //       dashBoardScore?.incometax_data?.count
  //   ) * 100 || 0;
  const incometax_score =
    score_data?.incometax_data?.count == 0
      ? 100
      : parseInt((score_data?.incometax_data?.ontime / score_data?.incometax_data?.count) * 100) ||
        0;
  const [modalOpen, setmodalOpen] = useState(true);
  const handleModalClose = () => {};

  const handleModalSubmit = () => {
    setmodalOpen(false);
  };

  const handleChange = (event) => {
    setYear(event.target.value);
    setmodalOpen(true);
  };
  const orgState = useSelector((state) => state.orgProfileReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDemand(companyId));
    dispatch(fetchTdsDeducted(companyId));
    dispatch(fetchFormsFilled(companyId));
    dispatch(fetchTaxPayment(companyId));
    dispatch(fetchIncomeDueDates());
  }, []);

  return (
    <>
     {!isStartupFino && <Incometaxbook/>}
      <Card>
        <CardHeader title="Income Tax Dashboard" />
        <div>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Financial Year</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              // multiple
              value={year}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Financial Year" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>
              )}
              MenuProps={MenuProps}
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <BasicChart title="IT" score={incometax_score} />
            <Typography textAlign="center" mt={-2}>
              {/* {itfilled}/{itcount} */}
              {score_data?.incometax_data?.ontime} / {score_data?.incometax_data?.count}
              <br />
              Compliances cleared
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <br></br>
      <Card>
        <CardHeader title="TDS Deducted" />
        <TdsDeducted year={year} />
      </Card>
      <br></br>
      <Card>
        <CardHeader title="Tax Payment" />
        <TaxPayment year={year} />
      </Card>
      <br></br>
      <Card>
        <CardHeader title="Forms Filed" />
        <FormsFilled year={year} />
      </Card>
      <br></br>
      <Card>
        <CardHeader title="Details of Outstanding Demand" />
        <Ib4 year={year} />
      </Card>
    </>
  );
};
