import Constants from '../../utils/constants';

const intialState = {
  companyId: '',
  companyData: [],
  isNew: null,
  error:''
};

export const orgProfileReducer = (state = intialState, action) => {
  switch (action.type) {
    case Constants.SET_COMPANY_ID:
      return { ...state, companyId: action.payload };
    case Constants.SET_COMPANT_DATA:
      return { ...state, companyData: action.payload };
    case Constants.SET_NEW_COMPANY:
      return { ...state, isNew: action.payload}
    default:
      return state;
  }
};
