import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchScheduleBreakupInfo } from '../../../../../redux/actions/roc';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export default function SchedulesBreakup() {
  const rocState = useSelector((state) => state.rocReducer);
  const [data, setdata] = useState(null);
  useEffect(() => {
    if (rocState.rocData == null || rocState.rocData.length == 0) {
      setdata(null);
    } else {
      setdata(
        rocState.rocData[0].roc_data.InstaAPIReport.Reportdata.InstaCombo.InstaDetailed
          .BusinessActivitiesProductsServices.PrincipalProductAndService
      );
    }
  }, [rocState.rocData]);
  if (data == null) {
    return <></>;
  }
  delete data['Currency'];
  delete data['SectionHeader'];
  const yearToDataMap = data;
  const years = Object.keys(yearToDataMap);

  const fields = {
    CategoryDescription: 'Category Description',
    CategoryITC4DigitCode: 'Category ITC 4 DigitCode',
    CategoryTurnover: 'Category Turnover',
    ProductOrServiceDescription: 'Product Or Service Description',
    ProductOrServiceHighestTurnoverContributingITC8DigitCode:
      'Product Or Service Highest Turnover Contributing ITC 8 Digit Code',
    ProductOrServiceTurnoverOfHighestContributing:
      'Product Or Service Turnover Of Highest Contributing'
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },

              '& th': {
                color: 'green',
                fontWeight: '800'
              }
            }}
          >
            <TableCell>Element Name</TableCell>
            {years.map((row, index) => {
              return <TableCell key={index}>{row}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fields).map((key, index1) => (
            <TableRow key={index1}>
              <TableCell>{fields[key]}</TableCell>
              {years.map((year, index2) => {
                return yearToDataMap[year]['ProductService'][0][key] != null ? (
                  <TableCell key={index2}>
                    {yearToDataMap[year]['ProductService'][0][key]}
                  </TableCell>
                ) : (
                  <TableCell key={index2}>NA</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
